// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import React, { FC, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import {
    actionLastUnreadArrayNotificationsRequest,
    actionUnreadNotificationRequest,
} from "@magoot-sdk/redux-modules/actions/notifications/actionNotificationRead";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import { selectorNotificationUnreadCounter } from "@magoot-sdk/redux-modules/selectors/selectorNotifications";
import { selectorSubscriptionCurrentIsLoading } from "@magoot-sdk/redux-modules/selectors/selectorsSubscription";
import { Path } from "@magoot-sdk/utils/Routes";

import { UIIcon } from "@components-ui/Icon";

// #endregion

interface Prop {
    onToggleDropdown: () => void;
}

export const NotificationNavbar: FC<Prop> = memo(({ onToggleDropdown }): JSX.Element | null => {
    const { idStudio } = useStudioID();
    const dispatch = useDispatch();
    const location = useLocation();

    const notificationUnreadCounter = useSelector((store: TStore) =>
        selectorNotificationUnreadCounter({ store, idStudio })
    );
    const currentSubscriptionIsLoading = useSelector((store: TStore) =>
        selectorSubscriptionCurrentIsLoading({ store, idStudio })
    );

    const goNotification = (): void => {
        if (!currentSubscriptionIsLoading) onToggleDropdown();
    };

    useEffect(() => {
        if (idStudio) dispatch(actionUnreadNotificationRequest({ idStudio }));
        if (idStudio) dispatch(actionLastUnreadArrayNotificationsRequest({ idStudio }));
    }, [dispatch, idStudio]);

    return (
        <>
            {location.pathname !== Path.viewNotFound ? (
                <div
                    className='dropdown-hover dropdown indicator cursor-pointer'
                    onClick={goNotification}
                >
                    {notificationUnreadCounter && !currentSubscriptionIsLoading && (
                        <div className='badge indicator-item badge-warning'>
                            {notificationUnreadCounter}
                        </div>
                    )}

                    <div
                        tabIndex={0}
                        className='relative flex h-32 w-32 scale-100 items-center justify-center'
                    >
                        <UIIcon
                            size='lg'
                            svgIcon={faBell as IconProp}
                            color='#fff'
                            title='visualizza notifiche'
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
});

NotificationNavbar.displayName = "NotificationNavbar";
