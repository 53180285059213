interface Translations {
    "view.address.book": string;
    "view.address.book.delete": string;
}

interface ViewAddressBook {
    it: Translations;
    en: Translations;
}

export const translationsViewAddressBook: ViewAddressBook = {
    it: {
        "view.address.book": "Rubrica",
        "view.address.book.delete": "Elimina file da",
    },
    en: {
        "view.address.book": "Address Book",
        "view.address.book.delete": "Elimina file da",
    },
};

export type TTranslationsViewAddressBook = keyof Translations;
