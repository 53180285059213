// #region ::: IMPORT
import React, { Dispatch, FC, SetStateAction, memo } from "react";

import { translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { UISwitch } from "@components-ui/Switch";

// #endregion

interface Props {
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
}

export const WorkMunicipalPractices: FC<Props> = memo(({ isChecked, setIsChecked }) => {
    const locale = useLocale();

    const handleCheckbox = (): void => setIsChecked(!isChecked);
    const title = translate({ locale, id: "view-order.work.municipal.practices.title" });

    return (
        <div className='flex items-start space-x-20'>
            <div className='flex items-center space-x-20 '>
                <UISwitch isChecked={isChecked} onChange={handleCheckbox} />
            </div>

            <div className='text-left flex flex-col'>
                <p className='font-medium mb-2'>{title}</p>
            </div>
        </div>
    );
});

WorkMunicipalPractices.displayName = "WorkMunicipalPractices";
