interface Translations {
    "view.not-found.title": string;
    "view.not-found.description": string;
}

interface ViewNotFound {
    it: Translations;
    en: Translations;
}

export const translationsViewNotFound: ViewNotFound = {
    it: {
        "view.not-found.title": "Spiacenti, questa pagina non è disponibile!",
        "view.not-found.description":
            "È possibile che il link che hai seguito sia corrotto o che la pagina sia stata rimossa.",
    },
    en: {
        "view.not-found.title": "Page not found :(",
        "view.not-found.description": "Page doesn't exists or may be deleted.",
    },
};

export type TTranslationsViewNotFound = keyof Translations;
