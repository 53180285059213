import { Actions } from "../../utils/Actions";
import { TReducerUsers, TReducerUsersActions } from "./declarations";

export const reducerUsers = (
    prevStore: TReducerUsers = {},
    action: TReducerUsersActions
): TReducerUsers => {
    const newStore = { ...prevStore };
    switch (action.type) {
        case Actions.fetchUserListSuccess:
            return action.payload;
        case Actions.fetchuserListUpdate:
            return action.payload;
        case Actions.userDisconnectSuccess:
            delete newStore[action.payload];
            return newStore;
        case Actions.invitationSendSuccess:
            newStore[action.payload.id] = action.payload;
            return newStore;
        case Actions.userDisconnectRequest:
            newStore[action.payload.idUser].loading = true;
            return newStore;
        case Actions.studioTeamRoleUpdateSuccess:
            newStore[action.payload.idUser].ruolo = action.payload.idRole;
            return newStore;
        case Actions.studioTeamCostUpdateSuccess:
            action.payload.costs.forEach((user) => {
                newStore[user.id].costoOrario = user.costoOrario;
            });
            return newStore;
        case Actions.sessionExpired:
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
