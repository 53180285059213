interface Translations {
    "form.signup.password": string;
    "form.signup.repeat-password-label": string;
    "form.signup.repeat-password": string;
    "form.signup.link-to-login": string;
    "form.signup.password-advice": string;
    "form.signup.error-password-match": string;
}

interface FormSignup {
    it: Translations;
    en: Translations;
}

export const translationsFormSignup: FormSignup = {
    it: {
        "form.signup.password": "Almeno 8 caratteri tra lettere, numeri e simboli",
        "form.signup.repeat-password-label": "Conferma password",
        "form.signup.repeat-password": "Ripeti password",
        "form.signup.link-to-login": "Ho già un account Magoot",
        "form.signup.password-advice":
            "La Password deve essere lunga almeno 8 caratteri e contentere una lettere maiuscola, una minuscola,un simbolo e un numero",
        "form.signup.error-password-match": "Le password non coincidono",
    },
    en: {
        "form.signup.password": "Create Password (at least 6 characters)",
        "form.signup.repeat-password-label": "Confirm password",
        "form.signup.repeat-password": "Repeat password",
        "form.signup.link-to-login": "I have already an account",
        "form.signup.password-advice":
            "The Password must be at least 8 characters long and contain one upper case, one lower case letter, one symbol and one number",
        "form.signup.error-password-match": "Passwords don't match",
    },
};

export type TTranslationsFormSignup = keyof Translations;
