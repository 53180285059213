interface Translations {
    "view.inspection-list.create": string;
    "view.inspection-list.record": string;
    "view.inspection-list.not-found": string;
}

interface ViewInspection {
    it: Translations;
    en: Translations;
}

export const translationsViewInspectionList: ViewInspection = {
    it: {
        "view.inspection-list.create": "Crea",
        "view.inspection-list.record": "Registra sopralluogo",
        "view.inspection-list.not-found": "Nessun sopralluogo presente",
    },
    en: {
        "view.inspection-list.create": "Create",
        "view.inspection-list.record": "Record inspection",
        "view.inspection-list.not-found": "No inspection found",
    },
};

export type TTranslationsViewInspectionList = keyof Translations;
