import { useSelector } from "react-redux";

import { OrderStatus } from "../../../constants/general";
import { TStore } from "../../reducers/declarations";
import { useOrderID } from "../useOrderID";

// #region ::: SELECTORS
export const selectorOrderStatus = (store: TStore, idOrder: number): OrderStatus => {
    if (!store.orders) return OrderStatus.DaFare;
    if (!store.orders[idOrder]) return OrderStatus.DaFare;
    if (!store.orders[idOrder].statoEsecuzione) return OrderStatus.DaFare;
    if (!store.orders[idOrder].statoEsecuzione?.id) return OrderStatus.DaFare;

    return store.orders[idOrder].statoEsecuzione.id;
};
// #endregion

export const useOrderPermission = (): {
    hasValidPermission: boolean;
    isVisibleAlert: boolean;
} => {
    const { idOrder } = useOrderID();

    const orderStatus = useSelector((store: TStore) => selectorOrderStatus(store, idOrder));
    const hasValidPermission =
        orderStatus !== OrderStatus.Completata && orderStatus !== OrderStatus.Annullata;
    const isVisibleAlert = !hasValidPermission && !Number.isNaN(idOrder);

    return {
        hasValidPermission,
        isVisibleAlert,
    };
};
