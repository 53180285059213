import { useCallback } from "react";
import { useHistory } from "react-router";

type TReturn = (linkTo: string) => void;

export const useNavigation = (): TReturn => {
    const history = useHistory();

    return useCallback((linkTo: string): void => history.push(linkTo), [history]);
};
