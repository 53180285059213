import { Actions } from "../../utils/Actions";
import { TReducerSignedFile, TReducerSignedFileActions } from "./declarations";

export const reducerSignedFiles = (
    prevStore: TReducerSignedFile = {},
    action: TReducerSignedFileActions
): TReducerSignedFile => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.fileSignedInfoSuccess:
            newStore[action.payload.params.filePayload.idFile] = action.payload.result;
            return newStore;

        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
