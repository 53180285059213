import { Actions } from "../../utils/Actions";
import { TReducerInspections, TReducerInspectionsActions } from "./declarations";

export const reducerInspections = (
    prevStore: TReducerInspections = { list: {}, loading: false },
    action: TReducerInspectionsActions
): TReducerInspections => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.inspectionClearLoading:
            newStore.loading = false;
            return newStore;
        case Actions.inspectionListRequest:
            // delete newStore.current;
            return newStore;
        case Actions.inspectionListSuccess:
            newStore.list[action.payload.idOrder] = {
                ...newStore.list[action.payload.idOrder],
                ...action.payload.inspections,
            };
            return newStore;
        case Actions.inspectionDeleteLoading:
            newStore.loadingDelete = action.payload.isLoading;
            return newStore;
        case Actions.inspectionCreateRequest:
            newStore.loading = true;
            return newStore;
        case Actions.inspectionEditSuccess:
            newStore.loading = false;
            newStore.list[action.payload.idCommessa] = {
                ...newStore.list[action.payload.idCommessa],
                [action.payload.id]: action.payload,
            };
            return newStore;
        case Actions.inspectionCreateSuccess:
            if (action.payload.foundUploads === 0) {
                newStore.loading = false;
            }
            newStore.list[action.payload.idCommessa] = {
                ...newStore.list[action.payload.idCommessa],
                [action.payload.id]: action.payload,
            };
            return newStore;
        case Actions.inspectionListRemarksSuccess:
            newStore.loading = false;
            if (newStore.current) {
                newStore.current = {
                    ...newStore.current,
                    osservazioni: action.payload.inspection.osservazioni,
                };
            }
            return newStore;
        case Actions.inspectionDeleteSuccess:
            delete newStore.list[action.payload.idOrder][action.payload.idInspection];
            delete newStore.current;
            return newStore;
        case Actions.inspectionDetailRequest:
            delete newStore.current;
            newStore.loading = true;
            return newStore;
        case Actions.inspectionDetailSuccess:
            newStore.current = action.payload.inspection;
            newStore.loading = false;
            return newStore;
        case Actions.inspectionDetailFailure:
        case Actions.inspectionEditFailure:
        case Actions.inspectionCreateFailure:
            delete newStore.current;
            newStore.loading = false;
            return newStore;

        case Actions.documentUploadInspectionRequest:
            newStore.loading = true;
            return newStore;
        case Actions.documentUploadInspectionSuccess:
            if (newStore.current) {
                const prevFileList = newStore.current.files ?? [];
                newStore.current = {
                    ...newStore.current,
                    files: [...prevFileList, ...action.payload.fileList],
                };
            }
            newStore.loading = false;
            return newStore;
        case Actions.documentUploadInspectionFailure:
            newStore.loading = false;
            return newStore;
        case Actions.documentDeleteInspectionSuccess:
            if (newStore.current) {
                newStore.current = {
                    ...newStore.current,
                    files: newStore.current.files.filter(
                        (file) => file.id !== action.payload.idDocument
                    ),
                };
            }
            return newStore;
        case Actions.documentUploadInspectionRemarkRequest:
            newStore.loading = true;
            return newStore;
        case Actions.documentUploadInspectionRemarkSuccess:
            if (newStore.current) {
                const currentIndex = newStore.current.osservazioni.findIndex(
                    (osservazione) => osservazione.id === action.payload.idEntity
                );
                if (currentIndex > -1) {
                    const prevFileList = newStore.current.osservazioni[currentIndex].files ?? [];
                    newStore.current.osservazioni[currentIndex].files = [
                        ...prevFileList,
                        ...action.payload.fileList,
                    ];
                    newStore.loading = false;
                }
            }
            return newStore;
        case Actions.documentUploadInspectionRemarkFailure:
            newStore.loading = false;
            return newStore;
        case Actions.documentDeleteInspectionRemarkSuccess:
            if (newStore.current) {
                const remarkIndex = newStore.current.osservazioni.findIndex(
                    (osservazione) => osservazione.id === action.payload.idEntity
                );
                if (remarkIndex !== undefined && remarkIndex > -1) {
                    const docIndex = newStore.current.osservazioni[remarkIndex].files?.findIndex(
                        (osservazione) => osservazione.id === action.payload.idDocument
                    );
                    if (docIndex !== undefined && docIndex > -1) {
                        newStore.current.osservazioni[remarkIndex].files?.splice(docIndex, 1);
                    }
                }
            }
            return newStore;
        case Actions.inspectionRemarkCreateRequest:
            newStore.loading = true;
            return newStore;
        case Actions.inspectionRemarkEditRequest:
            newStore.loading = true;
            return newStore;
        case Actions.inspectionRemarkCreateSuccess:
            if (newStore.current) {
                newStore.current = {
                    ...newStore.current,
                    osservazioni: [...newStore.current.osservazioni, { ...action.payload }],
                };
            }
            return newStore;
        case Actions.inspectionRemarkEditSuccess:
            if (newStore.current) {
                const found = newStore.current.osservazioni.findIndex(
                    (osservazione) => osservazione.id === action.payload.id
                );
                if (found > -1) {
                    const { osservazioni } = newStore.current;
                    osservazioni[found] = action.payload;
                    newStore.current = {
                        ...newStore.current,
                        osservazioni,
                    };
                }
            }
            return newStore;
        case Actions.inspectionRemarkDeleteSuccess:
            if (newStore.current) {
                newStore.current = {
                    ...newStore.current,
                    osservazioni: newStore.current.osservazioni.filter(
                        (remark) => remark.id !== action.payload.idRemark
                    ),
                };
            }
            return newStore;
        case Actions.inspectionSignatureUploadSuccess:
            const userUploadIndex = newStore?.current?.partecipanti.findIndex(
                (partecipant) => partecipant.idTabella === action.payload.idEntity
            );
            if (userUploadIndex !== undefined && userUploadIndex !== -1 && newStore?.current) {
                const { partecipanti } = newStore.current;
                partecipanti[userUploadIndex] = {
                    ...newStore.current.partecipanti[userUploadIndex],
                    ...action.payload,
                };
                newStore.current = {
                    ...newStore.current,
                    partecipanti,
                };
            }
            return newStore;
        case Actions.inspectionSignatureDeleteSuccess:
            const userDelIndex = newStore?.current?.partecipanti.findIndex(
                (partecipant) => partecipant.idTabella === action.payload.idEntity
            );
            if (userDelIndex !== undefined && userDelIndex !== -1 && newStore?.current) {
                delete newStore.current.partecipanti[userDelIndex].dataFirma;
            }
            return newStore;
        case Actions.inspectionDetailClear:
            delete newStore.current;
            return newStore;
        case Actions.createLogoutSuccess:
            return { list: {}, loading: false };
        default:
            return prevStore;
    }
};
