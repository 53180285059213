// #region ::: IMPORT
import React, { ChangeEvent, FC, useState } from "react";

import { SectionDictionary } from "@magoot-sdk/declarations/general/urlParams";
import { TTranslation, translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { TDictionary } from "@magoot-sdk/redux-modules/reducers/reducerDictionaries/declarations";

import { InputGeneric } from "../Generic";
import { AutoComplete } from "./AutoComplete";

// #endregion

interface Props {
    workName: string;
    error?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<any>) => void;
    placeholder?: TTranslation;
    isRequired?: boolean;
    options: TDictionary[];
    onAutoComplete: (work: any) => void;
    size?: "sm" | "md" | "lg";
}

export const InputWorkSearch: FC<Props> = ({
    workName,
    error,
    onChange,
    onBlur,
    placeholder,
    isRequired = true,
    options,
    onAutoComplete,
    size,
}: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const locale = useLocale();
    const inputPlaceholder = placeholder ? translate({ locale, id: placeholder }) : "";

    const handleFocus = (): void => {
        setIsVisible(true);
    };

    const handleBlur = (event: React.FocusEvent<any>): void => {
        setIsVisible(false);
        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <>
            <InputGeneric
                dataCy='TO_DO'
                value={workName}
                fieldName='descr'
                placeholder={inputPlaceholder}
                isRequired={isRequired}
                label='view-order.work.label.work-name'
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                size={size}
                sectionDictionary={SectionDictionary.order}
                info={error}
            />
            {options && isVisible && (
                <div className='relative z-40 w-full bg-white'>
                    <AutoComplete options={options} onAutoComplete={onAutoComplete} />
                </div>
            )}
        </>
    );
};
