// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import React, { FC, memo } from "react";

import { translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { UIActionBar } from "@components-ui/ActionBar";
import { UIButton } from "@components-ui/Button";
import { UILoader } from "@components-ui/Loader";

// #endregion

interface Props {
    open: boolean;
    titleText: string;
    contentText: string;
    information?: boolean;
    hiddenClose?: boolean;
    loader?: boolean;
    onCancel?: any;
    onSave?: boolean;
    notOnDelete?: any;
    onConfirm?: any;
    handleLoading?: any;
    loading?: boolean;
    animate?: "animate-mount" | "animate-unmount";
}

export const UIPopup: FC<Props> = memo(
    ({
        open,
        titleText,
        contentText,
        onCancel,
        onConfirm,
        onSave = false,
        notOnDelete,
        information,
        hiddenClose = false,
        loader = false,
        handleLoading,
        loading = false,
        animate = "animate-mount",
    }): JSX.Element | null => {
        const locale = useLocale();

        return (
            <div
                className={`
        ${open ? "modal-open" : ""} 
        ${animate === "animate-mount" ? "animate-mount" : ""} 
        ${animate === "animate-unmount" ? "animate-unmount" : ""}
        modal`}
            >
                <div className='modal-box'>
                    <div className='flex flex-row justify-between'>
                        <h3 className='text-lg font-bold'>{titleText}</h3>
                        {information && !hiddenClose && (!!onCancel || !!onConfirm) && (
                            <UIButton
                                icon={faTimes as IconProp}
                                dataCy='TO_BE_FIXED'
                                onClick={onCancel}
                                variant='ternary'
                                fluid={false}
                                isCircle
                            />
                        )}
                    </div>
                    <p className='py-4'>{contentText}</p>
                    {!!onCancel && !!onConfirm && !information && !loading && (
                        <div className='modal-action'>
                            <UIActionBar
                                onSave={!loader ? onConfirm : handleLoading}
                                onDelete={notOnDelete ? undefined : onCancel}
                                labelOnSave='button.confirm'
                                labelOnDelete='button.cancel'
                                justifyContent='justify-end'
                            />
                        </div>
                    )}

                    {onSave && (
                        <div className='modal-action'>
                            <UIButton
                                dataCy='TO_BE_FIXED'
                                onClick={onCancel}
                                variant='primary'
                                text={translate({
                                    locale,
                                    id: "view-order-settings.button.onSave",
                                })}
                                isUppercase
                                fluid={false}
                            />
                        </div>
                    )}

                    {loader && (!onConfirm || loading) && <UILoader iconSpinner={false} />}
                </div>
            </div>
        );
    }
);

UIPopup.displayName = "UIPopup";
