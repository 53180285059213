import { TReducerDictionary } from "@magoot-sdk/redux-modules/reducers/reducerDictionaries/declarations";

import { TParamsApiDictionary } from "../../../../api/dictionary/dictionaryWorkList/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryWorkListFailure,
    TActionDictionaryWorkListRequest,
    TActionDictionaryWorkListSuccess,
} from "./declarations";

export const actionDictionaryWorkListRequest = (
    payload: TParamsApiDictionary
): TActionDictionaryWorkListRequest => ({
    type: Actions.dictionaryWorkListRequest,
    payload,
});

export const actionDictionaryWorkListSuccess = (
    payload: TReducerDictionary
): TActionDictionaryWorkListSuccess => ({
    type: Actions.dictionaryWorkListSuccess,
    payload,
});

export const actionDictionaryWorkListFailure = (
    payload: TError
): TActionDictionaryWorkListFailure => ({
    type: Actions.dictionaryWorkListFailure,
    payload,
});
