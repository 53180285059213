// #region ::: IMPORT
import { memoize } from "lodash";

import { TLocale } from "./declarations";
import { translations } from "./translations";
import { TTranslationsResponseApi } from "./translations/button";
import { TTranslationsEmptyList } from "./translations/emptyList";
import { TTranslationsFile } from "./translations/file";
import { TTranslationsFormChangePassword } from "./translations/formChangePassword";
import { TTranslationsFormForgotPassword } from "./translations/formForgotPassword";
import { TTranslationsFormInviteUser } from "./translations/formInviteUser";
import { TTranslationsFormJoinStudio } from "./translations/formJoinStudio";
import { TTranslationsFormResetPassword } from "./translations/formResetPassword";
import { TTranslationsFormSignup } from "./translations/formSignup";
import { TTranslationsGeneral } from "./translations/general";
import { TTranslationsInfo } from "./translations/info";
import { TTranslationsModalUpgrade } from "./translations/modalUpgrade";
import { TTranslationsOfflineBar } from "./translations/offlineBar";
import { TTranslationsPopup } from "./translations/popup";
import { TTranslationsReports } from "./translations/reports";
import { TTranslationsViewAccount } from "./translations/viewAccount";
import { TTranslationsViewActivation } from "./translations/viewActivation";
import { TTranslationsViewAddressBook } from "./translations/viewAddressBook";
import { TTranslationsViewAnnotationCreate } from "./translations/viewAnnotationCreate";
import { TTranslationsViewAnnotationList } from "./translations/viewAnnotationList";
import { TTranslationsViewBusinessOperator } from "./translations/viewBusinessOperator";
import { TTranslationsViewCustomerDetail } from "./translations/viewCustomerDetail";
import { TTranslationsViewCustomerList } from "./translations/viewCustomerList";
import { TTranslationsViewDashboard } from "./translations/viewDashboard";
import { TTranslationsViewDocumentCreate } from "./translations/viewDocumentCreate";
import { TTranslationsViewDocumentList } from "./translations/viewDocumentList";
import { TTranslationsViewDownloadFile } from "./translations/viewDownloadFile";
import { TTranslationsViewForgotPassword } from "./translations/viewForgotPassword";
import { TTranslationsViewForgotPasswordSuccess } from "./translations/viewForgotPasswordSuccess";
import { TTranslationsViewIdeaCreate } from "./translations/viewIdeaCreate";
import { TTranslationsViewInspectionCreate } from "./translations/viewInspectionCreate";
import { TTranslationsViewInspectionList } from "./translations/viewInspectionList";
import { TTranslationsViewInvitation } from "./translations/viewInvitation";
import { TTranslationsViewLogin } from "./translations/viewLogin";
import { TTranslationsViewMunicipalPractices } from "./translations/viewMunicipalPractices";
import { TTranslationsViewNotFound } from "./translations/viewNotFound";
import { TTranslationsViewOrder } from "./translations/viewOrder";
import { TTranslationsViewOrderCreate } from "./translations/viewOrderCreate";
import { TTranslationsViewOrderSetting } from "./translations/viewOrderSetting";
import { TTranslationsViewPayments } from "./translations/viewPayments";
import { TTranslationsViewProfile } from "./translations/viewProfile";
import { TTranslationsViewProtocolList } from "./translations/viewProtocolList";
import { TTranslationsViewResetPasswordSuccess } from "./translations/viewResetPasswordSuccess";
import { TTranslationsViewSignup } from "./translations/viewSignup";
import { TTranslationsViewStudioDetail } from "./translations/viewStudioDetail";
import { TTranslationsViewStudioInvitation } from "./translations/viewStudioInvitation";
import { TTranslationsViewStudioList } from "./translations/viewStudioList";
import { TTranslationsViewSubscription } from "./translations/viewSubscription";
import { TTranslationsViewSummary } from "./translations/viewSummary";
import { TTranslationsViewTaskList } from "./translations/viewTaskList";
import { TTranslationsViewTerritorialData } from "./translations/viewTerritorialData";
import { TTranslationsViewWizard } from "./translations/viewWizard";

// #endregion

export type TTranslation =
    | TTranslationsOfflineBar
    | TTranslationsResponseApi
    | TTranslationsFormChangePassword
    | TTranslationsFormForgotPassword
    | TTranslationsFormInviteUser
    | TTranslationsFormJoinStudio
    | TTranslationsViewWizard
    | TTranslationsFormResetPassword
    | TTranslationsFormSignup
    | TTranslationsGeneral
    | TTranslationsViewActivation
    | TTranslationsViewForgotPasswordSuccess
    | TTranslationsViewForgotPassword
    | TTranslationsViewInvitation
    | TTranslationsViewOrder
    | TTranslationsViewLogin
    | TTranslationsViewNotFound
    | TTranslationsViewProfile
    | TTranslationsViewResetPasswordSuccess
    | TTranslationsViewSignup
    | TTranslationsEmptyList
    | TTranslationsViewOrderSetting
    | TTranslationsPopup
    | TTranslationsViewStudioDetail
    | TTranslationsViewStudioInvitation
    | TTranslationsViewDashboard
    | TTranslationsViewBusinessOperator
    | TTranslationsViewCustomerList
    | TTranslationsViewInspectionList
    | TTranslationsViewAnnotationList
    | TTranslationsViewAnnotationCreate
    | TTranslationsViewDocumentList
    | TTranslationsViewInspectionCreate
    | TTranslationsViewDocumentCreate
    | TTranslationsViewProtocolList
    | TTranslationsViewTaskList
    | TTranslationsViewIdeaCreate
    | TTranslationsViewStudioList
    | TTranslationsViewCustomerDetail
    | TTranslationsViewOrderCreate
    | TTranslationsViewAccount
    | TTranslationsViewSummary
    | TTranslationsViewPayments
    | TTranslationsViewDownloadFile
    | TTranslationsViewTerritorialData
    | TTranslationsModalUpgrade
    | TTranslationsViewSubscription
    | TTranslationsViewMunicipalPractices
    | TTranslationsViewAddressBook
    | TTranslationsInfo
    | TTranslationsReports
    | TTranslationsFile;

interface Params {
    id: TTranslation;
    locale: TLocale;
}

export const translate = memoize(({ id, locale }: Params): string => {
    if (!translations[locale][id]) {
        console.warn("MISS TRANSLATION FOR ID", id);
        return id;
    }
    return translations[locale][id];
});
