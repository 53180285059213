interface Translations {
    "view.login.title": string;
    "view.login.no-account": string;
    "view.login.button.create-account": string;
    "view.login.forgot-password": string;
    "view.login.copyrights": string;
}

interface ViewLogin {
    it: Translations;
    en: Translations;
}

export const translationsViewLogin: ViewLogin = {
    it: {
        "view.login.title": "Log In",
        "view.login.no-account": "Non hai ancora un account?",
        "view.login.button.create-account": "Crealo ora!",
        "view.login.forgot-password": "Dimenticato la password?",
        "view.login.copyrights": "Magoot è un servizio realizzato da",
    },
    en: {
        "view.login.title": "Log In",
        "view.login.no-account": "You have not an account yet?",
        "view.login.button.create-account": "Create now!",
        "view.login.forgot-password": "Password forgotten?",
        "view.login.copyrights": "Magoot è un servizio realizzato da",
    },
};

export type TTranslationsViewLogin = keyof Translations;
