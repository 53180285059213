// #region ::: IMPORT
import React, { FC, memo } from "react";

import { translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { UIButton } from "@components-ui/Button";

// #endregion

interface Props {
    open: boolean;
    titleText: string;
    contentText: string;
    onCancel: any;
    onConfirm: any;
}

export const PopupLeaveForm: FC<Props> = memo(
    ({ open, titleText, contentText, onCancel, onConfirm }): JSX.Element | null => {
        const locale = useLocale();

        return (
            <div className={`${open ? "modal-open" : ""} modal-bottom md:modal-middle modal`}>
                <div className='modal-box'>
                    <h3 className='text-lg font-bold'>{titleText}</h3>
                    <p className='py-4'>{contentText}</p>
                    <div className='modal-action'>
                        <div className='flex flex-row justify-end'>
                            <UIButton
                                text={translate({ locale, id: "button.leave" })}
                                onClick={onConfirm}
                                dataCy='TO_BE_FIXED'
                                variant='secondary'
                            />
                            <div className='ml-16' />
                            <UIButton
                                text={translate({ locale, id: "button.stay" })}
                                onClick={onCancel}
                                dataCy='TO_BE_FIXED'
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

PopupLeaveForm.displayName = "PopupLeaveForm";
