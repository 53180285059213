import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { StatoSezione } from "@magoot-sdk/api/diritti/response";
import { EUrl } from "@magoot-sdk/declarations/general/urlParams";
import { actionUIGoNewRoutes } from "@magoot-sdk/redux-modules/actions/ui/actionUi";
import { useDiritti } from "@magoot-sdk/redux-modules/hooks/diritti/useDiritti";
import { selectorGoNewRoutes } from "@magoot-sdk/redux-modules/selectors/selectorsUI";

import {
    utilityGetUrlViewAnnotationList,
    utilityGetUrlViewCommitmentList,
    utilityGetUrlViewCustomerCreate,
    utilityGetUrlViewCustomerDetail,
    utilityGetUrlViewCustomerList,
    utilityGetUrlViewDocumentList,
    utilityGetUrlViewIdeaCreate,
    utilityGetUrlViewIdeaDetail,
    utilityGetUrlViewIdeaList,
    utilityGetUrlViewInspectionList,
    utilityGetUrlViewMunicipalPractices,
    utilityGetUrlViewOperatorList,
    utilityGetUrlViewOrder,
    utilityGetUrlViewOrderSettings,
    utilityGetUrlViewPayments,
    utilityGetUrlViewSummary,
    utilityGetUrlViewTaskList,
    utilityGetUrlViewTerritorialData,
} from "../utils/Routes";

interface Params {
    idStudio: number;
    idOrder: number;
    idWork?: number;
    idCustomer?: number;
    idIdea?: number;
    locationPathname: string;
}

interface TReturn {
    onNavigateToCommimentList: () => void;
    onNavigateToOperatorList: () => void;
    onNavigateToIdeaList: () => void;
    onNavigateToWorkList: () => void;
    onNavigateToSettings: () => void;
    onNavigateToTerritorialData: () => void;
    onNavigateToSummary: () => void;
    onNavigateToPayments: () => void;
    onNavigateToActivities: () => void;
    onNavigateToDocuments: () => void;
    onNavigateToInspections: () => void;
    onNavigateToAnnotations: () => void;
    onNavigateToMunicipalPractices: () => void;
    isSummary: boolean;
    isCommitment: boolean;
    isOperator: boolean;
    isTerritorialData: boolean;
    isWork: boolean;
    isIdea: boolean;
    isPayment: boolean;
    isSetting: boolean;
    isTabActivities: boolean;
    isTabDocuments: boolean;
    isTabInspections: boolean;
    isTabAnnotations: boolean;
    isTabMunicipalPractices: boolean;
    isNewRoutes: boolean | undefined;
}

export const useToolbarNavigation = ({
    idStudio,
    idOrder,
    idWork,
    idCustomer,
    idIdea,
    locationPathname,
}: Params): TReturn => {
    const dispatch = useDispatch();
    const isNewRoutes = useSelector(selectorGoNewRoutes);
    const history = useHistory();
    const { tabSelected, category }: any = useParams();
    const { tabPagamenti, tabImpostazioni } = useDiritti();

    const isTabActivities = tabSelected === "attivita";
    const isTabDocuments = tabSelected === "documenti" && !category;
    const isTabInspections = tabSelected === "sopralluoghi";
    const isTabAnnotations = tabSelected === "annotazioni";
    const isTabMunicipalPractices = tabSelected === "praticaComunale";
    const isTabWork =
        isTabActivities ||
        isTabDocuments ||
        isTabInspections ||
        isTabAnnotations ||
        isTabMunicipalPractices;

    // #region ::: LINKS
    const linkCommitmentList = utilityGetUrlViewCommitmentList({
        idOrder,
        idStudio,
    });
    const linkCommitmentCreate = utilityGetUrlViewCustomerCreate({
        idStudio,
        idOrder,
        category: EUrl.Committenti,
        tabSelected: "generale",
    });
    const linkOperatorCreate = utilityGetUrlViewCustomerCreate({
        idStudio,
        idOrder,
        category: EUrl.Operatori,
        tabSelected: "generale",
    });

    const linkCommitmentDetailContact = utilityGetUrlViewCustomerDetail({
        idStudio,
        idOrder,
        idCustomer: idCustomer || 0,
        category: EUrl.Committenti,
        tabSelected: "generale",
    });
    const linkCommitmentDetailDocument = utilityGetUrlViewCustomerDetail({
        idStudio,
        idOrder,
        idCustomer: idCustomer || 0,
        category: EUrl.Committenti,
        tabSelected: "documenti",
    });
    const linkCommitmentCustomerList = utilityGetUrlViewCustomerList({
        idStudio,
        idOrder,
        category: EUrl.Committenti,
    });
    const linkOperatorList = utilityGetUrlViewOperatorList({
        idOrder,
        idStudio,
    });

    const linkOperatorDetailContact = utilityGetUrlViewCustomerDetail({
        idStudio,
        idOrder,
        idCustomer: idCustomer || 0,
        category: EUrl.Operatori,
        tabSelected: "generale",
    });
    const linkOperatorDetailDocument = utilityGetUrlViewCustomerDetail({
        idStudio,
        idOrder,
        idCustomer: idCustomer || 0,
        category: EUrl.Operatori,
        tabSelected: "documenti",
    });
    const linkOperatorCustomerList = utilityGetUrlViewCustomerList({
        idStudio,
        idOrder,
        category: EUrl.Operatori,
    });
    const linkIdeaList = utilityGetUrlViewIdeaList({
        idOrder,
        idStudio,
    });
    const linkIdeaCreate = utilityGetUrlViewIdeaCreate({
        idOrder,
        idStudio,
    });
    const linkIdeaDetail = utilityGetUrlViewIdeaDetail({
        idOrder,
        idIdea: idIdea || 0,
        idStudio,
    });
    const linkWorkList = utilityGetUrlViewOrder({
        idOrder,
        idStudio,
    });
    const linkSettingGeneral = utilityGetUrlViewOrderSettings({
        idOrder,
        tabSelected: "generale",
        idStudio,
    });
    const linkSettingTeam = utilityGetUrlViewOrderSettings({
        idOrder,
        tabSelected: "team",
        idStudio,
    });
    const linkSettingCosts = utilityGetUrlViewOrderSettings({
        idOrder,
        tabSelected: "costi",
        idStudio,
    });
    const linkSettingRights = utilityGetUrlViewOrderSettings({
        idOrder,
        tabSelected: "diritti",
        idStudio,
    });
    const linkTerritorialData = utilityGetUrlViewTerritorialData({
        idOrder,
        idStudio,
    });
    const linkSummary = utilityGetUrlViewSummary({
        idOrder,
        idStudio,
    });
    const linkPaymentsPrevision = utilityGetUrlViewPayments({
        idOrder,
        idStudio,
        tabSelected: "preventivo",
    });
    const linkPaymentsEarn = utilityGetUrlViewPayments({
        idOrder,
        idStudio,
        tabSelected: "incasso",
    });
    const linkPaymentsCost = utilityGetUrlViewPayments({
        idOrder,
        idStudio,
        tabSelected: "spese",
    });
    const linkActivities = utilityGetUrlViewTaskList({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    const linkDocuments = utilityGetUrlViewDocumentList({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    const linkMunicipalPractices = utilityGetUrlViewMunicipalPractices({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    const linkInspections = utilityGetUrlViewInspectionList({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    const linkAnnotations = utilityGetUrlViewAnnotationList({
        idOrder,
        idStudio,
        idWork: idWork || 0,
    });
    // #endregion

    // #region ::: LOCATIONS
    const isSummary = locationPathname === linkSummary;
    const isCommitment =
        locationPathname === linkCommitmentList ||
        locationPathname === linkCommitmentCreate ||
        locationPathname === linkCommitmentDetailContact ||
        locationPathname === linkCommitmentDetailDocument ||
        locationPathname === linkCommitmentCustomerList;

    const isOperator =
        locationPathname === linkOperatorList ||
        locationPathname === linkOperatorCreate ||
        locationPathname === linkOperatorDetailContact ||
        locationPathname === linkOperatorDetailDocument ||
        locationPathname === linkOperatorCustomerList;
    const isTerritorialData = locationPathname === linkTerritorialData;
    const isWork = locationPathname === linkWorkList || isTabWork;
    const isIdea =
        locationPathname === linkIdeaList ||
        locationPathname === linkIdeaCreate ||
        locationPathname === linkIdeaDetail;
    const isPayment =
        locationPathname === linkPaymentsPrevision ||
        locationPathname === linkPaymentsEarn ||
        locationPathname === linkPaymentsCost;
    const isSetting =
        locationPathname === linkSettingGeneral || locationPathname === linkSettingTeam;
    // #endregion

    // #region ::: ON NAVIGATE
    const onNavigateToCommimentList = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkCommitmentList);
    };
    const onNavigateToOperatorList = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkOperatorList);
    };
    const onNavigateToIdeaList = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkIdeaList);
    };
    const onNavigateToWorkList = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkWorkList);
    };
    const onNavigateToSettings = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));

        if (tabImpostazioni && tabImpostazioni.length > 0) {
            if (tabImpostazioni[0].sezione.id === StatoSezione.ImpostazioniCommessa) {
                history.push(linkSettingGeneral);
            }

            if (tabImpostazioni[0].sezione.id === StatoSezione.ImpostazioniTeamCommessa) {
                history.push(linkSettingTeam);
            }

            if (tabImpostazioni[0].sezione.id === StatoSezione.ImpostazioniTeamCostiCommessa) {
                history.push(linkSettingCosts);
            }

            if (tabImpostazioni[0].sezione.id === StatoSezione.ImpostazioniDirittiCommessa) {
                history.push(linkSettingRights);
            }
        } else history.push(linkSettingRights);
    };
    const onNavigateToTerritorialData = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkTerritorialData);
    };
    const onNavigateToSummary = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        history.push(linkSummary);
    };
    const onNavigateToPayments = (): void => {
        dispatch(actionUIGoNewRoutes({ state: true }));
        if (tabPagamenti && tabPagamenti.length > 0) {
            if (tabPagamenti[0].sezione.id === StatoSezione.Preventivo) {
                history.push(linkPaymentsPrevision);
            }

            if (tabPagamenti[0].sezione.id === StatoSezione.Incasso) {
                history.push(linkPaymentsEarn);
            }

            if (tabPagamenti[0].sezione.id === StatoSezione.Spese) {
                history.push(linkPaymentsCost);
            }
        } else history.push(linkPaymentsPrevision);
    };
    const onNavigateToActivities = (): void => history.push(linkActivities);
    const onNavigateToDocuments = () => history.push(linkDocuments);
    const onNavigateToInspections = () => history.push(linkInspections);
    const onNavigateToAnnotations = () => history.push(linkAnnotations);
    const onNavigateToMunicipalPractices = () => history.push(linkMunicipalPractices);
    // #endregion

    useEffect(() => {
        setTimeout(() => {
            dispatch(actionUIGoNewRoutes({ state: false }));
        }, 750);
    }, [isNewRoutes]);

    return {
        onNavigateToCommimentList,
        onNavigateToOperatorList,
        onNavigateToIdeaList,
        onNavigateToWorkList,
        onNavigateToSettings,
        onNavigateToTerritorialData,
        onNavigateToSummary,
        onNavigateToPayments,
        onNavigateToActivities,
        onNavigateToDocuments,
        onNavigateToInspections,
        onNavigateToMunicipalPractices,
        onNavigateToAnnotations,
        isSummary,
        isCommitment,
        isOperator,
        isTerritorialData,
        isWork,
        isIdea,
        isPayment,
        isSetting,
        isTabActivities,
        isTabDocuments,
        isTabInspections,
        isTabAnnotations,
        isTabMunicipalPractices,
        isNewRoutes,
    };
};
