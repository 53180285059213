import { TParamsApiLogin } from "../../../../api/auth/authLogin/params";
import { TResponseLogin } from "../../../../declarations/general/login";
import { TReducerAuth } from "../../../reducers/reducerAuth/declarations";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionLoginFailure,
    TActionLoginRequest,
    TActionLoginSuccess,
    TActionRestorePrevSession,
} from "./declarations";

export const actionLoginRequest = (payload: TParamsApiLogin): TActionLoginRequest => ({
    type: Actions.createLoginRequest,
    payload,
});

export const actionLoginSuccess = (payload: TResponseLogin): TActionLoginSuccess => ({
    type: Actions.createLoginSuccess,
    payload,
});

export const actionLoginFailure = (payload: TError): TActionLoginFailure => ({
    type: Actions.createLoginFailure,
    payload,
});

export const actionRestorePrevUser = (payload: TReducerAuth): TActionRestorePrevSession => ({
    type: Actions.restorePrevSession,
    payload,
});
