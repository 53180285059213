import { Actions, TAction } from "../../../utils/Actions";

export const actionRootDictionaries = ({
    idStudio,
    idOrderModel,
}: {
    idStudio: number;
    idOrderModel?: number;
}): TAction => ({
    type: Actions.rootDictionaries,
    payload: { idStudio, idOrderModel },
});
