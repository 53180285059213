import { Actions } from "../../utils/Actions";
import { TReducerAddressBook, TReducerAddressBookActions } from "./declarations";

export const reducerAddressBook = (
    prevStore: TReducerAddressBook = { value: {}, loading: true },
    action: TReducerAddressBookActions
): TReducerAddressBook => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.addressBookListRequest:
            return { value: {}, loading: true };
        case Actions.addressBookListSuccess:
            return { value: action.payload.addressBook, loading: false };
        case Actions.addressBookListFailure:
            return { value: {}, loading: false };
        case Actions.addressBookListLoading:
            return { value: {}, loading: action.payload.loading };
        case Actions.addressBookCreateSuccess:
            return {
                ...prevStore,
                [action.payload.id]: action.payload,
            };
        case Actions.addressBookUpdateSuccess:
            newStore[action.payload.id] = action.payload;
            return newStore;
        case Actions.addressBookDeleteSuccess:
            delete newStore[action.payload.idCustomer];
            return newStore;
        case Actions.createLogoutSuccess:
            return { value: {}, loading: false };
        case Actions.addressBookUploadRequest:
            const storeRequest = {
                value: {
                    ...newStore.value,
                    [action?.payload?.idEntity]: {
                        ...newStore.value[action?.payload?.idEntity],
                        loading: true,
                    },
                },
                loading: true,
            };
            return storeRequest;
        case Actions.addressBookUploadSuccess:
            const arrayFiles = newStore.value[action?.payload?.idEntity]?.files || [];

            const store = {
                value: {
                    ...newStore.value,
                    [action?.payload?.idEntity]: {
                        ...newStore.value[action?.payload?.idEntity],
                        files: arrayFiles.concat(action.payload.fileList),
                        loading: false,
                    },
                },
                loading: false,
            };
            return store;
        case Actions.addressBookUploadFailure:
            const storeFailure = {
                value: {},
                loading: false,
            };
            return storeFailure;
        case Actions.addressBookDeleteFileSuccess:
            const fileListDelete = newStore[action?.payload?.idAddressBook]?.files.filter(
                (file) => file.id !== action.payload.idEntity
            );

            newStore[action.payload.idAddressBook] = {
                ...newStore[action?.payload?.idAddressBook],
                files: fileListDelete,
            };

            return newStore;
        default:
            return prevStore;
    }
};
