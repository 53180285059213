import { TNoteType } from "../../../api/note/declarations";
import { utilityGetTaskStatusDescription } from "../../../utils/helpers/getStatusTaskDescription";
import { Actions } from "../../utils/Actions";
import { TReducerTask, TReducerTaskActions } from "./declarations";

export const reducerTask = (
    prevStore: TReducerTask = { value: {}, loading: false, showCalendar: false },
    action: TReducerTaskActions
): TReducerTask => {
    const newStore: TReducerTask = { ...prevStore };

    switch (action.type) {
        case Actions.taskListSuccess:
            const newTaskList = {
                ...newStore,
                value: {
                    [action.payload.idOrder]: action.payload.tasks,
                },
                loading: false,
            };

            return newTaskList;
        case Actions.taskCreateSuccess:
        case Actions.taskUpdateSuccess:
            const newTaskCreateUpdate = {
                ...newStore,
                value: {
                    ...newStore.value,
                    [action.payload.idCommessa]: {
                        ...newStore.value[action.payload.idCommessa],
                        [action.payload.id]: action.payload,
                    },
                },
                loading: false,
            };

            return newTaskCreateUpdate;
        case Actions.taskUpdateRequest:
            const newTaskUpdateRequest = {
                ...newStore,
                value: {
                    ...newStore.value,
                    [action.payload?.idOrder]: {
                        ...newStore.value[action.payload?.idOrder],
                        [action.payload?.idTask]: {
                            ...newStore.value[action.payload?.idOrder][action.payload?.idTask],
                            statoEsecuzione: {
                                id: action.payload?.task?.idStatoEsecuzione,
                                descr: utilityGetTaskStatusDescription(
                                    action.payload?.task?.idStatoEsecuzione || 1
                                ),
                            },
                        },
                    },
                },
                loading: true,
            };

            return newTaskUpdateRequest;
        case Actions.taskListLoading:
            const newTaskLoading = {
                ...newStore,
                loading: action.payload.loading,
            };
            return newTaskLoading;
        case Actions.taskDeleteSuccess:
            delete newStore.value[action.payload?.idOrder][action.payload.idTask];
            const newStoreDelete = {
                ...newStore,
                value: {
                    ...newStore.value,
                    [action.payload.idOrder]: {
                        ...newStore.value[action.payload?.idOrder],
                        [action.payload?.idOrder]: {
                            ...newStore.value[action.payload?.idOrder][action.payload.idTask],
                        },
                    },
                },
                loading: false,
            };

            return newStoreDelete;
        case Actions.createLogoutSuccess:
            return {
                ...newStore,
                value: {},
                loading: false,
            };
        case Actions.taskToggleCalendar:
            return {
                value: prevStore.value,
                loading: prevStore.loading,
                showCalendar: !prevStore.showCalendar,
            };
        case Actions.noteAddSuccess:
            if (action.payload.tipo === TNoteType.Attivita) {
                const { idOrder, idTask, result } = action.payload;
                const notes = newStore.value[idOrder][idTask].note;
                notes.push(result);
                newStore.value[idOrder][idTask] = {
                    ...newStore.value[idOrder][idTask],
                    note: notes,
                };
            }
            return {
                value: newStore.value,
                loading: prevStore.loading,
                showCalendar: prevStore.showCalendar,
            };
        case Actions.noteDeleteSuccess:
            if (action.payload.tipo === TNoteType.Attivita) {
                const { idOrder, objectId } = action.payload;
                const notes = [...newStore.value[idOrder][objectId].note];
                notes.splice(0, 1);
                newStore.value[idOrder][objectId] = {
                    ...newStore.value[idOrder][objectId],
                    note: notes,
                };
            }
            return {
                value: newStore.value,
                loading: prevStore.loading,
                showCalendar: prevStore.showCalendar,
            };
        default:
            return prevStore;
    }
};
