import { useEffect } from "react";

interface Prop {
    studioName?: string;
    userId?: number;
    idStudio?: number;
    nameUser?: string;
    lastNameUser?: string;
    userEmail?: string;
}

export const useZoho = ({
    studioName,
    userId,
    idStudio,
    nameUser,
    lastNameUser,
    userEmail,
}: Prop): void => {
    const zoho = (window as any).$zoho.salesiq?.visitor;

    useEffect(() => {
        if (!!zoho && !!userEmail) zoho?.email(userEmail || "");
    }, [userEmail]);

    useEffect(() => {
        if (!!nameUser && !!lastNameUser && !!zoho) {
            zoho?.name(`${nameUser || ""} ${lastNameUser || ""}`);
        }
    }, [nameUser, lastNameUser]);

    useEffect(() => {
        if (!!zoho && !!zoho.id && !!userId) zoho?.id(userId.toString() || "");
    }, [userId]);

    useEffect(() => {
        if (!!idStudio && !!zoho) {
            zoho?.info({ idStudio: idStudio || 0, nomeStudio: studioName || "" });
        }
    }, [idStudio, studioName]);
};
