// #region ::: IMPORT
import React, { FC, MutableRefObject, memo } from "react";

import { handleDrawerNavigation } from "@magoot-sdk/utils/components/drawer";

// #endregion

export interface Props {
    children?: any;
    isMobile?: boolean;
    drawerRef?: MutableRefObject<HTMLInputElement | undefined>;
}

export const UISidebar: FC<Props> = memo(
    ({ children, isMobile = false, drawerRef }): JSX.Element => {
        return (
            <div
                className={`drawer-side absolute hidden w-full shadow-xl xl:block xl:w-fit max-h-container h-auto
          ${isMobile ? "top-[4rem] h-full" : "relative h-screen"}
        `}
            >
                <label
                    htmlFor='my-drawer-3'
                    className='drawer-overlay z-30 h-full 2xl:hidden'
                    onClick={() => {
                        handleDrawerNavigation({ drawerRef });
                    }}
                />
                {children}
            </div>
        );
    }
);
UISidebar.displayName = "UISidebar";
