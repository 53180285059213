import { createSelector } from "reselect";

import { TStore } from "../reducers/declarations";
import { TCustomer } from "../reducers/reducerOrder/declarations";
import { selectorCommitments } from "./selectorsCommitment";

export const selectorOperators = (store: TStore): TStore["operators"] => store.operators;

export const selectorOperatorList = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): TCustomer[] => {
    if (!store.operators) return [];
    if (!store.operators[idOrder]) return [];
    return Object.values(store.operators[idOrder]);
};

export const selectorOperatorDetail = ({
    store,
    idOrder,
    idOperator,
}: {
    store: TStore;
    idOrder: number;
    idOperator: number;
}): TCustomer | undefined => {
    if (!store.operators) return undefined;
    return store.operators[idOrder][idOperator];
};

export const selectoperatorType = ({ store, customer }: { store: TStore; customer: TCustomer }) => {
    const operatorTypes = store.dictionaries?.operator || {};
    const values = Object.values(operatorTypes);
    const operatorType = values.find((value) => value.id === customer.idTipoOperatore);
    if (operatorType) {
        return operatorType.descr;
    }
    return "";
};

export const selectorOperatorNoCommitmentList = (idOrder: number) =>
    createSelector([selectorCommitments, selectorOperators], (commitments, operators) => {
        const commitmentList: TCustomer[] = commitments[idOrder]
            ? Object.values(commitments[idOrder])
            : [];
        const operatorList: TCustomer[] = operators[idOrder]
            ? Object.values(operators[idOrder])
            : [];
        return operatorList.filter(
            (operator) =>
                commitmentList.findIndex((commitment) => commitment.id === operator.id) === -1
        );
    });

export const selectorCommonSubjectList = (idOrder: number) =>
    createSelector([selectorCommitments, selectorOperators], (commitments, operators) => {
        const commitmentList: TCustomer[] = commitments[idOrder]
            ? Object.values(commitments[idOrder])
            : [];
        const operatorList: TCustomer[] = operators[idOrder]
            ? Object.values(operators[idOrder])
            : [];
        return operatorList.filter(
            (operator) =>
                commitmentList.findIndex((commitment) => commitment.id === operator.id) !== -1
        );
    });
