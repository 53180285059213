import React, { FC, memo } from "react";

import { translate } from "../../@magoot-sdk/i18n/translate";
import { useLocale } from "../../@magoot-sdk/redux-modules/hooks/ui/useLocale";

export interface Props {
    user: any;
}

export const UserRole: FC<Props> = memo(({ user }): JSX.Element => {
    const locale = useLocale();

    return (
        <span className='text-sm text-secondary font-medium uppercase'>
            {user?.ruolo === 0 && <>{translate({ locale, id: "general.label.owner" })}</>}
            {user?.ruolo === 1 && <>{translate({ locale, id: "general.label.admin" })}</>}
            {user?.ruolo === 2 && <>{translate({ locale, id: "general.label.partner.indoor" })}</>}
            {user?.ruolo === 3 && <>{translate({ locale, id: "general.label.partner.outside" })}</>}
        </span>
    );
});
