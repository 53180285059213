import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Dispatch } from "redux";

import {
    actionLastUnreadArrayNotificationsRequest,
    actionUnreadNotificationRequest,
} from "../actions/notifications/actionNotificationRead";
import { actionNotificationIncrement } from "../actions/notifications/actionNotificationReceive";
import { TNotification } from "../reducers/reducerNotifications/declarations";
import { Actions, TAction } from "../utils/Actions";

const URL = `${process.env.REACT_APP_MAIN_ENDPOINT}/Notifiche/Index`;

let connection: HubConnection | null = null;

const onConnectionStart = async (connectionStart: HubConnection) => {
    if (typeof connectionStart !== "number") {
        console.error("ConnectionStart error: ", connectionStart);
    }

    try {
        await (connectionStart as HubConnection).start();
    } catch (error) {
        console.error("SignalR Connection Error: ", error);
        throw error;
    }
};

const onInitConnection = (dispatch: Dispatch, token: string): void => {
    const magootStore = (!!localStorage.Magoot && JSON.parse(localStorage.Magoot)) || undefined;
    const idStudio = magootStore?.auth?.idStudio;

    connection = new HubConnectionBuilder()
        .withUrl(URL, { accessTokenFactory: (): string => token })
        .withAutomaticReconnect()
        .build();

    if (
        !magootStore?.notifications ||
        !magootStore?.notifications[idStudio] ||
        !magootStore?.notifications[idStudio]?.unreadNotifications
    ) {
        connection.on("ReceiveMessage", (notification: TNotification) => [
            dispatch(
                actionLastUnreadArrayNotificationsRequest({ idStudio: notification.idStudio })
            ),
            dispatch(actionUnreadNotificationRequest({ idStudio: notification.idStudio })),
        ]);
    }

    if (
        magootStore?.notifications &&
        magootStore?.notifications[idStudio] &&
        magootStore?.notifications[idStudio]?.unreadNotifications
    ) {
        connection.on("ReceiveMessage", (notification: TNotification) => [
            dispatch(actionNotificationIncrement({ idStudio: notification.idStudio })),
            dispatch(
                actionLastUnreadArrayNotificationsRequest({ idStudio: notification.idStudio })
            ),
        ]);
    }

    onConnectionStart(connection);
};

export const middlewareNotification =
    ({ dispatch }: { dispatch: Dispatch }) =>
    (next: (action: TAction) => void) =>
    async (action: TAction): Promise<void> => {
        if (
            action.type === Actions.createLoginSuccess ||
            action.type === Actions.restorePrevSession
        ) {
            onInitConnection(dispatch, action.payload.token);
        }

        if (action.type === Actions.createLogoutSuccess && connection) connection.stop();

        return next(action);
    };
