import { Actions, TAction } from "../../utils/Actions";
import { TStore } from "../declarations";

export const reducerCommitments = (
    prevStore: TStore["commitments"] = {},
    action: TAction
): TStore["commitments"] => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.commitmentListSuccess:
            newStore[action.payload.idOrder] = action.payload.commitments;
            return newStore;
        case Actions.commitmentDeleteSuccess:
            delete newStore[action.payload.idOrder][action.payload.idCustomer];
            return newStore;
        case Actions.customerUpdateSuccess:
            if (action.payload.category === "commitment") {
                newStore[action.payload.idOrder][action.payload.customer.idCommittente] = {
                    ...newStore[action.payload.idOrder][action.payload.customer.idCommittente],
                    ...action.payload.customer,
                };
            }
            return newStore;
        case Actions.commitmentConnectSuccess:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                [action.payload.idCustomer]: action.payload,
            };
            return newStore;
        /*         case Actions.contactCreateSuccess:
            if (action.payload.category === "commitments") {
                const prevOrder = newStore[action.payload.contact.idCommessa] || {};
                const prevCustomer = prevOrder[action.payload.idSoggettoUrl] || {};
                const prevContacts = prevCustomer.contatti || [];
                newStore[action.payload.contact.idCommessa][action.payload.idSoggettoUrl].contatti =
                    [...prevContacts, action.payload.contact];
            }
            return newStore; */
        case Actions.contactUpdateSuccess:
            if (action.payload.category === "commitment") {
                const prevOrder = newStore[action.payload.contact.idCommessa] || {};
                const prevCustomer = prevOrder[action.payload.idSoggettoUrl] || {};
                const prevContacts = prevCustomer.contatti || [];
                const newContacts = prevContacts.map((contact) =>
                    contact.id === action.payload.contact.id ? action.payload.contact : contact
                );
                newStore[action.payload.contact.idCommessa][action.payload.idSoggettoUrl].contatti =
                    newContacts;
            }
            return newStore;
        case Actions.contactDeleteSuccess:
            if (action.payload.category === "commitment") {
                const prevOrder = newStore[action.payload.idOrder] || {};
                const prevCustomer = prevOrder[action.payload.idSoggetto] || {};
                const prevContacts = prevCustomer.contatti || [];
                const newContacts = prevContacts.filter(
                    (contact) => contact.id !== action.payload.idContact
                );

                newStore[action.payload.idOrder][action.payload.idSoggetto].contatti = newContacts;
            }
            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
