import emptyList from "./json/emptyList.json";

interface Translations {
    "empty.title.order-list": string;
    "empty.description.order-list": string;
    "empty.title.expired-list": string;
    "empty.description.expired-list": string;
    "empty.title.commitment-list": string;
    "empty.title.commitment-title": string;
    "empty.title.operator-title": string;
    "empty.description.commitment-list": string;
    "empty.title.operator-list": string;
    "empty.description.operator-list": string;
    "empty.description.commitment-contact-list": string;
    "empty.description.commitment-document-list": string;
    "empty.description.operator-contact-list": string;
    "empty.description.operator-document-list": string;
    "empty.title.task-list": string;
    "empty.description.task-list": string;
    "empty.title.document-list": string;
    "empty.description.document-list": string;
    "empty.title.inspection-list": string;
    "empty.description.inspection-list": string;
    "empty.title.annotation-list": string;
    "empty.description.annotation-list": string;
    "empty.title.idea-list": string;
    "empty.description.idea-list": string;
    "empty.description.idea-document-list": string;
    "empty.title.prevision-list": string;
    "empty.description.prevision-list": string;
    "empty.title.earn-list": string;
    "empty.description.earn-list": string;
    "empty.title.cost-list": string;
    "empty.description.cost-list": string;
    "empty.title.work-summary": string;
    "empty.title.work-summary.title": string;
    "empty.title.work-summary.document.title": string;
    "empty.title.work-summary.annotation.title": string;
    "empty.title.work-summary.inspection.title": string;
    "empty.title.work-summary.loading": string;
    "empty.description.work-summary": string;
    "empty.description.work-summary.title.card.1": string;
    "empty.description.work-summary.title.card.2": string;
    "empty.description.work-summary.card.1": string;
    "empty.description.work-summary.card.2": string;
    "empty.description.summary-commitments-card": string;
    "empty.description.summary-operators-card": string;
    "empty.description.summary-idea-card": string;
    "empty.description.summary-costs-card": string;
    "empty.description.work": string;
    "empty.description.work-summary-inspections-card": string;
    "empty.description.work-summary-annotations-card": string;
    "empty.description.work-summary-document-card": string;
}

interface EmptyList {
    it: Translations;
    en: Translations;
}

export const translationsEmptyList: EmptyList = {
    it: {
        "empty.title.order-list": emptyList.orderList.it.title,
        "empty.description.order-list": emptyList.orderList.it.description,
        "empty.title.expired-list": emptyList.expiredList.it.title,
        "empty.description.expired-list": emptyList.expiredList.it.description,
        "empty.title.commitment-list": emptyList.commitmentList.it.title,
        "empty.title.commitment-title": "Committenti commessa",
        "empty.title.operator-title": "Operatori commessa",
        "empty.description.commitment-list": emptyList.commitmentList.it.description,
        "empty.title.operator-list": emptyList.operatorList.it.title,
        "empty.description.operator-list": emptyList.operatorList.it.description,
        "empty.description.commitment-contact-list": emptyList.commitmentContactList.it.description,
        "empty.description.commitment-document-list":
            emptyList.commitmentDocumentList.it.description,
        "empty.description.operator-contact-list": emptyList.operatorContactList.it.description,
        "empty.description.operator-document-list": emptyList.operatorDocumentList.it.description,
        "empty.title.task-list": emptyList.taskList.it.title,
        "empty.description.task-list": emptyList.taskList.it.description,
        "empty.title.document-list": emptyList.documentList.it.title,
        "empty.description.document-list": emptyList.documentList.it.description,
        "empty.title.inspection-list": emptyList.inspectionList.it.title,
        "empty.description.inspection-list": emptyList.inspectionList.it.description,
        "empty.title.annotation-list": emptyList.annotationList.it.title,
        "empty.description.annotation-list": emptyList.annotationList.it.description,
        "empty.title.idea-list": emptyList.ideaList.it.title,
        "empty.description.idea-list": emptyList.ideaList.it.description,
        "empty.description.idea-document-list": emptyList.ideaDocumentList.it.description,
        "empty.title.prevision-list": emptyList.previsionList.it.title,
        "empty.description.prevision-list": emptyList.previsionList.it.description,
        "empty.title.earn-list": emptyList.earnList.it.title,
        "empty.description.earn-list": emptyList.earnList.it.description,
        "empty.title.cost-list": emptyList.costList.it.title,
        "empty.description.cost-list": emptyList.costList.it.description,
        "empty.title.work-summary": emptyList.workSummary.it.title,
        "empty.title.work-summary.title": "Riepilogo fasi",
        "empty.title.work-summary.document.title": "Documenti commessa",
        "empty.title.work-summary.inspection.title": "Sopralluoghi commessa",
        "empty.title.work-summary.annotation.title": "Annotazioni commessa",
        "empty.title.work-summary.loading": "Caricamento riepilogo in corso...",
        "empty.description.work-summary": emptyList.workSummary.it.description,
        "empty.description.work-summary.title.card.1": "Premessa",
        "empty.description.work-summary.title.card.2": "Il riepilogo",
        "empty.description.work-summary.card.1":
            "Prima di illustrarti questa sezione è bene che tu abbia ben chiaro cosa siano le fasi prestazionali. Una commessa, soprattutto se complessa, può essere suddivisa in fasi, ognuna delle quali è composta da 4 sezioni: <b>Attività</b>, <b>Documenti</b>, <b>Sopralluoghi</b> e <b>Annotazioni</b>.</br> Puoi aggiungere una fase in qualsisasi momento cliccando sul pulsante <b>Aggiungi nuova fase</b> oppure potrai eliminarla o spostarla di posizione cliccando sui 3 puntini che compaiono quando si passa con il cursore su ciascuna voce.",
        "empty.description.work-summary.card.2":
            "La sezione <b>Riepilogo</b> ti permette di avere una visione completa sulle ultime attività svolte dal team all’interno di tutte le fasi della commessa. Potrai quindi vedere quali sono stati  gli ultimi sopralluoghi effettuati, le utime note registrate e avrai la possibilità di accedere a tutti i documenti caricati nelle varie fasi. <br/> <b>Cosa aspetti, clicca sulla prima fase per scoprire le potenzialità di Magoot.</b>",
        "empty.description.summary-commitments-card":
            emptyList.summaryCommitmentsCard.it.description,
        "empty.description.summary-operators-card": emptyList.summaryOperatorsCard.it.description,
        "empty.description.summary-idea-card": emptyList.summaryIdeaCard.it.description,
        "empty.description.summary-costs-card": emptyList.summaryCostsCard.it.description,
        "empty.description.work": "Nessuna fase ancora inserita",
        "empty.description.work-summary-inspections-card":
            emptyList.workSummaryInspectionsCard.it.description,
        "empty.description.work-summary-annotations-card":
            emptyList.workSummaryAnnotationsCard.it.description,
        "empty.description.work-summary-document-card":
            emptyList.workSummaryDocumentCard.it.description,
    },
    en: {
        "empty.title.order-list": emptyList.orderList.en.title,
        "empty.description.order-list": emptyList.orderList.en.description,
        "empty.title.expired-list": emptyList.expiredList.en.title,
        "empty.description.expired-list": emptyList.expiredList.en.description,
        "empty.title.commitment-list": emptyList.commitmentList.en.title,
        "empty.title.commitment-title": "Committenti commessa",
        "empty.title.operator-title": "Operatori commessa",
        "empty.description.commitment-list": emptyList.commitmentList.en.description,
        "empty.title.operator-list": emptyList.operatorList.en.title,
        "empty.description.operator-list": emptyList.operatorList.en.description,
        "empty.description.commitment-contact-list": emptyList.commitmentContactList.en.description,
        "empty.description.commitment-document-list":
            emptyList.commitmentDocumentList.en.description,
        "empty.description.operator-contact-list": emptyList.operatorContactList.en.description,
        "empty.description.operator-document-list": emptyList.operatorDocumentList.en.description,
        "empty.title.task-list": emptyList.taskList.en.title,
        "empty.description.task-list": emptyList.taskList.en.description,
        "empty.title.document-list": emptyList.documentList.en.title,
        "empty.description.document-list": emptyList.documentList.en.description,
        "empty.title.inspection-list": emptyList.inspectionList.en.title,
        "empty.description.inspection-list": emptyList.inspectionList.en.description,
        "empty.title.annotation-list": emptyList.annotationList.en.title,
        "empty.description.annotation-list": emptyList.annotationList.en.description,
        "empty.title.idea-list": emptyList.ideaList.en.title,
        "empty.description.idea-document-list": emptyList.ideaDocumentList.en.description,
        "empty.description.idea-list": emptyList.ideaList.en.description,
        "empty.title.prevision-list": emptyList.previsionList.en.title,
        "empty.description.prevision-list": emptyList.previsionList.en.description,
        "empty.title.earn-list": emptyList.earnList.en.title,
        "empty.description.earn-list": emptyList.earnList.en.description,
        "empty.title.cost-list": emptyList.costList.en.title,
        "empty.description.cost-list": emptyList.costList.en.description,
        "empty.title.work-summary": emptyList.workSummary.en.title,
        "empty.title.work-summary.title": "Riepilogo fasi",
        "empty.title.work-summary.document.title": "Documenti commessa",
        "empty.title.work-summary.inspection.title": "Sopralluoghi commessa",
        "empty.title.work-summary.annotation.title": "Annotazioni commessa",
        "empty.title.work-summary.loading": "Caricamento riepilogo in corso...",
        "empty.description.work-summary": emptyList.workSummary.en.description,
        "empty.description.work-summary.title.card.1": "Premessa",
        "empty.description.work-summary.title.card.2": "Il riepilogo",
        "empty.description.work-summary.card.1":
            "Prima di illustrarti questa sezione è bene che tu abbia ben chiaro cosa siano le fasi prestazionali. Una commessa, soprattutto se complessa, può essere suddivisa in fasi, ognuna delle quali è composta da 4 sezioni: <b>Attività</b>,<b>Documenti</b>,<b>Sopralluoghi</b> e <b>Annotazioni</b>. Puoi aggiungere una fase in qualsisasi momento cliccando sul pulsante <b>Aggiungi Fase</b> oppure potrai eliminarla o spostarla di posizione cliccando sui 3 puntini che compaiono quando si passa con il cursore su ciascuna voce.",
        "empty.description.work-summary.card.2":
            "La sezione <b>Riepilogo</b> ti permette di avere una visione a 360 gradi sulle ultime attività svolte dal team all’interno di tutte le fasi della commessa. Potrai quindi vedere quali sono stati  gli ultimi sopralluoghi effettuati, le utime note registrate e avrai la possibilità di accedere a tutti i documenti caricati nelle varie fasi. <br/> <b>Cosa aspetti, clicca sulla prima fase e scopri le potenzialità di Magoot.</b>",
        "empty.description.summary-commitments-card":
            emptyList.summaryCommitmentsCard.en.description,
        "empty.description.summary-operators-card": emptyList.summaryOperatorsCard.en.description,
        "empty.description.summary-idea-card": emptyList.summaryIdeaCard.en.description,
        "empty.description.summary-costs-card": emptyList.summaryCostsCard.en.description,
        "empty.description.work": "Nessuna fase ancora inserita",
        "empty.description.work-summary-inspections-card":
            emptyList.workSummaryInspectionsCard.en.description,
        "empty.description.work-summary-annotations-card":
            emptyList.workSummaryAnnotationsCard.en.description,
        "empty.description.work-summary-document-card":
            emptyList.workSummaryDocumentCard.en.description,
    },
};

export type TTranslationsEmptyList = keyof Translations;
