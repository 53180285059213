interface Translations {
    "modal.upgrade.label.user": string;
    "modal.upgrade.label.user.action": string;
    "modal.upgrade.label.no-functionality-in-your-subscription": string;
    "modal.upgrade.label.canceled": string;
    "modal.upgrade.label.canceled-subscription": string;
    "modal.upgrade.label.upgrade-to-activate": string;
    "modal.upgrade.label.no-subscription": string;
}

interface ModalUpgrade {
    it: Translations;
    en: Translations;
}

export const translationsModalUpgrade: ModalUpgrade = {
    it: {
        "modal.upgrade.label.user":
            "Hai raggiunto il numero massimo di utenti consentito dal tuo abbonamento.",
        "modal.upgrade.label.user.action": `Per aggiungere nuovi collaborato, fai l'upgrade e amplia il tuo team.`,
        "modal.upgrade.label.no-functionality-in-your-subscription":
            "Questa funzionalità non è disponibile nel tuo attuale abbonamento.",
        "modal.upgrade.label.canceled-subscription": "Abbonamento annullato!",
        "modal.upgrade.label.upgrade-to-activate": "Fai l’upgrade per attivare questa sezione.",
        "modal.upgrade.label.canceled":
            "Riattiva il tuo abbonamento per poter utilizzare le funzionalità di Magoot.",
        "modal.upgrade.label.no-subscription": "Nessun abbonamento attivo",
    },
    en: {
        "modal.upgrade.label.user":
            "Hai raggiunto il numero massimo di utenti consentito dal tuo abbonamento.",
        "modal.upgrade.label.user.action": `Per aggiungere nuovi collaborato, fai l'upgrade e amplia il tuo team.`,
        "modal.upgrade.label.no-functionality-in-your-subscription":
            "This funcionality it is not available in your current subscription.",
        "modal.upgrade.label.canceled-subscription": "Abbonamento annullato!",
        "modal.upgrade.label.upgrade-to-activate": "Upgrade to activate this section.",
        "modal.upgrade.label.canceled":
            "Riattiva il tuo abbonamento per poter utilizzare le funzionalità di Magoot.",
        "modal.upgrade.label.no-subscription": "No active subscription",
    },
};

export type TTranslationsModalUpgrade = keyof Translations;
