import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryOperatorCreate/response";
import { TParamsApiDictionaryOperatorEdit } from "../../../../api/dictionary/dictionaryOperatorEdit/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryOperatorEditFailure,
    TActionDictionaryOperatorEditRequest,
    TActionDictionaryOperatorEditSuccess,
} from "./declarations";

export const actionDictionaryOperatorEditRequest = (
    payload: TParamsApiDictionaryOperatorEdit
): TActionDictionaryOperatorEditRequest => ({
    type: Actions.dictionaryOperatorEditRequest,
    payload,
});

export const actionDictionaryOperatorEditSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryOperatorEditSuccess => ({
    type: Actions.dictionaryOperatorEditSuccess,
    payload,
});

export const actionDictionaryOperatorEditFailure = (
    payload: TError
): TActionDictionaryOperatorEditFailure => ({
    type: Actions.dictionaryOperatorEditFailure,
    payload,
});
