import { createTokenImageHeader } from "./config";

const fetchDownloadZipOrder = async (
    url: string
): Promise<{ blob: Promise<any>; filename: string } | null> => {
    const response = await fetch(url, {
        method: "GET",
        headers: createTokenImageHeader(),
    });
    const filename =
        response && response.headers && response.headers.get("Content-Disposition")
            ? response.headers
                  .get("Content-Disposition")!
                  ?.split("filename=")[1]
                  ?.split(";")[0]
                  ?.replaceAll('"', "")
                  ?.trim()
            : "";
    const responseWithFilename = { blob: response.blob(), filename };
    return response ? responseWithFilename : null;
};

export default fetchDownloadZipOrder;
