import { TTranslationsPopup } from "../../../../i18n/translations/popup";
import { Actions } from "../../../utils/Actions";
import { TActionHidePopup, TActionShowPopup } from "./declarations";

export const actionShowPopup = (payload: {
    title: TTranslationsPopup;
    body: TTranslationsPopup;
    information?: boolean;
    onConfirm?: Actions;
    centerBody?: boolean;
    hiddenClose?: boolean;
    onSave?: boolean;
    loader?: boolean;
    payload?: any;
    notOnDelete?: Actions;
}): TActionShowPopup => ({
    type: Actions.showPopup,
    payload,
});

export const actionHidePopup = (): TActionHidePopup => ({
    type: Actions.hidePopup,
});
