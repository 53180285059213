import { createSelector } from "reselect";

import { TStore } from "../reducers/declarations";
import { TCustomer } from "../reducers/reducerOrder/declarations";
import { selectorOperators } from "./selectorsOperator";

export const selectorCommitments = (store: TStore): TStore["commitments"] => store.commitments;

export const selectorCommitmentList = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): TCustomer[] => {
    if (!store.commitments) return [];
    if (!store.commitments[idOrder]) return [];
    return Object.values(store.commitments[idOrder]);
};

export const selectorCommitmentNoOperatorList = (idOrder: number) =>
    createSelector([selectorCommitments, selectorOperators], (commitments, operators) => {
        const commitmentList: TCustomer[] = commitments[idOrder]
            ? Object.values(commitments[idOrder])
            : [];
        const operatorList: TCustomer[] = operators[idOrder]
            ? Object.values(operators[idOrder])
            : [];
        return commitmentList.filter(
            (commitment) =>
                operatorList.findIndex((operator) => commitment.id === operator.id) === -1
        );
    });
