interface Translations {
    "view.annotation-create.label.place": string;
    "view.annotation-create.label.interlocutors": string;
    "view.annotation-create.label.new-note": string;
    "section.annotatio.annotation-type": string;
}

interface ViewAnnotationCreate {
    it: Translations;
    en: Translations;
}

export const translationsViewAnnotationCreate: ViewAnnotationCreate = {
    it: {
        "view.annotation-create.label.place": "Luogo",
        "view.annotation-create.label.interlocutors": "Interlocutori*",
        "view.annotation-create.label.new-note": "Nuova nota",
        "section.annotatio.annotation-type": "Tipo di annotazione",
    },
    en: {
        "view.annotation-create.label.place": "Place",
        "view.annotation-create.label.interlocutors": "Interlocutors*",
        "view.annotation-create.label.new-note": "New Notes",
        "section.annotatio.annotation-type": "Annotation type",
    },
};

export type TTranslationsViewAnnotationCreate = keyof Translations;
