interface Translations {
    "view.profile.title": string;
    "view.profile.description": string;
    "view.profile.file-size-error": string;
}

interface ViewProfile {
    it: Translations;
    en: Translations;
}

export const translationsViewProfile: ViewProfile = {
    it: {
        "view.profile.title": "Profilo utente",
        "view.profile.description": "Inserisci i dati e crea il tuo studio",
        "view.profile.file-size-error": "Il file deve pesare massimo 1 MB",
    },
    en: {
        "view.profile.title": "Profile",
        "view.profile.description": "Insert your information and create your workspace",
        "view.profile.file-size-error": "The file must weight a maximum of 1 MB",
    },
};

export type TTranslationsViewProfile = keyof Translations;
