import { Actions } from "../../utils/Actions";
import { TReducerTerritorial, TReducerTerritorialActions } from "./declarations";

export const reducerTerritorial = (
    prevStore: TReducerTerritorial = {},
    action: TReducerTerritorialActions
): TReducerTerritorial => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.landRegistryCreateSuccess:
            newStore[action.payload.idCommessa].landRegistry = {
                ...newStore[action.payload.idCommessa].landRegistry,
                [action.payload.id]: action.payload,
            };
            return newStore;
        case Actions.landRegistryListSuccess:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                landRegistry: action.payload.list,
            };
            return newStore;
        case Actions.landRegistryDeleteSuccess:
            delete newStore[action.payload.idOrder].landRegistry[action.payload.idLandRegistry];
            return newStore;
        case Actions.landRegistryUpdateSuccess:
            newStore[action.payload.idCommessa].landRegistry = {
                ...newStore[action.payload.idCommessa].landRegistry,
                [action.payload.id]: {
                    ...action.payload,
                    id: action.payload.id,
                    idCommessa: action.payload.idCommessa,
                },
            };
            return newStore;
        case Actions.urbanToolAreaCreateSuccess:
            newStore[action.payload.idCommessa].urbanoToolArea = {
                ...newStore[action.payload.idCommessa].urbanoToolArea,
                [action.payload.id]: action.payload,
            };
            return newStore;
        case Actions.urbanToolAreaListRequest:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                urbanoToolArea: {
                    loading: true,
                },
            };
            return newStore;
        case Actions.urbanToolAreaListSuccess:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                urbanoToolArea: {
                    ...action.payload.list,
                    loading: false,
                },
            };
            return newStore;
        case Actions.urbanToolAreaDeleteSuccess:
            delete newStore[action.payload.idOrder].urbanoToolArea[action.payload.idArea];
            return newStore;
        case Actions.urbanToolAreaUpdateRequest:
            newStore[action.payload.idOrder].urbanoToolArea = {
                ...newStore[action.payload.idOrder].urbanoToolArea,
                loading: true,
            };
            return newStore;
        case Actions.urbanToolAreaUpdateSuccess:
            newStore[action.payload.idCommessa].urbanoToolArea = {
                ...newStore[action.payload.idCommessa].urbanoToolArea,
                [action.payload.id]: {
                    ...action.payload,
                    id: action.payload.id,
                    idCommessa: action.payload.idCommessa,
                },
                loading: false,
            };
            return newStore;
        case Actions.urbanToolAreaLoading:
            newStore[action.payload.idCommessa] = {
                ...newStore[action.payload.idCommessa],
                urbanoToolArea: {
                    ...newStore[action.payload.idCommessa].urbanoToolArea,
                    loading: false,
                },
                documents: {
                    ...newStore[action.payload.idCommessa].documents,
                    loading: false,
                    forceUpdate: false,
                },
            };
            return newStore;
        case Actions.territorialDocumentListRequest:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                documents: { value: [], loading: true, forceUpdate: false },
            };
            return newStore;
        case Actions.territorialDocumentListSuccess:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                documents: { value: action.payload.documents, loading: false, forceUpdate: false },
            };
            return newStore;
        case Actions.territorialDocumentCreateRequest:
            newStore[action.payload.idOrder].documents = {
                ...newStore[action.payload.idOrder]?.documents,
                loading: true,
            };
            return newStore;
        case Actions.territorialDocumentCreateSuccess:
            newStore[action.payload.idOrder].documents = {
                ...newStore[action.payload.idOrder].documents,
                forceUpdate: true,
            };
            return newStore;
        case Actions.documentUploadTerritorialSuccess:
            newStore[action.payload.idOrder].documents[action.payload.idEntity].files =
                action.payload.fileList;
            return newStore;
        case Actions.territorialDocumentUpdateRequest:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                documents: {
                    ...newStore[action.payload.idOrder].documents,
                    [action.payload.document.id]: {
                        ...action.payload.document,
                        loading: true,
                    },
                },
            };
            return newStore;
        case Actions.territorialDocumentUpdateSuccess:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                documents: {
                    ...newStore[action.payload.idOrder].documents,
                    [action.payload.document.id]: {
                        ...action.payload.document,
                        loading: false,
                    },
                    forceUpdate: true,
                },
            };
            return newStore;
        case Actions.territorialDocumentDeleteSuccess:
            delete newStore[action.payload.idOrder].documents[action.payload.idDocument];

            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                documents: {
                    ...newStore[action.payload.idOrder].documents,
                    forceUpdate: true,
                    loading: false,
                },
            };
            return newStore;
        case Actions.geoCreateSuccess:
        case Actions.geoListSuccess:
            newStore[action.payload.idCommessa] = {
                ...newStore[action.payload.idCommessa],
                geo: action.payload.position,
            };
            return newStore;
        case Actions.addressUpdateCap:
            if (newStore[action.payload.idOrder])
                newStore[action.payload.idOrder] = {
                    ...newStore[action.payload.idOrder],
                    address: {
                        ...newStore[action.payload.idOrder]?.address,
                        comune: action.payload.address.comune,
                        cap: action.payload.address.cap,
                    },
                };

            return newStore;
        case Actions.addressCreateSuccess:
        case Actions.addressListSuccess:
        case Actions.addressUpdateSuccess:
            if (action.payload) {
                newStore[action.payload.idCommessa] = {
                    ...newStore[action.payload.idCommessa],
                    address: action.payload,
                };
            }
            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
