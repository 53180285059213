import { createSelector } from "reselect";

import { TStore } from "../reducers/declarations";
import { TAnnotation, TReducerAnnotations } from "../reducers/reducerAnnotations/declarations";

export const selectorAnnotationCounter = ({
    store,
    idOrder,
    idWork,
}: {
    store: TStore;
    idWork: number;
    idOrder: number;
}): number => {
    if (!store.annotations || !store.annotations.value) return 0;
    const annotationsOrder = store.annotations.value[idOrder];
    if (!annotationsOrder) return 0;
    const annotationList = Object.values(annotationsOrder);
    const annotationWork = annotationList.filter(
        (annotation) => annotation.idLavorazione === idWork
    );
    return annotationWork.length;
};

export const selectorAnnotationObj = (store: TStore): TReducerAnnotations => {
    if (!store.annotations)
        return {
            value: undefined,
            loading: false,
        };
    return store.annotations;
};

export const selectorAnnotationLoading = (store: TStore): boolean => {
    return store.annotations?.loading ?? false;
};
export const selectorAnnotationLoadingNoRender = createSelector(
    selectorAnnotationLoading,
    (loading) => loading
);
export const selectorAnnotationLoadingDocument = (store: TStore): boolean => {
    return store.annotations?.loadingDocument ?? false;
};
export const selectorAnnotationLoadingDocumentNoRender = createSelector(
    selectorAnnotationLoadingDocument,
    (loading) => loading
);

export const selectorAnnotationList = ({
    store,
    idOrder,
    idWork,
}: {
    store: TStore;
    idOrder: number;
    idWork: number;
}): TAnnotation[] | false => {
    if (!store.annotations || !store.annotations.value) return false;
    if (!store.annotations.value[idOrder]) return false;
    const annotationList = Object.values(store.annotations.value[idOrder]).filter(
        (annotation) => annotation.idLavorazione === idWork
    );
    return annotationList.length > 0 ? annotationList : false;
};
