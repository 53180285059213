import { utilityArrayToObjectList } from "@magoot-sdk/utils/formatting/arrayToObjectList";

import { Actions } from "../../utils/Actions";
import { TStore } from "../declarations";
import { TReducerWorksActions } from "./declarations";

export const reducerWorks = (
    prevStore: TStore["works"] = {},
    action: TReducerWorksActions
): TStore["works"] => {
    switch (action.type) {
        case Actions.workListSuccess:
            return {
                ...prevStore,
                ...action.payload.works,
            };
        case Actions.workCreateSuccess:
            let prevWorksCreate = {};
            if (action.payload?.work?.idCommessa && action.payload?.work?.id) {
                prevWorksCreate = prevStore[action.payload.work.idCommessa];

                const newIndex = prevStore[action.payload.work.idCommessa]
                    ? Object.values(prevStore[action.payload.work.idCommessa]).length
                    : 0;

                if (prevWorksCreate)
                    prevWorksCreate[action.payload.work.id] = {
                        ...action.payload.work,
                        position: newIndex,
                    };
            }

            return {
                ...prevStore,
                ...(action.payload?.work?.idCommessa && {
                    [action.payload.work.idCommessa]: prevWorksCreate,
                }),
            };
        case Actions.workUpdateSuccess:
            let prevWorksUpdate = {};
            if (action.payload?.work?.idCommessa && action.payload?.work?.id) {
                prevWorksUpdate = prevStore[action.payload.work.idCommessa];

                prevWorksUpdate[action.payload.work.id] = {
                    ...action.payload.work,
                    position: action.payload.position,
                };
            }

            return {
                ...prevStore,
                ...(action.payload?.work?.idCommessa && {
                    [action.payload.work.idCommessa]: prevWorksUpdate,
                }),
            };
        case Actions.workDeleteSuccess:
            delete prevStore[action.payload.idOrder][action.payload.idWork];
            return {
                ...prevStore,
            };
        case Actions.workListSelected:
            const { idWork } = action.payload;
            const workList =
                !!prevStore[action.payload?.idOrder] &&
                Object.values(prevStore[action.payload?.idOrder]).map((value) =>
                    value.id === idWork
                        ? { ...value, selected: true }
                        : { ...value, selected: false }
                );

            const objWorks =
                !!workList &&
                (utilityArrayToObjectList(workList, "idCommessa", "id") as TStore["works"]);

            const newState = {
                ...prevStore,
                ...objWorks,
            };
            return { ...newState };
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
