interface Translations {
    "view.forgot-password.forgotten": string;
    "view.forgot-password.description": string;
    "form.forgot-password.link-to-login": string;
}

interface ViewForgotPassword {
    it: Translations;
    en: Translations;
}

export const translationsViewForgotPassword: ViewForgotPassword = {
    it: {
        "view.forgot-password.forgotten": "dimenticata?",
        "view.forgot-password.description":
            "Inserisci qui la tua e-mail e ti invieremo un link per accedere di nuovo al tuo account",
        "form.forgot-password.link-to-login": "Torna alla pagina di log in",
    },
    en: {
        "view.forgot-password.forgotten": "forgotten?",
        "view.forgot-password.description": "we send you a link to reset your password",
        "form.forgot-password.link-to-login": "Back to log in",
    },
};

export type TTranslationsViewForgotPassword = keyof Translations;
