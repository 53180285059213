import { DEFAULT_LOCALE } from "../../../i18n";
import { TLocale } from "../../../i18n/declarations";
import { Actions } from "../../utils/Actions";
import { TReducerLocaleActions } from "./declarations";

export const reducerLocale = (
    prevStore: TLocale = DEFAULT_LOCALE,
    action: TReducerLocaleActions
): string => {
    if (action.type === Actions.updateLocaleSuccess) return action.payload;
    return prevStore;
};
