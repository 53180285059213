import React, { FC } from "react";
import { Provider } from "react-redux";

import { ReduxStore } from "../configureStore";

interface Props {
    children: JSX.Element;
}
export const store = ReduxStore.getInstance();

export const ProviderRedux: FC<Props> = ({ children }: Props): JSX.Element => (
    <Provider store={store}>{children}</Provider>
);
