import { TReducerDictionary } from "@magoot-sdk/redux-modules/reducers/reducerDictionaries/declarations";

import { TParamsApiDictionaryOperatorCreate } from "../../../../api/dictionary/dictionaryOperatorCreate/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryOperatorCreateFailure,
    TActionDictionaryOperatorCreateRequest,
    TActionDictionaryOperatorCreateSuccess,
} from "./declarations";

export const actionDictionaryOperatorCreateRequest = (
    payload: TParamsApiDictionaryOperatorCreate
): TActionDictionaryOperatorCreateRequest => ({
    type: Actions.dictionaryOperatorCreateRequest,
    payload,
});

export const actionDictionaryOperatorCreateSuccess = (
    payload: TReducerDictionary
): TActionDictionaryOperatorCreateSuccess => ({
    type: Actions.dictionaryOperatorCreateSuccess,
    payload,
});

export const actionDictionaryOperatorCreateFailure = (
    payload: TError
): TActionDictionaryOperatorCreateFailure => ({
    type: Actions.dictionaryOperatorCreateFailure,
    payload,
});
