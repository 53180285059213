import { TTranslation } from "../../../../i18n/translate";
import { Actions } from "../../../utils/Actions";
import { TActionShowToast, TActionTaskHideToast } from "./declarations";

export const actionShowToast = (payload: {
    message: string | TTranslation;
    category: "success" | "info" | "error";
    intlFormatter?: boolean;
    timer?: number;
}): TActionShowToast => ({
    type: Actions.showToast,
    payload,
});

export const actionHideToast = (): TActionTaskHideToast => ({
    type: Actions.hideToast,
});
