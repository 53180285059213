import { fetch } from "../../../utils/fetch";
import { utilityGetUrlEndPoint } from "../../../utils/helpers/getUrlEndPoint";
import { TParamsApiRefreshToken } from "./params";
import { TResponseApiRefreshToken } from "./response";

export const apiRefreshToken = async ({
    token,
    refreshToken,
    idStudio = 0,
}: TParamsApiRefreshToken): Promise<TResponseApiRefreshToken> =>
    await fetch.post(`${utilityGetUrlEndPoint()}/auth/refresh`, {
        token,
        refreshToken,
        idStudio,
    });
