// #region ::: IMPORT
import React, { FC, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { MenuSection } from "@magoot-sdk/declarations/general/urlParams";
import { useNavigation } from "@magoot-sdk/hooks/useNavigation";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import { selectorOrderName } from "@magoot-sdk/redux-modules/selectors/selectorsOrder";
import { selectorStudioDetail } from "@magoot-sdk/redux-modules/selectors/selectorsStudio";
import { utilityGetUrlViewDashboard } from "@magoot-sdk/utils/Routes";

// #endregion

export const Breadcrumb: FC = memo((): JSX.Element | null => {
    const location = useLocation();
    const navTo = useNavigation();
    const [firstLabel, setFirstLabel] = useState("");
    const [idOrder, setIdOrder] = useState("");
    const idStudio = useSelector((store: TStore) => store.auth?.idStudio);
    const studioName = useSelector((store: TStore) => selectorStudioDetail({ store, idStudio }));
    const orderName = useSelector((store: TStore) =>
        selectorOrderName({ store, idOrder: parseInt(idOrder, 10) })
    );

    const linkOrderView = utilityGetUrlViewDashboard({
        idStudio: idStudio ?? 0,
        menuSelected: MenuSection.ElencoCommesse,
    });
    const isOrderView = location.pathname.includes("commessa");
    const hasIdStudio = idStudio && location.pathname.includes(idStudio.toString());

    useEffect(() => {
        setFirstLabel(studioName);
        const pathSplit = location.pathname.split("/");
        setIdOrder(pathSplit[3]);
    }, [isOrderView, location, studioName]);

    const onClick = (): void => {
        navTo(linkOrderView);
    };

    if (hasIdStudio && studioName)
        return (
            <div className='breadcrumbs w-breadcrumbSm overflow-hidden text-white md:w-breadcrumbLg'>
                <ul className='w-full overflow-x-auto py-12'>
                    {firstLabel && (
                        <li
                            className={`w-fit cursor-pointer opacity-70 ${
                                orderName
                                    ? "hover:underline hover:opacity-100"
                                    : "hover:opacity-100"
                            }`}
                            onClick={onClick}
                        >
                            <p className='w-full truncate text-center'>{firstLabel}</p>
                        </li>
                    )}
                    {orderName && <li className='w-fit'>{orderName}</li>}
                </ul>
            </div>
        );
    return null;
});
Breadcrumb.displayName = "Breadcrumb";
