import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryOperatorCreate/response";
import { TParamsApiDictionaryOrderEdit } from "../../../../api/dictionary/dictionaryOrderEdit/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryOrderEditFailure,
    TActionDictionaryOrderEditRequest,
    TActionDictionaryOrderEditSuccess,
} from "./declarations";

export const actionDictionaryOrderEditRequest = (
    payload: TParamsApiDictionaryOrderEdit
): TActionDictionaryOrderEditRequest => ({
    type: Actions.dictionaryOrderEditRequest,
    payload,
});

export const actionDictionaryOrderEditSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryOrderEditSuccess => ({
    type: Actions.dictionaryOrderEditSuccess,
    payload,
});

export const actionDictionaryOrderEditFailure = (
    payload: TError
): TActionDictionaryOrderEditFailure => ({
    type: Actions.dictionaryOrderEditFailure,
    payload,
});
