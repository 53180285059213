interface Translations {
    "view.customer-detail.update-commitment": string;
    "view.customer-detail.update-operator": string;
}

interface ViewCustomerDetail {
    it: Translations;
    en: Translations;
}

export const translationsViewCustomerDetail: ViewCustomerDetail = {
    it: {
        "view.customer-detail.update-commitment": "Modifica committente",
        "view.customer-detail.update-operator": "Modifica operatore",
    },
    en: {
        "view.customer-detail.update-commitment": "Update customer",
        "view.customer-detail.update-operator": "Update operator",
    },
};

export type TTranslationsViewCustomerDetail = keyof Translations;
