// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import React, { ChangeEvent, FC, SyntheticEvent, memo, useEffect, useState } from "react";

import {
    SectionDictionary,
    getSectionDictionary,
} from "@magoot-sdk/declarations/general/urlParams";
import { TTranslation, translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { UIIcon } from "@components-ui/Icon";
import { UIInfo } from "@components-ui/Info";
import { UILoader } from "@components-ui/Loader";

import { UIButton } from "../../Button";
import { UIInputDefault } from "./partials/InputVariants/InputDefault";
import { UIInputSearch } from "./partials/InputVariants/InputSearch";

// #endregion

export interface Props {
    min?: number;
    max?: number;
    placeholder?: string;
    label?: TTranslation;
    dataCy: string;
    value?: string | number;
    type?: string;
    disabled?: boolean;
    disabledBorderColor?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    uppercase?: boolean;
    valid?: boolean;
    isLoading?: boolean;
    isRequired?: boolean;
    info?: string;
    prefix?: string | undefined;
    onFocus?: () => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
    fluid?: boolean;
    isSearching?: boolean;
    textCenter?: boolean;
    height?: number;
    fieldName?: string;
    size?: "sm" | "md" | "lg";
    tooltipText?: TTranslation;
    sectionDictionary?: SectionDictionary;
    icon?: IconProp;
    onClickIcon?: () => void;
    hasInputFocus?: (state: boolean) => void;
}

export const InputGeneric: FC<Props> = memo(
    ({
        placeholder = "",
        label: labelPassed,
        dataCy,
        value = "",
        disabled,
        disabledBorderColor,
        onChange,
        maxLength,
        min,
        max,
        type = "text",
        valid = true,
        isRequired,
        info,
        prefix,
        uppercase = false,
        fluid = true,
        isSearching = false,
        onFocus,
        onBlur,
        textCenter = false,
        height,
        size,
        fieldName,
        tooltipText,
        sectionDictionary,
        icon,
        onClickIcon,
        isLoading,
        hasInputFocus,
    }: Props): JSX.Element => {
        const locale = useLocale();
        const [hasFocus, setHasFocus] = useState(false);
        const [hidePassword, setHidePassword] = useState(false);
        const [isOpen, setIsOpen] = useState(false);

        const isPassword = type === "password";
        const labelPassword = hidePassword
            ? "general.message.password.hide"
            : "general.message.password.show";
        const label = labelPassed ? translate({ locale, id: labelPassed }) : "";

        const handlePassword = (event: SyntheticEvent): void => {
            event.preventDefault();
            setHidePassword(!hidePassword);
        };

        const onInputFocus = (): void => {
            if (onFocus) onFocus();
            setHasFocus(true);
        };

        const onInputBlur = (event: React.FocusEvent<any>): void => {
            if (onBlur) onBlur(event);
            setHasFocus(false);
        };

        const handleIsOpen = (): void => {
            setIsOpen(!isOpen);
        };

        useEffect(() => {
            if (hasInputFocus) hasInputFocus(hasFocus);
        }, [hasFocus]);

        return (
            <div className={`form-control relative ${fluid ? "w-full" : "w-auto"}`}>
                {label && (
                    <label
                        className={`label pb-[0.25rem]
            ${fluid ? "w-full" : "w-auto"}
           `}
                    >
                        <span className='label-text text-sm font-bold w-full text-left'>
                            {label}
                            {isRequired && <strong className='text-error'>*</strong>}
                        </span>
                        {!!tooltipText && (
                            <UIIcon
                                svgIcon={(!isOpen ? faPlusCircle : faTimesCircle) as IconProp}
                                onClick={handleIsOpen}
                            />
                        )}

                        {isPassword && (
                            <UIButton
                                variant='link'
                                onClick={handlePassword}
                                tabIndex={-1}
                                dataCy='TO_DO'
                                textAlign='right'
                                text={translate({ locale, id: labelPassword })}
                            />
                        )}
                    </label>
                )}

                {!!isOpen && !!tooltipText && !!sectionDictionary && (
                    <div className='absolute top-32 right-0'>
                        <UIInfo
                            backgroundColor='tertiaryLight'
                            body={tooltipText}
                            size='xs'
                            link={getSectionDictionary(sectionDictionary)}
                            sectionDictionary={sectionDictionary}
                        />
                    </div>
                )}

                {!isSearching && !isLoading && (
                    <UIInputDefault
                        type={hidePassword ? "text" : type}
                        dataCy={dataCy}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                        disabled={disabled}
                        disabledBorderColor={disabledBorderColor}
                        maxLength={maxLength}
                        max={max}
                        min={min}
                        valid={valid}
                        prefix={prefix}
                        uppercase={uppercase}
                        fluid={fluid}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        hasFocus={hasFocus}
                        textCenter={textCenter}
                        height={height}
                        size={size}
                        fieldName={fieldName}
                        icon={icon}
                        onClickIcon={onClickIcon}
                    />
                )}

                {isSearching && !isLoading && (
                    <UIInputSearch
                        type='text'
                        dataCy={dataCy}
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                        disabled={disabled}
                        maxLength={maxLength}
                        max={max}
                        min={min}
                        valid={valid}
                        prefix={prefix}
                        uppercase={uppercase}
                        onLeave={false}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        hasFocus={hasFocus}
                        size={size}
                        fieldName={fieldName}
                    />
                )}

                {info && (
                    <label className='label'>
                        <span className='label-text-alt text-error text-left'>{info}</span>
                    </label>
                )}

                {isLoading && <UILoader size={size} />}
            </div>
        );
    }
);

InputGeneric.displayName = "InputGeneric";
