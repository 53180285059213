import { useLocation } from "react-router-dom";

export const useWorkID = (): { idWork: number } => {
    const { pathname } = useLocation();
    const pathSplitted = pathname.split("/");
    const idWork = pathSplitted.includes("nuova-idea") ? NaN : parseInt(pathSplitted[5], 10);
    if (typeof idWork !== "number") throw new Error("idWork not found on URL params");

    return { idWork };
};
