import { fetch } from "../../../utils/fetch";
import { TParamsApiMoveRecord } from "./params";
import { TResponseApiMoveRecord } from "./response";

export const apiMoveRecord = async ({
    idStudio,
    idStatoEsecuzione,
    tabella,
    id,
    idPadre,
    ordine,
}: TParamsApiMoveRecord): Promise<TResponseApiMoveRecord> =>
    await fetch.post(`${process.env.REACT_APP_MAIN_ENDPOINT}/studio/${idStudio}/dizionari/ordina`, {
        tabella,
        id,
        idStatoEsecuzione,
        ordine,
        idPadre,
    });
