export enum TNoteType {
    Studio = 1,
    Commessa,
    Lavorazione,
    Attivita,
}

export interface TNote {
    id: number;
    idStudio: number;
    idUtente: number;
    idCommessa: number | null;
    idLavorazione: number | null;
    idTipoNote: number;
    idRiferimento: number;
    descr: string;
    privato: boolean;
    eliminato: boolean;
    dataEliminazione: string | null;
    dataCreazione: string;
    dataModifica: string | null;
}

export interface TCurrentNote {
    isOpen: boolean;
    tipo?: TNoteType;
    idOrder?: number;

    idWork?: number;
    idTask?: number;
}
