import { TNotification } from "../../../reducers/reducerNotifications/declarations";
import { Actions } from "../../../utils/Actions";
import {
    TActionNotificationDecrement,
    TActionNotificationIncrement,
    TActionNotificationReceive,
    TActionNotificationReload,
} from "./declarations";

export const actionNotificationReceive = (payload: TNotification): TActionNotificationReceive => ({
    type: Actions.notificationReceive,
    payload,
});

export const actionNotificationIncrement = (payload: {
    idStudio: number;
}): TActionNotificationIncrement => ({
    type: Actions.notificationIncrement,
    payload,
});

export const actionNotificationDecrement = (payload: {
    idStudio: number;
    idNotification: number;
}): TActionNotificationDecrement => ({
    type: Actions.notificationDecrement,
    payload,
});

export const actionNotificationReload = (payload: {
    idStudio: number;
}): TActionNotificationReload => ({
    type: Actions.notificationReload,
    payload,
});
