import { TParamsApiDictionaryTaskCreate } from "@magoot-sdk/api/dictionary/dictionaryTaskCreate/params";

import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryWorkCreate/response";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryTaskCreateFailure,
    TActionDictionaryTaskCreateRequest,
    TActionDictionaryTaskCreateSuccess,
} from "./declarations";

export const actionDictionaryTaskCreateRequest = (
    payload: TParamsApiDictionaryTaskCreate
): TActionDictionaryTaskCreateRequest => ({
    type: Actions.dictionaryTaskCreateRequest,
    payload,
});

export const actionDictionaryTaskCreateSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryTaskCreateSuccess => ({
    type: Actions.dictionaryTaskCreateSuccess,
    payload,
});

export const actionDictionaryTaskCreateFailure = (
    payload: TError
): TActionDictionaryTaskCreateFailure => ({
    type: Actions.dictionaryTaskCreateFailure,
    payload,
});
