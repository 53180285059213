import { Actions } from "../../utils/Actions";
import { TReducerNotification, TReducerNotificationsActions } from "./declarations";

export const reducerNotifications = (
    prevStore: TReducerNotification = {},
    action: TReducerNotificationsActions
): TReducerNotification => {
    switch (action.type) {
        case Actions.notificationUnreadListDecrement:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    allNotifications: {
                        ...prevStore[action.payload.idStudio].allNotifications,
                        nonLette: {
                            ...prevStore[action.payload.idStudio].allNotifications.nonLette,
                            items: prevStore[
                                action.payload.idStudio
                            ].allNotifications.nonLette.items.filter((item) => {
                                return item.id !== action.payload.idNotification;
                            }),
                        },
                    },
                },
            };
        case Actions.notificationReadListDecrement:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    allNotifications: {
                        ...prevStore[action.payload.idStudio].allNotifications,
                        lette: {
                            ...prevStore[action.payload.idStudio].allNotifications.lette,
                            items: prevStore[
                                action.payload.idStudio
                            ].allNotifications.lette.items.filter((item) => {
                                return item.id !== action.payload.idNotification;
                            }),
                        },
                    },
                },
            };
        case Actions.notificationIncrement:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    unreadNotifications: {
                        ...prevStore[action.payload.idStudio]?.unreadNotifications,
                        count:
                            (prevStore[action.payload.idStudio]?.unreadNotifications.count || 0) +
                            1,
                        reload: true,
                    },
                },
            };
        case Actions.notificationReload:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    unreadNotifications: {
                        ...prevStore[action.payload.idStudio]?.unreadNotifications,
                        reload: false,
                    },
                },
            };
        case Actions.notificationDecrement:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    unreadNotifications: {
                        ...prevStore[action.payload.idStudio]?.unreadNotifications,
                        count:
                            (prevStore[action.payload.idStudio]?.unreadNotifications.count || 0) -
                            1,
                        obj: {
                            ...prevStore[action.payload.idStudio]?.unreadNotifications.obj,
                            items: prevStore[
                                action.payload.idStudio
                            ]?.unreadNotifications.obj.items.filter(
                                (item) => item.id !== action.payload.idNotification
                            ),
                        },
                    },
                },
            };
        case Actions.notificationUnreadSuccess:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    unreadNotifications: {
                        ...prevStore[action.payload.idStudio]?.unreadNotifications,
                        count: action.payload.count,
                    },
                },
            };
        case Actions.arrayNotificationsUnreadSuccess:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    allNotifications: {
                        ...prevStore[action.payload.idStudio]?.allNotifications,
                        nonLette: {
                            ...prevStore[action.payload.idStudio]?.allNotifications.nonLette,
                            ...action.payload.obj,
                        },
                    },
                },
            };
        case Actions.arrayNotificationsLastUnreadSuccess:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    unreadNotifications: {
                        ...prevStore[action.payload.idStudio]?.unreadNotifications,
                        obj: action.payload.obj,
                    },
                },
            };
        case Actions.notificationAllRequest:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    unreadNotifications: {
                        ...prevStore[action.payload.idStudio]?.unreadNotifications,
                        reload: false,
                    },
                    allNotifications: {
                        loading: true,
                    },
                },
            };
        case Actions.notificationAllSuccess:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    allNotifications: {
                        loading: false,
                        ...action.payload.notifications,
                    },
                },
            };
        case Actions.notificationAllFailure:
            return {
                ...prevStore,
                [action.payload.idStudio]: {
                    ...prevStore[action.payload.idStudio],
                    allNotifications: {
                        loading: false,
                    },
                },
            };
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
