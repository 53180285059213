import { TWork } from "@magoot-sdk/redux-modules/reducers/reducerWorks/declarations";

import { Actions, TError } from "../../../utils/Actions";
import {
    TActionWorkListFailure,
    TActionWorkListRequest,
    TActionWorkListSelected,
    TActionWorkListSuccess,
} from "./declarations";

export const actionWorkListRequest = (payload: {
    idStudio: number;
    idOrder: number;
    idWork?: number;
}): TActionWorkListRequest => ({
    type: Actions.workListRequest,
    payload,
});

export const actionWorkListSuccess = (payload: {
    works: {
        [idOrder: string]: {
            [idWork: string]: TWork;
        };
    };
    stats: {
        idOrder: number;
        total: number;
    };
}): TActionWorkListSuccess => ({
    type: Actions.workListSuccess,
    payload,
});

export const actionWorkListFailure = (payload: TError): TActionWorkListFailure => ({
    type: Actions.workListFailure,
    payload,
});

export const actionWorkListSelected = (payload: {
    idWork: number;
    idOrder: number;
}): TActionWorkListSelected => ({
    type: Actions.workListSelected,
    payload,
});
