// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBan, faCheck, faPause } from "@fortawesome/free-solid-svg-icons";
import {
    faAngleLeft,
    faAnglesLeft,
    faAnglesRight,
    faBriefcase,
    faChartGantt,
    faFile,
    faGripDotsVertical,
    faList,
    faSeedling,
    faUserMagnifyingGlass,
} from "@fortawesome/pro-light-svg-icons";
import React, { FC, MutableRefObject, memo, useEffect, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { List } from "react-content-loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { apiMoveRecord } from "@magoot-sdk/api/dictionary/moveRecord";
import { StatoDiritto, StatoSezione } from "@magoot-sdk/api/diritti/response";
import { OrderStatus } from "@magoot-sdk/constants/general";
import { useNavigation } from "@magoot-sdk/hooks/useNavigation";
import { useToolbarNavigation } from "@magoot-sdk/hooks/useToolbarNavigation";
import { translate } from "@magoot-sdk/i18n/translate";
import { actionShowToast } from "@magoot-sdk/redux-modules/actions/general/actionToast";
import { actionUIFilterSummary } from "@magoot-sdk/redux-modules/actions/ui/actionUi";
import {
    actionWorkListRequest,
    actionWorkListSelected,
} from "@magoot-sdk/redux-modules/actions/work/actionWorkList";
import { useDiritti } from "@magoot-sdk/redux-modules/hooks/diritti/useDiritti";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useWorkID } from "@magoot-sdk/redux-modules/hooks/useWorkId";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import { TWork } from "@magoot-sdk/redux-modules/reducers/reducerWorks/declarations";
import { selectorWorkList } from "@magoot-sdk/redux-modules/selectors/selectorWork";
import { selectorIsFormNotSaved } from "@magoot-sdk/redux-modules/selectors/selectorsUI";
import {
    utilityGetUrlViewAnnotationList,
    utilityGetUrlViewDocumentList,
    utilityGetUrlViewInspectionList,
    utilityGetUrlViewMunicipalPractices,
    utilityGetUrlViewOrder,
    utilityGetUrlViewTaskList,
} from "@magoot-sdk/utils/Routes";
import { Service } from "@magoot-sdk/utils/Service";

import { UIButton } from "@components-ui/Button";
import { UIIcon } from "@components-ui/Icon";

import { WorkCreate } from "./WorkCreate";

export interface Props {
    idStudio: number;
    idOrder: number;
    isMobile: boolean;
    drawerRef?: MutableRefObject<HTMLInputElement | undefined>;
    goOrder?: (state: boolean) => void;
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WorkFields: FC<Props> = memo(
    ({ idStudio, idOrder, isMobile, goOrder, drawerRef, expanded, setExpanded }): JSX.Element => {
        const locale = useLocale();
        const dispatch = useDispatch();
        const navigateTo = useNavigation();
        const { idWork } = useWorkID();
        const [isWorkLoading, setIsWorkLoading] = useState(false);
        const [selectedOrder, setSelectedOrder] = useState(0);

        const isFormNotSaved = useSelector(selectorIsFormNotSaved);
        const location = useLocation();
        const { isTabDocuments, isTabInspections, isTabAnnotations, isTabActivities } =
            useToolbarNavigation({
                idStudio,
                idOrder,
                idWork,
                locationPathname: location.pathname,
            });
        const {
            dirittoAttivita,
            dirittoDocumento,
            dirittoSopralluogo,
            dirittoAnnotazione,
            tabAttiveFasi,
        } = useDiritti();

        const linkWorkList = utilityGetUrlViewOrder({
            idOrder,
            idStudio,
        });

        const onNavigateWorkSummary = (): void => {
            dispatch(actionUIFilterSummary({ state: "all" }));
            navigateTo(linkWorkList);
        };

        const onNavigate = (work: TWork): void => {
            let url = "";

            if (work?.id && dirittoAttivita !== StatoDiritto.Nessuno) {
                url = utilityGetUrlViewTaskList({
                    idOrder,
                    idWork: work.id,
                    idStudio,
                });
            }

            if (
                work?.praticaComunale &&
                work?.id &&
                !isTabDocuments &&
                !isTabInspections &&
                !isTabAnnotations &&
                !isTabActivities
            ) {
                url = utilityGetUrlViewMunicipalPractices({
                    idOrder,
                    idWork: work.id,
                    idStudio,
                });
            }

            if (isTabDocuments && work?.id && dirittoDocumento !== StatoDiritto.Nessuno) {
                url = utilityGetUrlViewDocumentList({
                    idOrder,
                    idWork: work.id,
                    idStudio,
                });
            }

            if (isTabInspections && work?.id && dirittoSopralluogo !== StatoDiritto.Nessuno) {
                url = utilityGetUrlViewInspectionList({
                    idOrder,
                    idWork: work.id,
                    idStudio,
                });
            }

            if (isTabAnnotations && work?.id && dirittoAnnotazione !== StatoDiritto.Nessuno) {
                url = utilityGetUrlViewAnnotationList({
                    idOrder,
                    idWork: work.id,
                    idStudio,
                });
            }

            if (tabAttiveFasi && tabAttiveFasi.length > 0 && work.id) {
                if (tabAttiveFasi[0].sezione.id === StatoSezione.Annotazione) {
                    url = utilityGetUrlViewAnnotationList({
                        idOrder,
                        idWork: work.id,
                        idStudio,
                    });
                }

                if (tabAttiveFasi[0].sezione.id === StatoSezione.Sopralluogo) {
                    url = utilityGetUrlViewInspectionList({
                        idOrder,
                        idWork: work.id,
                        idStudio,
                    });
                }

                if (tabAttiveFasi[0].sezione.id === StatoSezione.Documento) {
                    url = utilityGetUrlViewDocumentList({
                        idOrder,
                        idWork: work.id,
                        idStudio,
                    });
                }

                if (tabAttiveFasi[0].sezione.id === StatoSezione.Attivita) {
                    url = utilityGetUrlViewTaskList({
                        idOrder,
                        idWork: work.id,
                        idStudio,
                    });
                }
            }

            if (url) navigateTo(url);
            else
                dispatch(
                    actionShowToast({
                        message: "Nessun diritto abilitato per questa fase",
                        category: "error",
                        intlFormatter: true,
                    })
                );
        };

        const works = useSelector((store: TStore) => selectorWorkList({ store, idOrder }));
        const isDraggable = !!works && works.length > 1;

        useEffect(() => {
            setSelectedOrder(idWork);
        }, [idWork]);

        useEffect(() => {
            dispatch(actionWorkListSelected({ idOrder, idWork }));
        }, [selectedOrder]);

        const handleToolbarNavigation = (navigateFn: (work: TWork) => void, work: TWork) => {
            navigateFn(work);
            if (drawerRef && drawerRef.current) {
                drawerRef.current.click();
            }

            if (!isFormNotSaved && work?.id) {
                setSelectedOrder(work.id);
            }
        };

        const rowWork = ({ work }: { work: TWork }): JSX.Element => {
            return (
                <div className='active:transparent relative min-h-[2.5rem] w-full p-8 text-sm hover:bg-none focus:bg-none active:bg-tundora-200 active:text-tundora-500'>
                    {isDraggable && (
                        <div className='block left-0 top-2/4 -translate-y-2/4 absolute'>
                            <UIIcon
                                size='md'
                                svgIcon={faGripDotsVertical as IconProp}
                                title='ordina preventivo'
                            />
                        </div>
                    )}

                    <div
                        className={`relative ${
                            isDraggable && work?.statoEsecuzione?.id === OrderStatus.Completata
                                ? "ml-12"
                                : "ml-0"
                        }`}
                    >
                        {work?.statoEsecuzione?.id === OrderStatus.Completata && (
                            <div className='absolute px-0 top-2/4 -translate-y-2/4'>
                                <UIIcon svgIcon={faCheck as IconProp} color='#00D96C' />
                            </div>
                        )}
                        {work?.statoEsecuzione?.id === OrderStatus.Sospesa && (
                            <div className='absolute px-0 left-12 top-2/4 -translate-y-2/4'>
                                <UIIcon svgIcon={faPause as IconProp} color='#FE9900' />
                            </div>
                        )}
                        {work?.statoEsecuzione?.id === OrderStatus.InValutazione && (
                            <div className='absolute px-0 left-12 top-2/4 -translate-y-2/4'>
                                <UIIcon
                                    svgIcon={faUserMagnifyingGlass as IconProp}
                                    color='#FE9900'
                                />
                            </div>
                        )}
                        {work?.statoEsecuzione?.id === OrderStatus.Bozza && (
                            <div className='absolute px-0 left-12 top-2/4 -translate-y-2/4'>
                                <UIIcon svgIcon={faSeedling as IconProp} color='#FE9900' />
                            </div>
                        )}
                        {work?.statoEsecuzione?.id === OrderStatus.Annullata && (
                            <div className='absolute px-0 left-12 top-2/4 -translate-y-2/4'>
                                <UIIcon svgIcon={faBan as IconProp} color='#FB3F4A' />
                            </div>
                        )}

                        <p
                            className={`flex-1 text-ellipsis overflow-hidden font-medium ${
                                work?.statoEsecuzione?.id === OrderStatus.Completata ||
                                work?.statoEsecuzione?.id === OrderStatus.InCorso
                                    ? "pl-24"
                                    : "pl-36"
                            }`}
                        >
                            {work.descr}
                        </p>
                    </div>
                </div>
            );
        };

        const renderWork = (work: TWork, index: number): JSX.Element => {
            return (
                <section key={`${work.descr}-${work.id}`}>
                    {isDraggable ? (
                        <Draggable
                            draggableId={(!!work.id && work.id.toString()) || ""}
                            index={index}
                        >
                            {(provided): JSX.Element => (
                                <li
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    className={`relative block ${
                                        selectedOrder === work.id
                                            ? "rounded-lg bg-gradient-to-r from-secondary to-primary text-white active:bg-transparent"
                                            : "bg-tundora-50"
                                    }`}
                                    onClick={(): void => handleToolbarNavigation(onNavigate, work)}
                                >
                                    {rowWork({ work })}
                                </li>
                            )}
                        </Draggable>
                    ) : (
                        <li
                            className={`relative block ${
                                selectedOrder === work.id
                                    ? "rounded-lg bg-gradient-to-r from-secondary to-primary text-white active:bg-transparent"
                                    : "bg-tundora-50"
                            }`}
                            onClick={(): void => handleToolbarNavigation(onNavigate, work)}
                        >
                            {rowWork({ work })}
                        </li>
                    )}
                </section>
            );
        };

        const handleGoOrder = () => {
            if (goOrder) goOrder(true);
        };

        const onDragEnd = async (result: DropResult): Promise<any> => {
            setIsWorkLoading(true);

            const response = await Service.call(apiMoveRecord, {
                idStudio,
                ordine: result.destination?.index || 0,
                tabella: 10,
                id: +result.draggableId,
            });

            if (response) dispatch(actionWorkListRequest({ idStudio, idOrder, idWork }));

            setTimeout(() => {
                dispatch(actionWorkListSelected({ idOrder, idWork }));
                setIsWorkLoading(false);
            }, 500);
        };

        const handleFilterSummary = (state: "document" | "annotation" | "inspection" | "gantt") => {
            dispatch(actionUIFilterSummary({ state }));
            navigateTo(linkWorkList);
        };

        return (
            <section
                className={`menu h-container bg-tundora-50 p-12 py-0 shadow-xl transition-all ${
                    expanded ? "w-3/4 md:w-2/4 xl:w-[20rem]" : "w-[3rem]"
                }`}
            >
                {expanded && (
                    <div className='flex h-[3.9rem] items-center justify-between py-8'>
                        <div className='flex items-center space-x-8'>
                            {isMobile && (
                                <UIButton
                                    isCircle
                                    fluid={false}
                                    icon={faAngleLeft as IconProp}
                                    dataCy='TO_BE_FIXED'
                                    onClick={handleGoOrder}
                                    variant='secondary'
                                    size='sm'
                                    title='Torna indietro'
                                />
                            )}
                            {dirittoSopralluogo !== StatoDiritto.Nessuno &&
                                dirittoAnnotazione !== StatoDiritto.Nessuno &&
                                dirittoDocumento !== StatoDiritto.Nessuno && (
                                    <div
                                        className='link link-hover link-secondary text-lg font-medium uppercase w-full text-right'
                                        onClick={onNavigateWorkSummary}
                                    >
                                        {translate({ locale, id: "general.label.summary" })}
                                    </div>
                                )}
                        </div>
                        <div className='space-x-8'>
                            {dirittoAttivita !== StatoDiritto.Nessuno && (
                                <UIButton
                                    icon={faChartGantt as IconProp}
                                    onClick={() => handleFilterSummary("gantt")}
                                    dataCy='TO_BE_FIXED'
                                    variant='ternary'
                                    title='Gantt'
                                    size='sm'
                                    isCircle
                                    fluid={false}
                                />
                            )}
                            {dirittoDocumento !== StatoDiritto.Nessuno && (
                                <UIButton
                                    icon={faFile as IconProp}
                                    onClick={() => handleFilterSummary("document")}
                                    dataCy='TO_BE_FIXED'
                                    variant='ternary'
                                    title='Documenti'
                                    size='sm'
                                    isCircle
                                    fluid={false}
                                />
                            )}
                            {dirittoSopralluogo !== StatoDiritto.Nessuno && (
                                <UIButton
                                    icon={faBriefcase as IconProp}
                                    onClick={() => handleFilterSummary("inspection")}
                                    dataCy='TO_BE_FIXED'
                                    variant='ternary'
                                    title='Sopralluoghi'
                                    size='sm'
                                    isCircle
                                    fluid={false}
                                />
                            )}
                            {dirittoAnnotazione !== StatoDiritto.Nessuno && (
                                <UIButton
                                    icon={faList as IconProp}
                                    onClick={() => handleFilterSummary("annotation")}
                                    dataCy='TO_BE_FIXED'
                                    variant='ternary'
                                    title='Annotazioni'
                                    size='sm'
                                    isCircle
                                    fluid={false}
                                />
                            )}
                        </div>
                    </div>
                )}

                <section className='relative block w-full'>
                    <div className='mb-24 border-b border-base-300' />

                    <div
                        className={`absolute -right-28 -top-16 hidden xl:block ${
                            expanded ? "-top-16" : "top-16"
                        }`}
                    >
                        <UIButton
                            icon={(expanded ? faAnglesLeft : faAnglesRight) as IconProp}
                            size='sm'
                            onClick={() => setExpanded((prev) => !prev)}
                            dataCy='TO_BE_FIXED'
                            variant='ternary'
                            title={expanded ? "Chiudi menù fasi" : "Apri menù fasi"}
                            isCircle
                            fluid={false}
                        />
                    </div>
                </section>

                {expanded && (
                    <>
                        <WorkCreate />
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='lista-fasi'>
                                {(provided): JSX.Element => (
                                    <ul
                                        className='mt-12 max-h-workListContainer h-full overflow-y-auto pb-40'
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {works && !isWorkLoading && works.map(renderWork)}
                                        {isWorkLoading && (
                                            <List backgroundColor='rgb(242, 234, 255)' />
                                        )}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}
            </section>
        );
    }
);

WorkFields.displayName = "WorkFields";
