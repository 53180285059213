// #region ::: IMPORT
import React, { FC, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useNavigation } from "@magoot-sdk/hooks/useNavigation";
import { translate } from "@magoot-sdk/i18n/translate";
import { actionHidePopup } from "@magoot-sdk/redux-modules/actions/general/actionPopup";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import {
    selectorPopupBody,
    selectorPopupHiddenClose,
    selectorPopupInformationFlag,
    selectorPopupIsVisible,
    selectorPopupLoader,
    selectorPopupNotOnDeleteType,
    selectorPopupOnConfirmPayload,
    selectorPopupOnConfirmType,
    selectorPopupOnSave,
    selectorPopupTitle,
} from "@magoot-sdk/redux-modules/selectors/selectorPopup";
import { useDispatch } from "@magoot-sdk/redux-modules/utils/useDispatch";
import {
    utilityGetUrlViewInspectionList,
    utilityGetUrlViewInspectionSelected,
} from "@magoot-sdk/utils/Routes";

import { UIPopup } from "./partials/Popup";

// #endregion

export const Popup: FC = memo((): JSX.Element | null => {
    const dispatch = useDispatch();
    const locale = useLocale();
    const title = useSelector(selectorPopupTitle);
    const body = useSelector(selectorPopupBody);
    const notOnDelete = useSelector(selectorPopupNotOnDeleteType);
    const actionType = useSelector(selectorPopupOnConfirmType);
    const information = useSelector(selectorPopupInformationFlag);
    const hiddenClose = useSelector(selectorPopupHiddenClose);
    const loader = useSelector(selectorPopupLoader);
    const payload = useSelector(selectorPopupOnConfirmPayload);
    const isPopupVisible = useSelector(selectorPopupIsVisible);
    const onSave = useSelector(selectorPopupOnSave);

    const [loading, setLoading] = useState(false);
    const [animate, setAnimate] = useState<"animate-mount" | "animate-unmount">("animate-mount");

    // #region ::: WHEN DELETED REDIRECT ON INSPECTION LIST CAUSE URL
    const location = useLocation();
    const navigateTo = useNavigation();
    const linkViewInspectionListEmpty = utilityGetUrlViewInspectionList({
        idOrder: payload ? payload.idOrder : 0,
        idWork: payload ? payload.idWork : 0,
        idStudio: payload ? payload.idStudio : 0,
    });
    const linkViewInspectionListSelected = utilityGetUrlViewInspectionSelected({
        idOrder: payload ? payload.idOrder : 0,
        idWork: payload ? payload.idWork : 0,
        idStudio: payload ? payload.idStudio : 0,
        idInspection: payload ? payload.idInspection : 0,
    });
    // #endregion
    useEffect(() => {
        setLoading(false);
    }, [loader]);

    if (!isPopupVisible || !actionType || !title || !body) return null;

    const onCancel = (): void => {
        setAnimate("animate-unmount");

        dispatch(actionHidePopup());
        setAnimate("animate-mount");
    };

    const handleLoading = (): void => {
        setLoading(true);
        dispatch({ type: actionType, payload });
    };

    const onConfirm = (): void => {
        dispatch({ type: actionType, payload });
        dispatch(actionHidePopup());

        if (
            location.pathname === linkViewInspectionListEmpty ||
            location.pathname === linkViewInspectionListSelected
        )
            navigateTo(linkViewInspectionListEmpty);
    };

    return (
        <UIPopup
            open={isPopupVisible}
            titleText={translate({ locale, id: title })}
            contentText={translate({ locale, id: body })}
            information={information}
            hiddenClose={hiddenClose}
            loader={loader}
            onCancel={onCancel}
            onSave={onSave}
            notOnDelete={notOnDelete}
            onConfirm={onConfirm}
            loading={loading}
            handleLoading={handleLoading}
            animate={animate}
        />
    );
});

Popup.displayName = "Popup";
