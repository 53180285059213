// #region ::: IMPORT
import React, { FC, memo } from "react";
import { TButtonVariant } from "theme/maps/button/mapButtonVariants";
import { useBreakpoint } from "theme/mediaQuery/breakpoints";

import { TTranslation, translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { UIButton } from "../Button";

// #endregion

export interface Props {
    isLoading?: boolean;
    isDisabledSave?: boolean;
    isDisabledDelete?: boolean;
    onSave: (params: any) => void;
    onDelete?: (params: any) => void;
    labelOnSave?: TTranslation;
    labelOnDelete?: TTranslation;
    justifyContent?:
        | "justify-center"
        | "justify-start"
        | "justify-end"
        | "justify-between"
        | "justify-around";
    isRowReversed?: boolean;
    variantDelete?: TButtonVariant;
    variantSave?: TButtonVariant;
}

export const UIActionBar: FC<Props> = memo(
    ({
        isLoading,
        isDisabledSave,
        isDisabledDelete,
        onDelete,
        onSave,
        labelOnSave = "button.save",
        labelOnDelete = "button.delete",
        justifyContent = "justify-end",
        isRowReversed = false,
        variantDelete = "link",
        variantSave = "primary",
    }): JSX.Element => {
        const locale = useLocale();
        const point = useBreakpoint();
        const isMobile = point === "md" || point === "sm" || point === "xs";

        return (
            <div
                className={`flex w-full items-center space-x-20 ${
                    isRowReversed ? "flex-row-reverse" : "flex-row"
                } ${justifyContent}`}
            >
                {onDelete && (
                    <div
                        className={`w-1/2 sm:w-fit ${
                            variantDelete === "link" ? "text-center" : ""
                        }`}
                    >
                        <UIButton
                            variant={variantDelete}
                            text={translate({ locale, id: labelOnDelete })}
                            onClick={onDelete}
                            dataCy='TO_DO'
                            fluid={isMobile}
                            disabled={isDisabledDelete}
                        />
                    </div>
                )}

                <div className='w-1/2 sm:w-fit'>
                    <UIButton
                        text={translate({ locale, id: labelOnSave })}
                        onClick={onSave}
                        isLoading={isLoading}
                        dataCy='TO_DO'
                        disabled={isDisabledSave}
                        isUppercase
                        fluid={isMobile}
                        variant={variantSave}
                    />
                </div>
            </div>
        );
    }
);
UIActionBar.displayName = "UIActionBar";
