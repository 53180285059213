import { Actions, TError } from "../../../utils/Actions";
import {
    TActionRefreshTokenFailure,
    TActionRefreshTokenRequest,
    TActionRefreshTokenSuccess,
} from "./declarations";

export const actionTokenRefreshRequest = (): TActionRefreshTokenRequest => ({
    type: Actions.refreshTokenRequest,
});

export const actionTokenRefreshSuccess = (payload: any): TActionRefreshTokenSuccess => ({
    type: Actions.refreshTokenSuccess,
    payload,
});

export const actionTokenRefreshFailure = (payload: TError): TActionRefreshTokenFailure => ({
    type: Actions.refreshTokenFailure,
    payload,
});
