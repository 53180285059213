import { TStore } from "../../../reducers/declarations";
import { Actions } from "../../../utils/Actions";
import { TActionEmptyLocalStorage, TActionSaveToLocalStorage } from "./declarations";

export const actionSaveToLocalStorageSuccess = (payload: TStore): TActionSaveToLocalStorage => ({
    type: Actions.saveToLocalStorageSuccess,
    payload,
});

export const actionEmptyLocalStorage = (): TActionEmptyLocalStorage => ({
    type: Actions.eliminaLocalStorage,
});
