interface Translations {
    "view.studio-detail.permissions.error": string;
    "view.studio-detail.not-admin": string;
    "view.studio-detail.information": string;
    "view.studio-detail.tab-studio.general-info": string;
    "view.studio-detail.tab-studio.office": string;
    "view.studio-detail.tab-studio.city": string;
    "view.studio-detail.tab-studio.contacts": string;
    "view.studio-detail.tab-team.panel": string;
    "view.studio-detail.tab-team.declined-invite": string;
    "view.studio-detail.tab-team.pending-invite": string;
    "view.studio-detail.tab-title.studio-info": string;
    "view.studio-detail.tab-title.team": string;
    "view.studio-detail.tab.team.empty": string;
    "view.studio-detail.tab.team.waiting": string;
    "view.studio-detail.tab.team.card.title": string;
    "view.studio-detail.tab.team.card.owner-title": string;
    "view.studio-detail.tab.team.card.owner-body": string;
    "view.studio-detail.tab.team.card.admin-title": string;
    "view.studio-detail.tab.team.card.admin-body.header": string;
    "view.studio-detail.tab.team.card.admin-body": string;
    "view.studio-detail.tab.team.card.admin-body.footer": string;

    "view.studio-detail.tab.team.card.partner.indoor.title": string;
    "view.studio-detail.tab.team.card.partner.indoor.body.header": string;
    "view.studio-detail.tab.team.card.partner.indoor.body": string;

    "view.studio-detail.tab.team.card.partner.outside.title": string;
    "view.studio-detail.tab.team.card.partner.outside.body.header": string;
    "view.studio-detail.tab.team.card.partner.outside.body": string;

    "view.studio-detail.tab.team.title": string;
    "view.studio-detail.tab.team.outside": string;
    "view.studio-detail.tab.rights": string;
    "view.studio-detail.tab.team.costi": string;
    "view.studio-detail.tab.team.outside.permissions": string;
    "view.studio-detail.tab.team.inside": string;
    "view.studio-detail.tab.team.outside.title": string;
    "view.studio-detail.tab.team.outside.empty": string;
}

interface ViewStudioDetail {
    it: Translations;
    en: Translations;
}

export const translationsViewStudioDetail: ViewStudioDetail = {
    it: {
        "view.studio-detail.permissions.error": "Errore nella modifica del permesso",
        "view.studio-detail.not-admin": `Solo l'amministratore può modificare i dati di questa sezione`,
        "view.studio-detail.information": `Attualmente sono stati invitati`,
        "view.studio-detail.tab-studio.general-info": "Informazioni generali",
        "view.studio-detail.tab-studio.office": "Sede",
        "view.studio-detail.tab-studio.city": "Città",
        "view.studio-detail.tab-studio.contacts": "Recapiti",
        "view.studio-detail.tab-team.panel": "Lista ruoli",
        "view.studio-detail.tab-team.declined-invite": "L'utente ha rifiutato l'invito",
        "view.studio-detail.tab-team.pending-invite": "Invito in attesa...",
        "view.studio-detail.tab-title.studio-info": "Dati studio",
        "view.studio-detail.tab-title.team": "Team",
        "view.studio-detail.tab.team.empty": "Nessun utente collegato al tuo studio",
        "view.studio-detail.tab.team.waiting": "Inviti in attesa di conferma",
        "view.studio-detail.tab.team.title": "Gestione Team",
        "view.studio-detail.tab.team.outside": "Collaboratori Esterni",
        "view.studio-detail.tab.rights": "Diritti utenti",
        "view.studio-detail.tab.team.costi": "Costi collaboratori",
        "view.studio-detail.tab.team.outside.permissions": "Abilita creazione commessa",
        "view.studio-detail.tab.team.outside.title": "Gestione Collaboratori Esterni",
        "view.studio-detail.tab.team.outside.empty":
            "Invita i collaboratori allo Studio, sperimenta la forza del lavoro di squadra con Magoot!",
        "view.studio-detail.tab.team.inside": "Team Studio",
        "view.studio-detail.tab.team.card.title": "Ruoli e diritti",

        "view.studio-detail.tab.team.card.owner-title": "Il Titolare",
        "view.studio-detail.tab.team.card.owner-body": `É l'utente che ha sottoscritto l'abbonamento e che risulta, quindi, titolare dello Studio.
        Oltre ad avere i diritti di Amministratore (vedi sotto) è l'unico utente che può gestire l'abbonamento ed i pagamenti.`,

        "view.studio-detail.tab.team.card.admin-title": "L’Amministratore",
        "view.studio-detail.tab.team.card.admin-body.header": "L’utente Amministratore può:",
        "view.studio-detail.tab.team.card.admin-body": `
        <li>Modificare i dati anagrafici dello studio</li>
        <li>Gestire i dizionari</li>
        <li>Configurare le notifiche per tutto il team</li>
        <li>Gestire il team, quindi invitare o rimuovere i collaboratori</li>
        <li>Accedere a tutte le commesse dello studio con pieni diritti</li>
        <li>Eliminare le commesse</li>
        <li>Definire il team che lavora su una commessa</li>
        `,
        "view.studio-detail.tab.team.card.admin-body.footer":
            "<b>n.b:</b> il titolare dello studio, cioè l'intestatario dell'abbonamento, oltre ai diritti di amministratore, è l'unico utente che può gestire l'abbonamento e i relativi pagamenti.",

        "view.studio-detail.tab.team.card.partner.indoor.title": "Il Collaboratore interno",
        "view.studio-detail.tab.team.card.partner.indoor.body.header":
            "L’ utente Collaboratore (interno) può:",
        "view.studio-detail.tab.team.card.partner.indoor.body": `
            <li>Creare e gestire una nuova commessa</li>
            <li>Accedere a tutte le commesse in cui è stato aggiunto, con i diritti che gli sono stati assegnati (lettura o scrittura)</li>
            <li>Gestire la rubrica dello studio</li>
            <li>Visualizzare i dati anagrafici dello studio</li>
            <li>Visualizzare i dizionari</li>
            <li>Visualizzare il team</li>
            `,

        "view.studio-detail.tab.team.card.partner.outside.title": "Il Collaboratore esterno",
        "view.studio-detail.tab.team.card.partner.outside.body.header":
            "L’ utente Collaboratore (esterno) può:",
        "view.studio-detail.tab.team.card.partner.outside.body": `
            <li>Accedere a tutte le commesse in cui è stato aggiunto, con i diritti che gli sono stati assegnati (lettura o scrittura), senza poter visualizzare le sezioni pagamenti e impostazioni, con la possibilità di vedere in sola lettura i committenti/operatori.</li>
            `,
    },
    en: {
        "view.studio-detail.permissions.error": "Errore nella modifica del permesso",
        "view.studio-detail.not-admin": `Solo l'amministratore può modificare i dati di questa sezione`,
        "view.studio-detail.information": `Attualmente sono stati invitati`,
        "view.studio-detail.tab-studio.general-info": "General info",
        "view.studio-detail.tab-studio.office": "Office",
        "view.studio-detail.tab-studio.city": "City",
        "view.studio-detail.tab-studio.contacts": "Contacts",
        "view.studio-detail.tab-team.panel": "Roles",
        "view.studio-detail.tab-team.declined-invite": "The user declined the invitation",
        "view.studio-detail.tab-team.pending-invite": "Inviation pending...",
        "view.studio-detail.tab-title.studio-info": "Studio data",
        "view.studio-detail.tab-title.team": "Team",
        "view.studio-detail.tab.team.empty": "No user connected to your study",
        "view.studio-detail.tab.team.waiting": "Pending invitations",
        "view.studio-detail.tab.team.card.title": "Roles and rights",

        "view.studio-detail.tab.team.card.owner-title": "Titolare",
        "view.studio-detail.tab.team.card.owner-body": `É l'utente che ha sottoscritto l'abbonamento e che risulta, quindi, titolare dello Studio.
        Oltre ad avere i diritti di Amministratore (vedi sotto) è l'unico utente che può gestire l'abbonamento ed i pagamenti.`,

        "view.studio-detail.tab.team.card.admin-title": "L’Amministratore",
        "view.studio-detail.tab.team.card.admin-body.header": "L’ utente Amministratore può:",
        "view.studio-detail.tab.team.card.admin-body": `
        <li>modificare i dati anagrafici dello studio</li>
        <li>gestire i dizionari</li>
        <li>configurare le notifiche per tutto il team</li>
        <li>gestire il team, quindi invitare o rimuovere i collaboratori</li>
        <li>accedere a tutte le commesse dello studio con pieni diritti</li>
        <li>eliminare le commesse</li>
        <li>definire il team che lavora su una commessa</li>
        `,
        "view.studio-detail.tab.team.card.admin-body.footer":
            "<b>n.b:</b> il titolare dello studio, cioè l'intestatario dell'abbonamento, oltre ai diritti di amministratore, è l'unico utente che può gestire l'abbonamento e i relativi pagamenti.",

        "view.studio-detail.tab.team.card.partner.indoor.title": "Il Collaboratore interno",
        "view.studio-detail.tab.team.card.partner.indoor.body.header":
            "L’ utente Collaboratore (interno) può:",
        "view.studio-detail.tab.team.card.partner.indoor.body": `
            <li>creare e gestire una nuova commessa</li>
            <li>accedere a tutte le commesse in cui è stato aggiunto, con i diritti che gli sono stati assegnati (lettura o scrittura)</li>
            <li>gestire la rubrica dello studio</li>
            <li>gestire il team, quindi invitare o rimuovere i collaboratori</li>
            <li>visualizzare i dati anagrafici dello studio</li>
            <li>visualizzare i dizionari</li>
            <li>visualizzare il team</li>
            `,

        "view.studio-detail.tab.team.card.partner.outside.title": "Il Collaboratore esterno",
        "view.studio-detail.tab.team.card.partner.outside.body.header":
            "L’ utente Collaboratore (esterno) può:",
        "view.studio-detail.tab.team.card.partner.outside.body": `
        <li>accedere a tutte le commesse in cui è stato aggiunto, con i diritti che gli sono stati assegnati (lettura o scrittura), senza poter visualizzare le sezioni pagamenti e impostazioni, con la possibilità di vedere in sola lettura i committenti/operatori.</li>
        `,

        "view.studio-detail.tab.team.title": "Gestione Team",
        "view.studio-detail.tab.team.outside": "Collaboratori Esterni",
        "view.studio-detail.tab.rights": "Diritti",
        "view.studio-detail.tab.team.costi": "Costi collaboratori",
        "view.studio-detail.tab.team.outside.permissions": "Abilita creazione commessa",
        "view.studio-detail.tab.team.outside.title": "Gestione Collaboratori Esterni",
        "view.studio-detail.tab.team.outside.empty":
            "Invita i collaboratori esterni a far parte del tuo Studio!",
        "view.studio-detail.tab.team.inside": "Team Studio",
    },
};

export type TTranslationsViewStudioDetail = keyof Translations;
