import { TStudio } from "../../../reducers/reducerStudio/declarations";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionStudioListFailure,
    TActionStudioListRequest,
    TActionStudioListSuccess,
} from "./declarations";

export const actionStudioListRequest = (): TActionStudioListRequest => ({
    type: Actions.studioListRequest,
});

interface Payload {
    [id: string]: TStudio;
}

export const actionStudioListSuccess = (payload: Payload): TActionStudioListSuccess => ({
    type: Actions.studioListSuccess,
    payload,
});

export const actionStudioListFailure = (payload: TError): TActionStudioListFailure => ({
    type: Actions.studioListFailure,
    payload,
});
