import { Store as TStore } from "redux";

import { apiRefreshToken } from "../api/auth/authRefeshToken";
import { TResponseApiRefreshToken } from "../api/auth/authRefeshToken/response";
import { actionSessionExpired } from "../redux-modules/actions/auth/actionSessionExpired";
import {
    actionTokenRefreshFailure,
    actionTokenRefreshRequest,
    actionTokenRefreshSuccess,
} from "../redux-modules/actions/auth/actionTokenRefresh";
import { actionError } from "../redux-modules/actions/general/actionError";
import { ReduxStore } from "../redux-modules/configureStore";
import { selectorUser } from "../redux-modules/selectors/selectorsAuth";
import { selectorIsRefreshingToken } from "../redux-modules/selectors/selectorsUI";
import { Actions } from "../redux-modules/utils/Actions";
import { utilityCheckExpirationDateUTC } from "./time/checkExpirationDateUTC";

type Fn<P> = (params?: any) => P;

class ServiceImplementation {
    tokenRefreshPromise: Promise<void> | null = null;

    store: TStore;

    constructor(store: TStore) {
        this.store = store;
    }

    updateToken = async (idStudioParam?: number): Promise<void> => {
        try {
            this.store.dispatch(actionTokenRefreshRequest());
            const state = this.store.getState();

            const { token, refreshToken, idStudio } = selectorUser(state);

            if (!token || !refreshToken) {
                this.store.dispatch(
                    actionTokenRefreshFailure({
                        error: "BadRequest",
                        message: {
                            message: "The Token field is required.",
                            details: [
                                "The Token field is required.",
                                "The RefreshToken field is required.",
                            ],
                        },
                    })
                );
                this.store.dispatch(actionSessionExpired());
            } else {
                const response: TResponseApiRefreshToken = await apiRefreshToken({
                    token,
                    refreshToken,
                    idStudio: idStudioParam || idStudio || 0,
                });
                const { error, result, message } = response;
                if (error) {
                    this.store.dispatch(
                        actionTokenRefreshFailure({
                            error: message,
                            message: {
                                message: error.message,
                                details: error.detail,
                            },
                        })
                    );
                    this.store.dispatch(actionSessionExpired());
                } else if (result) this.store.dispatch(actionTokenRefreshSuccess(result));
            }
        } catch (error) {
            this.store.dispatch(actionError(Actions.refreshTokenFailure));
            this.store.dispatch(actionSessionExpired());
        }
    };

    private isTokenValid = (idStudioParam?: number): boolean => {
        const state = this.store.getState();
        const { token, tokenExpiry, idStudio } = selectorUser(state);
        const isTokenExpired = utilityCheckExpirationDateUTC(tokenExpiry);
        if (!token) return false;
        if (idStudioParam && idStudioParam !== idStudio) return false;
        if (isTokenExpired) return false;
        return true;
    };

    getTokenStored = (): string | undefined => {
        const state = this.store.getState();
        const { token } = selectorUser(state);
        return token;
    };

    getToken = async (idStudioParam?: number): Promise<string> => {
        const state = this.store.getState();
        const token = this.getTokenStored();
        const isRefreshing = selectorIsRefreshingToken(state);

        if (isRefreshing) await this.tokenRefreshPromise;
        if (this.isTokenValid(idStudioParam)) return token!;
        this.tokenRefreshPromise = this.updateToken(idStudioParam);
        await this.tokenRefreshPromise;
        this.tokenRefreshPromise = null;

        return this.getTokenStored()!;
    };

    call = async <T>(apiFunction: Fn<T>, params?: any, idStudio?: number): Promise<T> => {
        await this.getToken(idStudio);
        const response = await apiFunction(params);

        return response;
    };
}

export const Service = new ServiceImplementation(ReduxStore.getInstance());
