import { Actions } from "../../utils/Actions";
import { TStore } from "../declarations";
import { TReducerStatsActions } from "./declarations";

export const reducerStatistics = (
    prevStore: TStore["statistics"] = {},
    action: TReducerStatsActions
): TStore["statistics"] => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.studioStatsSuccess:
            return {
                ...prevStore,
                studios: {
                    ...prevStore.studios,
                    [action.payload.id]: {
                        ...action.payload,
                    },
                },
            };
        case Actions.orderStatsSuccess:
            newStore.orders = { ...newStore.orders };
            newStore.orders[action.payload.idOrder] = action.payload.stats;
            return newStore;
        case Actions.commitmentDeleteSuccess:
            return {
                ...prevStore,
                orders: {
                    ...prevStore.orders,
                    [action.payload.idOrder]: {
                        ...prevStore.orders!![action.payload.idOrder],
                        committenti: prevStore.orders!![action.payload.idOrder].committenti - 1,
                    },
                },
            };
        case Actions.operatorDeleteSuccess:
            return {
                ...prevStore,
                orders: {
                    ...prevStore.orders,
                    [action.payload.idOrder]: {
                        ...prevStore.orders!![action.payload.idOrder],
                        operatori: prevStore.orders!![action.payload.idOrder].operatori - 1,
                    },
                },
            };
        case Actions.ideaDeleteSuccess:
            return {
                ...prevStore,
                orders: {
                    ...prevStore.orders,
                    [action.payload.idOrder]: {
                        ...prevStore.orders!![action.payload.idOrder],
                        idee: prevStore.orders!![action.payload.idOrder].idee - 1,
                    },
                },
            };
        case Actions.ideaCreateSuccess:
            return {
                ...prevStore,
                orders: {
                    ...prevStore.orders,
                    [action.payload?.idCommessa]:
                        {
                            ...prevStore.orders!![action.payload.idCommessa],
                            idee: prevStore.orders!![action.payload.idCommessa].idee + 1,
                        } || [],
                },
            };
        case Actions.workListSuccess:
            return prevStore.orders
                ? {
                      ...prevStore,
                      orders: {
                          ...prevStore.orders,
                          [action.payload?.stats?.idOrder]: {
                              ...prevStore?.orders!![action.payload?.stats?.idOrder],
                              lavorazioni: action.payload?.stats?.total,
                          },
                      },
                  }
                : { ...prevStore };
        case Actions.workCreateSuccess:
            let workCreate = {};
            newStore.orders = newStore.orders || {};

            if (newStore && newStore?.orders) {
                workCreate = {
                    ...newStore,
                    orders: {
                        ...newStore?.orders,
                        ...(action.payload?.work?.idCommessa && {
                            [action.payload?.work?.idCommessa]: {
                                ...newStore?.orders[action.payload?.work?.idCommessa],
                                ...(newStore?.orders[action.payload?.work?.idCommessa] && {
                                    lavorazioni:
                                        newStore.orders[action.payload?.work?.idCommessa]
                                            .lavorazioni + 1,
                                }),
                            },
                        }),
                    },
                };

                return workCreate;
            }

            return newStore;
        case Actions.workDeleteSuccess:
            let workDelete = {};
            newStore.orders = newStore.orders || {};

            if (newStore && newStore?.orders) {
                workDelete = {
                    ...newStore,
                    orders: {
                        ...newStore?.orders,
                        [action.payload.idOrder]: {
                            ...newStore?.orders[action.payload.idOrder],
                            ...(newStore?.orders[action.payload.idOrder] && {
                                lavorazioni:
                                    newStore.orders!![action.payload.idOrder].lavorazioni - 1,
                            }),
                        },
                    },
                };

                return workDelete;
            }

            return newStore;
        case Actions.sessionExpired:
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
