import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle, faTimes } from "@fortawesome/pro-light-svg-icons";
import React, { FC, memo } from "react";

import { MenuSection, SubMenuSection } from "@magoot-sdk/declarations/general/urlParams";
import { useNavigation } from "@magoot-sdk/hooks/useNavigation";
import { useStudio } from "@magoot-sdk/redux-modules/hooks/hooksStudio";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { utilityGetUrlViewDashboard } from "@magoot-sdk/utils/Routes";

import { UIButton } from "@components-ui/Button";
import { UIIcon } from "@components-ui/Icon";

interface Props {
    setIsVisible: (value: boolean) => void;
    text: string;
    buttonText: string;
    onClick: () => void;
}

export const SubscriptionAlert: FC<Props> = memo(
    ({ setIsVisible, text, buttonText }): JSX.Element => {
        const { idStudio } = useStudioID();
        const navigateTo = useNavigation();
        const { isOwner } = useStudio();

        const onClose = (): void => setIsVisible(false);

        const onClick = (): void => {
            const linkSubMenu = utilityGetUrlViewDashboard({
                idStudio,
                menuSelected: MenuSection.GestioneStudio,
                subMenuSelected: SubMenuSection.Abbonamento,
            });

            navigateTo(linkSubMenu);
        };

        return (
            <div className='alert alert-warning fixed top-8 -translate-x-2/4 left-2/4 z-50 m-0 flex w-toast flex-col justify-between rounded-lg shadow-lg md:flex-row'>
                <div>
                    <UIIcon svgIcon={faExclamationTriangle as IconProp} color='#2c004d' />
                    <span>{text}</span>
                </div>
                <div className='flex-none'>
                    {isOwner && (
                        <UIButton
                            text={buttonText}
                            dataCy='TO_BE_FIXED'
                            fluid={false}
                            onClick={onClick}
                            variant='secondary'
                        />
                    )}
                    <div className='px-16'>
                        <UIButton
                            icon={faTimes as IconProp}
                            dataCy='TO_BE_FIXED'
                            onClick={onClose}
                            variant='secondary'
                            size='md'
                        />
                    </div>
                </div>
            </div>
        );
    }
);

SubscriptionAlert.displayName = "SubscriptionAlert";
