import { Actions } from "../../utils/Actions";
import { TStore } from "../declarations";
import { TReducerDictionaryActions } from "./declarations";

export const reducerDictionaries = (
    prevStore: TStore["dictionaries"] = {},
    action: TReducerDictionaryActions
): TStore["dictionaries"] => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.dictionaryOrderListSuccess:
            newStore.order = action.payload;
            return newStore;
        case Actions.dictionarySendingListSuccess:
            newStore.sending = action.payload;
            return newStore;
        case Actions.dictionaryWorkListSuccess:
            newStore.work = action.payload;
            return newStore;
        case Actions.dictionaryOperatorListSuccess:
            newStore.operator = action.payload;
            return newStore;
        case Actions.dictionaryCostCenterListSuccess:
            newStore.costCenter = action.payload;
            return newStore;
        case Actions.dictionaryDocumentTypeListSuccess:
            newStore.documentType = action.payload;
            return newStore;
        case Actions.dictionaryLandRegistrySuccess:
            newStore.landRegistry = action.payload;
            return newStore;
        case Actions.dictionaryModalRow:
            newStore.fields = action.payload;
            return newStore;
        case Actions.dictionaryIsLoading:
            newStore.loading = action.payload.loading;
            return newStore;
        case Actions.dictionaryCollapse:
            const remove = newStore.collapse?.filter((c) => c.id !== action.payload.id);
            newStore.collapse = [...(remove ?? []), action.payload];
            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
