import { sortBy } from "lodash";
import { createSelector } from "reselect";

import { TStore } from "../reducers/declarations";
import { List, TWorkSummaryCount } from "../reducers/reducerWorkSummary/declarations";
import { TWork } from "../reducers/reducerWorks/declarations";

export const selectorWorkDetail = ({
    store,
    idWork,
    idOrder,
}: {
    store: TStore;
    idWork: number;
    idOrder: number;
}): TWork | undefined => {
    if (!store.works) return undefined;
    if (!store.works[idOrder]) return undefined;
    if (!store.works[idOrder][idWork]) return undefined;

    return store.works[idOrder][idWork];
};

export const selectorWorks = (store: TStore): TStore["works"] => store.works || {};

export const selectorWorkList = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): TWork[] | undefined => {
    const works = selectorWorks(store);
    const workList = works[idOrder] ? Object.values(works[idOrder]) : undefined;
    if (!workList) return undefined;

    return sortBy(workList, "position");
};

export const selectorMunicipalPractice = ({
    store,
    idOrder,
    idWork,
}: {
    store: TStore;
    idOrder: number;
    idWork: number;
}): TWork | undefined => {
    const works = selectorWorks(store);
    const workList = works[idOrder] ? Object.values(works[idOrder]) : undefined;
    if (!workList) return undefined;
    return workList.find((work) => work.id === idWork);
};

export const selectorWorkSelected = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): TWork | undefined => {
    const works = selectorWorks(store);
    const workList = works[idOrder] ? Object.values(works[idOrder]) : undefined;
    if (!workList) return undefined;
    return !!workList && workList.find((value) => value.selected);
};

export const selectorDocumentList = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): List | undefined => {
    if (!store.workSummary[idOrder]) return undefined;
    return store.workSummary[idOrder]?.documentList;
};

export const selectorInspectionList = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): List | undefined => {
    if (!store.workSummary[idOrder]) return undefined;
    return store.workSummary[idOrder]?.inspectionList;
};

export const selectorAnnotationList = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): List | undefined => {
    if (!store.workSummary[idOrder]) return undefined;
    return store.workSummary[idOrder]?.annotationList;
};

export const selectorSummatyOfCount = ({
    store,
    idOrder,
    idWork,
}: {
    store: TStore;
    idOrder: number;
    idWork: number;
}): TWorkSummaryCount | undefined => {
    if (!store.workSummary[idOrder] || !idWork) return undefined;
    if (!store.workSummary[idOrder]?.count) return undefined;
    if (!store.workSummary[idOrder]?.count[idWork]) return undefined;
    return store.workSummary[idOrder].count[idWork];
};

export const selectorWorkListNoRerender = createSelector([selectorWorkList], (list) => {
    return list;
});
