// #region ::: IMPORT
import React, { ChangeEvent, FC, memo } from "react";

import { TTranslation } from "@magoot-sdk/i18n/translate";

import { UIInputDefault } from "./InputDefault";

// #endregion
interface Props {
    min?: number;
    max?: number;
    placeholder: string;
    label?: TTranslation;
    dataCy: string;
    value?: string | number;
    type?: string;
    disabled?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    uppercase?: boolean;
    valid?: boolean;
    isLoading?: boolean;
    isRequired?: boolean;
    info?: string;
    prefix?: string;
    onFocus?: () => void;
    onBlur?: (event: React.FocusEvent<any>) => void;
    onSubmit?: (event: ChangeEvent<HTMLInputElement>) => void;
    onLeave: boolean;
    shouldBlockNavigation?: boolean;
    isSearching?: boolean;
    hasFocus: boolean;
    fieldName?: string;
    size?: "sm" | "md" | "lg";
}

export const UIInputSearch: FC<Props> = memo(
    ({
        placeholder,
        dataCy,
        value = "",
        disabled,
        onChange,
        maxLength,
        min,
        max,
        valid = true,
        prefix,
        uppercase = false,
        onFocus,
        onBlur,
        hasFocus,
        size,
        fieldName,
    }) => (
        <UIInputDefault
            type='text'
            dataCy={dataCy}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            maxLength={maxLength}
            max={max}
            min={min}
            valid={valid}
            prefix={prefix}
            uppercase={uppercase}
            onFocus={onFocus}
            onBlur={onBlur}
            hasFocus={hasFocus}
            size={size}
            fieldName={fieldName}
        />
    )
);

UIInputSearch.displayName = "UIInputSearch";
