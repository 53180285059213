// #region ::: IMPORT
import React, { FC, SyntheticEvent, memo } from "react";

import { useNavigation } from "@magoot-sdk/hooks/useNavigation";

// #endregion
export interface Props {
    to: string;
    children: any;
    dataCy: string;
    fluid?: boolean;
    fit?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}

export const NavigateTo: FC<Props> = memo(
    ({
        to,
        children,
        dataCy,
        fluid = true,
        fit = false,
        onClick: callback,
        disabled,
    }: Props): JSX.Element => {
        const navigateTo = useNavigation();

        const onClick = (event: SyntheticEvent): void => {
            event.stopPropagation();
            if (disabled) return;
            if (to) navigateTo(to);
            if (callback) callback();
        };

        return (
            <div
                className={`flex ${fluid ? "" : "w-full flex-1"} cursor-pointer ${
                    !fit ? "h-full " : "h-fit"
                }`}
                onClick={onClick}
                data-cy={dataCy}
            >
                {children}
            </div>
        );
    }
);
NavigateTo.displayName = "NavigateTo";
