// #region ::: IMPORT
import magootLogo from "assets/spinner-magoot.gif";
import spinner from "assets/spinner.svg";
import React, { FC, memo } from "react";

// #endregion

export interface Props {
    fullScreen?: boolean;
    fullHeight?: boolean;
    color?: "primary" | "secondary" | "default";
    size?: "sm" | "md" | "lg";
    isSpinner?: boolean;
    iconSpinner?: boolean;
    blurred?: boolean;
    text?: string;
}

export const UILoader: FC<Props> = memo(
    ({
        fullScreen,
        fullHeight,
        color = "default",
        size,
        isSpinner = true,
        iconSpinner = true,
        blurred = false,
        text = "loading",
    }: Props): JSX.Element =>
        fullScreen ? (
            <div
                className={`absolute top-0 left-0 z-50 flex ${
                    fullHeight ? "h-full" : "h-screen"
                } w-full items-center justify-center bg-white ${
                    blurred ? "backdrop-blur-md bg-opacity-10" : ""
                }`}
            >
                {!isSpinner ? (
                    <button
                        className={`btn loading btn-accent btn-lg border-none bg-white
                            ${color === "primary" ? "text-primary" : ""}
                            ${color === "secondary" ? "text-secondary" : ""}
                        `}
                        type='button'
                    >
                        {text}
                    </button>
                ) : (
                    <img
                        className={`${
                            iconSpinner
                                ? "h-10 w-10 animate-spin"
                                : "h-[5rem] w-full object-contain"
                        }`}
                        src={iconSpinner ? spinner : magootLogo}
                        alt={text}
                    />
                )}
            </div>
        ) : (
            <>
                {!isSpinner ? (
                    <button
                        className={`btn loading btn-accent btn-xs border-none bg-white
                            ${size === "sm" ? "btn-sm" : ""}
                            ${size === "md" ? "btn-md" : ""}
                            ${size === "lg" ? "btn-lg" : ""}
                            ${color === "primary" ? "text-primary" : ""}
                            ${color === "secondary" ? "text-secondary" : ""}
                            ${color === "default" ? "text-black" : ""}
                        `}
                        type='button'
                    >
                        {text}
                    </button>
                ) : (
                    <img
                        className={`${
                            iconSpinner
                                ? `${size !== "sm" ? "h-10 w-10" : "h-7 w-7"} animate-spin`
                                : "h-[5rem] w-full object-contain"
                        }`}
                        src={iconSpinner ? spinner : magootLogo}
                        alt={text}
                    />
                )}
            </>
        )
);

UILoader.displayName = "Loader";
