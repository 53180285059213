interface Translations {
    "view.activation.title": string;
    "view.activation.description": string;
}

interface ViewActivation {
    it: Translations;
    en: Translations;
}

export const translationsViewActivation: ViewActivation = {
    it: {
        "view.activation.title": "Sei pronto ad entrare in Magoot?",
        "view.activation.description": "Un mondo di novità ti aspetta...",
    },
    en: {
        "view.activation.title": "Are you ready to Magoot?",
        "view.activation.description": "Discover your new world...",
    },
};

export type TTranslationsViewActivation = keyof Translations;
