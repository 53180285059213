import { createDownloadZipHeader } from "./config";

const fetchDOWNLOADZIP = async (url: string, params: any): Promise<any> => {
    const response = await fetch(url, {
        method: "POST",
        headers: createDownloadZipHeader(),
        body: JSON.stringify(params),
    });
    return response ? response.blob() : null;
};

export default fetchDOWNLOADZIP;
