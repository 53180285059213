import { useEffect } from "react";
import { useSelector } from "react-redux";

import { actionIdeaListRequest } from "../../actions/idea/actionIdeaList";
import { TStore } from "../../reducers/declarations";
import { TIdea } from "../../reducers/reducerIdeas/declarations";
import { selectorIsCreatingIdea, selectorIsUploadLoadingIdea } from "../../selectors/selectorsUI";
import { useDispatch } from "../../utils/useDispatch";
import { useOrderID } from "../useOrderID";
import { useStudioID } from "../useStudioID";

// #region ::: SELECTORS
export const selectorIdeaList = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): TIdea[] | false => {
    if (!store?.ideas) return false;
    if (!store?.ideas[idOrder]) return false;
    if (!store?.ideas[idOrder]?.value) return false;

    const ideaList = Object.values((store.ideas[idOrder] as any).value as TIdea[]);
    return ideaList.length > 0 ? ideaList : false;
};

export const selectorIdeaListLoading = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): boolean => {
    if (!store?.ideas) return false;
    if (!store?.ideas[idOrder]) return false;
    return store.ideas[idOrder].loading;
};
// #endregion

export const useIdeaList = (): {
    ideaList: TIdea[] | false;
    isLoadingIdea: boolean | undefined;
} => {
    const dispatch = useDispatch();
    const { idOrder } = useOrderID();
    const { idStudio }: any = useStudioID();
    const ideaList = useSelector((store: TStore) =>
        selectorIdeaList({
            store,
            idOrder,
        })
    );
    const isCreatingIdea = useSelector(selectorIsCreatingIdea);
    const isUploadLoadingIdeaLoadingIdea = useSelector(selectorIsUploadLoadingIdea);
    const isLoadingIdea = useSelector(selectorIdeaListLoading);

    const ideaListSortedByDate =
        ideaList &&
        ideaList.sort((a, b) => {
            const dateA = new Date(a.dataCreazione).getTime();
            const dateB = new Date(b.dataCreazione).getTime();
            return dateA < dateB ? 1 : -1;
        });

    useEffect(() => {
        if (!isCreatingIdea) dispatch(actionIdeaListRequest({ idOrder, idStudio }));
    }, [dispatch, isCreatingIdea]);

    return {
        ideaList: ideaListSortedByDate,
        isLoadingIdea: isUploadLoadingIdeaLoadingIdea ?? isLoadingIdea,
    };
};
