interface Translations {
    "reports.start.date": string;
    "reports.end.date": string;
    "reports.dashboard.orders.tab": string;
    "reports.statistics.tab": string;
    "reports.payments.tab": string;
    "reports.all.movement": string;
    "reports.movement": string;
    "reports.orders.movement": string;
    "reports.economic.movement": string;
    "reports.error": string;
    "reports.reload.data": string;
    "reports.error.persistent": string;
}

interface Reports {
    it: Translations;
    en: Translations;
}

export const translationsReports: Reports = {
    it: {
        "reports.dashboard.orders.tab": "Dashboard",
        "reports.start.date": "Dal",
        "reports.end.date": "al",
        "reports.statistics.tab": "Statistiche commesse",
        "reports.payments.tab": "Report economici",
        "reports.all.movement": "movimentazioni economiche",
        "reports.movement": "Movimentazioni",
        "reports.orders.movement": "Filtra i dati per la data di creazione commessa",
        "reports.economic.movement": "Filtra i dati per le movimentazioni economiche",
        "reports.error": "Errore nel recupero del report",
        "reports.reload.data": "ricarica report",
        "reports.error.persistent":
            "Ci scusiamo per il disagio, se l’errore persiste contatta assistenza@magoot.it",
    },
    en: {
        "reports.dashboard.orders.tab": "Dashboard",
        "reports.start.date": "Dal",
        "reports.end.date": "al",
        "reports.statistics.tab": "Statistiche commesse",
        "reports.payments.tab": "Report economici",
        "reports.all.movement": "movimentazioni economiche",
        "reports.movement": "Movimentazioni",
        "reports.orders.movement": "Commesse",
        "reports.economic.movement": "Economiche",
        "reports.error": "Errore nel recupero del report",
        "reports.reload.data": "ricarica report",
        "reports.error.persistent":
            "Ci scusiamo per il disagio, se l’errore persiste contatta assistenza@magoot.it",
    },
};

export type TTranslationsReports = keyof Translations;
