import { TAnnotation } from "@magoot-sdk/redux-modules/reducers/reducerAnnotations/declarations";
import { TDocumentWork } from "@magoot-sdk/redux-modules/reducers/reducerDocumentsWork/declarations";
import { TInspection } from "@magoot-sdk/redux-modules/reducers/reducerInspections/declarations";
import { Count } from "@magoot-sdk/redux-modules/reducers/reducerWorkSummary/declarations";

import { TParamsApiWorkSummary } from "../../../../api/work/workSummary/params";
import { Actions } from "../../../utils/Actions";
import {
    TActionAnnotationSummaryFailure,
    TActionAnnotationSummaryLoading,
    TActionAnnotationSummaryRequest,
    TActionAnnotationSummarySuccess,
    TActionDocumentSummaryFailure,
    TActionDocumentSummaryLoading,
    TActionDocumentSummaryRequest,
    TActionDocumentSummarySuccess,
    TActionInspectionSummaryFailure,
    TActionInspectionSummaryLoading,
    TActionInspectionSummaryRequest,
    TActionInspectionSummarySuccess,
    TActionSummaryOfCountFailure,
    TActionSummaryOfCountLoading,
    TActionSummaryOfCountRequest,
    TActionSummaryOfCountSuccess,
} from "./declarations";

export const actionDocumentSummaryRequest = (
    payload: TParamsApiWorkSummary
): TActionDocumentSummaryRequest => ({
    type: Actions.documentSummaryRequest,
    payload,
});

export const actionDocumentSummarySuccess = (payload: {
    idOrder: number;
    summary: TDocumentWork[];
}): TActionDocumentSummarySuccess => ({
    type: Actions.documentSummarySuccess,
    payload,
});

export const actionDocumentSummaryFailure = (payload: {
    idOrder: number;
}): TActionDocumentSummaryFailure => ({
    type: Actions.documentSummaryFailure,
    payload,
});

export const actionDocumentSummaryLoading = (payload: {
    idOrder: number;
    loading: boolean;
}): TActionDocumentSummaryLoading => ({
    type: Actions.documentSummaryLoading,
    payload,
});

/** Inspection */

export const actionInspectionSummaryRequest = (
    payload: TParamsApiWorkSummary
): TActionInspectionSummaryRequest => ({
    type: Actions.inspectionSummaryRequest,
    payload,
});

export const actionInspectionSummarySuccess = (payload: {
    idOrder: number;
    summary: TInspection[];
}): TActionInspectionSummarySuccess => ({
    type: Actions.inspectionSummarySuccess,
    payload,
});

export const actionInspectionSummaryFailure = (payload: {
    idOrder: number;
}): TActionInspectionSummaryFailure => ({
    type: Actions.inspectionSummaryFailure,
    payload,
});

export const actionInspectionSummaryLoading = (payload: {
    idOrder: number;
    loading: boolean;
}): TActionInspectionSummaryLoading => ({
    type: Actions.inspectionSummaryLoading,
    payload,
});

/** Annotation */
export const actionAnnotationSummaryRequest = (
    payload: TParamsApiWorkSummary
): TActionAnnotationSummaryRequest => ({
    type: Actions.annotationSummaryRequest,
    payload,
});

export const actionAnnotationSummarySuccess = (payload: {
    idOrder: number;
    summary: TAnnotation[];
}): TActionAnnotationSummarySuccess => ({
    type: Actions.annotationSummarySuccess,
    payload,
});

export const actionAnnotationSummaryFailure = (payload: {
    idOrder: number;
}): TActionAnnotationSummaryFailure => ({
    type: Actions.annotationSummaryFailure,
    payload,
});

export const actionAnnotationSummaryLoading = (payload: {
    idOrder: number;
    loading: boolean;
}): TActionAnnotationSummaryLoading => ({
    type: Actions.annotationSummaryLoading,
    payload,
});

/** Summary of count */
export const actionSummaryOfCountRequest = (
    payload: TParamsApiWorkSummary
): TActionSummaryOfCountRequest => ({
    type: Actions.summaryOfCountRequest,
    payload,
});

export const actionSummaryOfCountSuccess = (payload: {
    idOrder: number;
    idWork: number;
    count: Count;
}): TActionSummaryOfCountSuccess => ({
    type: Actions.summaryOfCountSuccess,
    payload,
});

export const actionSummaryOfCountFailure = (payload: {
    idOrder: number;
    idWork: number;
}): TActionSummaryOfCountFailure => ({
    type: Actions.summaryOfCountFailure,
    payload,
});

export const actionSummaryOfCountLoading = (payload: {
    idOrder: number;
    idWork: number;
    loading: boolean;
}): TActionSummaryOfCountLoading => ({
    type: Actions.summaryOfCountLoading,
    payload,
});
