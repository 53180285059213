interface Translations {
    "view-order-settings.button.order-delete": string;
    "view-order-settings.button.order-download": string;
    "view-order-settings.button.download": string;
    "view-order-settings.button.onSave": string;
    "view-order-settings.order-download": string;
    "view-order-settings.tab1": string;
    "view-order-settings.tab2": string;
    "view-order-settings.tab3": string;
    "view-order-settings.tab4": string;
}

interface ViewActivation {
    it: Translations;
    en: Translations;
}

export const translationsViewOrderSetting: ViewActivation = {
    it: {
        "view-order-settings.button.order-delete": "Elimina commessa",
        "view-order-settings.button.order-download": "Richiedi la commessa",
        "view-order-settings.order-download":
            "La commessa è pronta per essere scaricata sul tuo device",
        "view-order-settings.button.download": "Scarica commessa",
        "view-order-settings.button.onSave": "ok, ho capito",
        "view-order-settings.tab1": "Informazioni generali",
        "view-order-settings.tab2": "Team commessa",
        "view-order-settings.tab3": "Costo orario",
        "view-order-settings.tab4": "Diritti utenti",
    },
    en: {
        "view-order-settings.button.order-delete": "Delete order",
        "view-order-settings.button.order-download": "Richiedi la commessa",
        "view-order-settings.order-download":
            "La commessa è pronta per essere scaricata sul tuo device",
        "view-order-settings.button.download": "Scarica commessa",
        "view-order-settings.button.onSave": "ok, ho capito",
        "view-order-settings.tab1": "Impostazioni commessa",
        "view-order-settings.tab2": "Gestione utenti",
        "view-order-settings.tab3": "Gestione costi utenti",
        "view-order-settings.tab4": "Gestione diritti",
    },
};

export type TTranslationsViewOrderSetting = keyof Translations;
