import { useSelector } from "react-redux";

import { TLocale } from "../../../i18n/declarations";
import { TStore } from "../../reducers/declarations";

const selectorLocaleSelected = (store: TStore): TLocale => store.locale;

export const useLocale = (): TLocale => {
    const locale = useSelector(selectorLocaleSelected);
    return locale;
};
