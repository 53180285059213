// #region ::: IMPORT
import { ChangeEvent, useEffect, useState } from "react";

import { useDictionaryWork } from "@magoot-sdk/redux-modules/hooks/dictionaries/useDictionaries";
import { TDictionary } from "@magoot-sdk/redux-modules/reducers/reducerDictionaries/declarations";

// #endregion

export const useInputWorks = ({
    initialValue = "",
}: {
    initialValue: string;
}): {
    work: string;
    debounceOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
    works: TDictionary[];
    setWorks: (options: TDictionary[]) => void;
} => {
    const [work, setWork] = useState(initialValue);
    const [works, setWorks] = useState<TDictionary[]>([]);
    const dictionaryWorkList = useDictionaryWork({ reload: false });
    const isDictionary = (!!dictionaryWorkList && dictionaryWorkList) || [];

    useEffect(() => {
        setWorks(isDictionary);
    }, []);

    const debounceOnChange = (value: ChangeEvent<HTMLInputElement>): void => {
        let text: any = "";

        if (!!value && !!value.target) {
            text = value.target.value;

            const newOptions = isDictionary.filter((valueNewOptions) =>
                valueNewOptions.descr.toLowerCase().includes(text.toLowerCase())
            );

            if (!!newOptions && newOptions.length > 0) setWorks(newOptions);
        } else {
            const copyValue = value as unknown;
            text = copyValue as string;
        }

        if (text.length) setWork(text);
        else setWork("");
    };

    return { work, debounceOnChange, works, setWorks };
};
