// #region ::: IMPORT
import { Location } from "history";
import React, { FC, memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Prompt, useHistory, useLocation } from "react-router";

import { translate } from "@magoot-sdk/i18n/translate";
import {
    actionUiCanLeaveForm,
    actionUiForceOpenDialog,
    actionUiIsFormNotSaved,
} from "@magoot-sdk/redux-modules/actions/ui/actionUi";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { PopupLeaveForm } from "./partials/PopupLeaveForm";

// #endregion

interface Props {
    isFormNotSaved: boolean;
    forceOpenDialog?: boolean;
    urlBlackList?: string[];
    handleStay?: () => void;
    handleLeave?: () => void;
}

export const PromptLeaveForm: FC<Props> = memo(
    ({ isFormNotSaved, forceOpenDialog, urlBlackList, handleLeave, handleStay }): JSX.Element => {
        const locale = useLocale();
        const location = useLocation();
        const history = useHistory();
        const dispatch = useDispatch();
        const [isVisiblePopup, setIsVisiblePopup] = useState(false);
        const [url, setUrl] = useState("");

        const onStay = (): void => {
            if (handleStay) {
                handleStay();
            }
            setIsVisiblePopup(false);
            setUrl("");
            dispatch(
                actionUiForceOpenDialog({
                    state: false,
                    action: "onStay",
                })
            );
        };

        const customRedirect = (): void => {
            setIsVisiblePopup(true);
        };

        const onShowPopup = (nextUrl: string): void => {
            setUrl(nextUrl);
            setIsVisiblePopup(true);
        };

        const onLeave = (): void => {
            if (handleLeave) {
                handleLeave();
            }
            isFormNotSaved = false;
            setIsVisiblePopup(false);
            if (!forceOpenDialog) history.push(url);

            dispatch(actionUiCanLeaveForm(true));
            dispatch(actionUiIsFormNotSaved(false));
            dispatch(
                actionUiForceOpenDialog({
                    state: false,
                    action: "onLeave",
                })
            );
        };

        useEffect(() => {
            if (forceOpenDialog) customRedirect();
        }, [forceOpenDialog]);

        const handlePrompt = (nextLocation: Location): boolean => {
            if (isFormNotSaved && nextLocation.pathname !== location.pathname) {
                if (urlBlackList && urlBlackList.length > 0) {
                    const isNotValidUrl = urlBlackList.some(
                        (urlBlack) => urlBlack === nextLocation.pathname
                    );
                    if (isNotValidUrl) return true;
                    onShowPopup(nextLocation.pathname);
                } else onShowPopup(nextLocation.pathname);

                return false;
            }

            return true;
        };

        return (
            <>
                <Prompt message={handlePrompt} />
                <PopupLeaveForm
                    open={isVisiblePopup}
                    titleText={translate({ locale, id: "general.modal-title.abort-form" })}
                    contentText={translate({ locale, id: "general.modal-body.abort-form" })}
                    onCancel={onStay}
                    onConfirm={onLeave}
                />
            </>
        );
    }
);

PromptLeaveForm.displayName = "PromptLeaveForm";
