interface Translations {
    "view.document-create.title-new": string;
    "view.document-create.title-update": string;
    "view.document-create.label.title": string;
    "view.document-create.label.elaborate-type": string;
    "view.document-create.label.reserved-document": string;
    "view.document-create.label.owner": string;
    "view.document-create.label.sender": string;
    "view.document-create.label.upload-date": string;
    "view.document-create.label.sender.to": string;
    "view.document-create.label.internal.upload-date": string;
    "view.document-create.label.note": string;
    "view.document-create.label.type": string;
    "view.document-create.label.internal-document": string;
    "view.document-create.label.incoming-document": string;
    "view.document-create.label.tag": string;
    "view.document-error.document": string;
    "view.document-error.document.link": string;
    "view.document-move.title": string;
    "view.document-move.work": string;
    "view.document-move.error": string;
}

interface ViewDocumentCreate {
    it: Translations;
    en: Translations;
}

export const translationsViewDocumentCreate: ViewDocumentCreate = {
    it: {
        "view.document-create.title-new": "Nuovo documento",
        "view.document-create.title-update": "Modifica documento",
        "view.document-create.label.title": "Titolo elaborato",
        "view.document-create.label.elaborate-type": "Tipo elaborato",
        "view.document-create.label.reserved-document": "Documento riservato",
        "view.document-create.label.owner": "Redatto da",
        "view.document-create.label.sender": "Inviato da",
        "view.document-create.label.internal.upload-date": "Redatto il",
        "view.document-create.label.upload-date": "Ricevuto il",
        "view.document-create.label.sender.to": "Inviato il",
        "view.document-create.label.note": "Notes",
        "view.document-create.label.type": "Tipo documento",
        "view.document-create.label.internal-document": "Documento interno",
        "view.document-create.label.incoming-document": "Documento in ingresso",
        "view.document-create.label.tag": "Tag",
        "view.document-error.document":
            "Tipo elaborato non trovato, probabilmente è stato cancellato dal dizionario",
        "view.document-error.document.link": "tipi-di-documento",
        "view.document-move.title": "Spostamento documenti",
        "view.document-move.work": "Seleziona la nuova fase",
        "view.document-move.error": "Spostamento non andato a buon fine.",
    },
    en: {
        "view.document-create.title-new": "New document",
        "view.document-create.title-update": "Update document",
        "view.document-create.label.title": "Elaborate title",
        "view.document-create.label.elaborate-type": "Elaborate type",
        "view.document-create.label.reserved-document": "Reserved document",
        "view.document-create.label.owner": "Redacted by",
        "view.document-create.label.sender": "Sender",
        "view.document-create.label.internal.upload-date": "Redatto il",
        "view.document-create.label.upload-date": "Send data",
        "view.document-create.label.sender.to": "Inviato il",
        "view.document-create.label.note": "Notes",
        "view.document-create.label.type": "Document type",
        "view.document-create.label.internal-document": "Internal document",
        "view.document-create.label.incoming-document": "Incoming document",
        "view.document-create.label.tag": "Tag",
        "view.document-error.document":
            "Tipo elaborato non trovato, probabilmente è stato cancellato dal dizionario",
        "view.document-error.document.link": "tipi-di-documento",
        "view.document-move.title": "Spostamento documenti",
        "view.document-move.work": "Seleziona la nuova fase",
        "view.document-move.error": "Spostamento non andato a buon fine.",
    },
};

export type TTranslationsViewDocumentCreate = keyof Translations;
