import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { memoize } from "lodash";

export const months = [
    "GEN",
    "FEB",
    "MAR",
    "APR",
    "MAG",
    "GIU",
    "LUG",
    "AGO",
    "SET",
    "OTT",
    "NOV",
    "DIC",
];

export const days = {
    "0": "domenica",
    "1": "lunedì",
    "2": "martedì",
    "3": "mercoledì",
    "4": "giovedì",
    "5": "venerdì",
    "6": "sabato",
};

export const utilityFormatDate = memoize((datetime: string | Date): string => {
    if (!datetime) return "";
    return format(new Date(datetime), "dd/MM/yyyy");
});

export const utilityGmtIntoUtcFormatHour = memoize((datetime?: string | Date): string => {
    if (!datetime) return "";

    try {
        const utcTimeZone = utcToZonedTime(datetime, "Europe/Rome");

        const hours = utcTimeZone.getHours();
        const minutes = utcTimeZone.getMinutes();
        const seconds = utcTimeZone.getSeconds();
        const convertedDate = new Date(Date.UTC(0, 0, 0, hours, minutes, seconds));

        const formattedHour = format(new Date(convertedDate), "HH:mm");
        return formattedHour !== "00:00" ? formattedHour : "";
    } catch (error) {
        return "";
    }
});

export const utilityFormatHour = memoize((datetime?: string | Date): string => {
    if (!datetime) return "";

    try {
        const formattedHour = format(new Date(datetime), "HH:mm");
        return formattedHour !== "00:00" ? formattedHour : "";
    } catch (error) {
        return "";
    }
});

export const utilityFormatDateToday = memoize((datetime: string | Date): string =>
    format(new Date(datetime), "yyyy-MM-dd")
);

export const utilityFormatDateUpperMonth = (datetime: Date): string => {
    const day = new Date(datetime).getDate();
    const monthIndex = new Date(datetime).getMonth();
    const year = new Date(datetime).getFullYear();

    const formattedDate = `${day}/${months[monthIndex]}/${year}`;

    return formattedDate;
};

export const utilityGetFullDay = (datetime: Date): string => {
    if (!datetime) return "";
    const date = new Date(datetime);
    const day = date.getDay();

    const formattedDay = days[day];

    return formattedDay;
};

export const utilityFormatTime = (dateTime: string): string => {
    const date = new Date(dateTime);
    const time = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
    });

    return time;
};

export const utilityFormatMinutes = (minutes: number | undefined): string => {
    if (!minutes) return "00";
    if (minutes < 10) return `0${minutes}`;

    return minutes.toString();
};

export const utilityFormatYear = (dateTime: string): string => {
    return new Date(dateTime).getFullYear().toString();
};
