import { memoize } from "lodash";

import {
    MenuSection,
    SubMenuSection,
    TParamsViewActivation,
    TParamsViewCommitmentCreate,
    TParamsViewCommitmentList,
    TParamsViewCustomerCreate,
    TParamsViewCustomerDetail,
    TParamsViewCustomerList,
    TParamsViewDashboard,
    TParamsViewDocumentWorkDetail,
    TParamsViewIdeaCreate,
    TParamsViewIdeaDetail,
    TParamsViewIdeaList,
    TParamsViewOperatorList,
    TParamsViewOrder,
    TParamsViewOrderCreate,
    TParamsViewOrderSettings,
    TParamsViewPayments,
    TParamsViewResetPassword,
    TParamsViewStudioDetail,
    TParamsViewSummary,
    TParamsViewTerritorialData,
    TParamsViewWork,
    TParamsViewWorkSelected,
    TParamsViewWorkTab,
    TabSection,
} from "../declarations/general/urlParams";

export enum Path {
    viewHome = `/`,
    viewNotFound = `/404`,
    // AUTH
    viewLogin = `/login`,
    viewLoginReferrer = `/login/:source?`,
    viewSignup = `/signup`,
    viewSignupReferrer = `/signup/:source?`,
    viewSignupSuccess = `/registrazione-avvenuta`,
    viewActiveAccount = `/a/:userID/:token/:idInvito?`,
    viewForgotPassword = `/password-dimenticata`,
    viewResetPassword = `/r/:userID/:token`,
    viewResetPasswordSuccess = `/p/password-cambiata`,
    viewInvitation = `/i/:idInvito`,
    // STUDIO
    viewListaStudi = `/studi`,
    viewStudioInvitation = `/invita-studio`,
    viewStudioCreate = "/crea-studio",
    // MAIN
    viewDashboard = "/:idStudio/dashboard/:menuSelected/:subMenuSelected?/:section?",
    // ORDERS
    viewOrder = "/:idStudio/commesse/:idOrder/lavorazioni/:tabSelected?",
    viewOrderSettings = "/:idStudio/impostazioni-commessa/:idOrder/:tabSelected",
    viewOrderCreate = "/:idStudio/commesse/nuova",
    // WORK
    viewWorkDashboard = "/:idStudio/commesse/:idOrder/lavorazioni/:idWork/:tabSelected/:tabSubSection?/:tabAction?",
    // COMMITMENTS
    viewCommitmentList = `/:idStudio/commessa/:idOrder/committenti`,
    viewCommitmentCreate = `/:idStudio/commessa/:idOrder/nuovo-committente/:tabSelected`,
    // OPERATORS
    viewOperatorList = `/:idStudio/commessa/:idOrder/operatori`,
    viewOperatorCreate = `/:idStudio/commessa/:idOrder/nuovo-operatore/:tabSelected?`,
    // CUSTOMERS
    viewCustomerList = `/:idStudio/commessa/:idOrder/:category/soggetti`,
    viewCustomerCreate = `/:idStudio/commessa/:idOrder/:category/soggetto/nuovo/:tabSelected`,
    viewCustomerDetail = `/:idStudio/commessa/:idOrder/:category/soggetti/:idCustomer/:tabSelected`,
    // IDEA
    viewIdeaList = `/:idStudio/commessa/:idOrder/bacheca-idee`,
    viewIdeaCreate = `/:idStudio/commessa/:idOrder/nuova-idea/:idIdea?`,
    // TERRITORIAL DATA
    viewTerritorialData = `/:idStudio/commessa/:idOrder/dati-territoriali`,
    // SUMMARY
    viewSummary = `/:idStudio/commessa/:idOrder/riepilogo`,
    // PAYMENTS
    viewPayments = `/:idStudio/commessa/:idOrder/pagamenti/:tabSelected`,
    // USER
    viewProfile = `/:idStudio/profilo`,
    // ACCOUNT
    viewAccount = `/:idStudio/account`,
    // NOTIFICATION LIST
    viewNotificationList = `/:idStudio/notifiche`,
    // DOWNLOAD FILE BY URL
    viewDownloadFile = "/f/:UId",
    //
    viewDownloadOrder = "/:idStudio/esportazione-commessa/:UId",
    // IMPORT
    viewPaperworkImport = "/:idStudio/importa-pratiche",
}

export const utilityGetUrlViewMunicipalPractices = memoize(
    ({ idStudio, idOrder, idWork }: TParamsViewWork): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/praticaComunale`
);

export const utilityGetUrlViewDocumentList = memoize(
    ({ idStudio, idOrder, idWork }: TParamsViewWork): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/documenti`
);

export const utilityGetUrlViewTaskList = memoize(
    ({ idStudio, idOrder, idWork }: TParamsViewWork): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/attivita`
);

export const utilityGetUrlViewInspectionList = memoize(
    ({ idStudio, idOrder, idWork }: TParamsViewWork): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/sopralluoghi`
);

export const utilityGetUrlViewAnnotationList = memoize(
    ({ idStudio, idOrder, idWork }: TParamsViewWork): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/annotazioni`
);

export const utilityGetUrlViewProtocolList = memoize(
    ({ idStudio, idOrder, idWork }: TParamsViewWork): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/protocolli`
);

export const utilityGetUrlViewInspectionSelected = memoize(
    ({ idStudio, idOrder, idWork, idInspection }: TParamsViewWorkSelected): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/sopralluoghi/${idInspection}`
);

export const utilityGetUrlViewInspectionCreate = memoize(
    ({ idStudio, idOrder, idWork }: TParamsViewWork): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/sopralluoghi/nuovo`
);

export const utilityGetUrlViewInspectionEdit = memoize(
    ({ idStudio, idOrder, idWork, idInspection }: TParamsViewWorkSelected): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/sopralluoghi/${idInspection}/modifica`
);

export const utilityGetUrlViewDocumentWorkDetail = memoize(
    ({ idStudio, idOrder, idWork, idDocument }: TParamsViewDocumentWorkDetail): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/nuovo-documento/${idDocument}`
);

export const utilityGetUrlViewCommitmentList = memoize(
    ({ idStudio, idOrder }: TParamsViewCommitmentList): string =>
        `/${idStudio}/commessa/${idOrder}/committenti`
);

export const utilityGetUrlViewIdeaList = memoize(
    ({ idStudio, idOrder }: TParamsViewIdeaList): string =>
        `/${idStudio}/commessa/${idOrder}/bacheca-idee`
);

export const utilityGetUrlViewIdeaCreate = memoize(
    ({ idStudio, idOrder }: TParamsViewIdeaCreate): string =>
        `/${idStudio}/commessa/${idOrder}/nuova-idea`
);

export const utilityGetUrlViewIdeaDetail = memoize(
    ({ idStudio, idOrder, idIdea }: TParamsViewIdeaDetail): string =>
        `/${idStudio}/commessa/${idOrder}/nuova-idea/${idIdea}`
);

export const utilityGetUrlViewCustomerCreate = memoize(
    ({ idStudio, idOrder, category, tabSelected }: TParamsViewCustomerCreate): string =>
        `/${idStudio}/commessa/${idOrder}/${category}/soggetto/nuovo/${tabSelected}`
);

export const utilityGetUrlViewCommitmentCreate = memoize(
    ({ idStudio, idOrder, tabSelected }: TParamsViewCommitmentCreate): string =>
        `/${idStudio}/commessa/${idOrder}/nuovo-committente/${tabSelected}`
);

export const utilityGetUrlViewOrderSettings = memoize(
    ({ idOrder, tabSelected, idStudio }: TParamsViewOrderSettings): string =>
        `/${idStudio}/impostazioni-commessa/${idOrder}/${tabSelected}`
);

export const utilityGetUrlViewWork = ({ idOrder, idWork, idStudio }: TParamsViewWork): string =>
    `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/:tabSelected?`;

export const utilityGetUrlExplicationPaperwork = ({ idStudio }: { idStudio: number }): string =>
    `/${idStudio}/importa-pratiche`;

export const utilityGetUrlViewWorkTab = ({
    idOrder,
    idWork,
    idStudio,
    tabSelected,
    tabSubSection,
}: TParamsViewWorkTab): string => {
    const isTabSubSection = tabSubSection ? `/${tabSubSection}` : "";
    return `/${idStudio}/commesse/${idOrder}/lavorazioni/${idWork}/${tabSelected}${isTabSubSection}`;
};

export const utilityGetUrlViewForgotPassword = memoize((): string => Path.viewForgotPassword);

export const utilityGetUrlViewResetPassword = memoize(
    ({ idUser, token }: TParamsViewResetPassword): string => `/r/${idUser}/${token}`
);

export const utilityGetUrlViewResetPasswordSuccess = memoize(
    (): string => Path.viewResetPasswordSuccess
);

export const utilityGetUrlViewStudioInvitation = memoize((): string => Path.viewStudioInvitation);

export const utilityGetUrlViewNotFound = memoize((): string => Path.viewNotFound);
export const utilityGetUrlViewHome = memoize((): string => Path.viewHome);

export const utilityGetUrlViewLogin = memoize((): string => Path.viewLogin);

export const utilityGetUrlViewSignup = memoize((): string => Path.viewSignup);

export const utilityGetUrlViewSignupSuccess = memoize((): string => Path.viewListaStudi);

export const utilityGetUrlViewStudioList = memoize((): string => Path.viewListaStudi);

export const utilityGetUrlViewActiveAccount = memoize(
    ({ idUser, token, idInvitation }: TParamsViewActivation): string =>
        `/a/${idUser}/${token}/${idInvitation}`
);

export const utilityGetUrlViewOrder = memoize(
    ({ idOrder, idStudio }: TParamsViewOrder): string =>
        `/${idStudio}/commesse/${idOrder}/lavorazioni`
);

export const utilityGetUrlViewStudioDetail = memoize(
    ({ tabSelected, idStudio }: TParamsViewStudioDetail): string =>
        `/studio/${idStudio}/${tabSelected}`
);

export const utilityGetUrlViewDashboard = memoize(
    ({
        idStudio,
        menuSelected,
        subMenuSelected,
        sectionDictionary,
        sectionSubscription,
    }: TParamsViewDashboard): string => {
        const subMenuFormatted = subMenuSelected ? `/${subMenuSelected}` : "";

        return sectionDictionary
            ? `/${idStudio}/dashboard/${menuSelected}${subMenuFormatted}/${sectionDictionary}`
            : sectionSubscription
            ? `/${idStudio}/dashboard/${menuSelected}${subMenuFormatted}/${sectionSubscription}`
            : `/${idStudio}/dashboard/${menuSelected}${subMenuFormatted}`;
    }
);

export const utilityGetUrlViewOrderCreate = memoize(
    ({ idStudio }: TParamsViewOrderCreate): string => `/${idStudio}/commesse/nuova`
);

export const utilityGetUrlViewProfile = memoize(
    ({ idStudio }: { idStudio: number }): string => `/${idStudio || "-"}/profilo`
);

export const utilityGetUrlViewAccount = memoize(
    ({ idStudio }: { idStudio: number }): string => `/${idStudio || "-"}/account`
);

export const utilityGetUrlViewNotificationList = memoize(
    ({ idStudio }): string => `/${idStudio}/notifiche`
);

export const utilityGetUrlViewStudioCreate = memoize((): string => `/crea-studio`);

export const utilityGetUrlViewOperatorList = memoize(
    ({ idStudio, idOrder }: TParamsViewOperatorList): string =>
        `/${idStudio}/commessa/${idOrder}/operatori`
);

export const utilityGetUrlViewCustomerList = memoize(
    ({ idStudio, idOrder, category }: TParamsViewCustomerList): string =>
        `/${idStudio}/commessa/${idOrder}/${category}/soggetti`
);

export const utilityGetUrlViewCustomerDetail = memoize(
    ({ idStudio, idOrder, category, idCustomer, tabSelected }: TParamsViewCustomerDetail): string =>
        `/${idStudio}/commessa/${idOrder}/${category}/soggetti/${idCustomer}/${tabSelected}`
);

export const utilityGetUrlViewTerritorialData = memoize(
    ({ idStudio, idOrder }: TParamsViewTerritorialData): string =>
        `/${idStudio}/commessa/${idOrder}/dati-territoriali`
);

export const utilityGetUrlViewSummary = memoize(
    ({ idStudio, idOrder }: TParamsViewSummary): string =>
        `/${idStudio}/commessa/${idOrder}/riepilogo`
);

export const utilityGetUrlViewPayments = memoize(
    ({ idStudio, idOrder, tabSelected }: TParamsViewPayments): string =>
        `/${idStudio}/commessa/${idOrder}/pagamenti/${tabSelected}`
);

export const isDashboard = (location: any): boolean =>
    location.pathname.includes("dashboard") &&
    !location.pathname.includes(SubMenuSection.Abbonamento);

export const isProfile = (location: any): boolean => location.pathname.includes("profilo");

export const isAccount = (location: any): boolean => location.pathname.includes("account");

export const isOrder = (location: any): boolean => location.pathname.includes("commessa");

export const isWork = (location: any): boolean => location.pathname.includes("lavorazioni");

export const isOrderList = (location: any): boolean =>
    location.pathname.includes(MenuSection.ElencoCommesse);

export const isSectionWork = (location: any): boolean =>
    location.pathname.includes("attivita") ||
    location.pathname.includes("praticaComunale") ||
    location.pathname.includes("documenti") ||
    location.pathname.includes("sopralluoghi") ||
    location.pathname.includes("annotazioni");

export const isSectionPayments = (location: any): boolean =>
    location.pathname.includes("pagamenti");

export const isSectionSettings = (location: any): boolean =>
    location.pathname.includes(TabSection.ImpostazioneCommessa) ||
    location.pathname.includes(SubMenuSection.TeamInterno) ||
    location.pathname.includes(SubMenuSection.TeamEsterno);

export const isWorkSettings = (location: any): boolean =>
    location.pathname.includes("impostazioni-commessa");
