interface Translations {
    "info.dictionary.modal.title.create": string;
    "info.dictionary.modal.title.update": string;
    "info.dictionary.modal.input.title": string;
    "info.dictionary.modal.input.descr1": string;
    "info.dictionary.modal.input.descr2": string;
    "info.title.dictionary.section.order": string;
    "info.body.dictionary.section.order": string;
    "info.title.dictionary.section.operator": string;
    "info.body.dictionary.section.operator": string;
    "info.title.dictionary.dictionary": string;
    "info.body.dictionary.dictionary": string;
    "info.title.dictionary.section.sending": string;
    "info.body.dictionary.section.sending": string;
    "info.title.dictionary.section.work": string;
    "info.body.dictionary.section.work": string;
    "info.title.dictionary.cost.centers": string;
    "info.body.dictionary.cost.centers": string;
    "info.title.dictionary.document.type": string;
    "info.body.dictionary.document.type": string;
    "info.title.section.notification.config": string;
    "info.body.section.notification.config": string;
    "info.studio.title": string;
    "info.studio.body": string;
    "dictionary.section.work.search": string;
}

interface Info {
    it: Translations;
    en: Translations;
}

export const translationsInfo: Info = {
    it: {
        "info.dictionary.modal.title.create": "Crea voce",
        "info.dictionary.modal.title.update": "Modifica voce",
        "info.dictionary.modal.input.title": "Titolo attività",
        "info.dictionary.modal.input.descr1": "Descrizione attività",
        "info.dictionary.modal.input.descr2": "Nome voce",
        "info.title.dictionary.section.order": "Cosa sono i tipi di commessa?",
        "info.body.dictionary.section.order":
            "In questa tabella sono elencati i tipi di commessa che puoi associare alle tue commesse. Di default abbiamo inserito dei tipi standard per l’edilizia ma puoi personalizzarli e aggiungerli a seconda delle esigenze del tuo studio. ",
        "info.title.dictionary.section.operator": "Chi sono gli operatori",
        "info.body.dictionary.section.operator":
            "In questa tabella puoi gestire l’elenco dei tipi di operatori che puoi associare ai soggetti coinvolti in una commessa. ",
        "info.title.dictionary.dictionary": "Cosa sono i dizionari? ",
        "info.body.dictionary.dictionary":
            "Nei dizionari puoi inserire quelle voci che poi ritroverai durante la gestione della commessa. Così facendo, non dovrai ogni volta scrivere il dato, ma lo sceglierai da una lista. Le informazioni saranno più strutturate e omogenee facilitando le ricerche, le statistiche e il recupero di informazioni rilevanti. ",
        "info.title.dictionary.section.sending": "Cosa si intende per mezzo invio",
        "info.body.dictionary.section.sending":
            "In questa tabella sono elencati i mezzi di invio con i quali potrai catalogare i documenti caricati in Magoot. Come nelle atre sezioni dei dizionari puoi aggiungere di altri, modificarli od eliminarli. ",
        "info.title.dictionary.section.work": "A cosa servono le fasi?",
        "info.body.dictionary.section.work":
            "Una commessa può essere di entità limitata, come una consulenza, una pratica APE o catastale oppure può essere complessa e protrarsi nel tempo come ad esempio la costruzione di un edificio residenziale. In questo caso avere il completo controllo non è per nulla semplice. Le fasi permettono di semplificare la gestione di una commessa suddividendola in più step. Di default abbiamo inserito le fasi più comuni di commesse che riguardano il mondo dell’edilizia ma ovviamente può aggiungerne di altre. ",
        "info.title.dictionary.cost.centers": "Centri di costo ",
        "info.body.dictionary.cost.centers":
            "In questa tabella puoi gestire l’elenco dei centri di costo che potrai associare quando registri una spesa effettuata dal tuo studio.",
        "info.title.dictionary.document.type": "Tipi di documento",
        "info.body.dictionary.document.type":
            "In questa tabella puoi gestire l’elenco dei documenti che potrai usare quando registri un nuovo documento.",
        "info.title.section.notification.config": "Gestione notifiche",
        "info.body.section.notification.config":
            " In questa sezione potrai gestire quali eventi notificare, a quali utenti e in che modo, via mail e/o direttamente all’interno di Magoot. Ad esempio puoi decidere di mandare una mail a tutti gli utenti del tuo studio ogni volta che viene creata una commessa, oppure decidere di inviare una notifica push agli utenti di una determinata commessa ogni volta che viene caricato un nuovo documento all’interno della stessa. ",
        "info.studio.title": `Ops, c'è stato un problema con il recupero del tuo studio`,
        "info.studio.body": `Se il problema persiste, contattaci pure a <a href="mailto:assistenza@magoot.it" class="link link-secondary">assistenza@magoot.it</a> oppure tramite la chat che trovi in fondo alla pagina.`,
        "dictionary.section.work.search": "Ricerca per fasi o attività",
    },
    en: {
        "info.dictionary.modal.title.create": "Crea voce",
        "info.dictionary.modal.title.update": "Modifica voce",
        "info.dictionary.modal.input.title": "Titolo attività",
        "info.dictionary.modal.input.descr1": "Descrizione attività",
        "info.dictionary.modal.input.descr2": "Nome voce",
        "info.title.dictionary.section.order": "Cosa sono i tipi di commessa?",
        "info.body.dictionary.section.order":
            "In questa tabella sono elencati i tipi di commessa che puoi associare alle tue commesse. Di default abbiamo inserito dei tipi standard per l’edilizia ma puoi personalizzarli e aggiungerli a seconda delle esigenze del tuo studio. ",
        "info.title.dictionary.section.operator": "Chi sono gli operatori",
        "info.body.dictionary.section.operator":
            "In questa tabella puoi gestire l’elenco dei tipi di operatori che puoi associare ai soggetti coinvolti in una commessa. ",
        "info.title.dictionary.dictionary": "Cosa sono i dizionari? ",
        "info.body.dictionary.dictionary":
            "Nei dizionari puoi inserire quelle voci che poi ritroverai durante la gestione della commessa. Così facendo, non dovrai ogni volta scrivere il dato, ma lo sceglierai da una lista. Le informazioni saranno più strutturate e omogenee facilitando le ricerche, le statistiche e il recupero di informazioni rilevanti. ",
        "info.title.dictionary.section.sending": "Cosa si intende per mezzo invio",
        "info.body.dictionary.section.sending":
            "In questa tabella sono elencati i mezzi di invio con i quali potrai catalogare i documenti caricati in Magoot. Come nelle atre sezioni dei dizionari puoi aggiungere di altri, modificarli od eliminarli. ",
        "info.title.dictionary.section.work": "A cosa servono le fasi?",
        "info.body.dictionary.section.work":
            "Una commessa può essere di entità limitata, come una consulenza, una pratica APE o catastale oppure può essere complessa e protrarsi nel tempo come ad esempio la costruzione di un edificio residenziale. In questo caso avere il completo controllo non è per nulla semplice. Le fasi permettono di semplificare la gestione di una commessa suddividendola in più step. Di default abbiamo inserito le fasi più comuni di commesse che riguardano il mondo dell’edilizia ma ovviamente può aggiungerne di altre. ",
        "info.title.dictionary.cost.centers": "Centri di costo ",
        "info.body.dictionary.cost.centers":
            "In questa tabella puoi gestire l’elenco dei centri di costo che potrai associare quando registri una spesa effettuata dal tuo studio.",
        "info.title.dictionary.document.type": "Tipi di documento",
        "info.body.dictionary.document.type":
            "In questa tabella puoi gestire l’elenco dei documenti che potrai usare quando registri un nuovo documento.",
        "info.title.section.notification.config": "Gestione notifiche",
        "info.body.section.notification.config":
            "In questa sezione potrai gestire quali eventi notificare, a quali utenti e in che modo, via mail e/o direttamente all’interno di Magoot. Ad esempio puoi decidere di mandare una mail a tutti gli utenti del tuo studio ogni volta che viene creata una commessa, oppure decidere di inviare una notifica push agli utenti di una determinata commessa ogni volta che viene caricato un nuovo documento all’interno della stessa. ",
        "info.studio.title": `Ops, c'è stato un problema con il recupero del tuo studio`,
        "info.studio.body": `Se il problema persiste, contattaci pure a <a href="mailto:assistenza@magoot.it" class="link link-secondary">assistenza@magoot.it</a> oppure tramite la chat che trovi in fondo alla pagina.`,
        "dictionary.section.work.search": "Ricerca per fasi o attività",
    },
};

export type TTranslationsInfo = keyof Translations;
