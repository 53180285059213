/* eslint-disable react/button-has-type */
// #region ::: IMPORT
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import React, { FC, SyntheticEvent, memo, useMemo, useState } from "react";

import { TButtonVariant } from "../../theme/maps/button/mapButtonVariants";
import { UIIcon } from "../Icon";

// #endregion

export interface Props {
    variant?: TButtonVariant;
    type?: "button" | "submit" | "reset";
    text?: string;
    textAlign?: "center" | "left" | "right";
    icon?: IconProp;
    onClick: (event: SyntheticEvent) => void;
    dataCy: string;
    disabled?: boolean;
    fluid?: boolean;
    isLoading?: boolean;
    isCircle?: boolean;
    size?: "xs" | "sm" | "md" | "lg";
    tabIndex?: number;
    isUppercase?: boolean;
    isHover?: boolean;
    title?: string;
    iconSize?: "xs" | "sm" | "md" | "lg" | SizeProp;
}

export const UIButton: FC<Props> = memo(
    ({
        text,
        textAlign = "center",
        type = "button",
        variant = "primary",
        icon,
        onClick,
        dataCy,
        disabled,
        tabIndex = 0,
        fluid = true,
        isLoading = false,
        isCircle = false,
        isUppercase = false,
        isHover = false,
        size = "md",
        iconSize = size,
        title,
    }: Props): JSX.Element => {
        const [isHovered, setIsHovered] = useState(isHover);

        const onMouseOver = (): void => setIsHovered(true);
        const onMouseLeave = (): void => setIsHovered(false);

        const isButtonDisabled = isLoading || disabled;

        const handleButton = (event: SyntheticEvent): void => {
            event.stopPropagation();
            onClick(event);
        };

        const color = useMemo(() => {
            if (!isHovered && variant === "primary") return "#fff";
            if (!isHovered && variant === "secondary") return "#8100DC";
            if (!isHovered && variant === "secondaryBgWhite") return "#8100DC";
            if (!isHovered && variant === "ternary") return "#797979";
            if (!isHovered && variant === "icon") return "#8100DC";

            return "#797979";
        }, [variant, isHovered]);

        const colorHover = useMemo(() => {
            if (isHovered && variant === "primary") return "#fff";
            if (isHovered && variant === "secondary") return "#fff";
            if (isHovered && variant === "secondaryBgWhite") return "#fff";
            if (isHovered && variant === "ternary") return "#797979";
            if (isHovered && variant === "icon") return "#8100DC";

            return "#797979";
        }, [variant, isHovered]);

        return (
            <>
                {variant === "light" && (
                    // eslint-disable-next-line
                    <a
                        className={`link link-hover link-secondary pointer-events-auto
            ${fluid ? "w-full" : ""}
          `}
                        tabIndex={tabIndex}
                        onClick={handleButton}
                    >
                        {text}
                    </a>
                )}

                {variant !== "light" && (
                    <button
                        onMouseOver={onMouseOver}
                        onMouseLeave={onMouseLeave}
                        tabIndex={tabIndex}
                        className={`
                            pointer-events-auto
              rounded-full 
              ${textAlign === "center" ? "text-center" : ""} 
              ${textAlign === "left" ? "text-left" : ""} 
              ${textAlign === "right" ? "text-right" : ""} 
              ${isUppercase ? "uppercase" : "normal-case"}
              ${isCircle ? "btn btn-circle" : ""}
              ${
                  isButtonDisabled
                      ? "!pointer-events-none border bg-tundora-100 text-tundora-600 opacity-20 hover:bg-none"
                      : ""
              }
              ${variant === "icon" ? "border-none bg-transparent hover:bg-transparent" : ""} 
              ${variant === "primary" ? "btn btn-primary" : ""} 
              ${variant === "secondary" ? "btn btn-outline btn-secondary" : ""}
              ${variant === "secondaryBgWhite" ? "btn btn-outline btn-secondary bg-white" : ""} 
              ${
                  variant === "ternary"
                      ? "btn border-0 bg-tundora-100 hover:bg-tundora-200 hover:ring-1 hover:ring-primary hover:ring-offset-2"
                      : ""
              } 
              ${variant === "warn" ? "btn btn-error " : ""} 
              ${variant === "link" ? "link link-hover link-secondary w-auto" : ""} 
              ${size === "xs" ? "btn btn-xs" : ""} 
              ${size === "sm" ? "btn btn-sm" : ""} 
              ${size === "lg" ? "btn btn-lg" : ""} 
              ${isLoading ? "loading" : ""} 
              ${fluid ? "w-full" : ""}
            `}
                        type={type}
                        data-cy={dataCy}
                        onClick={handleButton}
                        title={title}
                    >
                        {!!icon && !isLoading && (
                            <UIIcon
                                svgIcon={icon}
                                color={color}
                                colorHover={colorHover}
                                isHover={isHovered}
                                size={iconSize ?? size}
                            />
                        )}

                        {!!text && (
                            <span className={`${icon && !isLoading ? "ml-8" : ""}`}>{text}</span>
                        )}
                    </button>
                )}
            </>
        );
    }
);
UIButton.displayName = "Button";
