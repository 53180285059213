import { TParamsApiDictionaryWorkCreate } from "../../../../api/dictionary/dictionaryWorkCreate/params";
import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryWorkCreate/response";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryWorkCreateFailure,
    TActionDictionaryWorkCreateRequest,
    TActionDictionaryWorkCreateSuccess,
} from "./declarations";

export const actionDictionaryWorkCreateRequest = (
    payload: TParamsApiDictionaryWorkCreate
): TActionDictionaryWorkCreateRequest => ({
    type: Actions.dictionaryWorkCreateRequest,
    payload,
});

export const actionDictionaryWorkCreateSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryWorkCreateSuccess => ({
    type: Actions.dictionaryWorkCreateSuccess,
    payload,
});

export const actionDictionaryWorkCreateFailure = (
    payload: TError
): TActionDictionaryWorkCreateFailure => ({
    type: Actions.dictionaryWorkCreateFailure,
    payload,
});
