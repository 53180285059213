interface Translations {
    "view.protocol-list.label.no-protocols": string;
    "view.protocol-list.label.correspondence": string;
    "view.protocol-list.label.date": string;
    "view.protocol-list.label.document": string;
    "view.protocol-list.label.document-type": string;
    "view.protocol-list.api.create-successfully": string;
}

interface ViewProtocolList {
    it: Translations;
    en: Translations;
}

export const translationsViewProtocolList: ViewProtocolList = {
    it: {
        "view.protocol-list.label.no-protocols": "Nessun documento presente!",
        "view.protocol-list.label.correspondence": "Corrispondenza",
        "view.protocol-list.label.date": "Data",
        "view.protocol-list.label.document": "Documento",
        "view.protocol-list.label.document-type": "Tipo documento",
        "view.protocol-list.api.create-successfully": "Documento inviato con successo",
    },
    en: {
        "view.protocol-list.label.no-protocols": "No documents!",
        "view.protocol-list.label.correspondence": "Correspondence",
        "view.protocol-list.label.date": "Date",
        "view.protocol-list.label.document": "Document",
        "view.protocol-list.label.document-type": "Document type",
        "view.protocol-list.api.create-successfully": "Document sent successfully",
    },
};

export type TTranslationsViewProtocolList = keyof Translations;
