import { TParamsApiDictionarySendingCreate } from "../../../../api/dictionary/dictionarySendingCreate/params";
import { TResponseApiDictionary } from "../../../../api/dictionary/dictionarySendingCreate/response";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionarySendingCreateFailure,
    TActionDictionarySendingCreateRequest,
    TActionDictionarySendingCreateSuccess,
} from "./declaration";

export const actionDictionarySendingCreateRequest = (
    payload: TParamsApiDictionarySendingCreate
): TActionDictionarySendingCreateRequest => ({
    type: Actions.dictionarySendingCreateRequest,
    payload,
});

export const actionDictionarySendingCreateSuccess = (
    payload: TResponseApiDictionary
): TActionDictionarySendingCreateSuccess => ({
    type: Actions.dictionarySendingCreateSuccess,
    payload,
});

export const actionDictionarySendingCreateFailure = (
    payload: TError
): TActionDictionarySendingCreateFailure => ({
    type: Actions.dictionarySendingCreateFailure,
    payload,
});
