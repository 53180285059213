import { TParamsApiDictionaryOrderCreate } from "../../../../api/dictionary/dictionaryOrderCreate/params";
import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryOrderCreate/response";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryOrderCreateFailure,
    TActionDictionaryOrderCreateRequest,
    TActionDictionaryOrderCreateSuccess,
} from "./declaration";

export const actionDictionaryOrderCreateRequest = (
    payload: TParamsApiDictionaryOrderCreate
): TActionDictionaryOrderCreateRequest => ({
    type: Actions.dictionaryOrderCreateRequest,
    payload,
});

export const actionDictionaryOrderCreateSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryOrderCreateSuccess => ({
    type: Actions.dictionaryOrderCreateSuccess,
    payload,
});

export const actionDictionaryOrderCreateFailure = (
    payload: TError
): TActionDictionaryOrderCreateFailure => ({
    type: Actions.dictionaryOrderCreateFailure,
    payload,
});
