interface Translations {
    "form.forgot-password.button": string;
}

interface FormForgotPassword {
    it: Translations;
    en: Translations;
}

export const translationsFormForgotPassword: FormForgotPassword = {
    it: {
        "form.forgot-password.button": "Invia email",
    },
    en: {
        "form.forgot-password.button": "Send email",
    },
};

export type TTranslationsFormForgotPassword = keyof Translations;
