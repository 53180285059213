import { TParamsApiOrderTeam } from "../../../../api/order/orderTeam/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionOrderTeamFailure,
    TActionOrderTeamRequest,
    TActionOrderTeamSuccess,
} from "./declarations";

export const actionOrderTeamRequest = (payload: TParamsApiOrderTeam): TActionOrderTeamRequest => ({
    type: Actions.orderTeamRequest,
    payload,
});

export const actionOrderTeamSuccess = (payload: any): TActionOrderTeamSuccess => ({
    type: Actions.orderTeamSuccess,
    payload,
});

export const actionOrderTeamFailure = (payload: TError): TActionOrderTeamFailure => ({
    type: Actions.orderTeamFailure,
    payload,
});
