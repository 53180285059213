import { Actions } from "../../utils/Actions";
import { TReducerNotificationConfig, TReducerNotificationConfigActions } from "./declarations";

export const reducerNotificationConfig = (
    prevStore: TReducerNotificationConfig = {},
    action: TReducerNotificationConfigActions
): TReducerNotificationConfig => {
    switch (action.type) {
        case Actions.notificationConfigListSuccess:
            return action.payload;
        case Actions.notificationConfigUpdateSuccess:
            return action.payload;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
