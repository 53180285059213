interface Translations {
    "view.account.account": string;
    "view.account.subscriptions": string;
    "view.account.error-old-password-match": string;
    "view.account.error-password-requirements": string;
    "view.account.error-password-match": string;
}

interface ViewAccount {
    it: Translations;
    en: Translations;
}

export const translationsViewAccount: ViewAccount = {
    it: {
        "view.account.account": "Account",
        "view.account.subscriptions": "Abbonamenti",
        "view.account.error-old-password-match":
            "La nuova password non deve coincidere con la vecchia password",
        "view.account.error-password-requirements":
            "La password deve essere lunga almeno 8 caratteri e contenere una lettere maiuscola, una minuscola, un simbolo e un numero",
        "view.account.error-password-match": "La password non coincide con la nuova password",
    },
    en: {
        "view.account.account": "Account",
        "view.account.subscriptions": "Subscriptions",
        "view.account.error-old-password-match": "The new password must not match the old password",
        "view.account.error-password-requirements":
            "The password must be at least 8 characters long and contain an uppercase, lowercase, symbol and number",
        "view.account.error-password-match": "The password doesn't match the new password",
    },
};

export type TTranslationsViewAccount = keyof Translations;
