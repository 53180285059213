interface Translations {
    "view.territorial-data.title": string;
    "view.territorial-data.button.geolocale": string;
    "view.territorial-data.label.land-registry": string;
    "view.territorial-data.label.section": string;
    "view.territorial-data.label.sheet": string;
    "view.territorial-data.label.map": string;
    "view.territorial-data.label.subordinate": string;
    "view.territorial-data.label.land.registry": string;
    "view.territorial-data.label.urban-tool-area": string;
    "view.territorial-data.label.documents": string;
    "view.territorial-data.label.document": string;
    "view.territorial-data.label.upload-date": string;
    "view.territorial-data.modal.title": string;
    "view.territorial-data.modal-edit.title": string;
    "view.territorial-data.empty-message.urban-tool-area": string;
    "view.territorial-data.empty-message.land-registry": string;
    "view.territorial-data.label.no-document-list": string;
    "view.territorial-data.label.add-document": string;
    "view.territorial-data.label.add-documents": string;
    "view.territorial-data.empty-message.documents": string;
    "view.territorial-data.default-label.italy": string;
    "view.territorial-data.default-label.insert-new-address": string;
    "view.territorial-data.get.documents": string;
}

interface ViewTerritorialData {
    it: Translations;
    en: Translations;
}

export const translationsViewTerritorialData: ViewTerritorialData = {
    it: {
        "view.territorial-data.title": "Dati territoriali",
        "view.territorial-data.button.geolocale": "Geolocalizza",
        "view.territorial-data.label.land-registry": "Catasto",
        "view.territorial-data.label.section": "Sez.",
        "view.territorial-data.label.sheet": "Fg.",
        "view.territorial-data.label.map": "Map.",
        "view.territorial-data.label.subordinate": "Sub.",
        "view.territorial-data.label.land.registry": "Categoria catastale",
        "view.territorial-data.label.urban-tool-area": "Zona Strumento Urbanistico",
        "view.territorial-data.label.documents": "Documenti",
        "view.territorial-data.label.document": "Documento",
        "view.territorial-data.label.upload-date": "Data upload",
        "view.territorial-data.modal.title": "Aggiungi nuova zona strumento urbanistico",
        "view.territorial-data.modal-edit.title": "Modifica zona strumento urbanistico",
        "view.territorial-data.empty-message.urban-tool-area": "Nessun dato urbanistico inserito",
        "view.territorial-data.empty-message.land-registry": "Nessun estremo catastale inserito",
        "view.territorial-data.label.no-document-list": "Nessun documento per questa commessa",
        "view.territorial-data.label.add-document": "Aggiungi documento",
        "view.territorial-data.label.add-documents": "Aggiungi documenti",
        "view.territorial-data.empty-message.documents": "Nessun documento caricato",
        "view.territorial-data.default-label.italy": "Italia",
        "view.territorial-data.default-label.insert-new-address": "Inserisci nuovo indirizzo",
        "view.territorial-data.get.documents": "Recupero documenti urbanistici",
    },
    en: {
        "view.territorial-data.title": "Dati territoriali",
        "view.territorial-data.button.geolocale": "Geolocale",
        "view.territorial-data.label.land-registry": "Land registry",
        "view.territorial-data.label.section": "Section",
        "view.territorial-data.label.sheet": "Sheet",
        "view.territorial-data.label.map": "Map",
        "view.territorial-data.label.subordinate": "Subordinate",
        "view.territorial-data.label.land.registry": "Catasto",
        "view.territorial-data.label.urban-tool-area": "Urban Tool Area",
        "view.territorial-data.label.documents": "Documents",
        "view.territorial-data.label.document": "Document",
        "view.territorial-data.label.upload-date": "Upload date",
        "view.territorial-data.modal.title": "Add new urban tool area",
        "view.territorial-data.modal-edit.title": "Edit urban tool area",
        "view.territorial-data.empty-message.urban-tool-area":
            "List of empty urban planning tool zone",
        "view.territorial-data.empty-message.land-registry": "List of empty land registry",
        "view.territorial-data.label.no-document-list": "No documents for this order",
        "view.territorial-data.label.add-document": "Add document",
        "view.territorial-data.label.add-documents": "Add documents",
        "view.territorial-data.empty-message.documents": "List of empty documents",
        "view.territorial-data.default-label.italy": "Italy",
        "view.territorial-data.default-label.insert-new-address": "Insert new address",
        "view.territorial-data.get.documents": "Recupero documenti urbanistici",
    },
};

export type TTranslationsViewTerritorialData = keyof Translations;
