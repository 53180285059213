interface Translations {
    "view.dashboard.filters.team": string;
    "view.dashboard.filters.creation-date": string;
    "view.dashboard.input.free.search": string;
    "view.dashboard.tab.expiring-activities.label.empty-list": string;
    "view.dashboard.order.last-access": string;
    "view.dashboard.sidebar.menu.orders": string;
    "view.dashboard.sidebar.menu.orders.expired": string;
    "view.dashboard.sidebar.menu.gantt": string;
    "view.dashboard.sidebar.menu.expiring-activities": string;
    "view.dashboard.sidebar.menu.order-setting": string;
    "view.dashboard.sidebar.menu.report": string;
    "view.dashboard.sidebar.menu.address-book": string;
    "view.dashboard.sidebar.menu.feedback": string;
    "view.dashboard.sidebar.menu.tickets": string;
    "view.dashboard.sidebar.submenu.order-setting.studio-data": string;
    "view.dashboard.sidebar.submenu.order-setting.team": string;
    "view.dashboard.sidebar.submenu.order-setting.dictionaries": string;
    "view.dashboard.sidebar.submenu.order-setting.handle-notifications": string;
    "view.dashboard.sidebar.submenu.order-setting.subscription": string;
    "view.dashboard.sidebar.submenu.dictionaries.order-types": string;
    "view.dashboard.sidebar.submenu.dictionaries.land-registry": string;
    "view.dashboard.sidebar.submenu.dictionaries.sending-types": string;
    "view.dashboard.sidebar.submenu.dictionaries.operator-types": string;
    "view.dashboard.sidebar.submenu.dictionaries.cost-centers": string;
    "view.dashboard.sidebar.submenu.dictionaries.document-type": string;
    "view.dashboard.sidebar.submenu.dictionaries.empty": string;
    "view.dashboard.dictionaries.cart-title.work-list": string;
    "view.dashboard.order.filter-default": string;
    "view.dashboard.order.filter.status": string;
    "view.dashboard.order.filter.type": string;
    "view.dashboard.order.filter.year": string;
    "view.dashboard.handle-notifications-in-charge": string;
    "view.dashboard.handle-notifications-order-users": string;
    "view.dashboard.handle-notifications-order-chief": string;
    "view.dashboard.handle-notifications-studio-users": string;
    "view.dashboard.handle-notifications-administrators": string;
    "view.dashboard.handle-notifications-no-list": string;
    "view.dashboard.paperwork-import-in-progress": string;
    "view.dashboard.paperwork-import-action": string;
    "view.dashboard.notification": string;
}

interface ViewDashboard {
    it: Translations;
    en: Translations;
}

export const translationsViewDashboard: ViewDashboard = {
    it: {
        "view.dashboard.filters.team": "Collaboratori",
        "view.dashboard.filters.creation-date": "Data creazione",
        "view.dashboard.input.free.search": "Ricerca libera",
        "view.dashboard.tab.expiring-activities.label.empty-list": "Nessuna attività in scadenza",
        "view.dashboard.order.last-access": "Ultimo accesso",
        "view.dashboard.sidebar.menu.orders": "Commesse",
        "view.dashboard.sidebar.menu.orders.expired": "Attività",
        "view.dashboard.sidebar.menu.gantt": "Gantt",
        "view.dashboard.sidebar.menu.expiring-activities": "Scadenze",
        "view.dashboard.sidebar.menu.order-setting": "Gestione Studio",
        "view.dashboard.sidebar.menu.report": "Report e Analisi",
        "view.dashboard.sidebar.menu.address-book": "Rubrica",
        "view.dashboard.sidebar.menu.feedback": "Aiutaci a migliorare",
        "view.dashboard.sidebar.menu.tickets": "Assistenza",
        "view.dashboard.sidebar.submenu.order-setting.studio-data": "Dati Studio",
        "view.dashboard.sidebar.submenu.order-setting.team": "Team",
        "view.dashboard.sidebar.submenu.order-setting.handle-notifications": "Gestione notifiche",
        "view.dashboard.sidebar.submenu.order-setting.subscription": "Abbonamento",
        "view.dashboard.sidebar.submenu.order-setting.dictionaries": "Dizionari",
        "view.dashboard.sidebar.submenu.dictionaries.order-types": "Tipologie di commessa",
        "view.dashboard.sidebar.submenu.dictionaries.sending-types": "Mezzi invio",
        "view.dashboard.sidebar.submenu.dictionaries.operator-types": "Tipologie di operatori",
        "view.dashboard.sidebar.submenu.dictionaries.cost-centers": "Centri di costo",
        "view.dashboard.sidebar.submenu.dictionaries.document-type": "Tipi di documento",
        "view.dashboard.sidebar.submenu.dictionaries.land-registry": "Tipi di catasto",
        "view.dashboard.sidebar.submenu.dictionaries.empty":
            "Nessun dizionario disponibile per questa sezione",
        "view.dashboard.dictionaries.cart-title.work-list": "Elenco lavorazioni",
        "view.dashboard.order.filter-default": "Ordina per",
        "view.dashboard.order.filter.status": "Qualsiasi stato",
        "view.dashboard.order.filter.type": "Qualsiasi tipologia",
        "view.dashboard.order.filter.year": "Qualsiasi anno",
        "view.dashboard.handle-notifications-in-charge": "Incaricato",
        "view.dashboard.handle-notifications-order-users": "Utenti commessa",
        "view.dashboard.handle-notifications-order-chief": "Capo commessa",
        "view.dashboard.handle-notifications-studio-users": "Utenti studio",
        "view.dashboard.handle-notifications-administrators": "Amministratori",
        "view.dashboard.handle-notifications-no-list": "Nessuna configurazione presente!",
        "view.dashboard.paperwork-import-in-progress": "Elaborazione pratica in corso...",
        "view.dashboard.paperwork-import-action": "Elaborazione in corso...",
        "view.dashboard.notification": "Recupero notifiche in corso...",
    },
    en: {
        "view.dashboard.filters.team": "Team",
        "view.dashboard.filters.creation-date": "Creation date",
        "view.dashboard.input.free.search": "Ricerca libera",
        "view.dashboard.tab.expiring-activities.label.empty-list": "No expiring activities",
        "view.dashboard.order.last-access": "Last access",
        "view.dashboard.sidebar.menu.orders": "Order List",
        "view.dashboard.sidebar.menu.orders.expired": "Scadenziario",
        "view.dashboard.sidebar.menu.gantt": "Gantt",
        "view.dashboard.sidebar.menu.expiring-activities": "Expiring activities",
        "view.dashboard.sidebar.menu.order-setting": "Studio Settings",
        "view.dashboard.sidebar.menu.report": "Report e Analisi",
        "view.dashboard.sidebar.menu.address-book": "Adress Book",
        "view.dashboard.sidebar.menu.feedback": "Feedback",
        "view.dashboard.sidebar.menu.tickets": "Help",
        "view.dashboard.sidebar.submenu.order-setting.studio-data": "Studio Data",
        "view.dashboard.sidebar.submenu.order-setting.team": "Team",
        "view.dashboard.sidebar.submenu.order-setting.handle-notifications": "Handle notifications",
        "view.dashboard.sidebar.submenu.order-setting.subscription": "Subscription",
        "view.dashboard.sidebar.submenu.order-setting.dictionaries": "Dictionaries",
        "view.dashboard.sidebar.submenu.dictionaries.order-types": "Order types",
        "view.dashboard.sidebar.submenu.dictionaries.land-registry": "Tipi di catasto",
        "view.dashboard.sidebar.submenu.dictionaries.sending-types": "Sending types",
        "view.dashboard.sidebar.submenu.dictionaries.operator-types": "Operator types",
        "view.dashboard.sidebar.submenu.dictionaries.cost-centers": "Cost centers",
        "view.dashboard.sidebar.submenu.dictionaries.document-type": "Tipi di documento",
        "view.dashboard.sidebar.submenu.dictionaries.empty":
            "No dictionary aviable for this section",
        "view.dashboard.dictionaries.cart-title.work-list": "Work list",
        "view.dashboard.order.filter-default": "Sort by",
        "view.dashboard.order.filter.status": "Qualsiasi stato",
        "view.dashboard.order.filter.type": "Qualsiasi tipologia",
        "view.dashboard.order.filter.year": "Qualsiasi anno",
        "view.dashboard.handle-notifications-in-charge": "In charge",
        "view.dashboard.handle-notifications-order-users": "Order users",
        "view.dashboard.handle-notifications-order-chief": "Order chief",
        "view.dashboard.handle-notifications-studio-users": "Studio users",
        "view.dashboard.handle-notifications-administrators": "Administrators",
        "view.dashboard.handle-notifications-no-list": "No configurations!",
        "view.dashboard.paperwork-import-in-progress": "Paperwork processing in progress...",
        "view.dashboard.paperwork-import-action": "Elaborazione in corso...",
        "view.dashboard.notification": "Recupero notifiche in corso...",
    },
};

export type TTranslationsViewDashboard = keyof Translations;
