import { Actions } from "../../utils/Actions";
import { TReducerToast, TReducerToastActions } from "./declarations";

export const reducerToast = (
    prevStore: TReducerToast = {},
    action: TReducerToastActions
): TReducerToast => {
    let newStore = { ...prevStore };

    switch (action.type) {
        case Actions.showToast:
            newStore = action.payload;
            return newStore;
        case Actions.hideToast:
            delete newStore.category;
            delete newStore.message;
            delete newStore.intlFormatter;
            delete newStore.timer;
            return newStore;
        case Actions.sessionExpired:
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
