interface Translations {
    "view.studio-invitation.title": string;
    "view.studio-invitation.description": string;
}

interface ViewStudioInvitation {
    it: Translations;
    en: Translations;
}

export const translationsViewStudioInvitation: ViewStudioInvitation = {
    it: {
        "view.studio-invitation.title": "Fai conoscere Magoot",
        "view.studio-invitation.description":
            "Invita uno degli studi con cui collabori ad utilizzare Magoot e aggiungi 15 giorni gratuiti al tuo abbonamento",
    },
    en: {
        "view.studio-invitation.title": "Make Magoot known",
        "view.studio-invitation.description":
            "Invite one of the studios you work with to use Magoot and add 15 free days to your subscription!",
    },
};

export type TTranslationsViewStudioInvitation = keyof Translations;
