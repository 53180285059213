// #region ::: IMPORT
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { TRole } from "@magoot-sdk/api/diritti/response";

import { apiStudioPartner } from "../../api/studio/studioPartner";
import { TResponseApiStudioPartner } from "../../api/studio/studioPartner/response";
import { actionStudioListRequest } from "../actions/studio/actionStudioList";
import { TStore } from "../reducers/declarations";
import { TOwnStudio, TStudioConnected } from "../reducers/reducerAuth/declarations";
import { TStudio } from "../reducers/reducerStudio/declarations";
import { selectorAuthIsLoading } from "../selectors/selectorsAuth";
import {
    getStudios,
    selectorIdStudioOwn,
    selectorIdUserOwnStudio,
    selectorIsOrderCreate,
    selectorIsOwnStudio,
} from "../selectors/selectorsStudio";
import { selectorIsRefreshingToken } from "../selectors/selectorsUI";
import { useDispatch } from "../utils/useDispatch";
import { useStudioID } from "./useStudioID";

// #endregion

// #region ::: SELECTORS
const selectorStudioList = (store: TStore): TStudio[] | [] => {
    if (!store.studios) return [];
    const studioList = Object.values(store.studios);
    const connectedStudio = studioList.filter(
        (studio) => studio.idRuolo === TRole.Collaboratore_Interno
    );
    return connectedStudio;
};

const selectorConnectedStudioList = (store: TStore): TStudioConnected[] | [] => {
    if (!store.auth?.connectedStudioDetail) return [];
    return store.auth?.connectedStudioDetail;
};

const selectorOwnStudio = (store: TStore): TOwnStudio | false =>
    store.auth?.ownStudioDetail || false;
// #endregion

export const useStudioList = ({
    reload = true,
}: {
    reload: boolean;
}): {
    studioList: TStudioConnected[];
    hasStudioConnected: boolean;
    connectedStudios: TStudioConnected[];
} => {
    const dispatch = useDispatch();
    const studioList = useSelector(selectorStudioList);
    const connectedStudios = useSelector(selectorConnectedStudioList);

    useEffect(() => {
        if (reload) dispatch(actionStudioListRequest());
    }, [reload]);

    return {
        hasStudioConnected: connectedStudios && connectedStudios.length > 0,
        studioList,
        connectedStudios,
    };
};

export const useStudio = (): {
    studio: TStudio;
    isOwner: boolean;
    idOwner: number | boolean;
    idStudioOwner: boolean | number;
    isOrderCreate: boolean | undefined;
    isLoading: boolean;
} => {
    const { idStudio } = useStudioID();
    const studios = useSelector(getStudios);
    const isOwner = useSelector(selectorIsOwnStudio);
    const idOwner = useSelector(selectorIdUserOwnStudio);
    const idStudioOwner = useSelector(selectorIdStudioOwn);
    const isRefreshingToken = useSelector(selectorIsRefreshingToken);
    const isOrderCreate = useSelector((store: TStore) =>
        selectorIsOrderCreate({ store, idStudio })
    );

    return {
        studio: studios[idStudio] || {},
        isOwner,
        idOwner,
        idStudioOwner,
        isOrderCreate,
        isLoading: isRefreshingToken,
    };
};

export const useOwnStudio = (): {
    hasOwnStudio: boolean;
    ownStudio: TOwnStudio | boolean;
    isLoading: boolean | undefined;
} => {
    const ownStudio = useSelector(selectorOwnStudio);
    const isLoading = useSelector(selectorAuthIsLoading);
    return { hasOwnStudio: !!ownStudio, ownStudio, isLoading };
};

export const useStudioPartner = (
    idInvitation: string
): {
    studio: string;
    loading: boolean;
} => {
    const [studioPartner, setStudioPartner] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchStudioPartner = useCallback(async (): Promise<void> => {
        const response: TResponseApiStudioPartner = await apiStudioPartner(idInvitation);

        if (response.result) {
            setStudioPartner(response.result);
        }
        setLoading(false);
    }, [idInvitation]);

    useEffect(() => {
        setLoading(true);
        fetchStudioPartner();
    }, [fetchStudioPartner]);

    return { studio: studioPartner, loading };
};

const selectorIsAdmin = (store: TStore): boolean => {
    if (!store.auth?.idRuolo) return false;
    return store.auth?.idRuolo === TRole.Amministratore;
};

export const selectorIsCollaboratorIndoor = (store: TStore): boolean => {
    if (!store.auth?.idRuolo) return false;
    return store.auth?.idRuolo === TRole.Collaboratore_Interno;
};

export const selectorIsCollaboratorOutside = (store: TStore): boolean => {
    if (!store.auth?.idRuolo) return false;
    return store.auth?.idRuolo === TRole.Collaboratore_Esterno;
};

export const useStudioPermission = (): { hasPermission: boolean } => {
    const hasPermission = useSelector(selectorIsAdmin);
    return { hasPermission };
};
