import { useDispatch as useReduxDispatch } from "react-redux";

import { Actions } from "./Actions";

interface Params {
    type: Actions;
    payload?: any;
}

export const useDispatch = (): (({ type, payload }: Params) => void) => {
    const dispatch = useReduxDispatch();
    return dispatch;
};
