/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux";

import { OrderStatus } from "@magoot-sdk/constants/general";
import { TWork } from "@magoot-sdk/redux-modules/reducers/reducerWorks/declarations";

import { actionWorkCreateRequest } from "../../actions/work/actionWorkCreate";
import { actionWorkUpdateRequest } from "../../actions/work/actionWorkUpdate";
import { TStore } from "../../reducers/declarations";
import { selectorWorkDetail, selectorWorks } from "../../selectors/selectorWork";
import { selectorAnnotationCounter } from "../../selectors/selectorsAnnotation";
import { selectorDocumentsWorkCounter } from "../../selectors/selectorsDocumentsWork";
import { selectorInspectionCounter } from "../../selectors/selectorsInspection";
import { selectorProtocolCounter } from "../../selectors/selectorsProtocol";
import { selectorTaskCounter } from "../../selectors/selectorsTask";
import { useDispatch } from "../../utils/useDispatch";
import { useStudioID } from "../useStudioID";

interface Works {
    [idOrder: string]: {
        [idWork: string]: TWork;
    };
}

interface RWorkDetail {
    counterAnnotations: number;
    counterInspections: number;
    counterTasks: number;
    counterDocuments: number;
    counterProtocols: number;
    workDetail: TWork | undefined;
    onCreate: ({
        descr,
        dataInizio,
        dataScadenza,
        giorniAvvisoScadenza,
        praticaComunale,
        aggiungiAttivita,
    }: TWork) => void;
    onUpdate: ({
        descr,
        dataInizio,
        dataCreazione,
        dataFine,
        giorniAvvisoScadenza,
        statoEsecuzione,
        position,
        praticaComunale,
        terminate,
    }: TWork) => void;
}

export const useWorks = (): Works => {
    return useSelector((store: TStore) => selectorWorks(store));
};

export const useWorkDetail = ({
    idWork,
    idOrder,
}: {
    idWork?: number;
    idOrder: number;
}): RWorkDetail => {
    const { idStudio } = useStudioID();
    const dispatch = useDispatch();

    const counterAnnotations = idWork
        ? useSelector((store: TStore) =>
              selectorAnnotationCounter({
                  store,
                  idWork,
                  idOrder,
              })
          )
        : 0;
    const counterInspections = idWork
        ? useSelector((store: TStore) =>
              selectorInspectionCounter({
                  store,
                  idWork,
                  idOrder,
              })
          )
        : 0;
    const counterTasks = idWork
        ? useSelector((store: TStore) =>
              selectorTaskCounter({
                  store,
                  idWork,
                  idOrder,
              })
          )
        : 0;
    const counterDocuments = idWork
        ? useSelector((store: TStore) =>
              selectorDocumentsWorkCounter({
                  store,
                  idWork,
              })
          )
        : 0;
    const counterProtocols = idWork
        ? useSelector((store: TStore) =>
              selectorProtocolCounter({
                  store,
                  idWork,
              })
          )
        : 0;
    const workDetail = idWork
        ? useSelector((store: TStore) =>
              selectorWorkDetail({
                  store,
                  idWork,
                  idOrder,
              })
          )
        : undefined;

    const onCreate = ({
        descr,
        dataInizio,
        dataScadenza,
        giorniAvvisoScadenza,
        praticaComunale,
        idDizionario,
        aggiungiAttivita,
    }: TWork): void =>
        dispatch(
            actionWorkCreateRequest({
                idStudio,
                idOrder,
                descr: descr ?? "",
                dataInizio: dataInizio ?? null,
                dataScadenza: dataScadenza ?? null,
                giorniAvvisoScadenza: giorniAvvisoScadenza
                    ? parseInt(giorniAvvisoScadenza.toString(), 10)
                    : null,
                idStatoEsecuzione: OrderStatus.InCorso,
                praticaComunale: praticaComunale ?? false,
                idDizionario,
                aggiungiAttivita: aggiungiAttivita ?? false,
            })
        );

    const onUpdate = ({
        descr,
        dataInizio,
        dataScadenza,
        dataFine,
        giorniAvvisoScadenza,
        idStatoEsecuzione,
        position,
        praticaComunale,
        idDizionario,
    }: TWork): void => {
        dispatch(
            actionWorkUpdateRequest({
                idStudio,
                idOrder,
                id: idWork!,
                descr: descr ?? "",
                dataInizio: dataInizio ?? null,
                dataScadenza: dataScadenza ?? null,
                dataFine: dataFine ?? null,
                giorniAvvisoScadenza: giorniAvvisoScadenza
                    ? parseInt(giorniAvvisoScadenza.toString(), 10)
                    : null,
                idStatoEsecuzione: idStatoEsecuzione ?? OrderStatus.InCorso,
                position: position ?? 0,
                praticaComunale: praticaComunale ?? false,
                idDizionario,
            })
        );
    };

    return {
        counterAnnotations,
        counterInspections,
        counterTasks,
        counterDocuments,
        counterProtocols,
        workDetail,
        onCreate,
        onUpdate,
    };
};
