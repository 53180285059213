interface Translations {
    "view.wizard.title": string;
    "view.wizard.studio.title": string;
    "view.wizard.description-profile": string;
    "view.wizard.description-studio": string;
}

interface ViewWizard {
    it: Translations;
    en: Translations;
}

export const translationsViewWizard: ViewWizard = {
    it: {
        "view.wizard.title": "Completa il tuo profilo",
        "view.wizard.studio.title": "Inserisci i dati del tuo studio",
        "view.wizard.description-profile": "Inserisci tutti i dati!",
        "view.wizard.description-studio": "Inserisci i dati dello studio",
    },
    en: {
        "view.wizard.title": "Complete your profile",
        "view.wizard.studio.title": "Inserisci i dati del tuo studio",
        "view.wizard.description-profile": "Complete all data!",
        "view.wizard.description-studio": "Complete all studio data",
    },
};

export type TTranslationsViewWizard = keyof Translations;
