import { TStore } from "../reducers/declarations";
import { TNotification } from "../reducers/reducerNotifications/declarations";

export const selectorNotificationListRead = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TNotification[] | false => {
    if (!store.notifications) return false;
    if (!store.notifications[idStudio]) return false;
    if (!store.notifications[idStudio].allNotifications) return false;
    if (!store.notifications[idStudio].allNotifications.lette) return false;

    const notificationList =
        (!!idStudio && Object.values(store.notifications[idStudio].allNotifications.lette.items)) ||
        [];

    const stuidoNotificationList = notificationList.filter(
        (notification) => notification?.idStudio === idStudio
    );

    const notificationListSorted =
        stuidoNotificationList.length > 0 &&
        stuidoNotificationList.sort((a, b) => {
            const dateA = new Date(a.dataNotifica).getTime();
            const dateB = new Date(b.dataNotifica).getTime();
            return dateA < dateB ? 1 : -1;
        });

    return notificationListSorted && notificationListSorted.length > 0
        ? notificationListSorted
        : false;
};

export const selectorNotificationListNotRead = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TNotification[] | false => {
    if (!store.notifications) return false;
    if (!store.notifications[idStudio]) return false;
    if (!store.notifications[idStudio].allNotifications) return false;
    if (!store.notifications[idStudio].allNotifications.nonLette) return false;

    const notificationList =
        (!!idStudio &&
            Object.values(store.notifications[idStudio].allNotifications.nonLette.items)) ||
        [];

    const stuidoNotificationList = notificationList.filter(
        (notification) => notification?.idStudio === idStudio
    );

    const notificationListSorted =
        stuidoNotificationList.length > 0 &&
        stuidoNotificationList.sort((a, b) => {
            const dateA = new Date(a.dataNotifica).getTime();
            const dateB = new Date(b.dataNotifica).getTime();
            return dateA < dateB ? 1 : -1;
        });

    return notificationListSorted && notificationListSorted.length > 0
        ? notificationListSorted
        : false;
};

export const selectorNotificationAllListLoading = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.notifications) return false;
    if (!store.notifications[idStudio]) return false;
    if (!store.notifications[idStudio].allNotifications) return false;

    return store.notifications[idStudio]?.allNotifications.loading;
};

export const selectorNotificationUnreadCounter = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): number | false => {
    if (!store.notifications) return false;
    if (!store.notifications[idStudio]) return false;
    if (!store.notifications[idStudio].unreadNotifications) return false;
    if (!store.notifications[idStudio].unreadNotifications.count) return false;

    return store.notifications[idStudio]?.unreadNotifications?.count;
};

export const selectorIsNewNotificationUnread = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): boolean => {
    if (!store.notifications) return false;
    if (!store.notifications[idStudio]) return false;
    if (!store.notifications[idStudio].unreadNotifications) return false;

    return store.notifications[idStudio]?.unreadNotifications?.reload;
};

export const selectorArrayNotificationUnread = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): TNotification[] | false => {
    if (!store.notifications) return false;
    if (!store.notifications[idStudio]) return false;
    if (!store.notifications[idStudio].unreadNotifications) return false;
    if (!store.notifications[idStudio].unreadNotifications.obj) return false;

    return store.notifications[idStudio]?.unreadNotifications?.obj?.items;
};
