import { createSelector } from "reselect";

import { TStore } from "../reducers/declarations";
import { TOrderStats, TStudioStatsDetails } from "../reducers/reducerStatistics/declarations";

export const selectorStudioStats = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio: number;
}): {
    info: TStudioStatsDetails[];
    stat: TStudioStatsDetails[];
} => {
    if (!store.statistics?.studios) return { info: [], stat: [] };
    if (!store.statistics?.studios[idStudio]) return { info: [], stat: [] };
    const statistiche = store.statistics?.studios[idStudio].statistiche || false;
    if (!statistiche) return { info: [], stat: [] };

    return statistiche
        ? {
              info: statistiche.filter((stat: any) => stat.gruppo === 1),
              stat: statistiche.filter((stat: any) => stat.gruppo === 2),
          }
        : { info: [], stat: [] };
};

export const selectorOrderStats = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): TOrderStats | false => {
    if (!store.statistics) return false;
    if (!store.statistics.orders) return false;
    if (!store.statistics.orders[idOrder]) return false;
    return store.statistics.orders[idOrder];
};

export const selectorOrderStatsNoRerender = createSelector([selectorOrderStats], (stat) => {
    return stat;
});
