/* eslint-disable no-return-assign */
import { Actions } from "../../utils/Actions";
import { TReducerDocumentsWork, TReducerDocumentsWorkActions } from "./declarations";

export const reducerDocumentsWork = (
    prevStore: TReducerDocumentsWork = {},
    action: TReducerDocumentsWorkActions
): TReducerDocumentsWork => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.documentWorkListSuccess:
            newStore[action.payload.idWork] = action.payload.documents;
            return newStore;
        case Actions.documentWorkDeleteSuccess:
            delete newStore[action.payload.idWork][action.payload.idDocument];
            return newStore;
        case Actions.documentUploadWorkSuccess:
            newStore[action.payload.idWork] = action.payload;
            return newStore;
        case Actions.documentWorkUpdateSuccess:
            newStore[action.payload.document.idLavorazione] = {
                ...newStore[action.payload.document.idLavorazione],
                [action.payload.document.id]: action.payload.document,
            };
            return newStore;
        case Actions.protocolCreateSuccess:
            let searchWork;
            action.payload.idFile.map((file): number => {
                if (newStore[action.payload?.idWork] && newStore[action.payload?.idWork][file]) {
                    searchWork = {
                        ...newStore[action.payload.idWork][file],
                        numeroInvii: action.payload.protocol.numeroInvii,
                    };
                }

                return file;
            });

            return searchWork;
        case Actions.mailSendSuccess:
            action.payload.idDocumentList.forEach((idDocument: number) => {
                if (
                    newStore[action.payload.idWork] &&
                    newStore[action.payload.idWork][idDocument] &&
                    newStore[action.payload.idWork][idDocument]?.numeroCondivisioni
                ) {
                    newStore[action.payload.idWork][idDocument].numeroCondivisioni +=
                        newStore[action.payload.idWork][idDocument].numeroCondivisioni + 1;
                }
            });
            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
