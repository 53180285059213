import {
    AllNotifications,
    ArrayNotificationsUnread,
    TNotification,
} from "@magoot-sdk/redux-modules/reducers/reducerNotifications/declarations";

import {
    TParamsApiAllNotification,
    TParamsApiLastUnreadArrayNotifications,
    TParamsApiNotificationRead,
    TParamsApiUnreadArrayNotifications,
    TParamsApiUnreadNotification,
} from "../../../../api/notifications/notificationRead/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionAllNotificationFailure,
    TActionAllNotificationRequest,
    TActionAllNotificationSuccess,
    TActionLastUnreadArrayNotificationsFailure,
    TActionLastUnreadArrayNotificationsRequest,
    TActionLastUnreadArrayNotificationsSuccess,
    TActionNotificationReadFailure,
    TActionNotificationReadListDecrement,
    TActionNotificationReadRequest,
    TActionNotificationReadSuccess,
    TActionNotificationUnreadListDecrement,
    TActionUnreadArrayNotificationsFailure,
    TActionUnreadArrayNotificationsRequest,
    TActionUnreadArrayNotificationsSuccess,
    TActionUnreadNotificationFailure,
    TActionUnreadNotificationRequest,
    TActionUnreadNotificationSuccess,
} from "./declarations";

export const actionNotificationReadRequest = (
    payload: TParamsApiNotificationRead
): TActionNotificationReadRequest => ({
    type: Actions.notificationReadRequest,
    payload,
});

export const actionNotificationReadSuccess = (payload: {
    idStudio: number;
    notification: TNotification;
}): TActionNotificationReadSuccess => ({
    type: Actions.notificationReadSuccess,
    payload,
});

export const actionNotificationReadFailure = (payload: TError): TActionNotificationReadFailure => ({
    type: Actions.notificationReadFailure,
    payload,
});

export const actionUnreadNotificationRequest = (
    payload: TParamsApiUnreadNotification
): TActionUnreadNotificationRequest => ({
    type: Actions.notificationUnreadRequest,
    payload,
});

export const actionUnreadNotificationSuccess = (payload: {
    idStudio: number;
    count: number;
}): TActionUnreadNotificationSuccess => ({
    type: Actions.notificationUnreadSuccess,
    payload,
});

export const actionUnreadNotificationFailure = (
    payload: TError
): TActionUnreadNotificationFailure => ({
    type: Actions.notificationUnreadFailure,
    payload,
});

export const actionUnreadArrayNotificationsRequest = (
    payload: TParamsApiUnreadArrayNotifications
): TActionUnreadArrayNotificationsRequest => ({
    type: Actions.arrayNotificationsUnreadRequest,
    payload,
});

export const actionUnreadArrayNotificationsSuccess = (payload: {
    idStudio: number;
    obj: ArrayNotificationsUnread;
}): TActionUnreadArrayNotificationsSuccess => ({
    type: Actions.arrayNotificationsUnreadSuccess,
    payload,
});

export const actionUnreadArrayNotificationsFailure = (
    payload: TError
): TActionUnreadArrayNotificationsFailure => ({
    type: Actions.arrayNotificationsUnreadFailure,
    payload,
});

export const actionLastUnreadArrayNotificationsRequest = (
    payload: TParamsApiLastUnreadArrayNotifications
): TActionLastUnreadArrayNotificationsRequest => ({
    type: Actions.arrayNotificationsLastUnreadRequest,
    payload,
});

export const actionLastUnreadArrayNotificationsSuccess = (payload: {
    idStudio: number;
    obj: ArrayNotificationsUnread;
}): TActionLastUnreadArrayNotificationsSuccess => ({
    type: Actions.arrayNotificationsLastUnreadSuccess,
    payload,
});

export const actionLastUnreadArrayNotificationsFailure = (
    payload: TError
): TActionLastUnreadArrayNotificationsFailure => ({
    type: Actions.arrayNotificationsLastUnreadFailure,
    payload,
});

export const actionAllNotificationRequest = (
    payload: TParamsApiAllNotification
): TActionAllNotificationRequest => ({
    type: Actions.notificationAllRequest,
    payload,
});

export const actionAllNotificationSuccess = (payload: {
    idStudio: number;
    notifications: {
        lette: AllNotifications;
        nonLette: AllNotifications;
    };
}): TActionAllNotificationSuccess => ({
    type: Actions.notificationAllSuccess,
    payload,
});

export const actionAllNotificationFailure = (payload: {
    idStudio: number;
    error: TError;
}): TActionAllNotificationFailure => ({
    type: Actions.notificationAllFailure,
    payload,
});

export const actionNotificationUnreadListDecrement = (payload: {
    idStudio: number;
    idNotification: number;
}): TActionNotificationUnreadListDecrement => ({
    type: Actions.notificationUnreadListDecrement,
    payload,
});

export const actionNotificationReadListDecrement = (payload: {
    idStudio: number;
    idNotification: number;
}): TActionNotificationReadListDecrement => ({
    type: Actions.notificationReadListDecrement,
    payload,
});
