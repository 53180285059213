interface Translations {
    "button.login": string;
    "button.signup": string;
    "button.logout": string;
    "button.go-back-home": string;
    "button.delete-account": string;
    "button.order.update-data": string;
    "button.order.update-team": string;
    "button.new-commitment": string;
    "button.new-operator": string;
    "button.import-from-archive": string;
    "button.import": string;
    "button.delete": string;
    "button.save": string;
    "button.update": string;
    "button.pdf-download": string;
    "button.annotation.create": string;
    "button.label.upload-photo": string;
    "button.create.task": string;
    "button.access": string;
    "button.work.create": string;
    "button.close": string;
    "button.idea.create": string;
    "button.orders": string;
    "button.expiring-activities": string;
    "button.confirm": string;
    "button.stay": string;
    "button.cancel": string;
    "button.access.go.home": string;
    "button.invite-studio": string;
    "button.add": string;
    "button.add.work": string;
    "button.forward": string;
    "button.continue": string;
    "button.continue.terms.accept": string;
    "button.terms": string;
    "button.terms.go.back": string;
    "button.back": string;
    "button.order-create": string;
    "button.save-changes": string;
    "button.share": string;
    "button.move-up": string;
    "button.move-down": string;
    "button.upgrade": string;
    "button.active": string;
    "button.add.new.customer": string;
    "button.delete-all.not-read": string;
    "button.delete-all.read": string;
    "notification.read": string;
    "notification.not.read": string;
    "checkbox.notification": string;
    "button.all-notifications": string;
    "button.delete-notification": string;
    "button.delete-success": string;
    "button.search": string;
    "button.leave": string;
    "button.select-all": string;
    "button.deselect-all": string;
}

interface Button {
    it: Translations;
    en: Translations;
}

export const translationsButton: Button = {
    it: {
        "button.login": "Accedi a Magoot",
        "button.signup": "Crea un account",
        "button.logout": "Esci",
        "button.go-back-home": "Torna alla Home",
        "button.delete-account": "Elimina account",
        "button.order.update-data": "Salva Modifiche Commessa",
        "button.order.update-team": "Salva Modifiche Team",
        "button.new-commitment": "Nuovo soggetto",
        "button.new-operator": "Nuovo operatore",
        "button.import-from-archive": "Importa da rubrica",
        "button.import": "Importa",
        "button.delete": "Elimina",
        "button.save": "Salva",
        "button.share": "Condividi",
        "button.update": "Modifica",
        "button.pdf-download": "Genera PDF",
        "button.annotation.create": "Aggiungi annotazione",
        "button.label.upload-photo": "Carica foto",
        "button.create.task": "Aggiungi card",
        "button.access": "Accedi",
        "button.work.create": "Aggiungi fase",
        "button.close": "Chiudi",
        "button.idea.create": "Aggiungi idea",
        "button.orders": "Commesse",
        "button.expiring-activities": "Attività in scadenza",
        "button.confirm": "Conferma",
        "button.stay": "Rimani",
        "button.cancel": "Annulla",
        "button.access.go.home": "Torna alla home",
        "button.invite-studio": "Invita nello studio",
        "button.add": "Aggiungi",
        "button.add.work": "Aggiungi Fase",
        "button.forward": "Avanti",
        "button.continue": "Continua",
        "button.continue.terms.accept": "Accetta",
        "button.terms.go.back": "Accetta e continua",
        "button.terms": "Accetta i termini e condizioni",
        "button.back": "Indietro",
        "button.order-create": "Crea commessa",
        "button.save-changes": "Salva modifiche",
        "button.move-up": "Sposta su",
        "button.move-down": "Sposta giù",
        "button.upgrade": "Fai l'upgrade",
        "button.active": "attiva",
        "button.add.new.customer": "Aggiungi un nuovo soggetto",
        "button.delete-all.not-read": "Elimina tutte le notifiche non lette",
        "button.delete-all.read": "Elimina tutte le notifiche lette",
        "button.all-notifications": "Tutte le notifiche",
        "button.delete-notification": "Elimina notifica",
        "button.delete-success": "Notifiche cancellate con successo",
        "notification.read": "Notifiche lette",
        "notification.not.read": "Notifiche non lette",
        "checkbox.notification": "Solo notifiche non lette",
        "button.search": "Cerca",
        "button.leave": "Esci",
        "button.select-all": "Seleziona tutti",
        "button.deselect-all": "Deseleziona tutti",
    },
    en: {
        "button.login": "Login",
        "button.signup": "Create account",
        "button.logout": "Logout",
        "button.go-back-home": "Go Home",
        "button.delete-account": "Delete account",
        "button.order.update-data": "Update Order Data",
        "button.order.update-team": "Update Team",
        "button.new-commitment": "New customer",
        "button.new-operator": "New operator",
        "button.import-from-archive": "Import from archive",
        "button.import": "Import",
        "button.delete": "Delete",
        "button.save": "Save",
        "button.share": "Share",
        "button.update": "Modify",
        "button.pdf-download": "Download PDF",
        "button.annotation.create": "Add note",
        "button.label.upload-photo": "Upload photo",
        "button.create.task": "Add another card",
        "button.access": "Access",
        "button.work.create": "Add work",
        "button.close": "Close",
        "button.idea.create": "Add idea",
        "button.orders": "Orders",
        "button.expiring-activities": "Expiring activities",
        "button.confirm": "Confirm",
        "button.stay": "Stay",
        "button.cancel": "Cancel",
        "button.access.go.home": "Torna alla home",
        "button.invite-studio": "Invite in your study",
        "button.add": "Add",
        "button.add.work": "Aggiungi fase",
        "button.forward": "Continue",
        "button.continue": "Continue",
        "button.continue.terms.accept": "Accetta",
        "button.terms.go.back": "Accetta e continua",
        "button.terms": "Accetta i termini e condizioni",
        "button.back": "Back",
        "button.order-create": "Create order",
        "button.save-changes": "Save changes",
        "button.move-up": "Move up",
        "button.move-down": "Move down",
        "button.upgrade": "Upgrade",
        "button.active": "attiva",
        "button.add.new.customer": "Aggiungi un nuovo soggetto",
        "button.delete-all.not-read": "Elimina tutte le notifiche non lette",
        "button.delete-all.read": "Elimina tutte le notifiche lette",
        "notification.read": "Notifiche lette",
        "notification.not.read": "Notifiche non lette",
        "checkbox.notification": "Solo notifiche non lette",
        "button.all-notifications": "All notifications",
        "button.delete-notification": "Elimina notifica",
        "button.delete-success": "Notifiche cancellate con successo",
        "button.search": "Search",
        "button.leave": "Leave",
        "button.select-all": "Select all",
        "button.deselect-all": "Deselect all",
    },
};

export type TTranslationsResponseApi = keyof Translations;
