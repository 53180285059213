import { Actions } from "../../utils/Actions";
import { TReducerStudios, TReducerStudiosActions } from "./declarations";

export const reducerStudio = (
    prevStore: TReducerStudios = {},
    action: TReducerStudiosActions
): TReducerStudios => {
    let newStore = { ...prevStore };

    switch (action.type) {
        case Actions.studioListSuccess:
            newStore = action.payload;
            delete newStore.loading;
            return newStore;
        case Actions.createStudioSuccess:
            newStore[action.result] = { ...action.payload, id: action.result };
            return newStore;
        case Actions.updateStudioSuccess:
            newStore[action.payload.id] = action.payload;
            return newStore;
        case Actions.sessionExpired:
        case Actions.createLogoutSuccess:
            return {};
        default:
            return newStore;
    }
};
