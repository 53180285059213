// #region ::: IMPORT
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { CombinedState, Store, Store as TStoreRedux, applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { NAME_APP } from "@magoot-sdk/constants/general";
import { utilityGetFromLocalStorage } from "@magoot-sdk/utils/cache/getFromLocalStorage";

import { actionRestorePrevUser } from "./actions/auth/actionLogin";
import { actionAsyncResourceLoaded } from "./actions/general/actionAsyncResourceLoaded";
import { middlewares } from "./middlewares";
import { middlewareSaga } from "./middlewares/middlewareSaga";
import { reducers } from "./reducers";
import { TStore } from "./reducers/declarations";

// #endregion

export const history = createBrowserHistory();

class StoreImplementation {
    private store: Store<CombinedState<TStore>>;

    constructor() {
        const prevStore: any = utilityGetFromLocalStorage(NAME_APP);
        const version = process.env.REACT_APP_VERSION;
        prevStore.appReady = false;

        const enhancer = composeWithDevTools({ trace: true, traceLimit: 25 });
        this.store = createStore(
            reducers(history),
            version &&
                prevStore &&
                prevStore?.ui &&
                version === prevStore.ui.appVersion?.version &&
                prevStore,
            enhancer(applyMiddleware(routerMiddleware(history), ...middlewares))
        );

        import("./watchers").then((promise) => {
            promise.watchers.forEach((watcher: any) => middlewareSaga.run(watcher));
            this.store.dispatch(actionAsyncResourceLoaded());
        });

        if (prevStore.auth) this.store.dispatch(actionRestorePrevUser(prevStore.auth));
    }

    getInstance = (): TStoreRedux => this.store;
}

export const ReduxStore = new StoreImplementation();
