import { useLocation } from "react-router-dom";

export const useStudioID = (): { idStudio: number } => {
    const { pathname } = useLocation();
    const pathSplitted = pathname.split("/");
    const idStudio: number = parseInt(pathSplitted[1], 10) || 0;
    if (typeof idStudio !== "number") throw new Error("idStudio not found on URL params");

    return { idStudio };
};
