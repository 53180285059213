interface Translations {
    "form.invite-user.button": string;
    "form.invite-id-ruolo": string;
    "form.invite-id-ruolo.placeholder": string;
}

interface FormInviteUser {
    it: Translations;
    en: Translations;
}

export const translationsFormInviteUser: FormInviteUser = {
    it: {
        "form.invite-user.button": "Manda Invito",
        "form.invite-id-ruolo": "Ruolo utente",
        "form.invite-id-ruolo.placeholder": "Seleziona un ruolo",
    },
    en: {
        "form.invite-user.button": "Invite",
        "form.invite-id-ruolo": "Ruolo utente",
        "form.invite-id-ruolo.placeholder": "Seleziona un ruolo",
    },
};

export type TTranslationsFormInviteUser = keyof Translations;
