import { TResponseApiDictionary } from "../../../../api/dictionary/dictionarySendingEdit/response";
import { TParamsApiDictionaryEdit } from "../../../../api/dictionary/dictionaryWorkEdit/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryWorkEditFailure,
    TActionDictionaryWorkEditRequest,
    TActionDictionaryWorkEditSuccess,
} from "./declarations";

export const actionDictionaryWorkEditRequest = (
    payload: TParamsApiDictionaryEdit
): TActionDictionaryWorkEditRequest => ({
    type: Actions.dictionaryWorkEditRequest,
    payload,
});

export const actionDictionaryWorkEditSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryWorkEditSuccess => ({
    type: Actions.dictionaryWorkEditSuccess,
    payload,
});

export const actionDictionaryWorkEditFailure = (
    payload: TError
): TActionDictionaryWorkEditFailure => ({
    type: Actions.dictionaryWorkEditFailure,
    payload,
});
