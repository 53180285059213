interface Translations {
    "view.order-create.title": string;
    "view.order-create.phases-select": string;
    "view.order-create.phases-select.tooltip": string;
    "view.order-create.empty.geo": string;
    "view.order-create.error.order-number": string;
    "view.order-create.date.create": string;
    "view.order-create.date.possible.end": string;
    "view.order-create.date.end": string;
    "view.order-create.date.tooltip": string;
}

interface ViewOrderCreate {
    it: Translations;
    en: Translations;
}

export const translationsViewOrderCreate: ViewOrderCreate = {
    it: {
        "view.order-create.title": "Creazione nuova commessa",
        "view.order-create.phases-select": "Seleziona fasi",
        "view.order-create.phases-select.tooltip":
            "Fasi presente sotto la voce di menu Gestione Studio",
        "view.order-create.empty.geo": "Indirizzo non trovato",
        "view.order-create.error.order-number":
            "Numero commessa già esistente, per favore cambia numero oppure usa questa alternativa",
        "view.order-create.date.create": "Inizio commessa",
        "view.order-create.date.possible.end": "Fine prevista",
        "view.order-create.date.end": "Data di fine commessa",
        "view.order-create.date.tooltip": `La data di "possibile fine commessa" non può superare quella di fine`,
    },
    en: {
        "view.order-create.title": "Create order",
        "view.order-create.phases-select": "Select phases",
        "view.order-create.phases-select.tooltip":
            "Fasi presente sotto la voce di menu Gestione Studio",
        "view.order-create.empty.geo": "Indirizzo non trovato",
        "view.order-create.error.order-number":
            "Numero commessa già esistente, per favore cambia numero oppure usa questa alternativa",
        "view.order-create.date.create": "Inizio commessa",
        "view.order-create.date.possible.end": "Fine prevista",
        "view.order-create.date.end": "Data di fine commessa",
        "view.order-create.date.tooltip": `La data di "possibile fine commessa" non può superare quella di fine`,
    },
};

export type TTranslationsViewOrderCreate = keyof Translations;
