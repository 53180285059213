interface Translations {
    "form.join-studio.button.login": string;
    "form.join-studio.button.signup": string;
    "form.join-studio.divider.or": string;
    "form.join-studio.button.use-another-account": string;
    "form.join-studio.button.use-account": string;
}

interface FormJoinStudio {
    it: Translations;
    en: Translations;
}

export const translationsFormJoinStudio: FormJoinStudio = {
    it: {
        "form.join-studio.button.login": "Utilizza account esistente",
        "form.join-studio.button.signup": "Crea un nuovo account",
        "form.join-studio.divider.or": "Oppure",
        "form.join-studio.button.use-another-account": "Accedi con un altro account",
        "form.join-studio.button.use-account": "Accedi con",
    },
    en: {
        "form.join-studio.button.login": "Use existing account",
        "form.join-studio.button.signup": "Create new Account",
        "form.join-studio.divider.or": "Or",
        "form.join-studio.button.use-another-account": "Log in with another account",
        "form.join-studio.button.use-account": "Log in with",
    },
};

export type TTranslationsFormJoinStudio = keyof Translations;
