// #region ::: IMPORT
import { RouteGuest } from "@components-layout/RouteGuest";
import { RouteLogged } from "@components-layout/RouteLogged";
import magootLogo from "assets/magoot-logo-bianco.png";
import { ConnectedRouter } from "connected-react-router";
import React, { FC, Suspense, lazy, useEffect, useState } from "react";
import { ProductFruits } from "react-product-fruits";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import { useSmartlookClient } from "@magoot-sdk/hooks/useSmartlookClient";
import { useZoho } from "@magoot-sdk/hooks/useZoho";
import { translate } from "@magoot-sdk/i18n/translate";
import { actionEmptyLocalStorage } from "@magoot-sdk/redux-modules/actions/general/actionLocalStorage";
import {
    actionUIAppVersion,
    actionUIRestoreExternalLink,
} from "@magoot-sdk/redux-modules/actions/ui/actionUi";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import {
    selectorIdRuolo,
    selectorLastNameUser,
    selectorNameUser,
    selectorUserEmail,
    selectorUserID,
} from "@magoot-sdk/redux-modules/selectors/selectorsAuth";
import { selectorStudioDetail } from "@magoot-sdk/redux-modules/selectors/selectorsStudio";
import {
    selectorAppVersion,
    selectorAppVersionIsUpdate,
    selectorForceOpenDialog,
    selectorIsAppReady,
    selectorIsFormNotSaved,
} from "@magoot-sdk/redux-modules/selectors/selectorsUI";
import { Path, utilityGetUrlViewLogin } from "@magoot-sdk/utils/Routes";

import { UILoader } from "@components-ui/Loader";
import { UIModal } from "@components-ui/Modal";

import { Listener } from "./components/Listener";
import { Navbar } from "./components/Navbar";
import { OfflineBar } from "./components/OfflineBar";
import { Popup } from "./components/Popup";
import { PromptLeaveForm } from "./components/PromptLeaveForm";
import { Subscription } from "./components/Subscription";
import { Toast } from "./components/Toast";

const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem("page-has-been-force-refreshed") || "false"
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem("page-has-been-force-refreshed", "false");

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                window.localStorage.setItem("page-has-been-force-refreshed", "true");
                return window.location.reload();
            }

            throw error;
        }
    });
const MExplicationPaperwork = lazyWithRetry(() =>
    import("./components/Navbar/partials/PaperworkImport/partials/ExplicationPaperwork").then(
        ({ ExplicationPaperwork }) => ({ default: ExplicationPaperwork })
    )
);
const MDownloadOrder = lazyWithRetry(() =>
    import("@components-layout/DownloadOrder/DownloadOrder").then(({ DownloadOrder }) => ({
        default: DownloadOrder,
    }))
);
const MViewHome = lazyWithRetry(() =>
    import("@components-view/home/ViewHome").then(({ ViewHome }) => ({ default: ViewHome }))
);
const MViewDashboard = lazyWithRetry(() =>
    import("@components-view/home/ViewDashboard").then(({ ViewDashboard }) => ({
        default: ViewDashboard,
    }))
);
const MViewIdeaList = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewIdeaList").then(({ ViewIdeaList }) => ({
        default: ViewIdeaList,
    }))
);
const MViewCommitmentList = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewCommitmentAndOperator/ViewCommitmentList").then(
        ({ ViewCommitmentList }) => ({
            default: ViewCommitmentList,
        })
    )
);
const MViewCustomerCreate = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewCommitmentAndOperator/ViewCustomerCreate").then(
        ({ ViewCustomerCreate }) => ({
            default: ViewCustomerCreate,
        })
    )
);
const MViewCustomerDetail = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewCommitmentAndOperator/ViewCustomerDetail").then(
        ({ ViewCustomerDetail }) => ({
            default: ViewCustomerDetail,
        })
    )
);
const MViewCustomerList = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewCustomerList").then(({ ViewCustomerList }) => ({
        default: ViewCustomerList,
    }))
);
const MViewDownloadFile = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewDownloadFile").then(({ ViewDownloadFile }) => ({
        default: ViewDownloadFile,
    }))
);
const MViewIdeaCreate = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewIdeaCreate").then(({ ViewIdeaCreate }) => ({
        default: ViewIdeaCreate,
    }))
);
const MViewOperatorList = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewCommitmentAndOperator/ViewOperatorList").then(
        ({ ViewOperatorList }) => ({
            default: ViewOperatorList,
        })
    )
);
const MViewOrderSettings = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewOrderSettings").then(({ ViewOrderSettings }) => ({
        default: ViewOrderSettings,
    }))
);
const MViewPaymentList = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewPaymentList").then(({ ViewPaymentList }) => ({
        default: ViewPaymentList,
    }))
);
const MViewSummary = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewSummary").then(({ ViewSummary }) => ({
        default: ViewSummary,
    }))
);
const MViewTerritorialData = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewTerritorialData").then(({ ViewTerritorialData }) => ({
        default: ViewTerritorialData,
    }))
);
const MViewWorkDetail = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewWorkDetail").then(({ ViewWorkDetail }) => ({
        default: ViewWorkDetail,
    }))
);
const MViewWorkList = lazyWithRetry(() =>
    import("@components-view/dashboard/ViewWorkList").then(({ ViewWorkList }) => ({
        default: ViewWorkList,
    }))
);
const MViewStudioList = lazyWithRetry(() =>
    import("@components-view/general/ViewStudioList").then(({ ViewStudioList }) => ({
        default: ViewStudioList,
    }))
);
const MViewOrderCreate = lazyWithRetry(() =>
    import("@components-view/home/ViewOrderCreate").then(({ ViewOrderCreate }) => ({
        default: ViewOrderCreate,
    }))
);
const MViewNotificationList = lazyWithRetry(() =>
    import("@components-view/general/ViewNotificationList").then(({ ViewNotificationList }) => ({
        default: ViewNotificationList,
    }))
);
const MViewStudioCreate = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewStudioCreate").then(({ ViewStudioCreate }) => ({
        default: ViewStudioCreate,
    }))
);
const MViewForgotPassword = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewForgotPassword").then(({ ViewForgotPassword }) => ({
        default: ViewForgotPassword,
    }))
);
const MViewInvitation = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewInvitation").then(({ ViewInvitation }) => ({
        default: ViewInvitation,
    }))
);
const MViewActivateAccount = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewActivation").then(({ ViewActivateAccount }) => ({
        default: ViewActivateAccount,
    }))
);
const MViewLogin = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewLogin").then(({ ViewLogin }) => ({
        default: ViewLogin,
    }))
);
const MViewResetPassword = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewResetPassword").then(({ ViewResetPassword }) => ({
        default: ViewResetPassword,
    }))
);
const MViewResetPasswordSuccess = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewResetPasswordSuccess").then(
        ({ ViewResetPasswordSuccess }) => ({
            default: ViewResetPasswordSuccess,
        })
    )
);
const MViewSignup = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewSignup").then(({ ViewSignup }) => ({
        default: ViewSignup,
    }))
);
const MViewSignupSuccess = lazyWithRetry(() =>
    import("@components-view/onboarding/ViewSignupSuccess").then(({ ViewSignupSuccess }) => ({
        default: ViewSignupSuccess,
    }))
);
const MViewAccount = lazyWithRetry(() =>
    import("@components-view/profile/ViewAccount").then(({ ViewAccount }) => ({
        default: ViewAccount,
    }))
);
const MViewProfile = lazyWithRetry(() =>
    import("@components-view/profile/ViewProfile").then(({ ViewProfile }) => ({
        default: ViewProfile,
    }))
);
const MViewNotFound = lazyWithRetry(() =>
    import("@components-view/general/ViewNotFound").then(({ ViewNotFound }) => ({
        default: ViewNotFound,
    }))
);
// #endregion

export const App: FC = (): JSX.Element => {
    const isAppReady = useSelector(selectorIsAppReady);
    const userEmail = useSelector(selectorUserEmail);
    const lastNameUser = useSelector(selectorLastNameUser);
    const nameUser = useSelector(selectorNameUser);
    const userId = useSelector(selectorUserID);
    const idRuolo = useSelector(selectorIdRuolo);

    const oldVersion = useSelector(selectorAppVersion);
    const isUpdate = useSelector(selectorAppVersionIsUpdate);
    const { idStudio } = useStudioID();
    const studioName = useSelector((store: TStore) => selectorStudioDetail({ store, idStudio }));
    const isFormNotSaved = useSelector(selectorIsFormNotSaved);
    const forceOpenDialog = useSelector(selectorForceOpenDialog);
    const dispatch = useDispatch();
    const history = useHistory();
    const { location } = history;
    const locale = useLocale();

    const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
    const version = process.env.REACT_APP_VERSION;
    const isExternalLink =
        location.pathname.includes("/f/") ||
        location.pathname.includes("/a/") ||
        location.pathname.includes("/r/") ||
        location.pathname.includes("/p/") ||
        location.pathname.includes("/i/") ||
        location.pathname.includes("404") ||
        location.pathname.includes("NotificaEsterna");

    useZoho({ userEmail, userId, nameUser, lastNameUser, studioName, idStudio });
    useSmartlookClient({ lastNameUser, nameUser, userEmail });

    useEffect(() => {
        if (!!location.search && location.search.toLowerCase() === "?notificaesterna") {
            dispatch(actionUIRestoreExternalLink(location.pathname));
        }
    }, [location.search]);

    useEffect(() => {
        if (version && oldVersion && version !== oldVersion) {
            dispatch(actionUIAppVersion(version, true));
        }

        if (!oldVersion && version) dispatch(actionUIAppVersion(version, false));
    }, [version, idStudio]);

    useEffect(() => {
        if (version && version === oldVersion) {
            dispatch(actionUIAppVersion(version, false));
        }
    }, [oldVersion]);

    useEffect(() => {
        if (isUpdate) {
            dispatch(actionEmptyLocalStorage());
            localStorage.removeItem("Magoot");
            window.location.reload();
        }
    }, [isUpdate]);

    const userInfo = {
        username: userId?.toString() ?? "",
        email: userEmail,
        firstname: nameUser,
        lastname: lastNameUser,
        signUpAt: new Date().toString(),
        role: idRuolo?.toString() ?? "",
    };

    if (isUpdate && !isExternalLink) return <Redirect to={utilityGetUrlViewLogin()} />;
    if (!isAppReady) return <UILoader fullScreen isSpinner={false} />;

    return (
        <ConnectedRouter history={history}>
            {userId && (
                <ProductFruits workspaceCode='Pkkct33kgTWSazsQ' language='it' user={userInfo} />
            )}
            <Listener>
                <Subscription>
                    <OfflineBar />
                    <Toast />
                    <Popup />
                    <Navbar setFeedbackModalVisible={setFeedbackModalVisible} />
                    <PromptLeaveForm
                        isFormNotSaved={isFormNotSaved || false}
                        forceOpenDialog={forceOpenDialog?.state}
                    />

                    <section className='relative block h-container bg-tundora-100 z-0'>
                        <Suspense
                            fallback={
                                <div className='flex min-h-screen w-full items-center justify-center bg-gray-50 px-16'>
                                    <div className='relative w-full max-w-lg'>
                                        <img
                                            src={magootLogo}
                                            className='h-full w-full animate-blob blur-lg'
                                            alt='sezione in caricamento'
                                        />
                                    </div>
                                </div>
                            }
                        >
                            <Switch>
                                <Route exact path={Path.viewHome} component={MViewHome} />
                                {/* Rimuovere exact con reac-router 6+ */}
                                <Route path={Path.viewInvitation} component={MViewInvitation} />
                                <Route
                                    path={Path.viewActiveAccount}
                                    component={MViewActivateAccount}
                                />
                                <RouteGuest path={Path.viewLogin} component={MViewLogin} />
                                <RouteGuest path={Path.viewLoginReferrer} component={MViewLogin} />
                                <RouteGuest path={Path.viewSignup} component={MViewSignup} />
                                <RouteGuest
                                    path={Path.viewSignupReferrer}
                                    component={MViewSignup}
                                />
                                <RouteGuest
                                    path={Path.viewSignupSuccess}
                                    component={MViewSignupSuccess}
                                />
                                <RouteGuest
                                    path={Path.viewResetPassword}
                                    component={MViewResetPassword}
                                />
                                <RouteGuest
                                    path={Path.viewResetPasswordSuccess}
                                    component={MViewResetPasswordSuccess}
                                />
                                <RouteGuest
                                    exact
                                    path={Path.viewForgotPassword}
                                    component={MViewForgotPassword}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewListaStudi}
                                    component={MViewStudioList}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewOrder}
                                    component={MViewWorkList}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewPaperworkImport}
                                    component={MExplicationPaperwork}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewDashboard}
                                    component={MViewDashboard}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewOrderCreate}
                                    component={MViewOrderCreate}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewCustomerDetail}
                                    component={MViewCustomerDetail}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewOrderSettings}
                                    component={MViewOrderSettings}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewIdeaList}
                                    component={MViewIdeaList}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewIdeaCreate}
                                    component={MViewIdeaCreate}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewCustomerList}
                                    component={MViewCustomerList}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewCustomerCreate}
                                    component={MViewCustomerCreate}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewCommitmentList}
                                    component={MViewCommitmentList}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewOperatorList}
                                    component={MViewOperatorList}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewNotificationList}
                                    component={MViewNotificationList}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewProfile}
                                    component={MViewProfile}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewAccount}
                                    component={MViewAccount}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewWorkDashboard}
                                    component={MViewWorkDetail}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewTerritorialData}
                                    component={MViewTerritorialData}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewSummary}
                                    component={MViewSummary}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewPayments}
                                    component={MViewPaymentList}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewDownloadOrder}
                                    component={MDownloadOrder}
                                />
                                <Route
                                    exact
                                    path={Path.viewDownloadFile}
                                    component={MViewDownloadFile}
                                />
                                <RouteLogged
                                    exact
                                    path={Path.viewStudioCreate}
                                    component={MViewStudioCreate}
                                />
                                <Route path={Path.viewNotFound} component={MViewNotFound} />
                                <Redirect to={Path.viewNotFound} />
                            </Switch>
                        </Suspense>

                        {feedbackModalVisible && (
                            <UIModal
                                isFullscreen
                                onClose={() => setFeedbackModalVisible(false)}
                                title={translate({
                                    locale,
                                    id: "view.dashboard.sidebar.menu.feedback",
                                })}
                                size='7xl'
                            >
                                <iframe
                                    width='100%'
                                    height='100%'
                                    src='https://survey.zohopublic.eu/zs/LIB8Ln'
                                    allow='geolocation'
                                    className='border-0 max-w-full max-h-screen'
                                    allowFullScreen
                                    title='feedback'
                                />
                            </UIModal>
                        )}
                    </section>
                </Subscription>
            </Listener>
        </ConnectedRouter>
    );
};

App.displayName = "App";
