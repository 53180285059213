interface Translations {
    "view.idea-create.new-idea": string;
    "view.idea-create.update-idea": string;
    "view.idea-create.label.photo-upload": string;
    "view.idea-create.label.title": string;
    "view.idea-create.label.accordion": string;
    "view.idea-create.label.description": string;
    "view.idea-create.label.tag": string;
    "view.idea-create.label.delete-file-disabled": string;
}

interface ViewIdea {
    it: Translations;
    en: Translations;
}

export const translationsViewIdeaCreate: ViewIdea = {
    it: {
        "view.idea-create.new-idea": "Nuova idea",
        "view.idea-create.update-idea": "Modifica idea",
        "view.idea-create.label.photo-upload": "Caricamento foto",
        "view.idea-create.label.title": "Titolo idea",
        "view.idea-create.label.accordion": "Descrivi la tua idea",
        "view.idea-create.label.description": "Descrizione",
        "view.idea-create.label.tag": "Tag",
        "view.idea-create.label.delete-file-disabled":
            "Non è possibile eliminare l'unico file caricato rimasto",
    },
    en: {
        "view.idea-create.new-idea": "New idea",
        "view.idea-create.update-idea": "Update idea",
        "view.idea-create.label.photo-upload": "Upload photos",
        "view.idea-create.label.title": "Idea title",
        "view.idea-create.label.accordion": "Dati idea",
        "view.idea-create.label.description": "Description",
        "view.idea-create.label.tag": "Tag",
        "view.idea-create.label.delete-file-disabled":
            "The only remaining uploaded file cannot be deleted",
    },
};

export type TTranslationsViewIdeaCreate = keyof Translations;
