import { createConfigurationHeader } from "./config";

const fetchDELETE = async (url: string, params: any): Promise<any> => {
    const response = await fetch(url, {
        method: "DELETE",
        headers: createConfigurationHeader(),
        body: JSON.stringify(params),
    });

    return response ? response.json() : null;
};

export default fetchDELETE;
