import { Actions } from "../../utils/Actions";
import { TReducerIdeas, TReducerIdeasActions } from "./declarations";

export const reducerIdeas = (
    prevStore: TReducerIdeas = {},
    action: TReducerIdeasActions
): TReducerIdeas => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.ideaListRequest:
            newStore[action.payload.idOrder] = {
                loading: true,
                value: undefined,
            };

            return newStore;
        case Actions.ideaListSuccess:
            newStore[action.payload.idOrder] = {
                loading: false,
                value: action.payload.ideas,
            };
            return newStore;
        case Actions.ideaCreateSuccess:
            newStore[action.payload.idCommessa] = {
                ...newStore[action.payload.idCommessa],
                loading: false,
                value: {
                    [action.payload.id]: action.payload,
                },
            };
            return newStore;
        case Actions.documentUploadIdeaSuccess:
            if (newStore[action.payload.idOrder] && newStore[action.payload.idOrder].value) {
                const prevFileList = (newStore[action.payload.idOrder] as any).value[
                    action.payload.idEntity
                ].files
                    ? (newStore[action.payload.idOrder] as any).value[action.payload.idEntity].files
                    : [];
                (newStore[action.payload.idOrder] as any).value[action.payload.idEntity].files =
                    prevFileList.concat(action.payload.fileList);
            }
            newStore[action.payload.idOrder].loading = false;
            return newStore;
        case Actions.ideaDeleteSuccess:
            newStore[action.payload.idOrder].loading = false;
            newStore[action.payload.idOrder].value = undefined;
            return newStore;
        case Actions.ideaUpdateSuccess:
            newStore[action.payload.idCommessa] = {
                ...newStore[action.payload.idCommessa],
                loading: false,
                value: {
                    ...newStore[action.payload.idCommessa].value,
                    [action.payload.id]: action.payload,
                },
            };
            return newStore;
        case Actions.documentDeleteIdeaSuccess:
            if (newStore[action.payload.idOrder] && newStore[action.payload.idOrder].value) {
                (newStore[action.payload.idOrder] as any).value[action.payload.idEntity].files =
                    newStore[action.payload.idOrder][action.payload.idEntity].files.filter(
                        (file) => file.id !== action.payload.idDocument
                    );
            }
            newStore[action.payload.idOrder].loading = false;
            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
