import { Actions } from "../../utils/Actions";
import { TStore } from "../declarations";
import { TReducerWorkSummaryActions } from "./declarations";

export const reducerWorkSummary = (
    prevStore: TStore["workSummary"] = {},
    action: TReducerWorkSummaryActions
): TStore["workSummary"] => {
    switch (action.type) {
        case Actions.documentSummaryRequest:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    documentList: {
                        loading: true,
                    },
                },
            };
        case Actions.documentSummarySuccess:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    documentList: {
                        summary: action.payload.summary,
                        loading: false,
                    },
                },
            };
        case Actions.documentSummaryFailure:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    documentList: {
                        ...prevStore[action.payload.idOrder],
                        loading: false,
                    },
                },
            };
        case Actions.documentSummaryLoading:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    documentList: {
                        summary: prevStore[action.payload.idOrder]?.documentList?.summary,
                        loading: action.payload.loading,
                    },
                },
            };

        case Actions.inspectionSummaryRequest:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    inspectionList: {
                        loading: true,
                    },
                },
            };
        case Actions.inspectionSummarySuccess:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    inspectionList: {
                        summary: action.payload.summary,
                        loading: false,
                    },
                },
            };
        case Actions.inspectionSummaryFailure:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    inspectionList: {
                        ...prevStore[action.payload.idOrder],
                        loading: false,
                    },
                },
            };
        case Actions.inspectionSummaryLoading:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    inspectionList: {
                        summary: prevStore[action.payload.idOrder]?.inspectionList?.summary,
                        loading: action.payload.loading,
                    },
                },
            };

        case Actions.annotationSummaryRequest:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    annotationList: {
                        loading: true,
                    },
                },
            };
        case Actions.annotationSummarySuccess:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    annotationList: {
                        summary: action.payload.summary,
                        loading: false,
                    },
                },
            };
        case Actions.annotationSummaryFailure:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    annotationList: {
                        ...prevStore[action.payload.idOrder],
                        loading: false,
                    },
                },
            };
        case Actions.annotationSummaryLoading:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    annotationList: {
                        summary: prevStore[action.payload.idOrder]?.annotationList?.summary,
                        loading: action.payload.loading,
                    },
                },
            };

        case Actions.summaryOfCountRequest:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    count: {
                        [action.payload.idWork ?? 0]: {
                            loading: true,
                        },
                    },
                },
            };
        case Actions.summaryOfCountSuccess:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    count: {
                        [action.payload.idWork ?? 0]: {
                            value: action.payload.count,
                            loading: false,
                        },
                    },
                },
            };
        case Actions.summaryOfCountFailure:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    count: {
                        ...prevStore[action.payload.idOrder]?.count,
                        ...(action.payload.idWork && {
                            [action.payload.idWork]: {
                                value: {
                                    ...prevStore[action.payload.idOrder]?.count[
                                        action?.payload?.idWork ?? 0
                                    ]?.value,
                                },
                                loading: false,
                            },
                        }),
                    },
                },
            };
        case Actions.summaryOfCountLoading:
            return {
                ...prevStore,
                [action.payload.idOrder]: {
                    ...prevStore[action.payload.idOrder],
                    count: {
                        ...(action.payload.idWork && {
                            [action.payload.idWork]: {
                                value: {
                                    ...prevStore[action.payload.idOrder]?.count[
                                        action?.payload?.idWork ?? 0
                                    ]?.value,
                                },
                                loading: false,
                            },
                        }),
                    },
                },
            };
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
