import { Actions, TError } from "../../../utils/Actions";
import {
    TActionIdeaListFailure,
    TActionIdeaListRequest,
    TActionIdeaListSuccess,
} from "./declarations";

export const actionIdeaListRequest = (payload: {
    idStudio: number;
    idOrder: number;
}): TActionIdeaListRequest => ({
    type: Actions.ideaListRequest,
    payload,
});

export const actionIdeaListSuccess = (payload: any): TActionIdeaListSuccess => ({
    type: Actions.ideaListSuccess,
    payload,
});

export const actionIdeaListFailure = (payload: TError): TActionIdeaListFailure => ({
    type: Actions.ideaListFailure,
    payload,
});
