import { createDownloadCsvHeader } from "./config";

const fetchDownloadCsv = async (url: string, params: any): Promise<any> => {
    const response = await fetch(url, {
        method: "POST",
        headers: createDownloadCsvHeader(),
        body: JSON.stringify(params),
    });

    const filename =
        response && response.headers && response.headers.get("Content-Disposition")
            ? response.headers
                  .get("Content-Disposition")!
                  ?.split("filename=")[1]
                  ?.split(";")[0]
                  ?.replaceAll('"', "")
                  ?.trim()
            : "";
    const responseWithFilename = { arrayBuffer: response.arrayBuffer(), filename };
    return response ? responseWithFilename : null;
};

export default fetchDownloadCsv;
