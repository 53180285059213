import { TResponse } from "@magoot-sdk/declarations/general/responseGeneral";

type Utente = {
    id: number;
    cognome: string;
    nome: string;
    srcImage: string;
};

enum StatoDiritto {
    Nessuno = 0,
    Lettura = 1,
    LetturaEScrittura = 2,
    Scrittura = 3,
}

enum StatoSezione {
    Attivita = 1,
    Documento = 2,
    Sopralluogo = 3,
    Annotazione = 4,
    Committente = 5,
    Operatore = 6,
    DatoTerritoriale = 7,
    BachecaIdee = 8,
    Preventivo = 9,
    Incasso = 10,
    Spese = 11,
    CostiCommessa = 12,
    ImpostazioniCommessa = 13,
    ImpostazioniTeamCommessa = 14,
    ImpostazioniTeamCostiCommessa = 15,
    ImpostazioniDirittiCommessa = 16,
}

enum TRole {
    Titolare = 0,
    Amministratore = 1,
    Collaboratore_Interno = 2,
    Collaboratore_Esterno = 3,
    Capo_Commessa = 4,
}

type Diritto = {
    diritto: {
        id: StatoDiritto;
        descr: string;
    };
    sezione: {
        id: StatoSezione;
        descr: string;
    };
};

type UtentiEDiritti = {
    dirittiSezione: Diritto[];
    utente: Utente;
    idRuolo?: TRole;
    idCommessa?: number;
    capoCommessa?: boolean;
};

export type TResponseTabellaDiritti = TResponse<UtentiEDiritti[]>;
export type { Utente, Diritto, UtentiEDiritti };
export { StatoDiritto, StatoSezione, TRole };
