import { TReducerDictionary } from "@magoot-sdk/redux-modules/reducers/reducerDictionaries/declarations";

import { TParamsApiDictionary } from "../../../../api/dictionary/dictionaryOrderList/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryOrderListFailure,
    TActionDictionaryOrderListRequest,
    TActionDictionaryOrderListSuccess,
} from "./declarations";

export const actionDictionaryOrderListRequest = (
    payload: TParamsApiDictionary
): TActionDictionaryOrderListRequest => ({
    type: Actions.dictionaryOrderListRequest,
    payload,
});

export const actionDictionaryOrderListSuccess = (
    payload: TReducerDictionary
): TActionDictionaryOrderListSuccess => ({
    type: Actions.dictionaryOrderListSuccess,
    payload,
});

export const actionDictionaryOrderListFailure = (
    payload: TError
): TActionDictionaryOrderListFailure => ({
    type: Actions.dictionaryOrderListFailure,
    payload,
});
