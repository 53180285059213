import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLockAlt } from "@fortawesome/pro-light-svg-icons";
import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { MenuSection, SubMenuSection } from "@magoot-sdk/declarations/general/urlParams";
import { translate } from "@magoot-sdk/i18n/translate";
import { actionUISubscriptionModalClose } from "@magoot-sdk/redux-modules/actions/ui/actionUi";
import { useSubscription } from "@magoot-sdk/redux-modules/hooks/subscription/useSubscription";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import {
    selectorIsStudioSubscribed,
    selectorSubscriptionCanceled,
    selectorSubscriptionExpired,
} from "@magoot-sdk/redux-modules/selectors/selectorsSubscription";
import { selectorSubscriptionModalSource } from "@magoot-sdk/redux-modules/selectors/selectorsUI";
import { useDispatch } from "@magoot-sdk/redux-modules/utils/useDispatch";
import { utilityGetUrlViewDashboard } from "@magoot-sdk/utils/Routes";

import { UIButton } from "@components-ui/Button";
import { UIIcon } from "@components-ui/Icon";

export const ModalUpgrade: FC<{ pathSplitted: string[] }> = ({ pathSplitted }): JSX.Element => {
    const history = useHistory();
    const { idStudio } = useStudioID();
    const dispatch = useDispatch();
    const locale = useLocale();
    const { hasToUpgrade } = useSubscription({ code: "USERS" });
    const modalSource = useSelector(selectorSubscriptionModalSource);

    const isSectionTeam = useMemo(() => {
        return pathSplitted.includes("gestione-studio");
    }, [pathSplitted]);

    const isStudioSubscribed = useSelector((store: TStore) =>
        selectorIsStudioSubscribed({ store, idStudio })
    );
    const isSubscriptionExpired = useSelector((store: TStore) =>
        selectorSubscriptionExpired({ store, idStudio })
    );
    const isSubscriptionCanceled = useSelector((store: TStore) =>
        selectorSubscriptionCanceled({ store, idStudio })
    );

    const onClose = (): void => dispatch(actionUISubscriptionModalClose());

    const onClick = (): void => {
        onClose();
        history.push(
            utilityGetUrlViewDashboard({
                idStudio,
                menuSelected: MenuSection.GestioneStudio,
                subMenuSelected: SubMenuSection.Abbonamento,
            })
        );
    };

    return (
        <div className='modal modal-open' onClick={onClose}>
            <div className='modal-box bg-gradient-to-r from-secondary to-primaryGradient'>
                <div className='my-16 flex flex-row justify-center'>
                    <UIIcon svgIcon={faLockAlt as IconProp} size='4x' color='#fff' />
                </div>
                <p className='py-4 text-center text-white'>
                    {hasToUpgrade &&
                        isStudioSubscribed &&
                        !isSubscriptionExpired &&
                        !isSubscriptionCanceled &&
                        isSectionTeam &&
                        !modalSource && (
                            <>
                                <span className='text-center'>
                                    {translate({ locale, id: "modal.upgrade.label.user" })}
                                </span>
                                &nbsp;
                                <span className='text-center'>
                                    {translate({ locale, id: "modal.upgrade.label.user.action" })}
                                </span>
                            </>
                        )}

                    {((isStudioSubscribed &&
                        !isSubscriptionExpired &&
                        !isSubscriptionCanceled &&
                        !isSectionTeam) ||
                        modalSource) && (
                        <>
                            <span className='text-center'>
                                {translate({
                                    locale,
                                    id: "modal.upgrade.label.no-functionality-in-your-subscription",
                                })}
                            </span>
                            &nbsp;
                            <span className='text-center'>
                                {translate({
                                    locale,
                                    id: "modal.upgrade.label.upgrade-to-activate",
                                })}
                            </span>
                        </>
                    )}

                    {isStudioSubscribed && isSubscriptionCanceled && !isSubscriptionExpired && (
                        <>
                            <span className='text-center'>
                                {translate({
                                    locale,
                                    id: "modal.upgrade.label.canceled-subscription",
                                })}
                            </span>
                            &nbsp;
                            <span className='text-center'>
                                {translate({ locale, id: "modal.upgrade.label.canceled" })}
                            </span>
                        </>
                    )}

                    {!isStudioSubscribed && (
                        <span className='text-center'>
                            {translate({ locale, id: "modal.upgrade.label.no-subscription" })}
                        </span>
                    )}

                    {isSubscriptionExpired && (
                        <span className='text-center'>
                            {translate({ locale, id: "general.label.subscription-expired" })}
                        </span>
                    )}
                </p>
                <div className='modal-action flex flex-row justify-center'>
                    {isStudioSubscribed && isSubscriptionCanceled && !isSubscriptionExpired && (
                        <div className='mt-16'>
                            <UIButton
                                text={translate({ locale, id: "button.active" })}
                                onClick={onClick}
                                dataCy='TO_BE_FIXED'
                                fluid={false}
                            />
                        </div>
                    )}
                    {(isStudioSubscribed && !isStudioSubscribed && !isSubscriptionCanceled) ||
                        (hasToUpgrade && !isSubscriptionExpired && !isSubscriptionCanceled && (
                            <div className='mt-16'>
                                <UIButton
                                    text={translate({ locale, id: "button.upgrade" })}
                                    onClick={onClick}
                                    dataCy='TO_BE_FIXED'
                                    fluid={false}
                                />
                            </div>
                        ))}

                    {(isStudioSubscribed &&
                        isStudioSubscribed &&
                        !isSubscriptionCanceled &&
                        !hasToUpgrade) ||
                        (isSubscriptionExpired && (
                            <div className='mt-16'>
                                <UIButton
                                    text={translate({
                                        locale,
                                        id: "view.subscription.checkout.button.renews",
                                    })}
                                    onClick={onClick}
                                    dataCy='TO_BE_FIXED'
                                    fluid={false}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
