import { IOnEdit } from "@components-view/home/ViewDashboard/components/SectionDictionaries/partials/CreateRow";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { TParamsApiDictionaryTaskEdit } from "@magoot-sdk/api/dictionary/dictionaryTaskEdit/params";
import { actionDictionaryDocumentTypeCreateRequest } from "@magoot-sdk/redux-modules/actions/dictionary/actionDictionaryDocumentTypeCreate";
import { actionDictionaryDocumentTypeEditRequest } from "@magoot-sdk/redux-modules/actions/dictionary/actionDictionaryDocumentTypeEdit";
import { actionDictionaryTaskCreateRequest } from "@magoot-sdk/redux-modules/actions/dictionary/actionDictionaryTaskCreate";

import { actionDictionaryCostCenterCreateRequest } from "../../actions/dictionary/actionDictionaryCostCenterCreate";
import { actionDictionaryCostCenterUpdateRequest } from "../../actions/dictionary/actionDictionaryCostCenterUpdate";
import { actionDictionaryOperatorCreateRequest } from "../../actions/dictionary/actionDictionaryOperatorCreate";
import { actionDictionaryOperatorEditRequest } from "../../actions/dictionary/actionDictionaryOperatorEdit";
import { actionDictionaryOrderCreateRequest } from "../../actions/dictionary/actionDictionaryOrderCreate";
import { actionDictionaryOrderEditRequest } from "../../actions/dictionary/actionDictionaryOrderEdit";
import { actionDictionaryOrderListRequest } from "../../actions/dictionary/actionDictionaryOrderList";
import { actionDictionarySendingCreateRequest } from "../../actions/dictionary/actionDictionarySendingCreate";
import { actionDictionarySendingEditRequest } from "../../actions/dictionary/actionDictionarySendingEdit";
import { actionDictionaryWorkCreateRequest } from "../../actions/dictionary/actionDictionaryWorkCreate";
import { actionDictionaryWorkEditRequest } from "../../actions/dictionary/actionDictionaryWorkEdit";
import { actionDictionaryWorkListRequest } from "../../actions/dictionary/actionDictionaryWorkList";
import { TStore } from "../../reducers/declarations";
import { TDictionary, TDictionaryTask } from "../../reducers/reducerDictionaries/declarations";
import {
    selectorDictionaryCostCenterList,
    selectorDictionaryOperatorList,
    selectorDictionaryOrderList,
    selectorDictionaryOrderListNoRerender,
    selectorDictionarySendingList,
    selectorDictionaryTypeList,
    selectorDictionaryWorkList,
    selectorDictionaryWorkListNoRerender,
    selectorDictionatyLandRegistryList,
} from "../../selectors/selectorsDictionaries";
import { useDispatch } from "../../utils/useDispatch";
import { useStudioID } from "../useStudioID";

export const useDictionaryOrder = ({ reload }: { reload: boolean }): TDictionary[] => {
    const dispatch = useDispatch();
    const { idStudio } = useStudioID();
    const dictionaryOrderRaw = useSelector(selectorDictionaryOrderListNoRerender);
    const dictionaryOrder: TDictionary[] | undefined = dictionaryOrderRaw
        ? Object.values(dictionaryOrderRaw).sort(
              (a: TDictionary, b: TDictionary) => a.ordinamento - b.ordinamento
          )
        : [];

    useEffect(() => {
        if (reload) dispatch(actionDictionaryOrderListRequest({ idStudio }));
    }, [dispatch, idStudio, reload]);

    return dictionaryOrder;
};

export const useDictionaryWork = ({
    reload,
}: {
    reload: boolean;
}): undefined | TDictionary[] | TDictionaryTask[] => {
    const dispatch = useDispatch();
    const { idStudio } = useStudioID();
    const dictionaryWorkRaw = useSelector(selectorDictionaryWorkList);
    const dictionaryWork: TDictionary[] | TDictionaryTask[] | undefined = dictionaryWorkRaw
        ? Object.values(dictionaryWorkRaw).sort((a: any, b: any) => a.ordinamento - b.ordinamento)
        : [];

    useEffect(() => {
        if (reload) dispatch(actionDictionaryWorkListRequest({ idStudio }));
    }, [dispatch, idStudio, reload]);

    return dictionaryWork;
};

export const useDictionaries = (): {
    dictionaryOrderList: TDictionary[];
    dictionaryCostCenterList: TDictionary[];
    dictionaryOperatorList: TDictionary[];
    dictionaryWorkList: TDictionary[];
    dictionarySendingList: TDictionary[];
    dictionaryDocumentTypeList: TDictionary[];
    dictionaryLandRegistryList: TDictionary[];

    onCreateDictionaryCostCenter: (descr: string) => void;
    onCreateDictionaryOperator: (descr: string) => void;
    onCreateDictionaryOrder: (descr: string) => void;
    onCreateDictionarySendingType: (descr: string) => void;
    onCreateDictionaryWorksType: (descr: string) => void;
    onCreateDictionaryDocumentType: (descr: string) => void;
    onCreateDictionaryTaskType: ({
        descr,
        descrEstesa,
        idTipoLav,
        id,
    }: Partial<TParamsApiDictionaryTaskEdit>) => void;

    onEditDictionaryOperator: ({ idStudio, dictionary, newDescr }: IOnEdit) => void;
    onEditDictionaryOrder: ({ idStudio, dictionary, newDescr }: IOnEdit) => void;
    onEditDictionarySending: ({ idStudio, dictionary, newDescr }: IOnEdit) => void;
    onEditDictionaryWork: ({ idStudio, dictionary, newDescr }: IOnEdit) => void;
    onEditDictionaryCostCenter: ({ idStudio, dictionary, newDescr }: IOnEdit) => void;
    onEditDictionaryDocumentType: ({ idStudio, dictionary, newDescr }: IOnEdit) => void;
} => {
    const dispatch = useDispatch();
    const { idStudio } = useStudioID();

    // #region OPERATOR
    const dictionaryOperatorListRaw: { [key: number]: TDictionary } | undefined = useSelector(
        (store: TStore) => selectorDictionaryOperatorList(store)
    );
    const dictionaryOperatorList: TDictionary[] = dictionaryOperatorListRaw
        ? Object.values(dictionaryOperatorListRaw).sort(
              (a: any, b: any) => a.ordinamento - b.ordinamento
          )
        : [];

    const onCreateDictionaryOperator = (descr: string): void =>
        dispatch(actionDictionaryOperatorCreateRequest({ idStudio, description: descr }));

    const onEditDictionaryOperator = ({
        idStudio: idStudioEditDictionaryOperator,
        dictionary: dictionaryID,
        newDescr,
    }: IOnEdit) => {
        dispatch(
            actionDictionaryOperatorEditRequest({
                idStudio: idStudioEditDictionaryOperator,
                id: dictionaryID,
                descr: newDescr,
            })
        );
    };
    // #endregion

    // #region COST CENTER
    const dictionaryCostCenterListRaw: { [key: number]: TDictionary } | undefined = useSelector(
        (store: TStore) => selectorDictionaryCostCenterList(store)
    );
    const dictionaryCostCenterList: TDictionary[] = dictionaryCostCenterListRaw
        ? Object.values(dictionaryCostCenterListRaw).sort(
              (a: any, b: any) => a.ordinamento - b.ordinamento
          )
        : [];

    const onCreateDictionaryCostCenter = (descr: string): void =>
        dispatch(actionDictionaryCostCenterCreateRequest({ idStudio, description: descr }));

    const onEditDictionaryCostCenter = ({
        idStudio: idStudioEditDictionaryCostCenter,
        dictionary: dictionaryID,
        newDescr,
    }: IOnEdit) =>
        dispatch(
            actionDictionaryCostCenterUpdateRequest({
                idStudio: idStudioEditDictionaryCostCenter,
                id: dictionaryID,
                descr: newDescr,
            })
        );
    // #endregion

    // #region ORDER
    const dictionaryOrderListRaw: { [key: number]: TDictionary } | undefined = useSelector(
        (store: TStore) => selectorDictionaryOrderList(store)
    );
    const dictionaryOrderList: TDictionary[] | undefined = dictionaryOrderListRaw
        ? Object.values(dictionaryOrderListRaw).sort(
              (a: any, b: any) => a.ordinamento - b.ordinamento
          )
        : [];

    const onCreateDictionaryOrder = (descr: string): void =>
        dispatch(
            actionDictionaryOrderCreateRequest({
                idStudio,
                description: descr,
                // idWorks: [1],
            })
        );
    const onEditDictionaryOrder = ({
        idStudio: idStudioEditDictionaryOrder,
        dictionary: dictionaryID,
        newDescr,
    }: IOnEdit) => {
        dispatch(
            actionDictionaryOrderEditRequest({
                idStudio: idStudioEditDictionaryOrder,
                id: dictionaryID,
                descr: newDescr,
            })
        );
    };
    // #endregion

    // #region SENDING
    const dictionarySendingListRaw: { [key: number]: TDictionary } | undefined = useSelector(
        (store: TStore) => selectorDictionarySendingList(store)
    );
    const dictionarySendingList: TDictionary[] = dictionarySendingListRaw
        ? Object.values(dictionarySendingListRaw).sort(
              (a: any, b: any) => a.ordinamento - b.ordinamento
          )
        : [];

    const onCreateDictionarySendingType = (descr: string): void =>
        dispatch(actionDictionarySendingCreateRequest({ idStudio, description: descr }));

    const onEditDictionarySending = ({
        idStudio: idStudioEditDictionarySending,
        dictionary: dictionaryID,
        newDescr,
    }: IOnEdit) =>
        dispatch(
            actionDictionarySendingEditRequest({
                idStudio: idStudioEditDictionarySending,
                id: dictionaryID,
                descr: newDescr,
            })
        );

    // #endregion

    // #region WORK
    const dictionaryWorkListRaw: { [key: number]: TDictionary } | undefined = useSelector(
        (store: TStore) => selectorDictionaryWorkListNoRerender(store)
    );
    const dictionaryWorkList: TDictionary[] = dictionaryWorkListRaw
        ? Object.values(dictionaryWorkListRaw).sort(
              (a: any, b: any) => a.ordinamento - b.ordinamento
          )
        : [];

    const onCreateDictionaryWorksType = (descr: string): void =>
        dispatch(actionDictionaryWorkCreateRequest({ idStudio, description: descr }));

    const onEditDictionaryWork = ({
        idStudio: idStudioEditDictionaryWork,
        dictionary: dictionaryID,
        newDescr,
    }: IOnEdit) =>
        dispatch(
            actionDictionaryWorkEditRequest({
                idStudio: idStudioEditDictionaryWork,
                id: dictionaryID,
                descr: newDescr,
            })
        );
    // #endregion

    // TYPE DOCUMENT
    const dictionaryTypeListRaw: { [key: number]: TDictionary } | undefined = useSelector(
        (store: TStore) => selectorDictionaryTypeList(store)
    );

    const dictionaryLandRegistryListRaw: { [key: number]: TDictionary } | undefined = useSelector(
        (store: TStore) => selectorDictionatyLandRegistryList(store)
    );

    const dictionaryDocumentTypeList: TDictionary[] = dictionaryTypeListRaw
        ? Object.values(dictionaryTypeListRaw).sort(
              (a: any, b: any) => a.ordinamento - b.ordinamento
          )
        : [];

    const dictionaryLandRegistryList: TDictionary[] = dictionaryLandRegistryListRaw
        ? Object.values(dictionaryLandRegistryListRaw).sort(
              (a: any, b: any) => a.ordinamento - b.ordinamento
          )
        : [];

    const onCreateDictionaryDocumentType = (descr: string): void =>
        dispatch(actionDictionaryDocumentTypeCreateRequest({ idStudio, descr }));

    const onCreateDictionaryTaskType = ({
        descr,
        descrEstesa,
        idTipoLav,
    }: Partial<TParamsApiDictionaryTaskEdit>): void => {
        if (descr && idTipoLav) {
            dispatch(
                actionDictionaryTaskCreateRequest({
                    idStudio,
                    descr,
                    descrEstesa: descrEstesa ?? "",
                    idTipoLav,
                })
            );
        }
    };

    const onEditDictionaryDocumentType = ({
        idStudio: idStudioEditDictionaryDocumentType,
        dictionary: dictionaryID,
        newDescr,
    }: IOnEdit) =>
        dispatch(
            actionDictionaryDocumentTypeEditRequest({
                idStudio: idStudioEditDictionaryDocumentType,
                id: dictionaryID,
                descr: newDescr,
            })
        );

    return {
        dictionaryOrderList,
        dictionaryCostCenterList,
        dictionaryOperatorList,
        dictionaryWorkList,
        dictionarySendingList,
        dictionaryDocumentTypeList,
        dictionaryLandRegistryList,

        onCreateDictionaryCostCenter,
        onCreateDictionaryOperator,
        onCreateDictionaryOrder,
        onCreateDictionarySendingType,
        onCreateDictionaryWorksType,
        onCreateDictionaryDocumentType,
        onCreateDictionaryTaskType,

        onEditDictionaryOperator,
        onEditDictionaryOrder,
        onEditDictionarySending,
        onEditDictionaryWork,
        onEditDictionaryCostCenter,
        onEditDictionaryDocumentType,
    };
};
