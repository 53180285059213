import { TTranslationsPopup } from "../../i18n/translations/popup";
import { TStore } from "../reducers/declarations";
import { Actions } from "../utils/Actions";

export const selectorPopupTitle = (store: TStore): TTranslationsPopup | "" =>
    store.popup?.title || "";

export const selectorPopupBody = (store: TStore): TTranslationsPopup | "" =>
    store.popup?.body || "";

export const selectorPopupOnConfirmType = (store: TStore): Actions | false =>
    store.popup?.onConfirm || false;

export const selectorPopupNotOnDeleteType = (store: TStore): Actions | false =>
    store.popup?.notOnDelete || false;

export const selectorPopupInformationFlag = (store: TStore): boolean =>
    store.popup?.information || false;

export const selectorPopupCenterBody = (store: TStore): boolean => store.popup?.centerBody || false;

export const selectorPopupHiddenClose = (store: TStore): boolean =>
    store.popup?.hiddenClose || false;

export const selectorPopupLoader = (store: TStore): boolean => store.popup?.loader || false;

export const selectorPopupOnConfirmPayload = (store: TStore): any => store.popup?.payload;

export const selectorPopupIsVisible = (store: TStore): boolean =>
    (!!store.popup?.title && !!store.popup?.body) || false;

export const selectorPopupOnSave = (store: TStore): boolean => store.popup?.onSave ?? false;
