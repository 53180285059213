interface Translations {
    "view.forgot-password-success.title": string;
    "view.forgot-password-success.description": string;
}

interface ViewForgotPasswordSuccess {
    it: Translations;
    en: Translations;
}

export const translationsViewForgotPasswordSuccess: ViewForgotPasswordSuccess = {
    it: {
        "view.forgot-password-success.title": "Controlla la tua email!",
        "view.forgot-password-success.description":
            "Clicca sul link che ti abbiamo inviato via email per cambiare la password",
    },
    en: {
        "view.forgot-password-success.title": "Check your email!",
        "view.forgot-password-success.description":
            "Click on the link we emailed you to change your password",
    },
};

export type TTranslationsViewForgotPasswordSuccess = keyof Translations;
