import { Actions } from "../../utils/Actions";
import { TReducerPayments, TReducerPaymentsActions } from "./declarations";

export const reducerPayments = (
    prevStore: TReducerPayments = {},
    action: TReducerPaymentsActions
): TReducerPayments => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.paymentPrevisionListSuccess:
            newStore.previsions = { ...newStore.previsions };
            newStore.previsions[action.payload.idOrder] = action.payload.previsions;
            return newStore;
        case Actions.paymentPrevisionCreateSuccess:
            if (action.payload.prevision.id) {
                newStore.previsions = { ...newStore.previsions };
                newStore.previsions[action.payload.idOrder] = {
                    ...newStore.previsions[action.payload.idOrder],
                    [action.payload.prevision.id]: action.payload.prevision,
                };
            }
            return newStore;
        case Actions.paymentPrevisionUpdateSuccess:
            newStore.previsions = { ...newStore.previsions };
            newStore.previsions[action.payload.idOrder] = {
                ...newStore.previsions[action.payload.idOrder],
                [action.payload.prevision.ordinamento || 0]: action.payload.prevision,
            };

            return newStore;
        case Actions.paymentPrevisionDeleteSuccess:
            newStore.previsions = { ...newStore.previsions };
            delete newStore.previsions[action.payload.idOrder][action.payload.idPrevision];
            return newStore;
        case Actions.paymentEarnListSuccess:
            newStore.earnings = { ...newStore.earnings };
            newStore.earnings[action.payload.idOrder] = action.payload.earnings;
            return newStore;
        case Actions.paymentEarnCreateSuccess:
            if (action.payload.earn.id) {
                newStore.earnings = { ...newStore.earnings };
                newStore.earnings[action.payload.idOrder] = {
                    ...newStore.earnings[action.payload.idOrder],
                    [action.payload.earn.id]: action.payload.earn,
                };
            }
            return newStore;
        case Actions.paymentEarnUpdateSuccess:
            if (action.payload.earn.id) {
                newStore.earnings = { ...newStore.earnings };
                newStore.earnings[action.payload.idOrder] = {
                    ...newStore.earnings[action.payload.idOrder],
                    [action.payload.earn.id]: action.payload.earn,
                };
            }
            return newStore;
        case Actions.paymentEarnDeleteSuccess:
            newStore.earnings = { ...newStore.earnings };
            delete newStore.earnings[action.payload.idOrder][action.payload.idEarn];
            return newStore;
        case Actions.paymentCostListSuccess:
            newStore.costs = { ...newStore.costs };
            newStore.costs[action.payload.idOrder] = action.payload.costs;
            return newStore;
        case Actions.paymentCostUserListSuccess:
            newStore.userCosts = [...action.payload.costs];
            return newStore;
        case Actions.paymentCostCreateSuccess:
            if (action.payload.cost.id) {
                newStore.costs = { ...newStore.costs };
                newStore.costs[action.payload.idOrder] = {
                    ...newStore.costs[action.payload.idOrder],
                    [action.payload.cost.id]: action.payload.cost,
                };
            }
            return newStore;
        case Actions.paymentCostUpdateSuccess:
            if (action.payload.cost.id) {
                newStore.costs = { ...newStore.costs };
                newStore.costs[action.payload.idOrder] = {
                    ...newStore.costs[action.payload.idOrder],
                    [action.payload.cost.id]: action.payload.cost,
                };
            }
            return newStore;
        case Actions.paymentCostDeleteSuccess:
            newStore.costs = { ...newStore.costs };
            delete newStore.costs[action.payload.idOrder][action.payload.idCost];
            return newStore;
        case Actions.paymentPrevisionMaxSuccess:
            newStore.total = { ...newStore.total };
            newStore.total.previsionMax = { ...newStore.total.previsionMax };
            newStore.total.previsionMax[action.payload.idOrder] = action.payload.previsionMax;
            return newStore;
        case Actions.paymentPrevisionMinSuccess:
            newStore.total = { ...newStore.total };
            newStore.total.previsionMin = { ...newStore.total.previsionMin };
            newStore.total.previsionMin[action.payload.idOrder] = action.payload.previsionMin;
            return newStore;
        case Actions.paymentEarnTotalSuccess:
            newStore.total = { ...newStore.total };
            newStore.total.earnings = { ...newStore.total.earnings };
            newStore.total.earnings[action.payload.idOrder] = action.payload.earnings;
            return newStore;
        case Actions.paymentCostTotalSuccess:
            newStore.total = { ...newStore.total };
            newStore.total.costs = { ...newStore.total.costs };
            newStore.total.costs[action.payload.idOrder] = action.payload.costs;
            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
