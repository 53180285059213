import { ModalParzialeOreState } from "@components-layout/ModalParzialeOre";
import { IconDefinition, faSort } from "@fortawesome/pro-light-svg-icons";
import { MultiValue, OptionsOrGroups } from "react-select";

import { SelectSortRow } from "@magoot-sdk/api/search-pro/params";
import { SchemaSortBy } from "@magoot-sdk/declarations/general/filter";

import { TOption } from "@components-ui/Select";

import {
    RiepilogoAttivita,
    RiepilogoAttivitaOre,
    RiepilogoCommessa,
    RiepilogoCommesseCommittenti,
    RiepilogoCommesseTags,
    RiepilogoCommesseTeam,
} from "./response";

export enum Ordinamento {
    NomeCommessa = 1,
    DataInizio = 2,
    NumeroCommessa = 3,
    Anno = 4,
    StatoCommessa = 5,
    UltimoAccesso = 12,
}

export enum RicercaLibera {
    CommessaDati = 1,
    CommessaNumeroAnno = 2,
    Committenti = 3,
    Indirizzo = 4,
    AttivitaDati = 5,
}

export enum OrdinamentoBE {
    NomeCommessa = 1,
    DataCreazione = 2,
    NumeroCommessa = 3,
    AnnoCommessa = 4,
    TitoloAttivita = 13,
    DataScadenzaAttivita = 14,
}

export interface SelectStatus {
    value: number | string | null;
    reload: boolean;
}

export interface MultiSelectStatus {
    value: MultiValue<{
        value: number;
        label: string;
    }> | null;
    reload: boolean;
}

export interface OrderListContextProps {
    orderList: RiepilogoCommessa[];
    orderListGeo: RiepilogoCommessa[];
    setOrderList: React.Dispatch<React.SetStateAction<RiepilogoCommessa[]>>;
    currentPage: SelectStatus;
    setCurrentPage: React.Dispatch<React.SetStateAction<SelectStatus>>;
    totalPages: number | null;
    setTotalPages: React.Dispatch<React.SetStateAction<number | null>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    dimensionePagina: number;
    setDimensionePagina: React.Dispatch<React.SetStateAction<number>>;
    orderTypeOptions: TOption[];
    orderStatusOptions: TOption[];
    orderYearOptions: TOption[];
    orderUserOptions: TOption[];
    multiOrderCityOptions: OptionsOrGroups<never, any>;
    team: RiepilogoCommesseTeam[];
    tags: TOption[];
    committenti: RiepilogoCommesseCommittenti[];
    commesseTags: RiepilogoCommesseTags[];
    sortBy: SelectStatus;
    setSortBy: React.Dispatch<React.SetStateAction<SelectStatus>>;
    filters: SelectStatus;
    setFilters: React.Dispatch<React.SetStateAction<SelectStatus>>;
    mapView: boolean;
    setMapView: React.Dispatch<React.SetStateAction<boolean>>;
    tipologia: SelectStatus;
    setTipologia: React.Dispatch<React.SetStateAction<SelectStatus>>;
    stato: SelectStatus;
    setStato: React.Dispatch<React.SetStateAction<SelectStatus>>;
    anno: SelectStatus;
    setAnno: React.Dispatch<React.SetStateAction<SelectStatus>>;
    utente: SelectStatus;
    setUtente: React.Dispatch<React.SetStateAction<SelectStatus>>;
    localita: MultiSelectStatus;
    setLocalita: React.Dispatch<React.SetStateAction<MultiSelectStatus>>;
    tag: SelectStatus;
    setTag: React.Dispatch<React.SetStateAction<SelectStatus>>;
    previewFilters: SelectStatus;
    setPreviewFilters: React.Dispatch<React.SetStateAction<SelectStatus>>;
    ricercaLibera: SelectStatus;
    setRicercaLibera: React.Dispatch<React.SetStateAction<SelectStatus>>;
    getIcon: ({ property, order, key }: SchemaSortBy) => IconDefinition;
    setSortRow: React.Dispatch<React.SetStateAction<SelectSortRow>>;
    sortRow: SelectSortRow;
}

export interface TaskListContextProps {
    taskList: RiepilogoAttivita[];
    setTaskList: React.Dispatch<React.SetStateAction<RiepilogoAttivita[]>>;
    currentPage: SelectStatus;
    setCurrentPage: React.Dispatch<React.SetStateAction<SelectStatus>>;
    totalPages: number | null;
    setTotalPages: React.Dispatch<React.SetStateAction<number | null>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    dimensionePagina: number;
    setDimensionePagina: React.Dispatch<React.SetStateAction<number>>;
    orderUserOptions: TOption[];
    orderStatusOptions: TOption[];
    sortBy: SelectStatus;
    setSortBy: React.Dispatch<React.SetStateAction<SelectStatus>>;
    filters: SelectStatus;
    setFilters: React.Dispatch<React.SetStateAction<SelectStatus>>;
    tipologia: SelectStatus;
    setTipologia: React.Dispatch<React.SetStateAction<SelectStatus>>;
    utente: SelectStatus;
    setUtente: React.Dispatch<React.SetStateAction<SelectStatus>>;
    previewFilters: SelectStatus;
    setPreviewFilters: React.Dispatch<React.SetStateAction<SelectStatus>>;
    ricercaLibera: SelectStatus;
    setRicercaLibera: React.Dispatch<React.SetStateAction<SelectStatus>>;
    getIcon: ({ property, order, key }: SchemaSortBy) => IconDefinition;
    setSortRow: React.Dispatch<React.SetStateAction<SelectSortRow>>;
    sortRow: SelectSortRow;
    inlineUpdate: boolean;
    setInlineUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    salvataggioMultiplo: RiepilogoAttivita[];
    setSalvataggioMultiplo: React.Dispatch<React.SetStateAction<RiepilogoAttivita[]>>;
    handleUpdateSalvataggioMultiplo: () => void;
    groupByCommessa: boolean;
    setGroupByCommessa: React.Dispatch<React.SetStateAction<boolean>>;
    groupByCommessaList: {
        [key: number]: RiepilogoAttivita[];
    };
    parzialiOre: RiepilogoAttivitaOre[];
    openModal: ModalParzialeOreState;
    handleSetOpenModal: (modal: ModalParzialeOreState) => void;
    handleReloadParzialiOre: () => void;
}

export const initOrderListContext: OrderListContextProps = {
    orderList: [],
    orderListGeo: [],
    setOrderList: () => {},
    currentPage: { value: 1, reload: true },
    setCurrentPage: () => {},
    totalPages: null,
    setTotalPages: () => {},
    loading: false,
    setLoading: () => {},
    dimensionePagina: 13,
    setDimensionePagina: () => {},
    orderTypeOptions: [],
    orderStatusOptions: [],
    orderYearOptions: [],
    orderUserOptions: [],
    multiOrderCityOptions: [],
    team: [],
    tags: [],
    committenti: [],
    commesseTags: [],
    sortBy: { value: Ordinamento.UltimoAccesso, reload: false },
    setSortBy: () => {},
    filters: { value: "", reload: false },
    setFilters: () => {},
    mapView: false,
    setMapView: () => {},
    tipologia: { value: 0, reload: false },
    setTipologia: () => {},
    stato: { value: 0, reload: false },
    setStato: () => {},
    anno: { value: "", reload: false },
    setAnno: () => {},
    utente: { value: "", reload: false },
    setUtente: () => {},
    localita: { value: null, reload: false },
    setLocalita: () => {},
    tag: { value: null, reload: false },
    setTag: () => {},
    previewFilters: { value: null, reload: false },
    setPreviewFilters: () => {},
    ricercaLibera: { value: null, reload: false },
    setRicercaLibera: () => {},
    getIcon: ({ property, order, key }: SchemaSortBy) => faSort,
    setSortRow: () => {},
    sortRow: { value: { property: null, order: "DESC", key: 1 }, reload: true },
};

export const initTaskListContext: TaskListContextProps = {
    taskList: [],
    setTaskList: () => {},
    currentPage: { value: 1, reload: true },
    setCurrentPage: () => {},
    totalPages: null,
    setTotalPages: () => {},
    loading: false,
    setLoading: () => {},
    dimensionePagina: 13,
    setDimensionePagina: () => {},
    orderUserOptions: [],
    orderStatusOptions: [],
    sortBy: { value: Ordinamento.UltimoAccesso, reload: false },
    setSortBy: () => {},
    filters: { value: "", reload: false },
    setFilters: () => {},
    tipologia: { value: 0, reload: false },
    setTipologia: () => {},
    utente: { value: "", reload: false },
    setUtente: () => {},
    previewFilters: { value: null, reload: false },
    setPreviewFilters: () => {},
    ricercaLibera: { value: null, reload: false },
    setRicercaLibera: () => {},
    getIcon: ({ property, order, key }: SchemaSortBy) => faSort,
    setSortRow: () => {},
    sortRow: { value: { property: null, order: "DESC", key: 1 }, reload: true },
    inlineUpdate: false,
    setInlineUpdate: () => {},
    salvataggioMultiplo: [],
    setSalvataggioMultiplo: () => {},
    handleUpdateSalvataggioMultiplo: () => {},
    groupByCommessa: false,
    setGroupByCommessa: () => {},
    groupByCommessaList: {},
    parzialiOre: [],
    openModal: { open: false, id: null },
    handleSetOpenModal: (modal: ModalParzialeOreState) => {},
    handleReloadParzialiOre: () => {},
};

export interface RiepilogoBase {
    ordinamento: Ordinamento;
    ordinamentoCrescente: boolean;
    pagina: number;
    dimensionePagina: number;
}

export interface OnPagination {
    inlineResults?: RiepilogoCommessa[] | null;
    inlinePagina?: number | null;
    inlineOrdinamentoCrescente?: boolean | null;
    inlineOrdinamento?: number | null;
    inlineAnno?: number | null;
    inlineUtente?: number | null;
    inlineStato?: number | null;
    inlineTipologia?: number | null;
    inlineLocalita?: string[] | null;
    inlineRicerca?: {
        settore: RicercaLibera;
        campoLibero: string | null;
    } | null;
    inlineTags?: number | null;
    inlineIdIncaricato?: number | null;
    inlineCapoCommessa?: boolean | null;
    inlineIdCommesse?: number[] | null;
}

export interface RiepilogoCommesse extends RiepilogoBase {
    idStatoEsecuzione?: number | null;
    idTipoCommessa?: number | null;
    localita?: string[] | null;
    idPersoneTeam?: number[] | null;
    annoRicerca: number | null;
    ricerca?: {
        settore: RicercaLibera;
        campoLibero: string | null;
    } | null;
}

export interface RiepilogoAttivitaData extends RiepilogoBase {
    idStatoEsecuzione?: number | null;
    idTipoScadenza?: number | null;
    idIncaricato?: number | null;
    idCommesse?: number[] | null;
    capoCommessa: boolean | null;
    ricerca?: {
        settore: RicercaLibera;
        campoLibero: string | null;
    } | null;
}

export interface TParamsApiRiepilogoCommesse {
    idStudio: number;
    serchParams: Partial<RiepilogoCommesse>;
}

export interface TParamsApiRiepilogoAttivita {
    idStudio: number;
    serchParams: Partial<RiepilogoAttivitaData>;
}

export interface Daticatastali {
    SIGLA_PROV?: string;
    COD_COMUNE?: string;
    DENOM?: string;
    SEZIONE?: string;
    FOGLIO?: string;
    ALLEGATO?: string;
    SVILUPPO?: string;
    NUM_PART?: string;
    TIPOLOGIA?: string;
}
