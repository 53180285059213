import { TActionNoteCurrent } from "@magoot-sdk/redux-modules/actions/note/current/declaration";

import { TNoteType } from "../../../api/note/declarations";
import { TActionNoteAddSuccess } from "../../actions/note/noteAdd/declaration";
import { TActionNoteDeleteSuccess } from "../../actions/note/noteDelete/declaration";
import { TActionNoteGetSuccess } from "../../actions/note/noteGet/declaration";
import { Actions } from "../../utils/Actions";
import { TReducerNote, TReducerNoteActions } from "./declaration";

const initialState: TReducerNote = {
    studio: {},
    order: {},
    work: {},
    task: {},
    current: undefined,
};

export const reducerNote = (
    prevStore: TReducerNote = initialState,
    action: TReducerNoteActions
): TReducerNote => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.noteAddSuccess:
            const addAction = action as TActionNoteAddSuccess;
            const { result, tipo } = addAction.payload;
            switch (tipo) {
                case TNoteType.Studio:
                    const { idStudio } = result;
                    if (!newStore.studio.hasOwnProperty(result.idStudio)) {
                        newStore.studio[result.idStudio] = [];
                    }
                    const studioStore = [...newStore.studio[idStudio]];
                    studioStore.unshift(result);
                    newStore.studio[idStudio] = studioStore;
                    break;
                case TNoteType.Commessa:
                    const idCommessa = result.idCommessa ?? 0;
                    if (!newStore.order.hasOwnProperty(idCommessa)) {
                        newStore.order[idCommessa] = [];
                    }
                    const orderStore = [...newStore.order[idCommessa]];
                    orderStore.unshift(result);
                    newStore.order[idCommessa] = orderStore;
                    break;
                case TNoteType.Lavorazione:
                    const idLavorazione = result.idLavorazione ?? 0;
                    if (!newStore.work.hasOwnProperty(idLavorazione)) {
                        newStore.work[idLavorazione] = [];
                    }
                    const workStore = [...newStore.work[idLavorazione]];
                    workStore.unshift(result);
                    newStore.work[idLavorazione] = workStore;
                    break;
                case TNoteType.Attivita:
                    if (!newStore.task.hasOwnProperty(result.idRiferimento)) {
                        newStore.task[result.idRiferimento] = [];
                    }
                    const taskStore = [...newStore.task[result.idRiferimento]];
                    taskStore.unshift(result);
                    newStore.task[result.idRiferimento] = taskStore;
                    break;
                default:
                    break;
            }
            return newStore;
        case Actions.noteGetSuccess:
            const getAction = action as TActionNoteGetSuccess;
            const getPayload = getAction.payload;
            const getResult = getPayload.result;
            getResult.sort((a, b) => b.id - a.id);
            switch (getPayload.tipo) {
                case TNoteType.Studio:
                    if (!newStore.studio.hasOwnProperty(getPayload.idStudio)) {
                        newStore.studio[getPayload.idStudio] = [];
                    }
                    newStore.studio[getPayload.idStudio] = getResult;
                    break;
                case TNoteType.Commessa:
                    if (!newStore.order.hasOwnProperty(getPayload.idOrder ?? 0)) {
                        newStore.order[getPayload.idOrder ?? 0] = [];
                    }
                    newStore.order[getPayload.idOrder ?? 0] = getResult;
                    break;
                case TNoteType.Lavorazione:
                    if (!newStore.work.hasOwnProperty(getPayload.idWork ?? 0)) {
                        newStore.work[getPayload.idWork ?? 0] = [];
                    }
                    newStore.work[getPayload.idWork ?? 0] = getResult;
                    break;
                case TNoteType.Attivita:
                    if (!newStore.task.hasOwnProperty(getPayload.idTask ?? 0)) {
                        newStore.task[getPayload.idTask ?? 0] = [];
                    }
                    newStore.task[getPayload.idTask ?? 0] = getResult;
                    break;
                default:
                    break;
            }

            return newStore;
        case Actions.noteDeleteSuccess:
            const deleteAction = action as TActionNoteDeleteSuccess;
            const deletePayload = deleteAction.payload;
            switch (deletePayload.tipo) {
                case TNoteType.Studio:
                    const studioNotes = [...newStore.studio[deletePayload.objectId]];
                    studioNotes.splice(
                        studioNotes.findIndex((note) => note.id === deletePayload.id),
                        1
                    );
                    newStore.studio[deletePayload.objectId] = studioNotes;
                    break;
                case TNoteType.Commessa:
                    const orderNotes = [...newStore.order[deletePayload.objectId]];
                    orderNotes.splice(
                        orderNotes.findIndex((note) => note.id === deletePayload.id),
                        1
                    );
                    newStore.order[deletePayload.objectId] = orderNotes;
                    break;
                case TNoteType.Lavorazione:
                    const workNotes = [...newStore.work[deletePayload.objectId]];
                    workNotes.splice(
                        workNotes.findIndex((note) => note.id === deletePayload.id),
                        1
                    );
                    newStore.work[deletePayload.objectId] = workNotes;
                    break;
                case TNoteType.Attivita:
                    const taskNotes = [...newStore.task[deletePayload.objectId]];
                    taskNotes.splice(
                        taskNotes.findIndex((note) => note.id === deletePayload.id),
                        1
                    );
                    newStore.task[deletePayload.objectId] = taskNotes;
                    break;
                default:
                    break;
            }
            return newStore;
        case Actions.createLogoutSuccess:
            return {
                studio: {},
                order: {},
                work: {},
                task: {},
                current: undefined,
            };
        case Actions.noteCurrent:
            const currentAction = action as TActionNoteCurrent;
            const { isOpen, tipo: noteTipo, idTask, idWork, idOrder } = currentAction.payload;

            newStore.current = {
                isOpen,
                tipo: noteTipo,
                idOrder,
                idWork,
                idTask,
            };
            return newStore;
        default:
            return newStore;
    }
};
