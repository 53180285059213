interface Translations {
    "button.change-password": string;
    "form.username": string;
    "form.username.tooltip": string;
    "form.email.tooltip": string;
    "form.change-password.password": string;
    "form.change-password.new-password": string;
    "form.change-password.repeat-new-password": string;
}

interface FormChangePassword {
    it: Translations;
    en: Translations;
}

export const translationsFormChangePassword: FormChangePassword = {
    it: {
        "button.change-password": "Cambia password",
        "form.username": "Email di login",
        "form.username.tooltip": `L'email di login serve unicamente per accedere alla piattaforma (attualmente non modificabile)`,
        "form.email.tooltip": `L'email di ricezione verrà usata per inviare notifiche, fatture e comunicazioni`,
        "form.change-password.password": "Password attuale",
        "form.change-password.new-password": "Nuova password",
        "form.change-password.repeat-new-password": "Ripeti nuova password",
    },
    en: {
        "button.change-password": "Change password",
        "form.username": "Email di login",
        "form.username.tooltip": `L'email di login serve unicamente per accedere alla piattaforma (attualmente non modificabile)`,
        "form.email.tooltip": `L'email di ricezione verrà usata per inviare notifiche, fatture e comunicazioni`,
        "form.change-password.password": "Old password",
        "form.change-password.new-password": "New password",
        "form.change-password.repeat-new-password": "Repeat new password",
    },
};

export type TTranslationsFormChangePassword = keyof Translations;
