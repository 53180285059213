import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryCostCenterCreate/response";
import { TParamsApiDictionaryDocumentTypeCreate } from "../../../../api/dictionary/dictionaryDocumentTypeCreate/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryDocumentTypeCreateFailure,
    TActionDictionaryDocumentTypeCreateRequest,
    TActionDictionaryDocumentTypeCreateSuccess,
} from "./declarations";

export const actionDictionaryDocumentTypeCreateRequest = (
    payload: TParamsApiDictionaryDocumentTypeCreate
): TActionDictionaryDocumentTypeCreateRequest => ({
    type: Actions.dictionaryDocumentTypeCreateRequest,
    payload,
});

export const actionDictionaryDocumentTypeCreateSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryDocumentTypeCreateSuccess => ({
    type: Actions.dictionaryDocumentTypeCreateSuccess,
    payload,
});

export const actionDictionaryDocumentTypeCreateFailure = (
    payload: TError
): TActionDictionaryDocumentTypeCreateFailure => ({
    type: Actions.dictionaryDocumentTypeCreateFailure,
    payload,
});
