interface Translations {
    "offlinebar.no-connection": string;
}

interface OfflineBar {
    it: Translations;
    en: Translations;
}

export const translationsOfflinebar: OfflineBar = {
    it: {
        "offlinebar.no-connection":
            "Non sei connesso a internet - controlla il cavo di rete, il modem e il router oppure riconnettiti al Wi-Fi",
    },
    en: {
        "offlinebar.no-connection":
            "No Internet - checking the network cables, modem and router or reconnecting to Wi-Fi",
    },
};

export type TTranslationsOfflineBar = keyof Translations;
