// #region ::: IMPORT
import { AnimatePresence } from "framer-motion";
import React, { FC, MutableRefObject, memo } from "react";

import { handleDrawerNavigation } from "@magoot-sdk/utils/components/drawer";

import { OrderFields } from "./components/OrderFields";
import { WorkFields } from "./components/WorkFields";

// #endregion

export interface Props {
    idStudio: number;
    idOrder: number;
    idCustomer?: number;
    idIdea?: number;
    isWork?: boolean;
    locationPathname: string;
    isMobile?: boolean;
    goWork?: () => void;
    goOrder?: () => void;
    subMenuState?: "order" | "work";
    drawerRef?: MutableRefObject<HTMLInputElement | undefined>;
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Toolbar: FC<Props> = memo(
    ({
        idStudio,
        idOrder,
        idCustomer,
        idIdea,
        isWork,
        locationPathname,
        isMobile = false,
        goWork,
        goOrder,
        subMenuState,
        drawerRef,
        expanded,
        setExpanded,
    }): JSX.Element => {
        const widthSidenav = () => {
            if (isWork && !expanded) return "xl:w-[9rem]";
            if (isWork && expanded) return "xl:w-[26rem]";

            if (!isWork && expanded) return "xl:w-[6rem]";
            if (!isWork && !expanded) return "xl:w-[9rem]";

            return "w-full";
        };

        return (
            <AnimatePresence>
                <div
                    className={`drawer-side hidden w-full shadow-xl xl:block translate-all 
                    ${isMobile ? "absolute top-[4rem] h-container" : "fixed h-container"}
                    ${widthSidenav()}
                `}
                >
                    <label
                        htmlFor='my-drawer-4'
                        className='drawer-overlay z-20 h-full'
                        onClick={() => handleDrawerNavigation({ drawerRef })}
                    />

                    {subMenuState === "order" && isMobile && (
                        <OrderFields
                            idStudio={idStudio}
                            idOrder={idOrder}
                            idCustomer={idCustomer}
                            locationPathname={locationPathname}
                            idIdea={idIdea}
                            drawerRef={drawerRef}
                            goWork={goWork}
                        />
                    )}

                    {subMenuState === "work" && isMobile && (
                        <WorkFields
                            idStudio={idStudio}
                            idOrder={idOrder}
                            goOrder={goOrder}
                            isMobile={isMobile}
                            drawerRef={drawerRef}
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    )}

                    {(subMenuState === "order" || subMenuState === "work") && !isMobile && (
                        <div className='flex h-full relative'>
                            <OrderFields
                                idStudio={idStudio}
                                idOrder={idOrder}
                                idCustomer={idCustomer}
                                locationPathname={locationPathname}
                                idIdea={idIdea}
                                drawerRef={drawerRef}
                                goWork={goWork}
                            />

                            {isWork && (
                                <WorkFields
                                    idStudio={idStudio}
                                    idOrder={idOrder}
                                    goOrder={goOrder}
                                    drawerRef={drawerRef}
                                    isMobile={isMobile}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                />
                            )}
                        </div>
                    )}
                </div>
            </AnimatePresence>
        );
    }
);

Toolbar.displayName = "Toolbar";
