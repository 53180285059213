interface Translations {
    "form.reset-password.title": string;
    "form.reset-password.description": string;
    "form.reset-password.input.password": string;
    "form.reset-password.input.repeat-password": string;
    "form.reset-password.button": string;
}

interface FormResetPassword {
    it: Translations;
    en: Translations;
}

export const translationsFormResetPassword: FormResetPassword = {
    it: {
        "form.reset-password.title": "Configura nuova password",
        "form.reset-password.description": "Crea una nuova password e accedi nuovamente a Magoot",
        "form.reset-password.input.password": "Nuova password",
        "form.reset-password.input.repeat-password": "Ripeti password",
        "form.reset-password.button": "Invia",
    },
    en: {
        "form.reset-password.title": "Set new Password",
        "form.reset-password.description": "Create new password and sign in again",
        "form.reset-password.input.password": "New password",
        "form.reset-password.input.repeat-password": "Repeat password",
        "form.reset-password.button": "Send",
    },
};

export type TTranslationsFormResetPassword = keyof Translations;
