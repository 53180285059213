interface Translations {
    "view.reset-password-success.title": string;
    "view.reset-password-success.description": string;
}

interface ViewResetPasswordSuccess {
    it: Translations;
    en: Translations;
}

export const translationsViewResetPasswordSuccess: ViewResetPasswordSuccess = {
    it: {
        "view.reset-password-success.title": "Password cambiata con successo!",
        "view.reset-password-success.description": "Torna alla home e accedi nuovamente",
    },
    en: {
        "view.reset-password-success.title": "Password changed successfully",
        "view.reset-password-success.description": "Go Home and sign in again",
    },
};

export type TTranslationsViewResetPasswordSuccess = keyof Translations;
