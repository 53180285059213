interface Translations {
    "file.valid-image": string;
    "file.valid-image-pdf": string;
    "file.valid-image-video": string;
    "file.valid-image-pdf-audio": string;
    "file.valid-zip": string;
    "new-files.update": string;
    "files.update": string;
    "file.delete": string;
}

interface File {
    it: Translations;
    en: Translations;
}

export const translationsFile: File = {
    it: {
        "file.valid-image": "Il file deve essere un'immagine valida",
        "file.valid-image-pdf": "Il file deve essere un'immagine o un pdf valido",
        "file.valid-image-video": "Il file deve essere un'immagine o un video valido",
        "file.valid-image-pdf-audio": "Il file deve essere un'immagine, un pdf o un audio valido",
        "file.valid-zip": "Il file deve essere uno zip valido",
        "new-files.update": "Elenco nuovi file caricati",
        "files.update": "Elenco file caricati",
        "file.delete": "File eliminato con successo",
    },
    en: {
        "file.valid-image": "The file must be a valid image",
        "file.valid-image-pdf": "Il file deve essere un'immagine o un pdf valido",
        "file.valid-image-video": "Il file deve essere un'immagine o un video valido",
        "file.valid-image-pdf-audio": "Il file deve essere un'immagine, un pdf o un audio valido",
        "file.valid-zip": "Il file deve essere uno zip valido",
        "new-files.update": "Elenco nuovi file caricati",
        "files.update": "Elenco file caricati",
        "file.delete": "File eliminato con successo",
    },
};

export type TTranslationsFile = keyof Translations;
