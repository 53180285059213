/* eslint-disable jsx-a11y/anchor-is-valid */
// #region ::: IMPORT
import React, { FC, memo } from "react";

import { Tag } from "@magoot-sdk/api/tag/response";
import { TDictionary } from "@magoot-sdk/redux-modules/reducers/reducerDictionaries/declarations";

// #endregion

interface Props {
    option: TDictionary | Tag;
    onAutoComplete: (city: any) => void;
    setFocus: (focus: number) => void;
}

export const WorkItem: FC<Props> = memo(
    ({ option, onAutoComplete, setFocus }: Props): JSX.Element => {
        const onSelectOption = (): void => {
            onAutoComplete(option);
            setFocus(0);
        };

        return (
            <li
                className='p-12 hover:bg-tundora-100 cursor-pointer rounded'
                onMouseDown={onSelectOption}
            >
                <a className='p-0 m-0 hover:bg-transparent'>{option.descr}</a>
            </li>
        );
    }
);
WorkItem.displayName = "WorkItem";
