import { TParamsApiDictionaryOrderEdit } from "../../../../api/dictionary/dictionaryOrderEdit/params";
import { TResponseApiDictionary } from "../../../../api/dictionary/dictionarySendingEdit/response";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionarySendingEditFailure,
    TActionDictionarySendingEditRequest,
    TActionDictionarySendingEditSuccess,
} from "./declarations";

export const actionDictionarySendingEditRequest = (
    payload: TParamsApiDictionaryOrderEdit
): TActionDictionarySendingEditRequest => ({
    type: Actions.dictionarySendingEditRequest,
    payload,
});

export const actionDictionarySendingEditSuccess = (
    payload: TResponseApiDictionary
): TActionDictionarySendingEditSuccess => ({
    type: Actions.dictionarySendingEditSuccess,
    payload,
});

export const actionDictionarySendingEditFailure = (
    payload: TError
): TActionDictionarySendingEditFailure => ({
    type: Actions.dictionarySendingEditFailure,
    payload,
});
