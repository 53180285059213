interface Translations {
    "view.inspection-create.title-create": string;
    "view.inspection-create.title-edit": string;
    "view.inspection-create.import-image": string;
    "view.inspection.modal.pdf": string;
    "view.inspection.modal.locked": string;
    "view.inspection.modal.signature": string;
    "view.inspection.modal.locked.and.pdf": string;
    "view.inspection.modal.pdf.before.signature": string;
    "view.inspection.pdf.success": string;
    "view.inspection.pdf.failure": string;
    "view.inspection.pdf.tooltip": string;
}

interface ViewInspection {
    it: Translations;
    en: Translations;
}

export const translationsViewInspectionCreate: ViewInspection = {
    it: {
        "view.inspection-create.title-create": "Nuovo sopralluogo",
        "view.inspection-create.title-edit": "Modifica Sopralluogo",
        "view.inspection-create.import-image": "Importa immagini",
        "view.inspection.modal.pdf":
            "Attenzione! il PDF del sopralluogo è già stato generato, procedendo con la modifica del sopralluogo il PDF verrà rimosso",
        "view.inspection.modal.locked":
            "Attenzione! Sono già state raccolte alcune firme, pertanto non è possibile modificare i dati del sopralluogo. Se si procede verranno rimosse le firme raccolte",
        "view.inspection.modal.signature": "Sei sicuro di voler eliminare la firma di",
        "view.inspection.modal.locked.and.pdf":
            "Attenzione! Sono già state raccolte alcune firme e il PDF del sopralluogo è già stato generato, pertanto non è possibile modificare i dati del sopralluogo. Se si procede verranno rimosse le firme raccolte e il PDF",
        "view.inspection.modal.pdf.before.signature":
            "Attenzione! il PDF del sopralluogo è già stato generato, procedendo con il salvataggio della firma il PDF verrà rimosso",
        "view.inspection.pdf.success": "PDF generato correttamente",
        "view.inspection.pdf.failure": "Errore nella generazione del PDF",
        "view.inspection.pdf.tooltip":
            "Clicca sul bottone modifica e compila il campo mancante 'in qualità di' per abilitare la generazione del PDF",
    },
    en: {
        "view.inspection-create.title-create": "New inspection",
        "view.inspection-create.title-edit": "Edit inspection",
        "view.inspection-create.import-image": "Import images",
        "view.inspection.modal.pdf":
            "Attenzione! il PDF del sopralluogo è già stato generato, procedendo con la modifica del sopralluogo il PDF verrà rimosso",
        "view.inspection.modal.locked":
            "Attenzione! Sono già state raccolte alcune firme, pertanto non è possibile modificare i dati del sopralluogo. Se si procede verranno rimosse le firme raccolte",
        "view.inspection.modal.signature": "Sei sicuro di voler eliminare la firma di",
        "view.inspection.modal.locked.and.pdf":
            "Attenzione! Sono già state raccolte alcune firme, pertanto non è possibile modificare i dati del sopralluogo. Se si procede verranno rimosse le firme raccolte",
        "view.inspection.modal.pdf.before.signature":
            "Attenzione! il PDF del sopralluogo è già stato generato, procedendo con il salvataggio della firma il PDF verrà rimosso",
        "view.inspection.pdf.success": "PDF generato correttamente",
        "view.inspection.pdf.failure": "Errore nella generazione del PDF",
        "view.inspection.pdf.tooltip":
            "Clicca sul bottone modifica e compila il campo mancante 'in qualità di' per abilitare la generazione del PDF",
    },
};

export type TTranslationsViewInspectionCreate = keyof Translations;
