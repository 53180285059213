import { createSelector } from "reselect";

import { TStore } from "../reducers/declarations";
import { TInspection } from "../reducers/reducerInspections/declarations";

export const selectorInspections = (store: TStore) => store.inspections;

export const selectorInspectionCounter = ({
    store,
    idOrder,
    idWork,
}: {
    store: TStore;
    idWork: number;
    idOrder: number;
}): number => {
    if (!store.inspections) return 0;
    const inspectionsOrder = store.inspections.list[idOrder];
    if (!inspectionsOrder) return 0;
    const inspectionList = Object.values(inspectionsOrder);
    const inspectionsWork = inspectionList.filter(
        (inspection) => inspection.idLavorazione === idWork
    );
    return inspectionsWork.length;
};

export const selectorInspectionList = ({
    store,
    idOrder,
    idWork,
}: {
    store: TStore;
    idOrder: number;
    idWork: number;
}): TInspection[] => {
    if (!store.inspections) return [];
    if (!store.inspections.list) return [];
    if (!store.inspections.list[idOrder]) return [];

    const inspectionList = Object.values(store.inspections.list[idOrder]);
    const filteredInspectionList = inspectionList.filter(
        (inspection) => inspection.idLavorazione === idWork
    );

    return filteredInspectionList && filteredInspectionList.length > 0
        ? filteredInspectionList.sort((a, b) => {
              const dateA = new Date(a.data).getTime();
              const dateB = new Date(b.data).getTime();
              return dateA < dateB ? 1 : -1;
          })
        : [];
};

export const selectorInspectionHasFirmeComplete = ({ store }: { store: TStore }): boolean => {
    if (!store.inspections) return false;
    if (!store.inspections.current) return false;
    const inspection = store.inspections.current;
    const { partecipanti } = inspection;
    const hasFirmeComplete =
        partecipanti.filter((partecipante) => !partecipante.dataFirma).length === 0;
    return hasFirmeComplete;
};

export const selectorInspectionLoadingCurrent = ({ store }: { store: TStore }): boolean => {
    if (!store.inspections) return false;
    return store.inspections.loading;
};

export const selectorInspectionIsLocked = ({ store }: { store: TStore }): boolean => {
    if (!store.inspections) return false;
    if (!store.inspections.current) return false;
    const inspection = store.inspections.current;
    const { partecipanti } = inspection;
    const hasSomeSignature = partecipanti.some((partecipante) => partecipante.dataFirma);
    return hasSomeSignature || inspection.bloccato;
};

export const selectorCurrentInspection = createSelector(
    selectorInspections,
    (inspections) => inspections.current
);
export const selectorCurrentInspectionFiles = createSelector(
    selectorCurrentInspection,
    (inspection) => inspection?.files ?? []
);
export const selectorCurrentInspectionRemarks = createSelector(
    selectorCurrentInspection,
    (inspection) => inspection?.osservazioni ?? []
);

export const selectorInspectionDeleteLoading = ({ store }: { store: TStore }): boolean => {
    if (!store.inspections) return false;
    return store.inspections.loadingDelete ?? false;
};
