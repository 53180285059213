import { TOrder } from "../../../reducers/reducerOrder/declarations";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionOrderDeleteFailure,
    TActionOrderDeleteRequest,
    TActionOrderDeleteSuccess,
} from "./declarations";

interface Payload {
    idStudio: number;
    order: TOrder;
}

export const actionOrderDeleteRequest = (payload: Payload): TActionOrderDeleteRequest => ({
    type: Actions.deleteOrderRequest,
    payload,
});

export const actionOrderDeleteSuccess = (payload: any): TActionOrderDeleteSuccess => ({
    type: Actions.deleteOrderSuccess,
    payload,
});

interface PayloadError extends TError {
    payload: TOrder;
}

export const actionOrderDeleteFailure = (payload: PayloadError): TActionOrderDeleteFailure => ({
    type: Actions.deleteOrderFailure,
    payload,
});
