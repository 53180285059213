import { createSelector } from "reselect";

import { TFile } from "../../declarations/general/file";
import { TStore } from "../reducers/declarations";
import { TDocumentWork } from "../reducers/reducerDocumentsWork/declarations";

export const selectorDocumentsWorkCounter = ({
    store,
    idWork,
}: {
    store: TStore;
    idWork: number;
}): number => {
    if (!store.documentsWork) return 0;
    const documents = store.documentsWork[idWork];
    if (!documents) return 0;
    const documentList = Object.values(documents);
    const documentsWork = documentList.filter((document) => document.idLavorazione === idWork);
    return documentsWork.length;
};

export const selectorDocumentsWorkList = ({
    store,
    idWork,
}: {
    store: TStore;
    idWork: number;
}): TDocumentWork[] => {
    if (!store.documentsWork) return [];
    const documents = store.documentsWork[idWork];
    if (!documents) return [];
    const documentList = Object.values(documents);
    const documentsWork = documentList.filter((document) => document.idLavorazione === idWork);
    return documentsWork;
};

export const selectorDocumentList = ({
    store,
    idOrder,
    idCustomer,
}: {
    store: TStore;
    idOrder: number;
    idCustomer: number;
}): TFile[] | undefined => {
    if (!store.documents) return undefined;
    if (!store.documents[idOrder]) return undefined;
    if (!store.documents[idOrder][idCustomer]) return undefined;
    const documentList = Object.values(store.documents[idOrder][idCustomer]);
    return documentList.length > 0 ? documentList : undefined;
};

export const selectorDocumentListNoRerender = createSelector(
    [selectorDocumentList],
    (documentList) => {
        return documentList;
    }
);
