import { TParamsApiUserList } from "../../../../api/user/userList/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionUserListFailure,
    TActionUserListRequest,
    TActionUserListSuccess,
    TActionUserListUpdate,
} from "./declarations";

export const actionUserListRequest = (payload: TParamsApiUserList): TActionUserListRequest => ({
    type: Actions.fetchUserListRequest,
    payload,
});

export const actionUserListSuccess = (payload: any): TActionUserListSuccess => ({
    type: Actions.fetchUserListSuccess,
    payload,
});

export const actionUserListFailure = (payload: TError): TActionUserListFailure => ({
    type: Actions.fetchUserListFailure,
    payload,
});

export const actionUserListUpdate = (payload: any): TActionUserListUpdate => ({
    type: Actions.fetchuserListUpdate,
    payload,
});
