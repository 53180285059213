import { UtentiEDiritti } from "@magoot-sdk/api/diritti/response";

import { TStore } from "../reducers/declarations";

export const selectorUtentiDiritti = (store: TStore) => store.diritti.value;

export const selectorUtenteDiritti = ({
    store,
    idUtente,
}: {
    store: TStore;
    idUtente: number | undefined;
}): UtentiEDiritti | undefined => {
    const utente = store?.diritti?.value?.find((u) => u?.utente?.id === idUtente);
    return utente;
};

export const selectorUtenteDirittiLoading = ({ store }: { store: TStore }): boolean => {
    return store?.diritti?.loading;
};
