import { TStore } from "../reducers/declarations";

export const selectorProtocolCounter = ({
    store,
    idWork,
}: {
    store: TStore;
    idWork: number;
}): number => {
    if (!store.protocols) return 0;
    const protocols = store.protocols[idWork];
    if (!protocols) return 0;
    const protocolList = Object.values(protocols);
    const protocolsWork = protocolList.filter((protocol) => protocol.idLavorazione === idWork);
    return protocolsWork.length;
};
