import { Actions } from "../../utils/Actions";
import { TStore } from "../declarations";
import { TReducerCustomersActions } from "./declarations";

export const reducerCustomers = (
    prevStore: TStore["customers"] = [],
    action: TReducerCustomersActions
): TStore["customers"] => {
    switch (action.type) {
        case Actions.customerListSuccess:
            return action.payload;
        case Actions.customerCreateSuccess:
            return [...prevStore, action.payload];
        case Actions.createLogoutSuccess:
            return [];
        default:
            return prevStore;
    }
};
