import { TParamsApiOrderUpdate } from "../../../../api/order/orderUpdate/params";
import { TOrder } from "../../../reducers/reducerOrder/declarations";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionOrderUpdateFailure,
    TActionOrderUpdateRequest,
    TActionOrderUpdateSuccess,
} from "./declarations";

export const actionOrderUpdateRequest = (
    payload: TParamsApiOrderUpdate
): TActionOrderUpdateRequest => ({
    type: Actions.orderUpdateRequest,
    payload,
});

export const actionOrderUpdateSuccess = (payload: TOrder): TActionOrderUpdateSuccess => ({
    type: Actions.orderUpdateSuccess,
    payload,
});

export const actionOrderUpdateFailure = (payload: TError): TActionOrderUpdateFailure => ({
    type: Actions.orderUpdateFailure,
    payload,
});
