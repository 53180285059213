interface Translations {
    "view.payments.quotation": string;
    "view.payments.earn": string;
    "view.payments.cost": string;
    "view.payments.team.cost": string;
    "view.payments.button.add-voice": string;
    "view.payments.button.add-earn": string;
    "view.payments.button.add-cost": string;
    "view.payments.user": string;
    "view.payments.total-import": string;
    "view.payments.modal.title-create-prevision": string;
    "view.payments.modal.title-update-prevision": string;
    "view.payments.modal.title-create-earn": string;
    "view.payments.modal.description-create-earn": string;
    "view.payments.modal.earn-create-earn": string;
    "view.payments.modal.title-update-earn": string;
    "view.payments.modal.title-create-cost": string;
    "view.payments.modal.owner-create-cost": string;
    "view.payments.modal.title-update-cost": string;
    "view.payments.form.total-import": string;
    "view.payments.import-earn": string;
    "view.payments.import-cost": string;
    "view.payments.quote": string;
    "view.payments.estimate": string;
    "view.payments.earn-date": string;
    "view.payments.earn-total": string;
    "view.payments.earn-net": string;
    "view.payments.date": string;
    "view.payments.cost-center": string;
    "view.payments.cost-total": string;
    "view.payments.min-higher-than-max": string;
    "view.payments.import-too-high": string;
    "view.payments.cost-higher-than-earn": string;
    "view.payments.tab-cost.input-placeholder": string;
    "view.payments.tab-earn.input-placeholder": string;
    "view.payments.tab-prevision.input-placeholder": string;
    "view.payments.prevision-date": string;
}

interface ViewPayments {
    it: Translations;
    en: Translations;
}

export const translationsViewPayments: ViewPayments = {
    it: {
        "view.payments.quotation": "Preventivo",
        "view.payments.earn": "Incasso",
        "view.payments.cost": "Spese",
        "view.payments.team.cost": "Personale",
        "view.payments.button.add-voice": "Aggiungi voce",
        "view.payments.button.add-earn": "Aggiungi incasso",
        "view.payments.button.add-cost": "Aggiungi spesa",
        "view.payments.user": "Utente",
        "view.payments.total-import": "Importo",
        "view.payments.import-earn": "Incassato",
        "view.payments.import-cost": "Importo speso",
        "view.payments.modal.title-create-prevision": "Voce preventivo",
        "view.payments.modal.title-update-prevision": "Modifica preventivo",
        "view.payments.modal.title-create-earn": "Aggiungi incasso",
        "view.payments.modal.description-create-earn": "Voce incasso",
        "view.payments.modal.earn-create-earn": "Importo euro",
        "view.payments.modal.title-update-earn": "Modifica incasso",
        "view.payments.modal.title-create-cost": "Modifica spesa",
        "view.payments.modal.owner-create-cost": "Spesa sostenuta da",
        "view.payments.modal.title-update-cost": "Aggiungi spesa",
        "view.payments.form.total-import": "Importo",
        "view.payments.quote": "Preventivo",
        "view.payments.estimate": "Preventivato",
        "view.payments.earn-date": "Data incasso",
        "view.payments.earn-total": "Totale incassato",
        "view.payments.earn-net": "Incasso al netto delle spese",
        "view.payments.date": "Data spesa",
        "view.payments.cost-center": "Centro di costo",
        "view.payments.cost-total": "Totale spese",
        "view.payments.min-higher-than-max":
            "L'importo massimo deve essere maggiore dell'importo minimo",
        "view.payments.import-too-high": "L'importo deve essere inferiore a 1.000.000.000",
        "view.payments.cost-higher-than-earn":
            "Attenzione l'importo delle tue spese è superiore all'importo degli incassi",
        "view.payments.tab-cost.input-placeholder": "Ricerca per descrizione spesa",
        "view.payments.tab-earn.input-placeholder": "Ricerca per descrizione importo",
        "view.payments.tab-prevision.input-placeholder": "Ricerca per descrizione preventivo",
        "view.payments.prevision-date": "Data preventivo",
    },
    en: {
        "view.payments.quotation": "Preventivo",
        "view.payments.earn": "Takings",
        "view.payments.cost": "Costs",
        "view.payments.team.cost": "Personale",
        "view.payments.button.add-voice": "Add voice",
        "view.payments.button.add-earn": "Add takings",
        "view.payments.button.add-cost": "Add cost",
        "view.payments.user": "User",
        "view.payments.total-import": "Import",
        "view.payments.import-earn": "Import earn",
        "view.payments.import-cost": "Import cost",
        "view.payments.modal.title-create-prevision": "Create prevision",
        "view.payments.modal.title-update-prevision": "Update prevision",
        "view.payments.modal.title-create-earn": "Create takings",
        "view.payments.modal.description-create-earn": "Voce incasso",
        "view.payments.modal.earn-create-earn": "Importo euro",
        "view.payments.modal.title-update-earn": "Update takings",
        "view.payments.modal.title-create-cost": "Create cost",
        "view.payments.modal.owner-create-cost": "Spesa sostenuta da",
        "view.payments.modal.title-update-cost": "Update cost",
        "view.payments.form.total-import": "Import",
        "view.payments.quote": "quote",
        "view.payments.estimate": "Preventivato",
        "view.payments.earn-date": "Takings date",
        "view.payments.earn-total": "Total takings",
        "view.payments.earn-net": "Takings net of costs",
        "view.payments.date": "Date",
        "view.payments.cost-center": "Cost center",
        "view.payments.cost-total": "Total costs",
        "view.payments.min-higher-than-max":
            "The maximum amount must be greater than the minimum amount",
        "view.payments.import-too-high": "Import must be less than 1.000.000.000",
        "view.payments.cost-higher-than-earn":
            "Attention the amount of your costs is higher than the amount of your earnings",
        "view.payments.tab-cost.input-placeholder": "Search by expense description",
        "view.payments.tab-earn.input-placeholder": "Search by earn description",
        "view.payments.tab-prevision.input-placeholder": "Search by earn prevision description",
        "view.payments.prevision-date": "Data preventivo",
    },
};

export type TTranslationsViewPayments = keyof Translations;
