import { TTranslationsPopup } from "@magoot-sdk/i18n/translations/popup";

import { Actions, TAction } from "../../../utils/Actions";
import { TActionLogoutSuccess } from "./declarations";

export const actionLogoutRequest = ({
    title,
    message,
    notOnDelete,
}: {
    title: TTranslationsPopup;
    message: TTranslationsPopup;
    notOnDelete?: Actions;
}): TAction => ({
    type: Actions.createLogoutRequest,
    payload: {
        title,
        message,
        notOnDelete,
    },
});

export const actionLogoutSuccess = (): TActionLogoutSuccess => ({
    type: Actions.createLogoutSuccess,
});
