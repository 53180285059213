import { createSelector } from "reselect";

import { OrderStatus } from "@magoot-sdk/constants/general";

import { TStore } from "../reducers/declarations";
import { TTask } from "../reducers/reducerTask/declarations";

export const selectorTaskCounter = ({
    store,
    idOrder,
    idWork,
}: {
    store: TStore;
    idWork: number;
    idOrder: number;
}): number => {
    if (!store.tasks) return 0;
    const tasksOrder = store.tasks.value[idOrder];
    if (!tasksOrder) return 0;
    const taskList = Object.values(tasksOrder);
    const taskWork = taskList.filter((task) => task.idLavorazione === idWork);
    return taskWork.length;
};

export const selectorTaskLoading = ({ store }: { store: TStore }): boolean => {
    if (!store.tasks) return false;
    return store.tasks.loading;
};

export const selectorTaskList = ({
    store,
    idOrder,
    idWork,
}: {
    store: TStore;
    idOrder: number;
    idWork: number;
}): TTask[] | false => {
    if (!store.tasks || !store.tasks.value || !store.tasks[idOrder]) return false;
    const taskList = Object.values(store.tasks.value[idOrder]);
    const taskListFiltered =
        !!taskList && taskList.filter((task) => task?.idLavorazione === idWork);

    return taskListFiltered.length > 0 ? taskList : false;
};

export const selectorTaskFilteredNoRerender = ({
    idOrder,
    idWork,
}: {
    idWork: number;
    idOrder: number;
}) => {
    return createSelector(
        [(store) => Object.values(store?.tasks?.value[idOrder] ?? {})],
        (taskList) => {
            const filter =
                taskList && taskList?.length > 0
                    ? taskList?.filter((t: any) => t?.idLavorazione === idWork)
                    : [];
            return filter;
        }
    );
};

export const selectorTaskDoingNoRerender = ({
    idOrder,
    idWork,
}: {
    idWork: number;
    idOrder: number;
}) => {
    return createSelector(
        [(store) => Object.values(store?.tasks?.value[idOrder] ?? {})],
        (taskList) => {
            const filter =
                taskList && taskList?.length > 0
                    ? taskList?.filter((t: any) => t?.idLavorazione === idWork)
                    : [];
            return filter.filter((task: any) => task.statoEsecuzione.id === OrderStatus.InCorso);
        }
    );
};

export const selectorTaskDoneNoRerender = ({
    idOrder,
    idWork,
}: {
    idWork: number;
    idOrder: number;
}) => {
    return createSelector(
        [(store) => Object.values(store?.tasks?.value[idOrder] ?? {})],
        (taskList) => {
            const filter =
                taskList && taskList?.length > 0
                    ? taskList?.filter((t: any) => t?.idLavorazione === idWork)
                    : [];
            return filter.filter(
                (task: any) =>
                    task.statoEsecuzione.id === OrderStatus.Completata ||
                    task.statoEsecuzione.id === OrderStatus.Sospesa
            );
        }
    );
};

export const selectorTaskTodoNoRerender = ({
    idOrder,
    idWork,
}: {
    idWork: number;
    idOrder: number;
}) => {
    return createSelector(
        [(store) => Object.values(store?.tasks?.value[idOrder] ?? {})],
        (taskList) => {
            const filter =
                taskList && taskList?.length > 0
                    ? taskList?.filter((t: any) => t?.idLavorazione === idWork)
                    : [];
            return filter.filter((task: any) => task.statoEsecuzione.id === OrderStatus.DaFare);
        }
    );
};

export const selectorTaskShowCalendar = ({ store }: { store: TStore }): boolean => {
    if (!store.tasks) return false;
    return store.tasks.showCalendar;
};
