import { throttle } from "lodash";
import { useEffect, useState } from "react";

export enum ThemeBreakpoints {
    xs = 320,
    sm = 640,
    md = 768,
    lg = 1024,
    xl = 1280,
}

export const device = {
    sm: `(min-width: ${ThemeBreakpoints.sm}px)`,
    md: `(min-width: ${ThemeBreakpoints.md}px)`,
    lg: `(min-width: ${ThemeBreakpoints.lg}px)`,
};

const getDeviceConfig = (width: number): string => {
    if (width < ThemeBreakpoints.xs) {
        return "xs";
    }
    if (width >= ThemeBreakpoints.xs && width < ThemeBreakpoints.md) {
        return "sm";
    }
    if (width >= ThemeBreakpoints.md && width < ThemeBreakpoints.lg) {
        return "md";
    }
    if (width >= ThemeBreakpoints.lg && width < ThemeBreakpoints.xl) {
        return "lg";
    }

    return width.toString();
};

export const useBreakpoint = (): string => {
    const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

    useEffect(() => {
        const calcInnerWidth = throttle(() => {
            setBrkPnt(getDeviceConfig(window.innerWidth));
        }, 200);
        window.addEventListener("resize", calcInnerWidth);
        return (): void => window.removeEventListener("resize", calcInnerWidth);
    }, []);

    return brkPnt;
};
