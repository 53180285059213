import {
    TParamsApiWorkDelete,
    TParamsApiWorkDeleteSuccess,
} from "../../../../api/work/workDelete/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionWorkDeleteFailure,
    TActionWorkDeleteRequest,
    TActionWorkDeleteSuccess,
} from "./declarations";

export const actionWorkDeleteRequest = (
    payload: TParamsApiWorkDelete
): TActionWorkDeleteRequest => ({
    type: Actions.workDeleteRequest,
    payload,
});

export const actionWorkDeleteSuccess = (
    payload: TParamsApiWorkDeleteSuccess
): TActionWorkDeleteSuccess => ({
    type: Actions.workDeleteSuccess,
    payload,
});

export const actionWorkDeleteFailure = (payload: TError): TActionWorkDeleteFailure => ({
    type: Actions.workDeleteFailure,
    payload,
});
