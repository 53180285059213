import React, { FC, MutableRefObject, memo } from "react";
import { useParams } from "react-router";

import { MenuSection, SubMenuSection } from "@magoot-sdk/declarations/general/urlParams";
import { useNavigation } from "@magoot-sdk/hooks/useNavigation";
import { translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { utilityGetUrlViewDashboard } from "@magoot-sdk/utils/Routes";
import { handleDrawerNavigation } from "@magoot-sdk/utils/components/drawer";

interface Prop {
    drawerRef?: MutableRefObject<HTMLInputElement | undefined>;
}

export const SubMenuSubscription: FC<Prop> = memo(({ drawerRef }): JSX.Element => {
    const locale = useLocale();
    const { idStudio } = useStudioID();
    const navigateTo = useNavigation();

    const onSubMenuNavigation = (): void => {
        const linkSubMenu = utilityGetUrlViewDashboard({
            idStudio,
            menuSelected: MenuSection.GestioneStudio,
            subMenuSelected: SubMenuSection.Abbonamento,
        });
        navigateTo(linkSubMenu);
    };

    const { subMenuSelected }: any = useParams();
    const isSelected = subMenuSelected === SubMenuSection.Abbonamento;

    return (
        <div
            className={`cursor-pointer py-8 px-20 text-sm hover:bg-tundora-50 border-b transition-all ${
                isSelected ? "font-medium text-secondary px-36 " : ""
            }`}
            onClick={() => handleDrawerNavigation({ navigateFn: onSubMenuNavigation, drawerRef })}
        >
            {translate({
                locale,
                id: "view.dashboard.sidebar.submenu.order-setting.subscription",
            })}
        </div>
    );
});

SubMenuSubscription.displayName = "SubMenuSubscription";
