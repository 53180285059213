import { TParamsApiDictionaryCostCenterUpdate } from "../../../../api/dictionary/dictionaryCostCenterUpdate/params";
import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryCostCenterUpdate/response";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryCostCenterUpdateFailure,
    TActionDictionaryCostCenterUpdateRequest,
    TActionDictionaryCostCenterUpdateSuccess,
} from "./declarations";

export const actionDictionaryCostCenterUpdateRequest = (
    payload: TParamsApiDictionaryCostCenterUpdate
): TActionDictionaryCostCenterUpdateRequest => ({
    type: Actions.dictionaryCostCenterUpdateRequest,
    payload,
});

export const actionDictionaryCostCenterUpdateSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryCostCenterUpdateSuccess => ({
    type: Actions.dictionaryCostCenterUpdateSuccess,
    payload,
});

export const actionDictionaryCostCenterUpdateFailure = (
    payload: TError
): TActionDictionaryCostCenterUpdateFailure => ({
    type: Actions.dictionaryCostCenterUpdateFailure,
    payload,
});
