import {
    TParamsApiWorkCreate,
    TParamsApiWorkCreateSuccess,
} from "../../../../api/work/workCreate/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionWorkCreateFailure,
    TActionWorkCreateRequest,
    TActionWorkCreateSuccess,
} from "./declarations";

export const actionWorkCreateRequest = (
    payload: TParamsApiWorkCreate
): TActionWorkCreateRequest => ({
    type: Actions.workCreateRequest,
    payload,
});

export const actionWorkCreateSuccess = (
    payload: TParamsApiWorkCreateSuccess
): TActionWorkCreateSuccess => ({
    type: Actions.workCreateSuccess,
    payload,
});

export const actionWorkCreateFailure = (payload: TError): TActionWorkCreateFailure => ({
    type: Actions.workCreateFailure,
    payload,
});
