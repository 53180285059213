import { isNumber } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectorCommitmentList } from "@magoot-sdk/redux-modules/selectors/selectorsCommitment";

import { actionUISubscriptionModalOpen } from "../../actions/ui/actionUi";
import { TStore } from "../../reducers/declarations";
import { selectorWorkList } from "../../selectors/selectorWork";
import { selectorAnnotationList } from "../../selectors/selectorsAnnotation";
import { getGeneralStore } from "../../selectors/selectorsGeneral";
import { selectorInspectionList } from "../../selectors/selectorsInspection";
import { selectorOperatorList } from "../../selectors/selectorsOperator";
import { selectorOrderList } from "../../selectors/selectorsOrder";
import { selectorStudioStats } from "../../selectors/selectorsStats";
import {
    selectorIsStudioCanceled,
    selectorIsStudioSubscribed,
    selectorSubscriptionCurrentSubscription,
    selectorSubscriptionExpired,
} from "../../selectors/selectorsSubscription";
import { selectorTaskList } from "../../selectors/selectorsTask";
import { useDispatch } from "../../utils/useDispatch";
import { selectorIdeaList } from "../idea/useIdeaList";
import { useOrderID } from "../useOrderID";
import { useStudioID } from "../useStudioID";
import { useWorkID } from "../useWorkId";
import { selectorUserList } from "../user/useTeamList";

interface Params {
    code:
        | "STUDIO"
        | "USERS"
        | "USERS_OUTSIDE"
        | "ORDERS"
        | "WORKS"
        | "TASK"
        | "INSPECTIONS"
        | "ANNOTATIONS"
        | "DOCUMENTS"
        | "COMMITMENTS"
        | "OPERATORS"
        | "REPORTS"
        | "IDEAS"
        | "PAYMENTS"
        | "NOTIFICATIONS"
        | "SHARE"
        | "IMPORT"
        | "SELECT-WORKS-ON-CREATE-ORDER"
        | "SIGNATURE"
        | "SHOW-SIGNATURE"
        | "VERBSOP"
        | "RICERAVANZ";
    worksSelectedList?: number[];
}

export const useSubscription = ({
    code,
    worksSelectedList,
}: Params): {
    hasToUpgrade: boolean;
    onUpgrade: (source?: string) => void;
} => {
    const { idWork } = useWorkID();
    const { idStudio } = useStudioID();
    const { idOrder } = useOrderID();

    const store = useSelector(getGeneralStore);
    const dispatch = useDispatch();
    const isSubscriptionExpired = useSelector((storeSubscriptionExpired: TStore) =>
        selectorSubscriptionExpired({ store: storeSubscriptionExpired, idStudio })
    );
    const isStudioSubscribed = useSelector((storeStudioSubscribed: TStore) =>
        selectorIsStudioSubscribed({ store: storeStudioSubscribed, idStudio })
    );
    const isStudioCanceled = useSelector((storeStudioCanceled: TStore) =>
        selectorIsStudioCanceled({ store: storeStudioCanceled, idStudio })
    );
    const currentSubscription = useSelector((storeSubscription: TStore) =>
        selectorSubscriptionCurrentSubscription({ store: storeSubscription, idStudio })
    );

    const differenceDay = useMemo(() => {
        const differenceTime = currentSubscription
            ? new Date(currentSubscription?.dataScadenza).getTime() - new Date().getTime()
            : undefined;
        const differenceString = differenceTime
            ? (differenceTime / (1000 * 3600 * 24)).toString()
            : undefined;

        return differenceString ? Math.round(+differenceString) : undefined;
    }, [currentSubscription]);

    let isActive = true;
    let hasPermission = true;
    let limit = 0;
    let hasNoLimitPermission = false;

    const onUpgrade = (source?: string): void => {
        const payload = isActive ? limit : undefined;
        dispatch(actionUISubscriptionModalOpen(payload, source));
    };

    switch (code) {
        case "USERS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const userList = selectorUserList(store);
            const userListWaiting = userList.filter((user) => user.stato === 0 && user.ruolo !== 3);
            const userListAccepted = userList.filter(
                (user) => user.stato === 1 && user.ruolo !== 3
            );
            const activeUserList = userListAccepted.filter((user) => user.attivo);
            const summUser = userListWaiting.length + activeUserList.length;

            isActive = !(
                summUser >= store.subscription[idStudio]?.currentSubscription?.numeroUtenti
            );
            if (store.subscription[idStudio]?.currentSubscription?.numeroUtenti === 0)
                isActive = true;
            break;
        case "USERS_OUTSIDE":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const userOutsideList = selectorUserList(store);
            const userOutsideListWaiting = userOutsideList.filter(
                (user) => !user.attivo && user.ruolo === 3 && user.stato === 0
            );
            const userOutsideListAccepted = userOutsideList.filter(
                (user) => user.attivo && user.ruolo === 3 && user.stato === 1
            );

            const summUserOutside = userOutsideListWaiting.length + userOutsideListAccepted.length;

            isActive = !(
                summUserOutside >=
                store.subscription[idStudio]?.currentSubscription?.funzionalita.NCOLLEST.limite
            );
            if (
                store.subscription[idStudio]?.currentSubscription?.funzionalita.NCOLLEST.limite ===
                0
            )
                isActive = true;
            break;
        case "ORDERS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const orderList = selectorOrderList(store) || [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.COM.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.COM.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.COM.limite;
            hasPermission = orderList.length < limit;
            break;
        case "WORKS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const workList = selectorWorkList({ store, idOrder }) || [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.LAV.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.LAV.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.LAV.limite;
            hasPermission = workList.length < limit;
            break;
        case "TASK":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const taskList = selectorTaskList({ store, idOrder, idWork }) || [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.ATT.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.ATT.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.ATT.limite;
            hasPermission = taskList.length < limit;
            break;
        case "INSPECTIONS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const inspectionList = selectorInspectionList({ store, idOrder, idWork }) || [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.SOP.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.SOP.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.SOP.limite;
            hasPermission = inspectionList ? inspectionList.length < limit : true;
            break;
        case "ANNOTATIONS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const annotationList = selectorAnnotationList({ store, idOrder, idWork }) || [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.NOTE.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.NOTE.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.NOTE.limite;
            hasPermission = annotationList.length < limit;
            break;
        case "DOCUMENTS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const studioStats = selectorStudioStats({ store, idStudio });
            const studioStorage =
                studioStats.stat.length > 0 && studioStats.stat[1].valore.split(" ");
            const studioStorageSize = studioStorage && parseFloat(studioStorage[0]);
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.DOC.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.DOC.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.DOC.limite;
            hasPermission = isNumber(studioStorageSize) && studioStorageSize < limit;
            break;
        case "COMMITMENTS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const commitmentList = selectorCommitmentList({ store, idOrder }) || [];
            isActive =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.COMMIT.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.COMMIT.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.COMMIT.limite;
            hasPermission = commitmentList.length < limit;
            break;
        case "OPERATORS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const operatorList = selectorOperatorList({ store, idOrder }) || [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.OPER.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.OPER.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.OPER.limite;
            hasPermission = operatorList.length < limit;
            break;
        case "REPORTS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const reportList = [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.REP.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.REP.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.REP.limite;
            hasPermission = reportList.length < limit;
            break;
        case "RICERAVANZ":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;
            hasPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.RICERAVANZ.attivo;
            break;
        case "IDEAS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const ideaList = selectorIdeaList({ store, idOrder }) || [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.IDEE.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.IDEE.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.IDEE.limite;
            hasPermission = ideaList.length < limit;
            break;
        case "PAYMENTS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.CONT.attivo;
            break;
        case "NOTIFICATIONS":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.NOT.attivo;
            break;
        case "SHARE":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.SHARE.attivo;
            break;
        case "IMPORT":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            isActive =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.IMPORT.attivo;
            break;
        case "SELECT-WORKS-ON-CREATE-ORDER":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            const selectedList = worksSelectedList || [];
            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.LAV.attivo;
            hasNoLimitPermission =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.LAV.limite === 0;
            limit = store.subscription[idStudio]?.currentSubscription?.funzionalita.LAV.limite;
            hasPermission = selectedList.length < limit;
            break;
        case "SIGNATURE":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            isActive = store.subscription[idStudio]?.currentSubscription?.funzionalita.FIRMA.attivo;
            break;
        case "SHOW-SIGNATURE":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            isActive =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.VISFIRMA.attivo;
            break;
        case "VERBSOP":
            if (!store.subscription) break;
            if (!store.subscription[idStudio]) break;
            if (!store.subscription[idStudio]?.currentSubscription?.funzionalita) break;

            isActive =
                store.subscription[idStudio]?.currentSubscription?.funzionalita.VERBSOP.attivo;
            break;
        // Added this case to avoid console warning
        case "STUDIO":
            break;
        default:
            console.warn(`Code ${code} not valid`);
    }

    const hasToUpgrade = (): boolean => {
        if (
            !isStudioSubscribed ||
            isSubscriptionExpired ||
            !isActive ||
            (isStudioCanceled && differenceDay && +differenceDay === 0)
        )
            return true;
        const hasToUpgradeValue = hasNoLimitPermission ? false : !hasPermission;

        return hasToUpgradeValue;
    };

    return { hasToUpgrade: hasToUpgrade(), onUpgrade };
};
