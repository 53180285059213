import { App } from "@app";
import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import smartlookClient from "smartlook-client";

import { ProviderRedux } from "@magoot-sdk/redux-modules/utils/Provider";

import config from "../package.json";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

const isProd = process.env.NODE_ENV === "production";
smartlookClient.init("b740e8a38c1d182c11921791ff5bc4a33993612c");
smartlookClient.record({ ips: true, forms: false, emails: true, numbers: true, api: true });

if (isProd) {
    Sentry.init({
        dsn: "https://9b64f487fc02499bae0295f6ebe7201e@o112664.ingest.sentry.io/5923216",
        integrations: [
            new Integrations.BrowserTracing(),
            new RewriteFrames(),
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        release: config.version,
        environment: process.env.NODE_ENV,
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: isProd ? 0.1 : 1,
        replaysOnErrorSampleRate: 1.0,
    });
}

render(
    <ProviderRedux>
        <Router>
            <App />
        </Router>
    </ProviderRedux>,
    document.getElementById("root")
);

serviceWorker.unregister();
