import { MutableRefObject } from "react";

export const handleDrawerNavigation = ({
    navigateFn,
    drawerRef,
}: {
    navigateFn?: () => void;
    drawerRef?: MutableRefObject<HTMLInputElement | undefined>;
}) => {
    if (navigateFn) navigateFn();
    if (drawerRef && drawerRef.current) {
        drawerRef.current.click();
    }
};
