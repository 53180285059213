// #region ::: IMPORT
import React, { FC, memo } from "react";
import { useHistory } from "react-router";

import { translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { TNotification } from "@magoot-sdk/redux-modules/reducers/reducerNotifications/declarations";
import { utilityGetUrlViewNotificationList } from "@magoot-sdk/utils/Routes";

import { Notification } from "./partials/Notification";

// #endregion

interface Props {
    onClickOutside: () => void;
    notificationList: TNotification[] | false;
}

export const DropdownNotification: FC<Props> = memo(
    ({ onClickOutside, notificationList }): JSX.Element => {
        const history = useHistory();
        const locale = useLocale();
        const { idStudio } = useStudioID();

        const textLatestNotification = translate({
            locale,
            id: "general.label.latest-notifications",
        });
        const textNoNotifications = translate({ locale, id: "general.label.no-notifications" });

        const onNavigate = (): void => {
            onClickOutside();
            history.push(utilityGetUrlViewNotificationList({ idStudio }));
        };

        const renderNotification = (
            notification: TNotification,
            index: number
        ): JSX.Element | null => {
            if (index < 5) {
                return (
                    <Notification
                        key={`${notification.id}-${notification.tipoNotifica}`}
                        to={notification.url}
                        notification={notification}
                        onClickOutside={onClickOutside}
                    />
                );
            }
            return null;
        };

        return (
            <ul className='menu rounded-box absolute top-[3.5rem] right-[1rem] z-50 ml-32 w-fit min-w-[18rem] bg-base-100 p-12 shadow-2xl sm:right-[4rem] md:w-[24rem]'>
                <li className='mb-8 text-center text-lg font-medium text-secondaryDark'>
                    {textLatestNotification}
                </li>
                {!!notificationList && notificationList.length > 0 ? (
                    notificationList.map(renderNotification)
                ) : (
                    <p className='break-all py-12 text-center text-sm lg:text-base'>
                        {textNoNotifications}
                    </p>
                )}

                <p className='mt-4 border-t-[0.063rem] border-magootGray pt-12 text-center text-sm font-medium'>
                    <span
                        className='text-underline link-hover link-secondary cursor-pointer p-0'
                        onClick={onNavigate}
                    >
                        {translate({ locale, id: "button.all-notifications" })}
                    </span>
                </p>
            </ul>
        );
    }
);
DropdownNotification.displayName = "DropdownNotification";
