import { Actions } from "../../utils/Actions";
import { TReducerPopup, TReducerPopupActions } from "./declarations";

export const reducerPopup = (
    prevStore: TReducerPopup = {},
    action: TReducerPopupActions
): TReducerPopup => {
    let newStore = { ...prevStore };

    switch (action.type) {
        case Actions.showPopup:
            newStore = action.payload;
            return newStore;
        case Actions.hidePopup:
            delete newStore.title;
            delete newStore.body;
            delete newStore.onConfirm;
            delete newStore.notOnDelete;
            delete newStore.information;
            delete newStore.payload;
            delete newStore.loader;
            delete newStore.hiddenClose;
            delete newStore.onSave;
            return newStore;
        default:
            return prevStore;
    }
};
