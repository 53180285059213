import { Actions } from "@magoot-sdk/redux-modules/utils/Actions";

import { TReducerTag, TReducerTagActions } from "./declarations";

export const reducerTag = (
    prevStore: TReducerTag = { document: [], idea: [] },
    action: TReducerTagActions
): TReducerTag => {
    const newStore: TReducerTag = { ...prevStore };

    switch (action.type) {
        case Actions.tagListSuccess:
            newStore[action.payload.key] = action.payload.tag;
            return newStore;
        case Actions.createLogoutSuccess:
            return { document: [], idea: [] };
        default:
            return prevStore;
    }
};
