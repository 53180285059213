import { translationsButton } from "./button";
import { translationsEmptyList } from "./emptyList";
import { translationsFile } from "./file";
import { translationsFormChangePassword } from "./formChangePassword";
import { translationsFormForgotPassword } from "./formForgotPassword";
import { translationsFormInviteUser } from "./formInviteUser";
import { translationsFormJoinStudio } from "./formJoinStudio";
import { translationsFormResetPassword } from "./formResetPassword";
import { translationsFormSignup } from "./formSignup";
import { translationsGeneral } from "./general";
import { translationsInfo } from "./info";
import { translationsModalUpgrade } from "./modalUpgrade";
import { translationsOfflinebar } from "./offlineBar";
import { translationsPopup } from "./popup";
import { translationsReports } from "./reports";
import { translationsViewAccount } from "./viewAccount";
import { translationsViewActivation } from "./viewActivation";
import { translationsViewAddressBook } from "./viewAddressBook";
import { translationsViewAnnotationCreate } from "./viewAnnotationCreate";
import { translationsViewAnnotationList } from "./viewAnnotationList";
import { translationsViewBusinessOperator } from "./viewBusinessOperator";
import { translationsViewCustomerDetail } from "./viewCustomerDetail";
import { translationsViewCustomerList } from "./viewCustomerList";
import { translationsViewDashboard } from "./viewDashboard";
import { translationsViewDocumentCreate } from "./viewDocumentCreate";
import { translationsViewDocumentList } from "./viewDocumentList";
import { translationsViewDownloadFile } from "./viewDownloadFile";
import { translationsViewForgotPassword } from "./viewForgotPassword";
import { translationsViewForgotPasswordSuccess } from "./viewForgotPasswordSuccess";
import { translationsViewIdeaCreate } from "./viewIdeaCreate";
import { translationsViewInspectionCreate } from "./viewInspectionCreate";
import { translationsViewInspectionList } from "./viewInspectionList";
import { translationsViewInvitation } from "./viewInvitation";
import { translationsViewLogin } from "./viewLogin";
import { translationsViewMunicipalPractices } from "./viewMunicipalPractices";
import { translationsViewNotFound } from "./viewNotFound";
import { translationsViewOrder } from "./viewOrder";
import { translationsViewOrderCreate } from "./viewOrderCreate";
import { translationsViewOrderSetting } from "./viewOrderSetting";
import { translationsViewPayments } from "./viewPayments";
import { translationsViewProfile } from "./viewProfile";
import { translationsViewProtocolList } from "./viewProtocolList";
import { translationsViewResetPasswordSuccess } from "./viewResetPasswordSuccess";
import { translationsViewSignUp } from "./viewSignup";
import { translationsViewStudioDetail } from "./viewStudioDetail";
import { translationsViewStudioInvitation } from "./viewStudioInvitation";
import { translationsViewStudioList } from "./viewStudioList";
import { translationsViewSubscription } from "./viewSubscription";
import { translationsViewSummary } from "./viewSummary";
import { translationsViewTaskList } from "./viewTaskList";
import { translationsViewTerritorialData } from "./viewTerritorialData";
import { translationsViewWizard } from "./viewWizard";

export const translations = {
    it: {
        ...translationsOfflinebar.it,
        ...translationsButton.it,
        ...translationsFormChangePassword.it,
        ...translationsFormForgotPassword.it,
        ...translationsFormInviteUser.it,
        ...translationsFormJoinStudio.it,
        ...translationsViewWizard.it,
        ...translationsFormResetPassword.it,
        ...translationsFormSignup.it,
        ...translationsGeneral.it,
        ...translationsOfflinebar.it,
        ...translationsViewActivation.it,
        ...translationsViewForgotPasswordSuccess.it,
        ...translationsViewForgotPassword.it,
        ...translationsViewInvitation.it,
        ...translationsViewOrder.it,
        ...translationsViewLogin.it,
        ...translationsViewNotFound.it,
        ...translationsViewProfile.it,
        ...translationsViewResetPasswordSuccess.it,
        ...translationsViewSignUp.it,
        ...translationsEmptyList.it,
        ...translationsViewOrderSetting.it,
        ...translationsPopup.it,
        ...translationsViewStudioDetail.it,
        ...translationsViewDashboard.it,
        ...translationsViewStudioInvitation.it,
        ...translationsViewBusinessOperator.it,
        ...translationsViewCustomerList.it,
        ...translationsViewInspectionList.it,
        ...translationsViewAnnotationList.it,
        ...translationsViewAnnotationCreate.it,
        ...translationsViewDocumentList.it,
        ...translationsViewInspectionCreate.it,
        ...translationsViewDocumentCreate.it,
        ...translationsViewProtocolList.it,
        ...translationsViewTaskList.it,
        ...translationsViewIdeaCreate.it,
        ...translationsViewStudioList.it,
        ...translationsViewCustomerDetail.it,
        ...translationsViewOrderCreate.it,
        ...translationsViewAccount.it,
        ...translationsViewSummary.it,
        ...translationsViewPayments.it,
        ...translationsViewDownloadFile.it,
        ...translationsViewTerritorialData.it,
        ...translationsModalUpgrade.it,
        ...translationsViewSubscription.it,
        ...translationsViewMunicipalPractices.it,
        ...translationsInfo.it,
        ...translationsViewAddressBook.it,
        ...translationsReports.it,
        ...translationsFile.it,
    },
    en: {
        ...translationsOfflinebar.en,
        ...translationsButton.en,
        ...translationsFormChangePassword.en,
        ...translationsFormForgotPassword.en,
        ...translationsFormInviteUser.en,
        ...translationsFormJoinStudio.en,
        ...translationsViewWizard.en,
        ...translationsFormResetPassword.en,
        ...translationsFormSignup.en,
        ...translationsGeneral.en,
        ...translationsOfflinebar.en,
        ...translationsViewActivation.en,
        ...translationsViewForgotPasswordSuccess.en,
        ...translationsViewForgotPassword.en,
        ...translationsViewInvitation.en,
        ...translationsViewOrder.en,
        ...translationsViewLogin.en,
        ...translationsViewNotFound.en,
        ...translationsViewProfile.en,
        ...translationsViewResetPasswordSuccess.en,
        ...translationsViewSignUp.en,
        ...translationsEmptyList.en,
        ...translationsViewOrderSetting.en,
        ...translationsPopup.en,
        ...translationsViewStudioDetail.en,
        ...translationsViewStudioInvitation.en,
        ...translationsViewDashboard.en,
        ...translationsViewBusinessOperator.en,
        ...translationsViewCustomerList.en,
        ...translationsViewInspectionList.en,
        ...translationsViewAnnotationList.en,
        ...translationsViewAnnotationCreate.en,
        ...translationsViewDocumentList.en,
        ...translationsViewInspectionCreate.en,
        ...translationsViewDocumentCreate.en,
        ...translationsViewProtocolList.en,
        ...translationsViewTaskList.en,
        ...translationsViewIdeaCreate.en,
        ...translationsViewStudioList.en,
        ...translationsViewCustomerDetail.en,
        ...translationsViewOrderCreate.en,
        ...translationsViewAccount.en,
        ...translationsViewSummary.en,
        ...translationsViewPayments.en,
        ...translationsViewDownloadFile.en,
        ...translationsViewTerritorialData.en,
        ...translationsModalUpgrade.en,
        ...translationsViewSubscription.en,
        ...translationsViewMunicipalPractices.en,
        ...translationsInfo.en,
        ...translationsViewAddressBook.en,
        ...translationsReports.en,
        ...translationsFile.en,
    },
};
