import { TStore } from "../reducers/declarations";
import { TOwnStudio } from "../reducers/reducerAuth/declarations";
import { TReducerStudios } from "../reducers/reducerStudio/declarations";
import { selectorIdStudioToken } from "./selectorsAuth";

export const getStudios = (store: TStore): TReducerStudios => store.studios!;

export const selectorHasOwnStudio = (store: TStore): boolean => store.auth?.hasOwnStudio || false;

export const selectorHasConnectedStudio = (store: TStore): boolean => {
    if (!store.auth?.connectedStudio) return false;
    return store.auth?.connectedStudio > 0;
};

export const selectorOwnStudio = (store: TStore): TOwnStudio | false =>
    store.auth?.ownStudioDetail || false;

export const selectorIsOwnStudio = (store: TStore): boolean => {
    const idStudioToken = selectorIdStudioToken(store);
    const ownStudio = selectorOwnStudio(store);
    if (!ownStudio) return false;
    return ownStudio.id === idStudioToken;
};

export const selectorIsOrderCreate = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio?: number | null;
}): boolean | undefined => {
    if (!store.studios || !idStudio) return false;
    if (!store.studios[idStudio]) return false;
    return store.studios[idStudio].creazioneCommessaCollaboratoreEsterno;
};

export const selectorIdUserOwnStudio = (store: TStore): boolean | number => {
    const idStudioToken = selectorIdStudioToken(store);
    const ownStudio = selectorOwnStudio(store);
    if (!ownStudio) return false;
    return (ownStudio.id === idStudioToken && store.auth?.idUtente) || false;
};

export const selectorIdStudioOwn = (store: TStore): boolean | number => {
    const ownStudio = selectorOwnStudio(store);
    if (!ownStudio) return false;
    return ownStudio.id;
};

export const selectorStudioDetail = ({
    store,
    idStudio,
}: {
    store: TStore;
    idStudio?: number | null;
}): string => {
    if (!store) return "";
    if (!store.studios || !idStudio) return "";
    if (!store.studios[idStudio]) return "";
    if (!store.studios[idStudio].denominazione) return "";
    return store.studios[idStudio].denominazione;
};
