import { createTokenImageHeader } from "./config";

const fetchDOWNLOAD = async (url: string): Promise<any> => {
    const response = await fetch(url, {
        method: "GET",
        headers: createTokenImageHeader(),
    });

    return response ? response.blob() : null;
};

export default fetchDOWNLOAD;
