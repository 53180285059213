// #region ::: IMPORT
import React, { FC, memo } from "react";

import { useOnlineCheck } from "@magoot-sdk/hooks/useOnlineCheck";
import { translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

// #endregion

export const OfflineBar: FC = memo((): JSX.Element | null => {
    const isOnline = useOnlineCheck();
    const locale = useLocale();
    if (isOnline) return null;

    return (
        <div className='alert alert-error fixed top-8 -translate-x-2/4 left-2/4 z-50 m-0 flex w-toast flex-col justify-between rounded-lg shadow-lg md:flex-row'>
            <div>
                <span>{translate({ locale, id: "offlinebar.no-connection" })}</span>
            </div>
        </div>
    );
});
OfflineBar.displayName = "OfflineBar";
