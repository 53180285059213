import { KEY_FILTER_MY_ITEMS } from "../../../constants/general";
import { Actions } from "../../utils/Actions";
import { TReducerUI, TReducerUIActions } from "./declarations";

const initialStore: TReducerUI = {
    filter: {
        oramaSearch: undefined,
    },
    isSidebarOpen: true,
    isUploadingFile: {},
    globalNotify: false,
    isFormNotSaved: false,
    canLeaveForm: false,
    valueSwitchDeadlines: KEY_FILTER_MY_ITEMS.toString(),
    valueSwitchOrders: KEY_FILTER_MY_ITEMS.toString(),
    geoForm: undefined,
    restoreExternalLink: "",
    appVersion: {
        version: "",
        isUpdate: false,
    },
};

export const reducerUI = (prevStore = initialStore, action: TReducerUIActions): TReducerUI => {
    const newStore = { ...prevStore };
    const storeExternalLink = {
        ...initialStore,
        restoreExternalLink: newStore.restoreExternalLink,
    };

    switch (action.type) {
        // ::: LOGIN
        case Actions.createLoginFailure:
            newStore.loginErrors = action.payload.message?.message;
            return newStore;
        case Actions.createLoginRequest:
        case Actions.resetLoginError:
            delete newStore.loginErrors;
            return newStore;
        // ::: LOGOUT
        case Actions.sessionExpired:
        case Actions.createLogoutSuccess:
            return storeExternalLink;
        // ::: SIGNUP
        case Actions.createSignupRequest:
            newStore.isSigning = true;
            return newStore;
        case Actions.resetPasswordRequest:
            newStore.isResettingPassword = true;
            return newStore;
        case Actions.createSignupSuccess:
            delete newStore.signupErrors;
            delete newStore.isSigning;
            newStore.hasSignedUp = true;
            return newStore;
        case Actions.resetSignupRequestSent:
            delete newStore.hasSignedUp;
            return newStore;
        case Actions.createSignupFailure:
            delete newStore.isSigning;
            newStore.signupErrors = action.payload.message?.message;
            return newStore;
        case Actions.resetSignupError:
            delete newStore.signupErrors;
            return newStore;
        // ::: CHANGE PASSWORD
        case Actions.changePasswordRequest:
            delete newStore.passwordChangeErrors;
            newStore.isChangingPassword = true;
            return newStore;
        case Actions.changePasswordSuccess:
            delete newStore.isChangingPassword;
            return newStore;
        case Actions.changePasswordFailure:
            delete newStore.isChangingPassword;
            newStore.passwordChangeErrors = action.payload.details;
            return newStore;
        // ::: FORGOT PASSWORD
        case Actions.forgotPasswordRequest:
            newStore.isSendingForgtPassword = true;
            delete newStore.forgotPasswordErrors;
            return newStore;
        case Actions.forgotPasswordSuccess:
            newStore.hasEmailForgotPassword = true;
            delete newStore.isSendingForgtPassword;
            return newStore;
        case Actions.resetEmailForgotPasswordSent:
            delete newStore.hasEmailForgotPassword;
            return newStore;
        case Actions.forgotPasswordFailure:
            delete newStore.isSendingForgtPassword;
            newStore.forgotPasswordErrors = action.payload.message?.message;
            return newStore;
        case Actions.resetForgotPasswordError:
            delete newStore.forgotPasswordErrors;
            return newStore;
        // ::: RESET PASSWORD
        case Actions.resetPasswordFailure:
            delete newStore.isResettingPassword;
            newStore.resetPasswordError = action.payload.message?.message;
            return newStore;
        case Actions.resetPasswordSuccess:
            delete newStore.isResettingPassword;
            newStore.hasResetPassword = true;
            return newStore;
        /// ::: INVITATION
        case Actions.invitationSendRequest:
            newStore.isInvitingUser = true;
            return newStore;
        case Actions.invitationSendSuccess:
            delete newStore.isInvitingUser;
            return newStore;
        case Actions.invitationSendFailure:
            delete newStore.isInvitingUser;
            newStore.inviteUserErrors = action.payload.message?.message;
            return newStore;
        // ::: JOIN STUDIO
        case Actions.acceptInvitationAsUserLoggedSuccess:
        case Actions.acceptInvitationWithAnotherAccountSuccess:
            newStore.hasInviteStudioAccepted = true;
            return newStore;
        case Actions.resetInvitationRequestAccepted:
            delete newStore.hasInviteStudioAccepted;
            return newStore;
        // ::: UPDATE PROFILE
        case Actions.updateProfiloRequest:
            newStore.isUpdatingProfile = true;
            return newStore;
        case Actions.updateProfiloFailure:
        case Actions.updateProfiloSuccess:
            delete newStore.isUpdatingProfile;
            return newStore;
        // ::: UPLOAD IMAGE Studio
        case Actions.uploadStudioImageRequest:
            delete newStore.hasStudioImgUpdated;
            newStore.isUploadingFile.studio = true;
            return newStore;
        case Actions.uploadStudioImageSuccess:
            newStore.hasStudioImgUpdated = true;
            delete newStore.isUploadingFile.studio;
            return newStore;
        case Actions.uploadStudioImageFailure:
            delete newStore.isUploadingFile.studio;
            return newStore;
        // ORDER
        case Actions.createOrderRequest:
            newStore.isCreatingOrder = true;
            return newStore;
        case Actions.createOrderSuccess:
            delete newStore.isCreatingOrder;
            newStore.orderCreated = action.payload.id;
            return newStore;
        case Actions.resetOrderCreated:
            delete newStore.orderCreated;
            return newStore;
        case Actions.createOrderFailure:
            delete newStore.isCreatingOrder;
            return newStore;
        case Actions.updateStudioRequest:
            newStore.isUpdatingStudio = true;
            return newStore;
        case Actions.updateStudioFailure:
        case Actions.updateStudioSuccess:
            delete newStore.isUpdatingStudio;
            return newStore;
        case Actions.refreshTokenRequest:
            newStore.isRefreshingToken = true;
            return newStore;
        case Actions.refreshTokenFailure:
        case Actions.refreshTokenSuccess:
            delete newStore.isRefreshingToken;
            return newStore;
        case Actions.createStudioRequest:
            newStore.isCreatingStudio = true;
            return newStore;
        case Actions.createStudioSuccess:
        case Actions.createStudioFailure:
            delete newStore.isCreatingStudio;
            return newStore;
        case Actions.orderUpdateRequest:
            newStore.isUpdatingOrder = true;
            return newStore;
        case Actions.orderUpdateSuccess:
        case Actions.orderUpdateFailure:
            delete newStore.isUpdatingOrder;
            return newStore;
        // case Actions.deleteOrder:
        case Actions.orderListRequest:
            newStore.isLoadingOrders = true;
            return newStore;
        case Actions.deleteOrderSuccess:
        case Actions.deleteOrderFailure:
        case Actions.orderListSuccess:
        case Actions.orderListFailure:
            delete newStore.isLoadingOrders;
            return newStore;
        case Actions.fetchUserListRequest:
            newStore.isLoadingUsers = true;
            return newStore;
        case Actions.fetchUserListSuccess:
        case Actions.fetchUserListFailure:
            delete newStore.isLoadingUsers;
            return newStore;
        case Actions.orderTeamRequest:
            newStore.isUpdatingOrderTeam = true;
            return newStore;
        case Actions.orderTeamFailure:
        case Actions.orderTeamSuccess:
            delete newStore.isUpdatingOrderTeam;
            return newStore;
        case Actions.asyncResourcesLoaded:
            newStore.isAppReady = true;
            return newStore;
        case Actions.oramaSearch:
            newStore.filter = {
                ...prevStore.filter,
                oramaSearch: action.payload.oramaSearch ?? prevStore.filter?.oramaSearch,
            };
            return newStore;
        case Actions.oramaFiltersSearch:
            newStore.filter = {
                ...prevStore.filter,
                persistSearch: {
                    ...prevStore.filter?.persistSearch,
                    [action.payload.index as string]: {
                        filters: action.payload.filters,
                        sortBy: action.payload.sortBy,
                    },
                },
            };
            return newStore;
        case Actions.documentUploadIdeaRequest:
            newStore.isLoadingIdea = true;
            newStore.isUploadingFile.ideas = {
                ...newStore.isUploadingFile.ideas,
                [action.payload.idEntity]: true,
            };
            return newStore;
        case Actions.documentUploadIdeaSuccess:
        case Actions.documentUploadIdeaFailure:
            delete newStore.isLoadingIdea;
            delete newStore.isUploadingFile.ideas![action.payload.idEntity];
            return newStore;
        case Actions.uiSetUploadInfo:
            newStore.uploadingInfo = action.payload;
            return newStore;
        case Actions.orderStatsRequest:
            newStore.isLoadingOrderStats = true;
            return newStore;
        case Actions.orderStatsFailure:
        case Actions.orderStatsSuccess:
            delete newStore.isLoadingOrderStats;
            return newStore;
        case Actions.orderReadRequest:
            newStore.isSettingOrderRead = true;
            return newStore;
        case Actions.orderReadSuccess:
            delete newStore.isSettingOrderRead;
            return newStore;
        case Actions.orderReadFailure:
            delete newStore.isSettingOrderRead;
            return newStore;
        case Actions.uiDeleteUploadInfo:
            delete newStore.uploadingInfo;
            return newStore;
        case Actions.commitmentListRequest:
            newStore.isLoadingCommitments = true;
            return newStore;
        case Actions.commitmentListFailure:
        case Actions.commitmentListSuccess:
            delete newStore.isLoadingCommitments;
            return newStore;
        case Actions.operatorListRequest:
            newStore.isLoadingOperators = true;
            return newStore;
        case Actions.operatorListFailure:
        case Actions.operatorListSuccess:
            delete newStore.isLoadingOperators;
            return newStore;
        case Actions.uiSubscriptionModalOpen:
            newStore.showSubscriptionModal = true;
            newStore.source = action.payload.source;
            return newStore;
        case Actions.uiSubscriptionModalClose:
            delete newStore.showSubscriptionModal;
            delete newStore.source;
            return newStore;
        case Actions.uploadUserImageRequest:
            newStore.isUploadingFile.profile = true;
            return newStore;
        case Actions.uploadUserImageSuccess:
        case Actions.uploadUserImageFailure:
            delete newStore.isUploadingFile.profile;
            return newStore;
        case Actions.ideaCreateRequest:
            newStore.isCreatingIdea = true;
            return newStore;
        case Actions.ideaCreateSuccess:
        case Actions.ideaCreateFailure:
            delete newStore.isCreatingIdea;
            return newStore;
        case Actions.documentUploadCustomerRequest:
            newStore.isUploadingFile.customers = {
                ...newStore.isUploadingFile.customers,
                [action.payload.idEntity]: true,
            };
            return newStore;
        case Actions.documentUploadCustomerSuccess:
        case Actions.documentUploadCustomerFailure:
            delete newStore.isUploadingFile.customers![action.payload.idEntity];
            return newStore;
        case Actions.documentUploadInspectionRequest:
            newStore.isUploadingFile.inspections = {
                ...newStore.isUploadingFile.inspections,
                [action.payload.idEntity]: true,
            };
            return newStore;
        case Actions.documentUploadInspectionSuccess:
        case Actions.documentUploadInspectionFailure:
            delete newStore.isUploadingFile.inspections![action.payload.idEntity];
            return newStore;
        case Actions.documentUploadWorkRequest:
            newStore.isUploadingFile.documents = {
                ...newStore.isUploadingFile.documents,
                [action.payload.fakeId || 0]: true,
            };

            newStore.isCreatingDocuments = {
                ...newStore.isCreatingDocuments,
                [action.payload.fakeId || 0]: true,
            };
            return newStore;
        case Actions.documentUploadWorkSuccess:
        case Actions.documentUploadWorkFailure:
            delete newStore.isUploadingFile.documents![action.payload.fakeId || 0];

            newStore.isCreatingDocuments = {
                ...newStore.isCreatingDocuments,
                [action.payload.fakeId]: false,
            };
            return newStore;
        case Actions.documentWorkCreateSuccess:
        case Actions.documentWorkCreateFailure:
            newStore.isCreatingDocuments = {
                ...newStore.isCreatingDocuments,
                [action.payload?.documentWork?.fakeId || 0]: false,
            };
            return newStore;
        case Actions.documentWorkDeleteActionRequest:
            action.payload.idDocumentList.forEach((id) => {
                newStore.isDeleteDocuments = {
                    ...newStore.isDeleteDocuments,
                    [id]: true,
                };
            });
            return newStore;
        case Actions.documentWorkDeleteSuccess:
            newStore.isDeleteDocuments = {
                ...newStore.isDeleteDocuments,
                [action.payload.idDocument]: false,
            };
            return newStore;
        case Actions.documentWorkListRequest:
            newStore.isLoadingDocumentList = true;
            return newStore;
        case Actions.documentWorkListSuccess:
        case Actions.documentWorkListFailure:
            newStore.isLoadingDocumentList = false;
            return newStore;
        case Actions.inspectionDetailRequest:
            newStore.isInspectionDownloadingPdf = false;
            return newStore;
        case Actions.inspectionDownloadPdfRequest:
            newStore.isInspectionDownloadingPdf = true;
            return newStore;
        case Actions.inspectionDownloadPdfSuccess:
        case Actions.inspectionDownloadPdfFailure:
            newStore.isInspectionDownloadingPdf = false;
            return newStore;
        case Actions.paperworkImportRequest:
            newStore.isImportingPaperwork = true;
            return newStore;
        case Actions.paperworkImportSuccess:
            delete newStore.isImportingPaperwork;
            return newStore;
        case Actions.paperworkImportFailure:
            delete newStore.isImportingPaperwork;
            return newStore;
        case Actions.uiToggleGlobalNotify:
            newStore.globalNotify = action.payload.toggle;
            return newStore;
        case Actions.uiFormSwitchDeadlines:
            newStore.valueSwitchDeadlines = action.payload.value;
            return newStore;
        case Actions.uiEditWorkDetail:
            newStore.editWorkDetail = action.payload.value;
            return newStore;
        case Actions.uiGeoForm:
            newStore.geoForm = { ...action.payload };
            return newStore;
        case Actions.restoreExternalLink:
            newStore.restoreExternalLink = action.payload.link;
            return newStore;
        case Actions.uiGeoFormReset:
            newStore.geoForm = undefined;
            return newStore;
        case Actions.uiIsFormNotSaved:
            newStore.isFormNotSaved = action.payload.isFormNotSaved;
            return newStore;
        case Actions.uiCanLeaveForm:
            newStore.canLeaveForm = action.payload.canLeave;
            return newStore;
        case Actions.uiForceOpenDialog:
            newStore.forceOpenDialog = {
                state: action.payload.state,
                action: action.payload.action || "",
            };
            return newStore;
        case Actions.uiGoNewRoutes:
            newStore.goNewRoutes = action.payload.state;
            return newStore;
        case Actions.uiFilterSummary:
            newStore.filterSummary = action.payload.state;
            return newStore;
        case Actions.uiAppVersion:
            newStore.appVersion = {
                version: action.payload.version,
                isUpdate: action.payload.isUpdate,
            };
            return newStore;
        case Actions.uiRecalculateRights:
            newStore.recalculateRights = {
                idStudio: action.payload.idStudio,
                idUtente: action.payload.idUtente,
            };
            return newStore;
        default:
            return prevStore;
    }
};
