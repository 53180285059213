import { TCustomerAddressBook } from "@magoot-sdk/redux-modules/reducers/reducerAddressBook/declarations";

import { TParamsApiAddressBookList } from "../../../../api/addressBook/addressBookList/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionAddressBookListFailure,
    TActionAddressBookListLoading,
    TActionAddressBookListRequest,
    TActionAddressBookListSuccess,
} from "./declarations";

export const actionAddressBookListRequest = (
    payload: TParamsApiAddressBookList
): TActionAddressBookListRequest => ({
    type: Actions.addressBookListRequest,
    payload,
});

export const actionAddressBookListSuccess = (payload: {
    addressBook: { [idCustomer: string]: TCustomerAddressBook };
}): TActionAddressBookListSuccess => ({
    type: Actions.addressBookListSuccess,
    payload,
});

export const actionAddressBookListFailure = (payload: TError): TActionAddressBookListFailure => ({
    type: Actions.addressBookListFailure,
    payload,
});

export const actionAddressBookListLoading = (payload: {
    loading: boolean;
}): TActionAddressBookListLoading => ({
    type: Actions.addressBookListLoading,
    payload,
});
