import { fetch } from "../../../utils/fetch";
import {
    TParamsApiAllNotification,
    TParamsApiLastUnreadArrayNotifications,
    TParamsApiNotificationRead,
    TParamsApiUnreadArrayNotifications,
    TParamsApiUnreadNotification,
} from "./params";
import {
    TResponseApiAllNotification,
    TResponseApiArrayNotificationsUnread,
    TResponseApiNotificationDelete,
    TResponseApiNotificationRead,
    TResponseApiNotificationUnread,
} from "./response";

export const apiNotificationRead = async ({
    idStudio,
    notification,
}: TParamsApiNotificationRead): Promise<TResponseApiNotificationRead> =>
    await fetch.put(
        `${process.env.REACT_APP_MAIN_ENDPOINT}/${idStudio}/gestionenotifiche/aggiornalettura`,
        { idNotifiche: notification }
    );

export const apiNotificationDelete = async ({
    idStudio,
    notification,
}: TParamsApiNotificationRead): Promise<TResponseApiNotificationDelete> =>
    await fetch.delete(
        `${process.env.REACT_APP_MAIN_ENDPOINT}/${idStudio}/GestioneNotifiche/CancellaNotifiche
      `,
        { idNotifiche: notification }
    );

export const apiUnreadNotification = async ({
    idStudio,
}: TParamsApiUnreadNotification): Promise<TResponseApiNotificationUnread> =>
    await fetch.get(
        `${process.env.REACT_APP_MAIN_ENDPOINT}/${idStudio}/GestioneNotifiche/GetNumeroNotificheNonLette`
    );

export const apiUnreadArrayNotifications = async ({
    idStudio,
}: TParamsApiUnreadArrayNotifications): Promise<TResponseApiArrayNotificationsUnread> =>
    await fetch.get(
        `${process.env.REACT_APP_MAIN_ENDPOINT}/${idStudio}/GestioneNotifiche/GetNotificheNonLette`
    );

export const apiLastUnreadArrayNotifications = async ({
    idStudio,
}: TParamsApiLastUnreadArrayNotifications): Promise<TResponseApiArrayNotificationsUnread> =>
    await fetch.get(
        `${process.env.REACT_APP_MAIN_ENDPOINT}/${idStudio}/GestioneNotifiche/GetUltimeNotificheNonLette`
    );

export const apiAllNotification = async ({
    idStudio,
}: TParamsApiAllNotification): Promise<TResponseApiAllNotification> =>
    await fetch.get(
        `${process.env.REACT_APP_MAIN_ENDPOINT}/${idStudio}/GestioneNotifiche/GetAllNotifiche`
    );
