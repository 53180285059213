import { createDownloadPdfHeader } from "./config";

const fetchDOWNLOADPDF = async (url: string): Promise<any> => {
    const response = await fetch(url, {
        method: "POST",
        headers: createDownloadPdfHeader(),
    });

    return response ? response.blob() : null;
};

export default fetchDOWNLOADPDF;
