interface Translations {
    "view.download-file.verification-code-title": string;
    "view.download-file.verification-code-subtitle": string;
    "view.download-file.placeholder": string;
    "view.download-file.call-to-action": string;
    "view.download-file.resend-code": string;
    "view.download-file.document-list.title": string;
    "view.download-file.document-list.download-all": string;
    "view.download-file.error": string;
    "view.download-order": string;
    "view.download-empty.file": string;
}

interface ViewDownloadFile {
    it: Translations;
    en: Translations;
}

export const translationsViewDownloadFile: ViewDownloadFile = {
    it: {
        "view.download-file.verification-code-title": "Codice di verifica",
        "view.download-file.verification-code-subtitle":
            "Per poter scaricare i file inserisci il codice che ti abbiamo appena inviato",
        "view.download-file.placeholder": "Codice",
        "view.download-file.call-to-action": "Abilita download",
        "view.download-file.resend-code": "Reinvia codice",
        "view.download-file.document-list.title": "Documenti condivisi",
        "view.download-file.document-list.download-all": "Scarica tutti i file",
        "view.download-file.error": `Download non riuscito, per favore riprova nuovamente. Se l’errore persiste contatta assistenza@magoot.it`,
        "view.download-order": "Pratica esportata correttamente",
        "view.download-empty.file": "Nessun documento presente causa cancellazione dalla commessa",
    },
    en: {
        "view.download-file.verification-code-title": "Verification code",
        "view.download-file.verification-code-subtitle":
            "In order to download the files, enter the code we have just sent you",
        "view.download-file.placeholder": "Code",
        "view.download-file.call-to-action": "Enable download",
        "view.download-file.resend-code": "Resend codice",
        "view.download-file.document-list.title": "Shared documents",
        "view.download-file.document-list.download-all": "Download all files",
        "view.download-file.error": `Download non riuscito, per favore riprova nuovamente. Se l’errore persiste contatta assistenza@magoot.it`,
        "view.download-order": "Pratica esportata correttamente",
        "view.download-empty.file": "Nessun documento presente causa cancellazione dalla commessa",
    },
};

export type TTranslationsViewDownloadFile = keyof Translations;
