interface Translations {
    "view.document-list.label.no-documents": string;
    "view.document-list.label.document": string;
    "view.document-list.label.type": string;
    "view.document-list.label.user": string;
    "view.document-list.label.upload-date": string;
    "view.document-list.label.size": string;
    "view.document-list.label.document-type": string;
    "view.document-list.button.add-document": string;
    "view.document-list.signed-file.modal.signatory": string;
    "view.document-list.signed-file.modal.tax-code": string;
    "view.document-list.signed-file.modal.signature-date": string;
    "view.document-list.signed-file.modal.valid-certificate": string;
    "view.document-list.signed-file.modal.certificate-expiration": string;
    "view.document-list.signed-file.modal.yes": string;
    "view.document-list.signed-file.modal.no": string;
    "view.document-list.button.send": string;
    "view.document-list.label.sent-to": string;
    "view.document-list.label.send-date": string;
    "view.document-list.label.send-type-list": string;
    "view.document-list.input.placeholder.search-for-title": string;
    "view.document-list.input.placeholder.search-for-tag": string;
    "view.document-list.header-bar": string;
    "view.document-list.header-bar.body": string;
    "view.document-list.modal.register-document-send": string;
    "view.document-list.modal.update-document": string;
    "view.document-list.modal.share-documents": string;
    "view.document-list.modal.protection": string;
    "view.document-list.modal.title.share-info": string;
    "view.document-list.email.share": string;
}

interface ViewDocumentList {
    it: Translations;
    en: Translations;
}

export const translationsViewDocumentList: ViewDocumentList = {
    it: {
        "view.document-list.label.no-documents": "Nessun documento presente!",
        "view.document-list.label.document": "Documento",
        "view.document-list.label.type": "Tipo",
        "view.document-list.label.user": "Utente",
        "view.document-list.label.upload-date": "Data",
        "view.document-list.label.size": "Dimensione",
        "view.document-list.label.document-type": "Tipo documento",
        "view.document-list.button.add-document": "Aggiungi documento",
        "view.document-list.signed-file.modal.signatory": "Firmatario",
        "view.document-list.signed-file.modal.tax-code": "Codice fiscale",
        "view.document-list.signed-file.modal.signature-date": "Data firma",
        "view.document-list.signed-file.modal.valid-certificate": "Certificato valido",
        "view.document-list.signed-file.modal.certificate-expiration": "Scadenza Certificato",
        "view.document-list.signed-file.modal.yes": "Si",
        "view.document-list.signed-file.modal.no": "No",
        "view.document-list.button.send": "Registra invio",
        "view.document-list.label.sent-to": "Inviato a",
        "view.document-list.label.send-date": "Data invio",
        "view.document-list.label.send-type-list": "Mezzi invio",
        "view.document-list.input.placeholder.search-for-title": "Cerca titolo",
        "view.document-list.input.placeholder.search-for-tag": "Qualsiasi tag",
        "view.document-list.header-bar": "Corrispondenza",
        "view.document-list.header-bar.body":
            "Attiva questa modalità per individuare più facilmente i documenti inviati dal tuo studio e quelli ricevuti.",
        "view.document-list.modal.register-document-send": "Registra invio documento",
        "view.document-list.modal.update-document": "Modifica documento",
        "view.document-list.modal.share-documents": "Condividi documenti",
        "view.document-list.modal.protection": "Protezione",
        "view.document-list.modal.title.share-info": "Informazioni di condivisione",
        "view.document-list.email.share":
            "Non è presente nessun committente/operatore con email o pec valide",
    },
    en: {
        "view.document-list.label.no-documents": "No documents!",
        "view.document-list.label.document": "Document",
        "view.document-list.label.type": "Type",
        "view.document-list.label.user": "User",
        "view.document-list.label.upload-date": "Upload date",
        "view.document-list.label.size": "Size",
        "view.document-list.label.document-type": "Document type",
        "view.document-list.button.add-document": "Add document",
        "view.document-list.signed-file.modal.signatory": "Signatory",
        "view.document-list.signed-file.modal.tax-code": "Tax code",
        "view.document-list.signed-file.modal.signature-date": "Signature date",
        "view.document-list.signed-file.modal.valid-certificate": "Valid certification",
        "view.document-list.signed-file.modal.certificate-expiration": "Certificate expiration",
        "view.document-list.signed-file.modal.yes": "Yes",
        "view.document-list.signed-file.modal.no": "No",
        "view.document-list.button.send": "Register sending",
        "view.document-list.label.sent-to": "Sent to",
        "view.document-list.label.send-date": "Send date",
        "view.document-list.label.send-type-list": "Send means",
        "view.document-list.input.placeholder.search-for-title": "Search title",
        "view.document-list.input.placeholder.search-for-tag": "Qualsiasi tag",
        "view.document-list.header-bar": "Corrispondenza",
        "view.document-list.header-bar.body":
            "Attiva questa modalità per individuare più facilmente i documenti inviati dal tuo studio e quelli ricevuti.",
        "view.document-list.modal.register-document-send": "Register sending document",
        "view.document-list.modal.update-document": "Update document",
        "view.document-list.modal.share-documents": "Share documents",
        "view.document-list.modal.protection": "Protection",
        "view.document-list.modal.title.share-info": "Share informations",
        "view.document-list.email.share":
            "Non è presente nessun committente/operatore con email o pec valide",
    },
};

export type TTranslationsViewDocumentList = keyof Translations;
