import { NAME_APP } from "../../constants/general";
import { utilityGetFromLocalStorage } from "../cache/getFromLocalStorage";

export const createConfigurationHeader = (): any => {
    const localeStore = utilityGetFromLocalStorage(NAME_APP);
    const token = (localeStore && localeStore.auth && localeStore.auth.token) || "";

    const configHeader: any = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    if (token) configHeader.Authorization = `Bearer ${token}`;

    return configHeader;
};

export const createUploadHeader = (): any => {
    const localeStore = utilityGetFromLocalStorage(NAME_APP);
    const token = (localeStore && localeStore.auth && localeStore.auth.token) || "";

    const configHeader: any = {
        Accept: "application/json",
    };

    if (token) configHeader.Authorization = `Bearer ${token}`;

    return configHeader;
};

export const createTokenImageHeader = (): any => {
    const localeStore = utilityGetFromLocalStorage(NAME_APP);
    const token = (localeStore && localeStore.auth && localeStore.auth.token) || "";

    const configHeader: any = {
        Accept: "application/json",
        "Content-Type": "image/*",
    };

    if (token) configHeader.Authorization = `Bearer ${token}`;

    return configHeader;
};

export const createDownloadZipHeader = (): any => {
    const localeStore = utilityGetFromLocalStorage(NAME_APP);
    const token = (localeStore && localeStore.auth && localeStore.auth.token) || "";

    const configHeader: any = {
        Accept: "application/zip",
        "Content-Type": "application/json",
    };

    if (token) configHeader.Authorization = `Bearer ${token}`;

    return configHeader;
};

export const createDownloadPdfHeader = (): any => {
    const localeStore = utilityGetFromLocalStorage(NAME_APP);
    const token = (localeStore && localeStore.auth && localeStore.auth.token) || "";

    const configHeader: any = {
        Accept: "application/pdf",
        "Content-Type": "text",
    };

    if (token) configHeader.Authorization = `Bearer ${token}`;

    return configHeader;
};

export const createDownloadCsvHeader = (): any => {
    const localeStore = utilityGetFromLocalStorage(NAME_APP);
    const token = (localeStore && localeStore.auth && localeStore.auth.token) || "";

    const configHeader: any = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    if (token) configHeader.Authorization = `Bearer ${token}`;

    return configHeader;
};
