import { useSelector } from "react-redux";

import { TOrderStats } from "@magoot-sdk/redux-modules/reducers/reducerStatistics/declarations";

import { TStore } from "../../reducers/declarations";
import { selectorOrderStatsNoRerender } from "../../selectors/selectorsStats";

export const useOrderStats = (idOrder: number): TOrderStats => {
    const rawOrderStats = useSelector((store: TStore) =>
        selectorOrderStatsNoRerender({ store, idOrder })
    );

    const id = rawOrderStats ? rawOrderStats.id : 0;
    const ideasCounter = rawOrderStats ? rawOrderStats.idee : 0;
    const operatorsCounter = rawOrderStats ? rawOrderStats.operatori : 0;
    const commitmentsCounter = rawOrderStats ? rawOrderStats.committenti : 0;
    const worksCounter = rawOrderStats ? rawOrderStats.lavorazioni : 0;
    const documentCounter = rawOrderStats ? rawOrderStats.durataOreDocumenti : 0;
    const inspectionCounter = rawOrderStats ? rawOrderStats.durataOreSopralluoghi : 0;
    const annotationCounter = rawOrderStats ? rawOrderStats.durataOreAttivita : 0;
    const annotationsCounter = rawOrderStats ? rawOrderStats.durataOreAnnotazioni : 0;

    return {
        id,
        idee: ideasCounter,
        operatori: operatorsCounter,
        committenti: commitmentsCounter,
        lavorazioni: worksCounter,
        durataOreDocumenti: documentCounter,
        durataOreSopralluoghi: inspectionCounter,
        durataOreAttivita: annotationCounter,
        durataOreAnnotazioni: annotationsCounter,
    };
};
