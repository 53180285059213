// #region ::: IMPORT
import { useSelector } from "react-redux";

import { RiepilogoCommessa } from "@magoot-sdk/api/riepilogo/response";

import { TOption } from "@components-ui/Select";

import { actionOrderDeleteRequest } from "../actions/order/actionOrderDelete";
import { actionOrderTeamRequest } from "../actions/order/actionOrderTeam";
import { actionOrderUpdateRequest } from "../actions/order/actionOrderUpdate";
import { TStore } from "../reducers/declarations";
import { TOrder, TOrderBase, TOrderUser } from "../reducers/reducerOrder/declarations";
import { TUser } from "../reducers/reducerUsers/declarations";
import { selectorOrderSelected } from "../selectors/selectorOrder";
import { selectorOrderList, selectorOrderListLoading } from "../selectors/selectorsOrder";
import { selectorIsUpdatingOrder } from "../selectors/selectorsUI";
import { useDispatch } from "../utils/useDispatch";
import { useOrderID } from "./useOrderID";
import { useStudioID } from "./useStudioID";

// #endregion

// #region ::: HOOK USE ORDER LIST
export const useOrderList = (): {
    orderList: TOrder[];
    isLoading: boolean;
} => {
    const orderList = useSelector(selectorOrderList);
    const isLoading = useSelector(selectorOrderListLoading);

    return {
        orderList,
        isLoading,
    };
};

export const useCurrentOrderList = (): {
    filterListUtenti: TOrderUser[];
    filterListUserOptions: TOption[];
    isLoading: boolean;
} => {
    const { idOrder } = useOrderID();
    const orderList = useSelector(selectorOrderList);
    const isLoading = useSelector(selectorOrderListLoading);
    const filterList = orderList.find((order) => order.id === idOrder);

    const filterListUtenti = filterList ? filterList.utenti : [];
    const filterListUserOptions: TOption[] = filterList
        ? filterList?.utenti?.map((user) => {
              return {
                  value: user?.utente?.id,
                  name: `${user?.utente?.nome} ${user?.utente?.cognome}`,
              };
          })
        : [];

    return {
        filterListUtenti,
        filterListUserOptions,
        isLoading,
    };
};

// #endregion

// #region ::: HOOK USE ORDER DETAIL
export interface TParamsDeleteOrder {
    idStudioDeleteOrder: number;
    orderDeleteOrder: TOrder;
}

export const useOrderDetail = (): {
    order: RiepilogoCommessa;
    idStudio: number;
    onUpdateOrder: ({ orderUpdate }: { orderUpdate: TOrderBase }) => void;
    onDeleteOrder: ({ idStudioDeleteOrder, orderDeleteOrder }: TParamsDeleteOrder) => void;
    onUpdateTeam: (team: Partial<TUser> & TOrderUser[]) => void;
    isLoadingOrder: boolean;
} => {
    const { idStudio } = useStudioID();
    const { idOrder } = useOrderID();

    const dispatch = useDispatch();
    const order = useSelector((store: TStore) => selectorOrderSelected({ store, idOrder }));
    const isUpdating = useSelector(selectorIsUpdatingOrder);

    const onDeleteOrder = ({ idStudioDeleteOrder, orderDeleteOrder }: TParamsDeleteOrder): void =>
        dispatch(
            actionOrderDeleteRequest({ idStudio: idStudioDeleteOrder, order: orderDeleteOrder })
        );

    const onUpdateOrder = ({ orderUpdate }: { orderUpdate: TOrderBase }): void => {
        dispatch(actionOrderUpdateRequest({ idStudio, order: orderUpdate, idOrder }));
    };

    const onUpdateTeam = (team: Partial<TUser> & TOrderUser[]): void =>
        dispatch(actionOrderTeamRequest({ idStudio, idOrder, team }));

    return {
        order,
        onUpdateOrder,
        onDeleteOrder,
        onUpdateTeam,
        idStudio,
        isLoadingOrder: isUpdating,
    };
};
// #endregion
