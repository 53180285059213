import { NAME_APP } from "../../constants/general";
import { utilityGetFromLocalStorage } from "../cache/getFromLocalStorage";

export const utilityGetBrowserLocale = (): "it" | "en" => {
    const localState = utilityGetFromLocalStorage(NAME_APP);
    const localeCached = localState.locale;
    if (localeCached) return localeCached;

    const browserLocale = window.navigator.language;
    const localeFormatted = browserLocale.substring(0, 2);

    if (localeFormatted !== "it" && localeFormatted !== "en") return "it";

    return localeFormatted;
};
