import { TParamsApiDictionaryCostCenterCreate } from "../../../../api/dictionary/dictionaryCostCenterCreate/params";
import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryCostCenterCreate/response";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryCostCenterCreateFailure,
    TActionDictionaryCostCenterCreateRequest,
    TActionDictionaryCostCenterCreateSuccess,
} from "./declarations";

export const actionDictionaryCostCenterCreateRequest = (
    payload: TParamsApiDictionaryCostCenterCreate
): TActionDictionaryCostCenterCreateRequest => ({
    type: Actions.dictionaryCostCenterCreateRequest,
    payload,
});

export const actionDictionaryCostCenterCreateSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryCostCenterCreateSuccess => ({
    type: Actions.dictionaryCostCenterCreateSuccess,
    payload,
});

export const actionDictionaryCostCenterCreateFailure = (
    payload: TError
): TActionDictionaryCostCenterCreateFailure => ({
    type: Actions.dictionaryCostCenterCreateFailure,
    payload,
});
