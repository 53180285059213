// #region ::: IMPORT
import React, { FC, memo, useCallback, useEffect, useState } from "react";

import { Tag } from "@magoot-sdk/api/tag/response";
import { TDictionary } from "@magoot-sdk/redux-modules/reducers/reducerDictionaries/declarations";

import { WorkItem } from "./item";

// #endregion

interface Props {
    options: TDictionary[] | Tag[];
    onAutoComplete: (work: any) => void;
}

export const AutoComplete: FC<Props> = memo(({ options, onAutoComplete }: Props): JSX.Element => {
    const [focus, setFocus] = useState(0);

    const onKeyPress = useCallback(
        (event: KeyboardEvent): void => {
            if (options && options.length) {
                if (event.keyCode === 40) {
                    setFocus(focus === options.length - 1 ? 0 : focus + 1);
                    event.preventDefault();
                }
                if (event.keyCode === 38) {
                    setFocus(focus === 0 ? options.length - 1 : focus - 1);
                    event.preventDefault();
                }
                if (event.key === "Enter") {
                    event.preventDefault();
                    onAutoComplete(options[focus]);
                    setFocus(0);
                }
            }
        },
        [options, focus, onAutoComplete]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyPress, false);
        return (): void => document.removeEventListener("keydown", onKeyPress, false);
    }, [onKeyPress]);

    const renderOption = (option: TDictionary | Tag, index: number): JSX.Element => (
        <WorkItem option={option} onAutoComplete={onAutoComplete} key={index} setFocus={setFocus} />
    );
    return (
        <ul className='absolute left-0 z-50 max-h-[9rem] w-full overflow-y-auto rounded-lg bg-white p-8 text-left shadow-md'>
            {!!options && options.length > 0 && options.map(renderOption)}
        </ul>
    );
});
AutoComplete.displayName = "AutoComplete";
