import { createConfigurationHeader } from "./config";

const fetchGET = async (url: string): Promise<any> => {
    const headers = createConfigurationHeader();
    const response = await fetch(url, {
        method: "GET",
        headers,
    });

    return response && response.json ? response.json() : null;
};

export default fetchGET;
