// #region ::: IMPORT
import React, { Dispatch, FC, SetStateAction, memo } from "react";

import { translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";

import { UISwitch } from "@components-ui/Switch";

// #endregion

interface Props {
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
}

export const WorkCreateTask: FC<Props> = memo(({ isChecked, setIsChecked }) => {
    const locale = useLocale();

    const handleCheckbox = (): void => setIsChecked(!isChecked);

    return (
        <div className='flex items-center space-x-20'>
            <UISwitch isChecked={isChecked} onChange={handleCheckbox} />

            <div className='font-medium'>
                {translate({ locale, id: "view-order.work.add.task" })}
            </div>
        </div>
    );
});

WorkCreateTask.displayName = "WorkCreateTask";
