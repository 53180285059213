interface Translations {
    "view.operator-list.title": string;
}

interface ViewBusinessOperator {
    it: Translations;
    en: Translations;
}

export const translationsViewBusinessOperator: ViewBusinessOperator = {
    it: {
        "view.operator-list.title": "Operatori e imprese",
    },
    en: {
        "view.operator-list.title": "Edit Commitment",
    },
};

export type TTranslationsViewBusinessOperator = keyof Translations;
