import { createSelector } from "reselect";

import { TStore } from "../reducers/declarations";
import { Collapse, TDictionary } from "../reducers/reducerDictionaries/declarations";

export const selectorDictionaryOrderList = (
    store: TStore
): { [key: number]: TDictionary } | undefined => store.dictionaries?.order;

export const selectorDictionaryOperatorList = (
    store: TStore
): { [key: number]: TDictionary } | undefined => store.dictionaries?.operator;

export const selectorDictionaryWorkList = (
    store: TStore
): { [key: number]: TDictionary } | undefined => store.dictionaries?.work;

export const selectorDictionaryWorkListNoRerender = createSelector(
    [selectorDictionaryWorkList],
    (dictionaryWorkList) => {
        return dictionaryWorkList;
    }
);

export const selectorDictionaryTypeList = (
    store: TStore
): { [key: number]: TDictionary } | undefined => store.dictionaries?.documentType;

export const selectorDictionatyLandRegistryList = (
    store: TStore
): { [key: number]: TDictionary } | undefined => store.dictionaries?.landRegistry;

export const selectorDictionarySendingList = (
    store: TStore
): { [key: number]: TDictionary } | undefined => store.dictionaries?.sending;

export const selectorDictionaryCostCenterList = (
    store: TStore
): { [key: number]: TDictionary } | undefined => store.dictionaries?.costCenter;

export const selectorDictionaryOrderListNoRerender = createSelector(
    [selectorDictionaryOrderList],
    (dictionary) => {
        return dictionary;
    }
);

export const selectorDictionaryOpenModal = (store: TStore): boolean | undefined =>
    store.dictionaries?.fields?.open;

export const selectorDictionaryIsTask = (store: TStore): boolean | undefined =>
    store.dictionaries?.fields?.sectionDictionary === 5 && !!store.dictionaries?.fields.idTipoLav;

export const selectorDictionaryIdWork = (store: TStore): number | undefined =>
    store.dictionaries?.fields?.idWork;

export const selectorDictionaryIdTipoLav = (store: TStore): number | undefined =>
    store.dictionaries?.fields?.idTipoLav;

export const selectorDictionaryState = (store: TStore): "create" | "update" | undefined =>
    store.dictionaries?.fields?.state;

export const selectorDictionaryDescr = (store: TStore): string =>
    store.dictionaries?.fields?.descrEstesa ?? "";

export const selectorDictionaryTitoloAttivita = (store: TStore): string =>
    store.dictionaries?.fields?.descr ?? "";

export const selectorDictionaryIsloading = (store: TStore): boolean | undefined =>
    store.dictionaries?.loading;

export const selectorDictionaryCollapse = (store: TStore): Collapse[] | undefined =>
    store.dictionaries?.collapse;
