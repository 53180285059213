interface Translations {
    "view.municipal.practices.id": string;
    "view.municipal.practices.date": string;
    "view.municipal.practices.protocol": string;
    "view.municipal.practices.municipal.contact": string;
    "view.municipal.practices.note": string;
    "view.municipal.practices.descrProcedimento": string;
    "view.municipal.practices.descrLavori": string;
    "view.municipal.practices.modal.title": string;
    "view.municipal.practices.error": string;
    "view.municipal.practices.protocol.label": string;
    "view.municipal.practices.ref.label": string;
    "view.municipal.practices.note.label": string;
    "view.municipal.practices.descrProcedimento.label": string;
    "view.municipal.practices.descrLavori.label": string;
}

export interface ViewMunicipalPractices {
    it: Translations;
    en: Translations;
}

export const translationsViewMunicipalPractices: ViewMunicipalPractices = {
    it: {
        "view.municipal.practices.id": "Identificativo pratica",
        "view.municipal.practices.date": "Data presentazione",
        "view.municipal.practices.protocol": "Numero protocollo",
        "view.municipal.practices.municipal.contact": "Referente comunale",
        "view.municipal.practices.note": "Notes",
        "view.municipal.practices.descrProcedimento": "Descrizione procedimento",
        "view.municipal.practices.descrLavori": "Descrizione lavori",
        "view.municipal.practices.modal.title": "Modifica pratica comunale",
        "view.municipal.practices.error":
            "Errore generico nella modifica della pratica comunale, per favore riprova nuovamente. Se l’errore persiste contatta assistenza@magoot.it",
        "view.municipal.practices.protocol.label": "Inserire il numero di protocollo",
        "view.municipal.practices.ref.label": "Inserire il referente comunale",
        "view.municipal.practices.note.label": "Inserisci eventuali note",
        "view.municipal.practices.descrProcedimento.label": "Inserisci descrizione procedimento",
        "view.municipal.practices.descrLavori.label": "Inserisci descrizione lavori",
    },
    en: {
        "view.municipal.practices.id": "Identificativo pratica",
        "view.municipal.practices.date": "Data presentazione",
        "view.municipal.practices.protocol": "Numero protocollo",
        "view.municipal.practices.municipal.contact": "Referente comunale",
        "view.municipal.practices.note": "Notes",
        "view.municipal.practices.descrProcedimento": "Descrizione procedimento",
        "view.municipal.practices.descrLavori": "Descrizione lavori",
        "view.municipal.practices.modal.title": "Modifica pratica comunale",
        "view.municipal.practices.error":
            "Errore generico nella modifica della pratica comunale, per favore riprova nuovamente. Se l’errore persiste contatta assistenza@magoot.it",
        "view.municipal.practices.protocol.label": "Inserire il numero di protocollo",
        "view.municipal.practices.ref.label": "Inserire il referente comunale",
        "view.municipal.practices.note.label": "Inserisci eventuali note",
        "view.municipal.practices.descrProcedimento.label": "Inserisci descrizione procedimento",
        "view.municipal.practices.descrLavori.label": "Inserisci descrizione lavori",
    },
};

export type TTranslationsViewMunicipalPractices = keyof Translations;
