interface Translations {
    "view.invitation.title": string;
    "view.invitation.description": string;
    "view.invitation.invitation-accept": string;
}

interface ViewInvitation {
    it: Translations;
    en: Translations;
}

export const translationsViewInvitation: ViewInvitation = {
    it: {
        "view.invitation.title": "Benvenuto su Magoot",
        "view.invitation.description": "Inizia a collaborare con ",
        "view.invitation.invitation-accept": "Accetta invito come",
    },
    en: {
        "view.invitation.title": "Welcome on Magoot",
        "view.invitation.description": "Start collaborating with ",
        "view.invitation.invitation-accept": "Accept invitation as",
    },
};

export type TTranslationsViewInvitation = keyof Translations;
