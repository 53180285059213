// #region ::: IMPORT
import React, { FC, memo } from "react";

import {
    MenuSection,
    SectionDictionary,
    SubMenuSection,
} from "@magoot-sdk/declarations/general/urlParams";
import { useNavigation } from "@magoot-sdk/hooks/useNavigation";
import { TTranslation, translate } from "@magoot-sdk/i18n/translate";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useOrderID } from "@magoot-sdk/redux-modules/hooks/useOrderID";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import {
    utilityGetUrlViewDashboard,
    utilityGetUrlViewOrderSettings,
} from "@magoot-sdk/utils/Routes";

// #endregion

export interface Props {
    backgroundColor?: any;
    borderColor?: any;
    title?: TTranslation;
    body: TTranslation;
    size?: "xs" | "sm";
    link?: string;
    sectionDictionary?: SectionDictionary;
    bodyInnerHtml?: boolean;
}

export const UIInfo: FC<Props> = memo(
    ({
        backgroundColor = "white",
        borderColor = "borderInfo",
        title,
        body,
        size = "sm",
        link,
        sectionDictionary,
        bodyInnerHtml = false,
    }): JSX.Element => {
        const locale = useLocale();
        const { idStudio } = useStudioID();
        const { idOrder } = useOrderID();
        const navigateTo = useNavigation();
        const bodyTranslate = translate({ locale, id: body });
        const goDictionary = () => {
            const linkSubMenu = utilityGetUrlViewDashboard({
                idStudio,
                menuSelected: MenuSection.GestioneStudio,
                subMenuSelected: SubMenuSection.Dizionari,
                sectionDictionary,
            });
            navigateTo(linkSubMenu);
        };

        const goTeam = () => {
            const url = utilityGetUrlViewOrderSettings({
                idOrder,
                tabSelected: SubMenuSection.TeamInterno,
                idStudio,
            });

            navigateTo(url);
        };

        return (
            <div
                className={`alert z-50 justify-start rounded-lg shadow-xl bg-${backgroundColor} border-${borderColor}`}
            >
                <div className='flex flex-col items-start space-y-0'>
                    {!!title && (
                        <h3
                            className={`text-left font-bold ${
                                size === "sm" ? "text-sm" : "text-xs"
                            }`}
                        >
                            {translate({ locale, id: title })}
                        </h3>
                    )}
                    <p className={`text-left ${size === "sm" ? "text-sm" : "text-xs"}`}>
                        {!bodyInnerHtml ? (
                            <span>{bodyTranslate}&nbsp;</span>
                        ) : (
                            <span dangerouslySetInnerHTML={{ __html: bodyTranslate }} />
                        )}
                        {!!link && (
                            <span
                                className='link link-hover font-medium text-secondary'
                                onClick={sectionDictionary ? goDictionary : goTeam}
                            >
                                {link}
                            </span>
                        )}
                    </p>
                </div>
            </div>
        );
    }
);

UIInfo.displayName = "UIInfo";
