import fetchDELETE from "./fetchDELETE";
import fetchDOWNLOAD from "./fetchDOWNLOAD";
import fetchDOWNLOADPDF from "./fetchDOWNLOADPDF";
import fetchDOWNLOADZIP from "./fetchDOWNLOADZIP";
import fetchDownloadCsv from "./fetchDownloadCsv";
import fetchDownloadZipOrder from "./fetchDownloadZipOrder";
import fetchGET from "./fetchGET";
import fetchPOST from "./fetchPOST";
import fetchPUT from "./fetchPUT";
import fetchUPLOAD from "./fetchUPLOAD";

export interface TFetch {
    get: (url: string) => Promise<any>;
    post: (url: string, params: any) => Promise<any>;
    delete: (url: string, params?: any) => Promise<any>;
    put: (url: string, params?: any) => Promise<any>;
    upload: (url: string, params?: any) => Promise<any>;
    download: (url: string) => Promise<any>;
    downloadZip: (url: string, params: any) => Promise<any>;
    downloadPdf: (url: string, params: any) => Promise<any>;
    downloadCsv: (url: string, params: any) => Promise<any>;
    downloadZipOrder: (url: string) => Promise<any>;
}

export const fetch: TFetch = {
    get: fetchGET,
    post: fetchPOST,
    delete: fetchDELETE,
    put: fetchPUT,
    upload: fetchUPLOAD,
    download: fetchDOWNLOAD,
    downloadZip: fetchDOWNLOADZIP,
    downloadPdf: fetchDOWNLOADPDF,
    downloadCsv: fetchDownloadCsv,
    downloadZipOrder: fetchDownloadZipOrder,
};
