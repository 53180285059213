// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPlaneArrival, faPlaneDeparture } from "@fortawesome/pro-light-svg-icons";
import React, { FC, memo } from "react";
import { useSelector } from "react-redux";

import { useOrderDetail } from "@magoot-sdk/redux-modules/hooks/hooksOrder";
import { useOrderID } from "@magoot-sdk/redux-modules/hooks/useOrderID";
import { useWorkID } from "@magoot-sdk/redux-modules/hooks/useWorkId";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import { selectorWorkList } from "@magoot-sdk/redux-modules/selectors/selectorWork";
import { utilityFormatDate } from "@magoot-sdk/utils/time/formatDate";

import { UIBadge } from "@components-ui/Badge";
import { UIIcon } from "@components-ui/Icon";

export const WorksSummary: FC = memo((): JSX.Element => {
    const { idOrder } = useOrderID();
    const { idWork } = useWorkID();

    const currentWorks = useSelector((store: TStore) => selectorWorkList({ store, idOrder }));
    const { order } = useOrderDetail();

    return (
        <>
            <div className='flex-col items-center justify-between'>
                <p className='text-left text-sm font-medium text-secondary'>COMMESSA</p>

                <section className='flex items-center justify-start gap-32'>
                    {order?.dataInizio && (
                        <div className='flex  items-center gap-12'>
                            <UIIcon
                                size='xl'
                                svgIcon={faPlaneDeparture as IconProp}
                                title='inizio commessa'
                                color='#5300A3'
                            />
                            <div className='text-left leading-none'>
                                <p className='text-tundora-400 text-sm font-regular'>Inizio</p>
                                <p className='font-medium'>{utilityFormatDate(order.dataInizio)}</p>
                            </div>
                        </div>
                    )}
                    {order?.dataScadenza && (
                        <div className='flex flex-1 items-center gap-12'>
                            <UIIcon
                                size='xl'
                                svgIcon={faPlaneArrival as IconProp}
                                title='fine commessa'
                                color='#5300A3'
                            />
                            <div className='text-left leading-none'>
                                <p className='text-tundora-400 text-sm font-regular'>
                                    Fine prevista
                                </p>
                                <p className='font-medium'>
                                    {utilityFormatDate(order.dataScadenza)}
                                </p>
                            </div>
                        </div>
                    )}
                </section>
            </div>

            <section
                className={
                    currentWorks && currentWorks?.length > 0
                        ? "divide-y mt-12 text-sm bg-white max-h-[30rem] overflow-y-auto border p-12"
                        : "hidden"
                }
            >
                {currentWorks &&
                    currentWorks?.length > 0 &&
                    currentWorks?.map((work) => (
                        <div
                            className='flex items-center gap-24 py-8 justify-between'
                            key={work.id}
                        >
                            <div className='font-medium flex-1 text-left'>
                                {work.id === idWork && (
                                    <UIBadge
                                        text='attuale'
                                        backgroundColor='secondaryDarkLight'
                                        color='secondary'
                                    />
                                )}
                                {work.descr}
                            </div>

                            <div className='flex items-center gap-8'>
                                <UIIcon
                                    size='lg'
                                    svgIcon={faPlaneDeparture as IconProp}
                                    title='inizio fase'
                                    color='#5300A3'
                                />
                                <div className='text-left min-w-[100px]'>
                                    {work.dataInizio ? utilityFormatDate(work.dataInizio) : "-"}
                                </div>
                            </div>

                            <div className='flex items-center gap-8'>
                                <UIIcon
                                    size='lg'
                                    svgIcon={faPlaneArrival as IconProp}
                                    title='fine fase'
                                    color='#5300A3'
                                />
                                <div className='text-left min-w-[100px]'>
                                    {work.dataScadenza ? utilityFormatDate(work.dataScadenza) : "-"}
                                </div>
                            </div>
                        </div>
                    ))}
            </section>
        </>
    );
});
WorksSummary.displayName = "WorksSummary";
