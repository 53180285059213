import { Actions } from "../../utils/Actions";
import { TReducerProtocol, TReducerProtocolActions } from "./declarations";

export const reducerProtocols = (
    prevStore: TReducerProtocol = {},
    action: TReducerProtocolActions
): TReducerProtocol => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.protocolListByWorkSuccess:
            newStore[action.payload.idWork] = action.payload.protocols;
            return newStore;
        case Actions.protocolCreateSuccess:
            newStore[action.payload.idWork] = {
                ...newStore[action.payload.idWork],
                [action.payload.protocol.id]: action.payload.protocol,
            };
            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
