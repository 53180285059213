import { Actions } from "../../utils/Actions";
import { TReducerAnnotations, TReducerAnnotationsActions } from "./declarations";

export const reducerAnnotations = (
    prevStore: TReducerAnnotations = {
        value: undefined,
        loading: false,
    },
    action: TReducerAnnotationsActions
): TReducerAnnotations => {
    let newStore = { ...prevStore };

    switch (action.type) {
        case Actions.annotationListRequest:
        case Actions.annotationCreateRequest:
        case Actions.annotationUpdateRequest:
            // case Actions.annotationDeleteRequest:
            return {
                value: { ...newStore.value },
                loading: true,
                loadingDocument: newStore.loadingDocument,
            };
        case Actions.annotationListSuccess:
            const storeList: TReducerAnnotations = {
                value: {
                    [action.payload.idOrder]: {
                        ...action.payload.annotations,
                    },
                },
                loading: false,
            };
            newStore = { ...storeList };
            return newStore;
        case Actions.annotationCreateSuccess:
            let storeCreate: TReducerAnnotations;

            if (newStore.value) {
                storeCreate = {
                    value: {
                        ...newStore.value,
                        [action.payload.idCommessa]: {
                            ...newStore.value[action.payload.idCommessa],
                            [action.payload.id]: {
                                ...newStore.value[action.payload.idCommessa][action.payload.id],
                                ...action.payload,
                            },
                        },
                    },
                    loading: false,
                    loadingDocument: newStore.loadingDocument,
                };
                newStore = { ...storeCreate };
            }
            return newStore;
        case Actions.annotationUpdateSuccess:
            let storeUpdate: TReducerAnnotations;

            if (newStore.value) {
                storeUpdate = {
                    value: {
                        ...newStore.value,
                        [action.payload.idCommessa]: {
                            ...newStore.value[action.payload.idCommessa],
                            [action.payload.id]: {
                                ...newStore.value[action.payload.idCommessa][action.payload.id],
                                ...action.payload,
                            },
                        },
                    },
                    loading: false,
                    loadingDocument: newStore.loadingDocument,
                };
                newStore = { ...storeUpdate };
            }
            return newStore;
        case Actions.annotationDeleteSuccess:
            if (newStore.value)
                delete newStore.value[action.payload.idOrder][action.payload.idAnnotation];

            return {
                value: { ...newStore.value },
                loading: false,
                loadingDocument: newStore.loadingDocument,
            };
        case Actions.createLogoutSuccess:
            return {
                value: undefined,
                loading: false,
                loadingDocument: false,
            };
        case Actions.annotationListLoading:
            return {
                value: { ...newStore.value },
                loading: newStore.loading,
                loadingDocument: action.payload.loading,
            };
        default:
            return prevStore;
    }
};
