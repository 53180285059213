// #region ::: IMPORT
import React, { FC, memo, useEffect } from "react";
import { useSelector } from "react-redux";

import { translate } from "@magoot-sdk/i18n/translate";
import { actionHideToast } from "@magoot-sdk/redux-modules/actions/general/actionToast";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import {
    selectorToastIsVisible,
    selectorToastMessage,
    selectorToastTimer,
    selectorToastTranslateMessage,
    selectorToastVariant,
} from "@magoot-sdk/redux-modules/selectors/selectorToast";
import { useDispatch } from "@magoot-sdk/redux-modules/utils/useDispatch";

// #endregion

export const Toast: FC = memo((): JSX.Element => {
    const dispatch = useDispatch();
    const toastMessage: any = useSelector(selectorToastMessage);
    const locale = useLocale();
    const toastVariant = useSelector(selectorToastVariant);
    const isVisible = useSelector(selectorToastIsVisible);
    const intlFormatter = useSelector(selectorToastTranslateMessage);
    const timer = useSelector(selectorToastTimer);

    const text = intlFormatter ? translate({ locale, id: toastMessage }) : toastMessage;

    useEffect(() => {
        if (isVisible && toastMessage) {
            setTimeout(() => {
                dispatch(actionHideToast());
            }, timer);
        }
    }, [dispatch, isVisible, toastMessage]);

    if (!text && !isVisible) return <></>;

    return (
        <>
            {isVisible && (
                <div
                    className={`
      alert fixed top-8 -translate-x-2/4 left-2/4 z-50 m-0 flex w-toast flex-row justify-between rounded-lg shadow-lg
    ${toastVariant === "error" ? "alert-error" : ""}
    ${toastVariant === "success" ? "alert-success" : ""}
    ${toastVariant === "info" ? "alert-info" : ""}
    `}
                >
                    {text}
                </div>
            )}
        </>
    );
});
Toast.displayName = "Toast";
