// #region ::: IMPORT
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCalendar, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import it from "date-fns/locale/it";
import { isDate } from "lodash";
import React, { FC, Ref, forwardRef, memo } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ReactInputDateMask from "react-input-date-mask";

import { UIIcon } from "@components-ui/Icon";

import "./theme.css";

// #endregion

registerLocale("it", it);

interface InputProps {
    label?: string;
    value?: string | Date;
    onClick?: () => void;
    onChangeMask: (date: Date | null) => void;
    size?: "sm" | "md" | "lg";
    isRequired?: boolean;
    tooltipText?: string;
    disabled?: boolean;
}

const DatePickerCustomInput = forwardRef(
    (
        {
            label,
            isRequired,
            onClick,
            value,
            onChangeMask,
            size,
            tooltipText,
            disabled,
        }: InputProps,
        ref: Ref<HTMLInputElement>
    ): JSX.Element => {
        const handleInputOnChange = (date: string) => {
            const splitDate = date.split("/");
            const formatDate = new Date(+splitDate[2], +splitDate[1] - 1, +splitDate[0]);

            const isValidDate = isDate(formatDate) && formatDate.toString() !== "Invalid Date";

            if (isValidDate) onChangeMask(formatDate);
            if (!isValidDate && splitDate.toString() === "dd,mm,yyyy") onChangeMask(null);
        };

        return (
            <div className='w-full'>
                {label && (
                    <section className='w-full flex justify-end items-center'>
                        <label className='label flex-1'>
                            <span className='label-text font-bold'>
                                {label} {isRequired && <strong className='text-error'>*</strong>}
                            </span>
                        </label>
                        {tooltipText && (
                            <div className='tooltip tooltip-bottom w-auto' data-tip={tooltipText}>
                                <UIIcon svgIcon={faInfoCircle as IconProp} />
                            </div>
                        )}
                    </section>
                )}

                <div className='form-control'>
                    <div className='input-group'>
                        <ReactInputDateMask
                            mask='dd/mm/yyyy'
                            value={value}
                            onChange={handleInputOnChange}
                            disabled={disabled}
                            className={`input input-bordered w-full focus:outline-offset-negativeSmall focus:outline-secondaryLite
            ${size === "sm" ? `input-sm` : ""} 
            ${size === "md" ? `input-md` : ""}  
            ${size === "lg" ? `input-lg` : ""} 
            `}
                        />

                        <div
                            className={`
        btn btn-square bg-tundora-100 border-l-0 hover:border-tundora-200 border-tundora-200 hover:bg-tundora-200
        ${size === "sm" ? `btn-sm` : ""} 
        ${size === "md" ? `btn-md` : ""}  
        ${size === "lg" ? `btn-lg` : ""}
        `}
                            onClick={onClick}
                        >
                            <UIIcon svgIcon={faCalendar as IconProp} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
DatePickerCustomInput.displayName = "DatePickerCustomInput";

export interface Props {
    label?: string;
    value: Date | null;
    onChange: (date: Date | null) => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
    size?: "sm" | "md" | "lg";
    maxDate?: Date;
    minDate?: Date;
    placement?: "bottom-end" | "top-start";
    isRequired?: boolean;
    fieldName?: string;
    info?: string;
    tooltipText?: string;
    disabled?: boolean;
}

export const UIDatePicker: FC<Props> = memo(
    ({
        label,
        onChange,
        onBlur,
        value,
        size = "md",
        maxDate,
        minDate,
        placement = "top-start",
        isRequired,
        fieldName,
        info,
        tooltipText,
        disabled,
    }: Props): JSX.Element => {
        const handleChange = (date: Date | null): void => {
            if (!date) {
                onChange(null);
            }

            if (date) {
                const dateFormatted = new Date(date);
                const offset = date.getTimezoneOffset();
                if (offset < 0) dateFormatted.setHours(12, 0, 0);
                onChange(new Date(dateFormatted.toISOString().substring(0, 10)));
            }
        };

        return (
            <>
                <DatePicker
                    locale='it'
                    dateFormat='dd/MM/yyyy'
                    selected={value}
                    onChange={handleChange}
                    onBlur={onBlur}
                    minDate={minDate}
                    maxDate={maxDate}
                    popperProps={{
                        placement,
                    }}
                    name={fieldName}
                    disabled={disabled}
                    customInput={
                        <DatePickerCustomInput
                            label={label}
                            onChangeMask={handleChange}
                            value={value || new Date()}
                            size={size}
                            isRequired={isRequired}
                            tooltipText={tooltipText}
                            disabled={disabled}
                        />
                    }
                />
                {info && (
                    <label className='label'>
                        <span className='label-text-alt text-error text-left'>{info}</span>
                    </label>
                )}
            </>
        );
    }
);

UIDatePicker.displayName = "UIDatePicker";
