// #region ::: IMPORT
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import { reducerAddressBook } from "./reducerAddressBook";
import { reducerAnnotations } from "./reducerAnnotations";
import { reducerAppReady } from "./reducerAppReady";
import { reducerAuth } from "./reducerAuth";
import { reducerCommitments } from "./reducerCommitments";
import { reducerCustomers } from "./reducerCustomers";
import { reducerDictionaries } from "./reducerDictionaries";
import { reducerDocuments } from "./reducerDocuments";
import { reducerDocumentsWork } from "./reducerDocumentsWork";
import { reducerIdeas } from "./reducerIdeas";
import { reducerInspections } from "./reducerInspections";
import { reducerLocale } from "./reducerLocale";
import { reducerMail } from "./reducerMail";
import { reducerNote } from "./reducerNote";
import { reducerNotificationConfig } from "./reducerNotificationConfig";
import { reducerNotifications } from "./reducerNotifications";
import { reducerOperators } from "./reducerOperators";
import { reducerOrders } from "./reducerOrder";
import { reducerPayments } from "./reducerPayments";
import { reducerPopup } from "./reducerPopup";
import { reducerProtocols } from "./reducerProtocols";
import { reducerSignedFiles } from "./reducerSignedFiles";
import { reducerStatistics } from "./reducerStatistics";
import { reducerStudio } from "./reducerStudio";
import { reducerSubscription } from "./reducerSubscription";
import { reducerTag } from "./reducerTag";
import { reducerTask } from "./reducerTask";
import { reducerTerritorial } from "./reducerTerritorial";
import { reducerToast } from "./reducerToast";
import { reducerUI } from "./reducerUI";
import { reducerUsers } from "./reducerUsers";
import { reducerUtentiDiritti } from "./reducerUtentiDiritti";
import { reducerWorkSummary } from "./reducerWorkSummary";
import { reducerWorks } from "./reducerWorks";

// #endregion

export const reducers = (history) =>
    combineReducers({
        router: connectRouter(history),
        annotations: reducerAnnotations,
        appReady: reducerAppReady,
        auth: reducerAuth,
        commitments: reducerCommitments,
        customers: reducerCustomers,
        dictionaries: reducerDictionaries,
        documents: reducerDocuments,
        documentsWork: reducerDocumentsWork,
        ideas: reducerIdeas,
        tag: reducerTag,
        inspections: reducerInspections,
        locale: reducerLocale,
        mail: reducerMail,
        notes: reducerNote,
        operators: reducerOperators,
        orders: reducerOrders,
        payments: reducerPayments,
        popup: reducerPopup,
        protocols: reducerProtocols,
        signedFiles: reducerSignedFiles,
        studios: reducerStudio,
        subscription: reducerSubscription,
        tasks: reducerTask,
        toast: reducerToast,
        ui: reducerUI,
        users: reducerUsers,
        works: reducerWorks,
        territorial: reducerTerritorial,
        addressBook: reducerAddressBook,
        notificationConfig: reducerNotificationConfig,
        notifications: reducerNotifications,
        workSummary: reducerWorkSummary,
        statistics: reducerStatistics,
        diritti: reducerUtentiDiritti,
    });
