// #region ::: IMPORT
import React, { ChangeEvent, FC, memo } from "react";

// #endregion

export interface Props {
    isChecked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    name?: string;
}

export const UISwitch: FC<Props> = memo(
    ({ isChecked, onChange, disabled, name }): JSX.Element => (
        <input
            name={name}
            type='checkbox'
            className='toggle toggle-primary transition-all duration-300 delay-75'
            onChange={onChange}
            checked={isChecked}
            disabled={disabled}
        />
    )
);

UISwitch.displayName = "UISwitch";
