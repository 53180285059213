import { TResponseApiDictionary } from "../../../../api/dictionary/dictionaryCostCenterCreate/response";
import { TParamsApiDictionaryDocumentTypeEdit } from "../../../../api/dictionary/dictionaryDocumentTypeEdit/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionDictionaryDocumentTypeEditFailure,
    TActionDictionaryDocumentTypeEditRequest,
    TActionDictionaryDocumentTypeEditSuccess,
} from "./declarations";

export const actionDictionaryDocumentTypeEditRequest = (
    payload: TParamsApiDictionaryDocumentTypeEdit
): TActionDictionaryDocumentTypeEditRequest => ({
    type: Actions.dictionaryDocumentTypeEditRequest,
    payload,
});

export const actionDictionaryDocumentTypeEditSuccess = (
    payload: TResponseApiDictionary
): TActionDictionaryDocumentTypeEditSuccess => ({
    type: Actions.dictionaryDocumentTypeEditSuccess,
    payload,
});

export const actionDictionaryDocumentTypeEditFailure = (
    payload: TError
): TActionDictionaryDocumentTypeEditFailure => ({
    type: Actions.dictionaryDocumentTypeEditFailure,
    payload,
});
