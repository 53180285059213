interface Translations {
    "view.customer-list.title": string;
    "view.customer-list.header.customer": string;
    "view.customer-list.button.import": string;
    "view.customer-list.label.no-customers": string;
    "view.customer-list.label.search-customer": string;
}

interface ViewCustomerList {
    it: Translations;
    en: Translations;
}

export const translationsViewCustomerList: ViewCustomerList = {
    it: {
        "view.customer-list.title": "Importa soggetto",
        "view.customer-list.header.customer": "Soggetto",
        "view.customer-list.button.import": "Importa",
        "view.customer-list.label.no-customers": "Nessun soggetto presente per questa commessa",
        "view.customer-list.label.search-customer": "Cerca soggetto",
    },
    en: {
        "view.customer-list.title": "Import customer",
        "view.customer-list.header.customer": "Customer",
        "view.customer-list.button.import": "Import",
        "view.customer-list.label.no-customers": "No customers for this order",
        "view.customer-list.label.search-customer": "Search customer",
    },
};

export type TTranslationsViewCustomerList = keyof Translations;
