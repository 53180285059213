export const utilityCheckExpirationDateUTC = (timeUTC?: Date): boolean => {
    if (!timeUTC) return false;
    const timestamp = new Date(timeUTC);

    return timestamp.getTime() < new Date().getTime();
};

export const utilityCheckWithdrawalAttempts = (timeUTC?: Date): boolean => {
    if (!timeUTC) return false;
    const timestamp = new Date(timeUTC);
    const day = timestamp.getDate() + 3;
    const lastDay = new Date(timeUTC).setDate(day);

    return new Date().getTime() <= lastDay && new Date().getTime() >= timestamp.getTime();
};
