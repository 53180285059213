interface Translations {
    "view.subscription.trial.order.msg": string;
    "view.subscription.detail.plan": string;
    "view.subscription.detail.user": string;
    "view.subscription.detail.billing": string;
    "view.subscription.detail.cost": string;
    "view.subscription.detail.renewal": string;
    "view.subscription.detail.subscription": string;
    "view.subscription.detail.suspende": string;
    "view.subscription.detail.modal.title": string;
    "view.subscription.detail.modal.body": string;
    "view.subscription.detail.button.upgrade": string;
    "view.subscription.detail.button.expired": string;
    "view.subscription.detail.status.not.foud": string;
    "view.subscription.detail.trial.title": string;
    "view.subscription.detail.trial": string;
    "view.subscription.detail.expired.title": string;
    "view.subscription.detail.expired": string;
    "view.subscription.detail.expiring.title": string;
    "view.subscription.detail.canceled.title": string;
    "view.subscription.detail.canceled": string;
    "view.subscription.detail.expiring": string;
    "view.subscription.suspend.subscription": string;
    "view.subscription.change": string;
    "view.subscription.delete": string;
    "view.subscription.billing.deadline": string;
    "view.subscription.billing.data.name": string;
    "view.subscription.billing.data.address": string;
    "view.subscription.billing.data.payment": string;
    "view.subscription.billing.data.modal.title": string;
    "view.subscription.billing.data.payment.modal.title": string;
    "view.subscription.upgrade.title": string;
    "view.subscription.upgrade.no.products": string;
    "view.subscription.upgrade.see.all": string;
    "view.subscription.upgrade.not.see.all": string;
    "view.subscription.upgrade.subtitle.part.1": string;
    "view.subscription.upgrade.subtitle.part.2": string;
    "view.subscription.upgrade.team.size": string;
    "view.subscription.upgrade.billing": string;
    "view.subscription.upgrade.button.back": string;
    "view.subscription.upgrade.button.checkout": string;
    "view.subscription.remove.users": string;
    "view.subscription.remove.tooltip": string;
    "view.subscription.checkout.stripe.text": string;
    "view.subscription.checkout.button.back": string;
    "view.subscription.checkout.button.ok": string;
    "view.subscription.checkout.button.downgrade": string;
    "view.subscription.checkout.see.more": string;
    "view.subscription.checkout.trial.text": string;
    "view.subscription.checkout.not.trial.text.part.1": string;
    "view.subscription.checkout.not.trial.text.part.2": string;
    "view.subscription.checkout.not.trial.balance.text.part.2": string;
    "view.subscription.checkout.button.upgrade": string;
    "view.subscription.checkout.button.renews": string;
    "view.subscription.checkout.button.current.plan": string;
    "view.subscription.empty.method.payment": string;
    "view.subscription.typ": string;
    "view.subscription.update.typ": string;
    "view.subscription.typ.button": string;
    "view.subscription.error.title": string;
    "view.subscription.error.description": string;
    "view.subscription.error.3d": string;
    "view.subscription.error.button": string;
    "view.subscription.delete.error": string;
    "view.subscription.delete.success": string;
    "view.subscription.change.payment.success": string;
    "view.subscription.delete.payment.modal.title": string;
    "view.subscription.delete.payment.modal.description": string;
    "view.subscription.change.payment.delete.ok": string;
    "view.subscription.change.payment.delete.ko": string;
    "view.subscription.delete.payment.modal.button": string;
    "view.subscription.empty.billing": string;
}

export interface ViewSubscriptionDetail {
    it: Translations;
    en: Translations;
}

export const translationsViewSubscription: ViewSubscriptionDetail = {
    it: {
        "view.subscription.trial.order.msg":
            "Con il tuo piano Trial disponi di 3 commesse totali, 3 utenti e 10 collaboratori esterni",
        "view.subscription.detail.plan": "Il più richiesto",
        "view.subscription.detail.user": "Utenti",
        "view.subscription.detail.billing": "Fatturazione",
        "view.subscription.detail.cost": "Costo",
        "view.subscription.detail.subscription": "Abbonamento",
        "view.subscription.detail.renewal": "Rinnovo automatico:",
        "view.subscription.detail.suspende": `Rinnovo automatico disabilitato, l'abbonamento sarò attivo fino al:`,
        "view.subscription.detail.modal.title": `Conferma l'annullamento`,
        "view.subscription.detail.modal.body": `Se confermi e termini adesso il tuo abbonamento, potrai continuare ad accedervi fino al giorno`,
        "view.subscription.detail.button.upgrade": `Cambia piano`,
        "view.subscription.detail.button.expired": `Attiva abbonamento`,
        "view.subscription.detail.status.not.foud": `abbonamento non trovato, contatta l'assistenza per risolvere il problema`,
        "view.subscription.detail.trial.title": "Magoot Trial",
        "view.subscription.detail.trial": "Magoot Trial ti permette di utilizzare Magoot fino al",
        "view.subscription.detail.expired.title": "Magoot Scaduto",
        "view.subscription.detail.expired": `Il tuo abbonamento è scaduto in data`,
        "view.subscription.detail.expiring.title": "Magoot Trial",
        "view.subscription.detail.canceled.title": "Abbonamento Magoot Annullato",
        "view.subscription.detail.canceled":
            "Riattiva il tuo abbonamento per poter utilizzare le fantastiche funzionalità di Magoot",
        "view.subscription.detail.expiring": "Il tuo abbonamento di prova sarà valido fino al",
        "view.subscription.suspend.subscription": "Annulla abbonamento",
        "view.subscription.change": "Modifica",
        "view.subscription.delete": "Elimina",
        "view.subscription.billing.deadline": "Scadenza",
        "view.subscription.billing.data.name": "Nome azienda",
        "view.subscription.billing.data.address": "Dati di fatturazione",
        "view.subscription.billing.data.payment": "Metodo di pagamento",
        "view.subscription.billing.data.modal.title": "Dati fatturazione",
        "view.subscription.billing.data.payment.modal.title": "Dati carta di credito",
        "view.subscription.upgrade.title": "Scegli il piano più adatto al tuo Studio",
        "view.subscription.upgrade.no.products": "Nessun prodotto disponibile!",
        "view.subscription.upgrade.see.all": "Scopri tutte le funzionalità",
        "view.subscription.upgrade.not.see.all": "Nascondi tutte le funzionalità",
        "view.subscription.upgrade.subtitle.part.1": "Grazie per aver scelto Magoot! Hai ancora",
        "view.subscription.upgrade.subtitle.part.2": "di prova gratuita",
        "view.subscription.upgrade.team.size": "Dimensioni studio",
        "view.subscription.upgrade.billing": "Fatturazione:",
        "view.subscription.upgrade.button.back": "Indietro",
        "view.subscription.upgrade.button.checkout": "continua",
        "view.subscription.remove.users":
            "Seleziona gli utenti che non fanno più parte dello Studio",
        "view.subscription.remove.tooltip": "Il proprietario dello studio non può essere rimosso",
        "view.subscription.checkout.stripe.text":
            "*Pagamenti protetti e sicuri tramite la piattaforma Stripe",
        "view.subscription.checkout.button.back": "Annulla",
        "view.subscription.checkout.button.ok": "Effettua pagamento",
        "view.subscription.checkout.button.downgrade": "Cambia piano",
        "view.subscription.checkout.see.more": "...e molto altro",
        "view.subscription.checkout.button.upgrade": "Seleziona",
        "view.subscription.checkout.button.renews": "Rinnova",
        "view.subscription.checkout.button.current.plan": "piano attuale",
        "view.subscription.checkout.trial.text":
            "Al fine di fornire un servizio continuativo l’abbonamento verrà rinnovato automaticamente il",
        "view.subscription.checkout.not.trial.text.part.1": `Le modifiche al tuo abbonamento avverranno immediatamente. Al fine di fornire un servizio continuativo l’abbonamento verrà rinnovato automaticamente il`,
        "view.subscription.checkout.not.trial.text.part.2":
            "secondo le tariffe del nuovo abbonamento.",
        "view.subscription.checkout.not.trial.balance.text.part.2": `Al rinnovo del prossimo abbonamento verrà detratto il credito accumulato.`,
        "view.subscription.empty.method.payment":
            "Per procedere al pagamento è necessario inserire un metodo di pagamento",
        "view.subscription.typ": "Pagamento avvenuto con successo!",
        "view.subscription.update.typ": "Abbonamento aggiornato con successo!",
        "view.subscription.typ.button": "Visualizza i dati del tuo abbonamento",
        "view.subscription.error.title": "Ooops pagamento non riuscito!",
        "view.subscription.error.description": `Qualcosa è andato storto... Controlla che i dati della carta siano corretti o che non sia scaduta.
    Se l’errore persiste contatta assistenza@magoot.it`,
        "view.subscription.error.3d": `Metodo di pagamento rifiutato`,
        "view.subscription.error.button": "controlla i dati",
        "view.subscription.delete.error": `C'è stato un problema con la disattivazione del rinnovo, per favore riprova. Se l’errore persiste contatta assistenza@magoot.it`,
        "view.subscription.delete.success": "Rinnovo disattivato con successo!",
        "view.subscription.change.payment.success": "Metodo di pagamento modificato correttamente!",
        "view.subscription.delete.payment.modal.title": "Eliminazione carta",
        "view.subscription.delete.payment.modal.description":
            "Sei sicuro di voler eliminare la carta associata al tuo abbonamento?",
        "view.subscription.change.payment.delete.ok":
            "Metodo di pagamento eliminato correttamente!",
        "view.subscription.change.payment.delete.ko": `C'è stato un problema con l'eliminazione della tua carta, per favore riprova. Se l’errore persiste contatta assistenza@magoot.it`,
        "view.subscription.delete.payment.modal.button": "elimina",
        "view.subscription.empty.billing": "Indirizzo non presente",
    },
    en: {
        "view.subscription.trial.order.msg":
            "Con il tuo piano Trial disponi di 3 commesse totali, 3 utenti e 10 collaboratori esterni",
        "view.subscription.detail.plan": "Il più richiesto",
        "view.subscription.detail.user": "Utenti",
        "view.subscription.detail.billing": "Fatturazione",
        "view.subscription.detail.subscription": "Abbonamento",
        "view.subscription.detail.cost": "Costo",
        "view.subscription.detail.renewal": "Rinnovo automatico:",
        "view.subscription.detail.suspende": `Rinnovo automatico disabilitato, l'abbonamento sarò attivo fino al:`,
        "view.subscription.detail.modal.title": `Conferma l'annullamento`,
        "view.subscription.detail.modal.body": `Se confermi e termini adesso il tuo abbonamento, potrai continuare ad accedervi fino al giorno`,
        "view.subscription.detail.button.upgrade": `Cambia piano`,
        "view.subscription.detail.button.expired": `Attiva abbonamento`,
        "view.subscription.detail.status.not.foud": `Abbonamento non trovato, contatta l'assistenza per risolvere il problema`,
        "view.subscription.detail.trial.title": "Magoot Trial",
        "view.subscription.detail.trial": "Magoot Trial ti permette di utilizzare Magoot per.",
        "view.subscription.detail.expired.title": "Magoot Scaduto",
        "view.subscription.detail.expired": `Abbonamento a Magoot scaduto, rinnova il piano o fai l'upgrade per poter tornare ad utilizzare la piattaforma`,
        "view.subscription.detail.expiring.title": "Magoot Trial",
        "view.subscription.detail.canceled.title": "Abbonamento Magoot Annullato",
        "view.subscription.detail.canceled":
            "Riattiva il tuo abbonamento per poter utilizzare le fantastiche funzionalità di Magoot",
        "view.subscription.detail.expiring": "Il tuo abbonamento di prova sarà valido fino al",
        "view.subscription.suspend.subscription": "Annulla abbonamento",
        "view.subscription.change": "Modifica",
        "view.subscription.delete": "Elimina",
        "view.subscription.billing.deadline": "Scadenza",
        "view.subscription.billing.data.name": "Nome azienda",
        "view.subscription.billing.data.address": "Indirizzo di fatturazione",
        "view.subscription.billing.data.payment": "Metodo di pagamento",
        "view.subscription.billing.data.modal.title": "Dati fatturazione",
        "view.subscription.billing.data.payment.modal.title": "Dati carta di credito",
        "view.subscription.upgrade.title": "Scegli il piano più adatto al tuo Studio",
        "view.subscription.upgrade.no.products": "Nessun prodotto disponibile!",
        "view.subscription.upgrade.see.all": "Scopri tutte le funzionalità",
        "view.subscription.upgrade.not.see.all": "Nascondi tutte le funzionalità",
        "view.subscription.upgrade.subtitle.part.1": "Grazie per aver scelto Magoot! Hai ancora",
        "view.subscription.upgrade.subtitle.part.2": "di prova gratuita",
        "view.subscription.upgrade.team.size": "Dimensioni studio",
        "view.subscription.upgrade.billing": "Fatturazione",
        "view.subscription.upgrade.button.back": "Indietro",
        "view.subscription.upgrade.button.checkout": "continua",
        "view.subscription.remove.users":
            "Seleziona gli utenti che non fanno più parte dello Studio",
        "view.subscription.remove.tooltip": "Il proprietario dello studio non può essere rimosso",
        "view.subscription.checkout.stripe.text":
            "*Pagamenti protetti e sicuri tramite la piattaforma Stripe",
        "view.subscription.checkout.button.back": "Annulla",
        "view.subscription.checkout.button.ok": "Effettua pagamento",
        "view.subscription.checkout.button.downgrade": "Cambia piano",
        "view.subscription.checkout.see.more": "...e molto altro",
        "view.subscription.checkout.button.upgrade": "Seleziona",
        "view.subscription.checkout.button.renews": "Rinnova",
        "view.subscription.checkout.button.current.plan": "piano attuale",
        "view.subscription.checkout.trial.text":
            "Al fine di fornire un servizio continuativo l’abbonamento verrà rinnovato automaticamente il",
        "view.subscription.checkout.not.trial.text.part.1": `Le modifiche al tuo abbonamento avverranno immediatamente. Al fine di fornire un servizio continuativo l’abbonamento verrà rinnovato automaticamente il 22/10/2022 secondo le tariffe del nuovo abbonamento. Leggi termini e condizioni`,
        "view.subscription.checkout.not.trial.text.part.2":
            "secondo le tariffe del nuovo abbonamento.",
        "view.subscription.checkout.not.trial.balance.text.part.2": `Al rinnovo del prossimo abbonamento verrà detratto il credito accumulato.`,
        "view.subscription.empty.method.payment":
            "Per procedere al pagamento è necessario inserire un metodo di pagamento",
        "view.subscription.typ": "Pagamento avvenuto con successo!",
        "view.subscription.update.typ": "Abbonamento aggiornato con successo!",
        "view.subscription.typ.button": "Visualizza i dati del tuo abbonamento",
        "view.subscription.error.title": "Ooops pagamento non riuscito!",
        "view.subscription.error.description": `Qualcosa è andato storto... Controlla che i dati della carta siano corretti o che non sia scaduta.
    Se l’errore persiste contatta assistenza@magoot.it`,
        "view.subscription.error.3d": `Metodo di pagamento rifiutato`,
        "view.subscription.error.button": "controlla i dati",
        "view.subscription.delete.error": `C'è stato un problema con la disattivazione del rinnovo, per favore riprova. Se l’errore persiste contatta assistenza@magoot.it`,
        "view.subscription.delete.success": "Rinnovo disattivato con successo!",
        "view.subscription.change.payment.success": "Metodo di pagamento modificato correttamente!",
        "view.subscription.delete.payment.modal.title": "Eliminazione carta",
        "view.subscription.delete.payment.modal.description":
            "Sei sicuro di voler eliminare la carta associata al tuo abbonamento?",
        "view.subscription.change.payment.delete.ok":
            "Metodo di pagamento eliminato correttamente!",
        "view.subscription.change.payment.delete.ko": `C'è stato un problema con l'eliminazione della tua carta, per favore riprova. Se l’errore persiste contatta assistenza@magoot.it`,
        "view.subscription.delete.payment.modal.button": "elimina",
        "view.subscription.empty.billing": "Indirizzo non presente",
    },
};

export type TTranslationsViewSubscription = keyof Translations;
