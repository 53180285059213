import { TOrderStats } from "@magoot-sdk/redux-modules/reducers/reducerStatistics/declarations";

import { Actions, TError } from "../../../utils/Actions";
import {
    TActionOrderStatsFailure,
    TActionOrderStatsRequest,
    TActionOrderStatsSuccess,
} from "./declarations";

export const actionOrderStatsRequest = (payload: {
    idStudio: number;
    idOrder: number;
}): TActionOrderStatsRequest => ({
    type: Actions.orderStatsRequest,
    payload,
});

export const actionOrderStatsSuccess = (payload: {
    idOrder: number;
    stats: TOrderStats;
}): TActionOrderStatsSuccess => ({
    type: Actions.orderStatsSuccess,
    payload,
});

export const actionOrderStatsFailure = (payload: TError): TActionOrderStatsFailure => ({
    type: Actions.orderStatsFailure,
    payload,
});
