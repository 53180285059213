import {
    TParamsApiWorkUpdate,
    TParamsApiWorkUpdateSuccess,
} from "../../../../api/work/workUpdate/params";
import { Actions, TError } from "../../../utils/Actions";
import {
    TActionWorkUpdateFailure,
    TActionWorkUpdateRequest,
    TActionWorkUpdateSuccess,
} from "./declarations";

export const actionWorkUpdateRequest = (
    payload: TParamsApiWorkUpdate
): TActionWorkUpdateRequest => ({
    type: Actions.workUpdateRequest,
    payload,
});

export const actionWorkUpdateSuccess = (
    payload: TParamsApiWorkUpdateSuccess
): TActionWorkUpdateSuccess => ({
    type: Actions.workUpdateSuccess,
    payload,
});

export const actionWorkUpdateFailure = (payload: TError): TActionWorkUpdateFailure => ({
    type: Actions.workUpdateFailure,
    payload,
});
