interface Translations {
    "view.annotation-list.label.no-annotations": string;
    "view.annotation-list.input.placeholder": string;
}

interface ViewAnnotationList {
    it: Translations;
    en: Translations;
}

export const translationsViewAnnotationList: ViewAnnotationList = {
    it: {
        "view.annotation-list.label.no-annotations": "Nessuna annotazione presente per questa fase",
        "view.annotation-list.input.placeholder": "Ricerca per titolo o descrizione annotazione",
    },
    en: {
        "view.annotation-list.label.no-annotations": "No annotations present for this work",
        "view.annotation-list.input.placeholder": "Search for title or description annotation",
    },
};

export type TTranslationsViewAnnotationList = keyof Translations;
