import { Actions } from "../../utils/Actions";
import { TReducerAuth, TReducerAuthActions } from "./declarations";

const initialState = { idStudio: null };

export const reducerAuth = (
    prevStore: TReducerAuth = initialState,
    action: TReducerAuthActions
): TReducerAuth => {
    let newStore = { ...prevStore };

    switch (action.type) {
        case Actions.updateProfiloSuccess:
            return { ...prevStore, ...action.payload, isProfileComplete: true };
        case Actions.attivaAccountRequest:
            newStore = initialState;
            newStore.isActivatingAccount = true;
            return newStore;
        case Actions.resetActivatedAccount:
            delete newStore.hasActivatedAccount;
            return newStore;
        case Actions.attivaAccountFailure:
            delete newStore.isActivatingAccount;
            newStore.activationErrors = action.payload.error;
            return newStore;
        case Actions.resetActivationErrors:
            delete newStore.activationErrors;
            return newStore;
        case Actions.attivaAccountSuccess:
            delete newStore.isActivatingAccount;
            newStore.hasActivatedAccount = true;
            return newStore;
        case Actions.createLoginFailure:
        case Actions.createSignupFailure:
            newStore.isLoading = false;
            return newStore;
        case Actions.createLoginRequest:
            const store = { ...newStore, isLoading: true };
            return store;
        case Actions.createLoginSuccess:
            const payload = {
                ...action.payload,
                isLoading: false,
            };
            return payload;
        case Actions.refreshTokenSuccess:
            newStore = { ...newStore, ...action.payload };
            return newStore;
        case Actions.createStudioSuccess:
            newStore.hasOwnStudio = true;
            const newStudio = {
                denominazione: action.payload.denominazione,
                id: action.payload.id,
                srcImage: "",
                creazioneCommessaCollaboratoreEsterno:
                    action.payload.creazioneCommessaCollaboratoreEsterno,
            };
            newStore.ownStudioDetail = newStudio;
            return newStore;
        case Actions.uploadUserImageSuccess:
            const newUrlProfile = action.payload;
            newStore.srcImage = newUrlProfile;
            return newStore;
        case Actions.uploadStudioImageSuccess:
            const newUrlStudio = `${action.payload}${`?${Date.now()}`}`;
            // @ts-ignore
            newStore.ownStudioDetail = prevStore.ownStudioDetail || {};
            // @ts-ignore
            newStore.ownStudioDetail.srcImage = newUrlStudio;
            return newStore;
        case Actions.userDetailSuccess:
            return { ...prevStore, ...action.payload };
        case Actions.updateStudioSuccess:
            // @ts-ignore
            if (newStore?.ownStudioDetail?.denominazione) {
                newStore.ownStudioDetail.denominazione = action?.payload?.denominazione ?? "";
            }
            return newStore;
        case Actions.sessionExpired:
        case Actions.createLogoutSuccess:
            return initialState;
        default:
            return prevStore;
    }
};
