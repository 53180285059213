import { TStore } from "../reducers/declarations";

export const selectorToastVariant = (store: TStore): "success" | "error" | "info" | undefined =>
    store.toast?.category;

export const selectorToastMessage = (store: TStore): string => store.toast?.message || "";

export const selectorToastTranslateMessage = (store: TStore): boolean =>
    store.toast?.intlFormatter || false;

export const selectorToastTimer = (store: TStore): number => store.toast?.timer ?? 1000;

export const selectorToastIsVisible = (store: TStore): boolean =>
    (!!store.toast?.category && !!store.toast?.message) || false;
