import { createUploadHeader } from "./config";

const fetchUPLOAD = async (url: string, params: any): Promise<any> => {
    const response: string = await new Promise((resolve, _) => {
        const xml = new XMLHttpRequest();
        xml.onload = (): void => {
            resolve(xml.responseText);
        };
        xml.open("POST", url, true);
        const header = createUploadHeader();
        xml.setRequestHeader("Authorization", header.Authorization);
        xml.send(params);
    });
    return response ? JSON.parse(response) : null;
};

export default fetchUPLOAD;
