interface Translations {
    "view.studio-list.share": string;
    "view.studio-list.button.enter-your-studio": string;
    "view.studio-list.button.collaborate-with": string;
    "view.studio-list.occupied": string;
    "view.studio-list.other-team-members": string;
    "view.studio-list.try-magoot": string;
    "view.studio-list.button.studio-create": string;
    "view.studio-list.studio-create-description": string;
}

interface ViewStudioList {
    it: Translations;
    en: Translations;
}

export const translationsViewStudioList: ViewStudioList = {
    it: {
        "view.studio-list.share": "Share the love",
        "view.studio-list.button.enter-your-studio": "Entra nel tuo studio",
        "view.studio-list.button.collaborate-with": "Collabora con",
        "view.studio-list.occupied": "occupati",
        "view.studio-list.other-team-members": "Altri membri del team",
        "view.studio-list.try-magoot": "Prova gratis Magoot per 1 anno!",
        "view.studio-list.button.studio-create": "Crea il tuo studio",
        "view.studio-list.studio-create-description":
            "Cliccando sul pusante CREA IL TUO STUDIO avrai la possibilità di teletrasportare il tuo studio nel futuro per 1 anno e capire perchè non potrai più fare a meno di Magoot!",
    },
    en: {
        "view.studio-list.share": "Share the love",
        "view.studio-list.button.enter-your-studio": "Enter your studio",
        "view.studio-list.button.collaborate-with": "Collaborate with",
        "view.studio-list.occupied": "occupied",
        "view.studio-list.other-team-members": "Other team members",
        "view.studio-list.try-magoot": "Try Magoot for free for 15 days!",
        "view.studio-list.button.studio-create": "Create your studio",
        "view.studio-list.studio-create-description":
            "By clicking on the CREATE YOUR STUDIO button you will have the opportunity to teleport your studio into the future for 15 days and understand why you will no longer be able to do without Magoot!",
    },
};

export type TTranslationsViewStudioList = keyof Translations;
