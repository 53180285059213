// #region ::: IMPORT
import { Toolbar } from "@components-layout/Toolbar";
import { Sidebar } from "@components-view/home/ViewDashboard/components/Sidebar";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars, faBullhorn, faCommentCheck, faLifeRing } from "@fortawesome/pro-light-svg-icons";
import avatarPlaceholder from "assets/avatar-placeholder.png";
import logoXs from "assets/logo-xs-magoot.svg";
import logoLg from "assets/magoot-logo-bianco.png";
import React, {
    Dispatch,
    FC,
    MutableRefObject,
    SetStateAction,
    memo,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useProductFruitsApi } from "react-product-fruits";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { useBreakpoint } from "theme/mediaQuery/breakpoints";

import { useSubscription } from "@magoot-sdk/redux-modules/hooks/subscription/useSubscription";
import { useOrderID } from "@magoot-sdk/redux-modules/hooks/useOrderID";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { TStore } from "@magoot-sdk/redux-modules/reducers/declarations";
import { selectorArrayNotificationUnread } from "@magoot-sdk/redux-modules/selectors/selectorNotifications";
import {
    selectorIsAuthenticated,
    selectorIsProfileComplete,
    selectorUserAvatar,
} from "@magoot-sdk/redux-modules/selectors/selectorsAuth";
import {
    isAccount,
    isDashboard,
    isOrder,
    isOrderList,
    isProfile,
    isWork,
    utilityGetUrlViewHome,
} from "@magoot-sdk/utils/Routes";
import { utilityGetUrlEndPoint } from "@magoot-sdk/utils/helpers/getUrlEndPoint";

import { UIBadge } from "@components-ui/Badge";
import { UIIcon } from "@components-ui/Icon";
import { UISidebar } from "@components-ui/Sidebar";

import "./navbar.scss";
import { Breadcrumb } from "./partials/Breadcrumb";
import { LinkProfile } from "./partials/LinkProfile";
import { NotificationNavbar } from "./partials/NotificationNavbar";
import { DropdownNotification } from "./partials/NotificationNavbar/partials/DropdownNotification";

// #endregion

interface Prop {
    setFeedbackModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const Navbar: FC<Prop> = memo(({ setFeedbackModalVisible }): JSX.Element | null => {
    const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);
    const [isVisibleProfile, setIsVisibleProfile] = useState(false);
    const { idOrder } = useOrderID();
    const { idCustomer }: any = useParams();
    const location = useLocation();
    const linkHome = utilityGetUrlViewHome();
    const history = useHistory();
    const isAuthenticated = useSelector(selectorIsAuthenticated);
    const isProfileComplete = useSelector(selectorIsProfileComplete);
    const { hasToUpgrade, onUpgrade } = useSubscription({ code: "NOTIFICATIONS" });
    const { idStudio } = useStudioID();
    const isViewHome = location.pathname === linkHome;
    const point = useBreakpoint();
    const isMobile = point === "md" || point === "sm" || point === "xs" || point === "lg";
    const isIconMobile = point === "md" || point === "sm" || point === "xs";
    const checkIsOrder = useMemo(() => isOrder(location), [location]);
    const checkIsWork = useMemo(() => isWork(location), [location]);
    const checkIsProfile = useMemo(() => isProfile(location), [location]);
    const checkIsAccount = useMemo(() => isAccount(location), [location]);
    const checkIsOrderList = useMemo(() => isOrderList(location), [location]);
    const checkIsDashboard = useMemo(() => isDashboard(location), [location]);
    const drawerRef: MutableRefObject<HTMLInputElement | undefined> = useRef<HTMLInputElement>();
    const drawerRefDashboard: MutableRefObject<HTMLInputElement | undefined> =
        useRef<HTMLInputElement>();
    const value =
        (checkIsOrder && "order") ||
        (checkIsWork && "work") ||
        (checkIsDashboard && "dashboard") ||
        "order";
    const [subMenuState, setSubMenuState] = useState<"order" | "work" | "dashboard">(value);
    const [unreadCount, setUnreadCount] = useState(0);

    const onNavigateToHome = (): void => history.push(utilityGetUrlViewHome());

    const customLauncher = document.getElementById("annunci");
    const src = useSelector(selectorUserAvatar);

    const srcAvatar = useMemo(() => {
        return src ? `${utilityGetUrlEndPoint()}${src}?${Date.now()}` : avatarPlaceholder;
    }, [src]);

    const onToggleNotification = (): void => {
        if (hasToUpgrade) onUpgrade("NOTIFICATIONS");
        else {
            setIsVisibleProfile(false);
            setIsVisibleDropdown(!isVisibleDropdown);
        }
    };

    const onToggleProfile = (): void => {
        setIsVisibleDropdown(false);
        setIsVisibleProfile(!isVisibleProfile);
    };

    const arrayNotificationUnread = useSelector((store: TStore) =>
        selectorArrayNotificationUnread({ store, idStudio })
    );

    const onClickOutside = (): void => {
        setIsVisibleDropdown(false);
        setIsVisibleProfile(false);
    };

    const closeWhenClickedOutside = (event): void => {
        if (!event.target.closest(".dropdown")) {
            setIsVisibleDropdown(false);
            setIsVisibleProfile(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", closeWhenClickedOutside);
    }, [isVisibleDropdown]);

    useEffect(() => {
        if (checkIsOrder) setSubMenuState("order");
        if (checkIsWork) setSubMenuState("work");
        if (checkIsDashboard) setSubMenuState("dashboard");
    }, [location]);

    useProductFruitsApi(
        (api) => {
            if (customLauncher) {
                api?.announcementsV2?.attachNewsWidgetToElement(customLauncher);
                api?.announcementsV2?.listen("newsfeed-unread-count-changed", (data) => {
                    setUnreadCount(data?.count);
                });
            }
        },
        [customLauncher]
    );

    const handleGoWork = () => {
        setSubMenuState("work");
    };

    const handleGoOrder = () => {
        setSubMenuState("order");
    };

    return (
        <>
            {(isAuthenticated || isProfileComplete) && (
                <div className='drawer h-[4rem] w-full shadow'>
                    {(subMenuState === "order" || subMenuState === "work") && (
                        <input
                            id='my-drawer-3'
                            ref={drawerRef as MutableRefObject<HTMLInputElement>}
                            name='my-drawer-3'
                            type='checkbox'
                            className='drawer-toggle'
                        />
                    )}
                    {subMenuState === "dashboard" && (
                        <input
                            id='my-drawer-4'
                            name='my-drawer-4'
                            type='checkbox'
                            className='drawer-toggle'
                            ref={drawerRefDashboard as MutableRefObject<HTMLInputElement>}
                        />
                    )}

                    <div className='w-screen relative block'>
                        <div className='sm-space-x-20 relative flex w-full items-center justify-between space-x-8 bg-secondaryDark px-12 xl:px-20 h-full'>
                            <div className='flex w-2/3 flex-1 justify-start space-x-16'>
                                <div className='flex-x-12 flex w-fit items-center'>
                                    {location.pathname !== "/" &&
                                        (checkIsOrder ||
                                            checkIsWork ||
                                            checkIsOrderList ||
                                            checkIsDashboard) && (
                                            <div className='h-full flex-1 text-white xl:hidden'>
                                                {subMenuState === "dashboard" && (
                                                    <label
                                                        htmlFor='my-drawer-4'
                                                        className='btn btn-ghost btn-square mr-12 h-full w-full'
                                                    >
                                                        <UIIcon
                                                            size='lg'
                                                            svgIcon={faBars as IconProp}
                                                            title='menu'
                                                            color='#fff'
                                                        />
                                                    </label>
                                                )}

                                                {(subMenuState === "order" ||
                                                    subMenuState === "work") && (
                                                    <label
                                                        htmlFor='my-drawer-3'
                                                        className='btn btn-ghost btn-square btn-lg h-full w-full'
                                                    >
                                                        <UIIcon
                                                            size='lg'
                                                            svgIcon={faBars as IconProp}
                                                            title='apertura sidenav'
                                                            color='#fff'
                                                        />
                                                    </label>
                                                )}
                                            </div>
                                        )}

                                    <div
                                        className='h-auto w-full cursor-pointer'
                                        onClick={onNavigateToHome}
                                    >
                                        <img
                                            src={isIconMobile ? logoXs : logoLg}
                                            className={`
                       w-auto object-contain sm:h-32 
                       lg:h-auto lg:w-full
                       ${checkIsDashboard || checkIsProfile || checkIsAccount ? "h-40" : "h-28"} 
                      `}
                                            alt='logo magoot'
                                        />
                                    </div>
                                </div>

                                {!isViewHome && <Breadcrumb />}
                            </div>

                            {!isIconMobile && (
                                <div className='flex w-fit items-center justify-end space-x-8 sm:space-x-20 pr-20 relative text-sm text-white'>
                                    <div
                                        id='annunci'
                                        className='flex items-center flex-col gap-4 cursor-pointer'
                                    >
                                        <UIIcon
                                            svgIcon={faBullhorn as IconProp}
                                            color='#fff'
                                            title='annunci'
                                        />
                                        <div className='flex items-center gap-4'>
                                            <span>Annunci</span>
                                            {unreadCount > 0 && (
                                                <UIBadge
                                                    text={unreadCount?.toString()}
                                                    backgroundColor='error'
                                                    color='white'
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        className='flex items-center flex-col gap-4 cursor-pointer'
                                        onClick={() =>
                                            window.open("https://supporto.magoot.it/", "_blank")
                                        }
                                    >
                                        <UIIcon
                                            svgIcon={faLifeRing as IconProp}
                                            color='white'
                                            title='invia un ticket'
                                        />
                                        <p>Supporto</p>
                                    </div>
                                    <div
                                        className='flex items-center flex-col gap-4 cursor-pointer'
                                        onClick={() => setFeedbackModalVisible(true)}
                                    >
                                        <UIIcon
                                            svgIcon={faCommentCheck as IconProp}
                                            color='white'
                                            title='lascia un feedback'
                                        />
                                        <p>Feedback</p>
                                    </div>
                                </div>
                            )}

                            <div className='flex w-fit items-center justify-end space-x-4 sm:space-x-12 z-0'>
                                {location.pathname !== "/" && (
                                    <NotificationNavbar onToggleDropdown={onToggleNotification} />
                                )}

                                <div
                                    className='dropdown-hover avatar dropdown flex h-40 w-40 cursor-pointer items-center justify-center'
                                    onClick={onToggleProfile}
                                >
                                    <div className='h-full w-full rounded-full'>
                                        <img
                                            src={srcAvatar}
                                            className='h-full w-full select-none object-cover'
                                            alt='immagine profilo'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {subMenuState === "dashboard" && (
                        <UISidebar drawerRef={drawerRefDashboard} isMobile>
                            <Sidebar drawerRef={drawerRefDashboard} />
                        </UISidebar>
                    )}

                    {(subMenuState === "order" || subMenuState === "work") && isMobile && (
                        <Toolbar
                            isMobile
                            idStudio={idStudio}
                            idCustomer={idCustomer}
                            idOrder={idOrder}
                            locationPathname={location.pathname}
                            subMenuState={subMenuState}
                            drawerRef={drawerRef}
                            goOrder={handleGoOrder}
                            goWork={handleGoWork}
                            expanded
                            setExpanded={() => {}}
                        />
                    )}
                </div>
            )}

            {isVisibleDropdown && (
                <DropdownNotification
                    onClickOutside={onClickOutside}
                    notificationList={arrayNotificationUnread}
                />
            )}

            {isVisibleProfile && <LinkProfile onClickOutside={onClickOutside} />}
        </>
    );
});

Navbar.displayName = "Navbar";
