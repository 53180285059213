// #region ::: IMPORT
import React, { FC, LazyExoticComponent, memo } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";

import { selectorIsAuthenticated } from "@magoot-sdk/redux-modules/selectors/selectorsAuth";
import { utilityGetUrlViewHome } from "@magoot-sdk/utils/Routes";

// #endregion

interface Props {
    path: string;
    component: LazyExoticComponent<React.ComponentType<any>>;
    exact?: boolean;
}

export const RouteLogged: FC<Props> = memo(
    ({ component: Component, path, exact }: Props): JSX.Element => {
        const isAuthenticated = useSelector(selectorIsAuthenticated);

        return (
            <Route
                exact={exact}
                path={path}
                render={(props): JSX.Element =>
                    isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to={utilityGetUrlViewHome()} />
                    )
                }
            />
        );
    }
);
RouteLogged.displayName = "RouteLogged";
