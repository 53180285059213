import { Actions } from "../../utils/Actions";
import { TReducerDocuments, TReducerDocumentsActions } from "./declarations";

export const reducerDocuments = (
    prevStore: TReducerDocuments = {},
    action: TReducerDocumentsActions
): TReducerDocuments => {
    const newStore = { ...prevStore };

    switch (action.type) {
        case Actions.documentListSuccess:
            newStore[action.payload.idOrder] = {
                ...newStore[action.payload.idOrder],
                [action.payload.idEntity]: action.payload.documents,
            };
            return newStore;
        case Actions.documentUploadCustomerSuccess:
            if (
                newStore[action.payload?.idOrder] &&
                newStore[action.payload?.idEntity] &&
                newStore[action.payload?.idOrder][action.payload?.idEntity]
            ) {
                newStore[action.payload.idOrder][action.payload.idEntity] = {
                    ...newStore[action.payload?.idOrder][action.payload?.idEntity],
                    ...action.payload.documents,
                };
            } else {
                newStore[action.payload?.idOrder] = {
                    ...newStore[action.payload?.idOrder],
                    [action.payload?.idEntity]: action.payload.documents,
                };
            }
            return newStore;
        case Actions.documentDeleteCustomerSuccess:
            if (
                newStore[action.payload.idOrder] &&
                newStore[action.payload.idOrder][action.payload.idEntity] &&
                newStore[action.payload.idOrder][action.payload.idEntity][action.payload.idDocument]
            ) {
                delete newStore[action.payload.idOrder][action.payload.idEntity][
                    action.payload.idDocument
                ];
            }

            return newStore;
        case Actions.createLogoutSuccess:
            return {};
        default:
            return prevStore;
    }
};
