import { useLocation } from "react-router-dom";

export const useOrderID = (): { idOrder: number } => {
    const { pathname } = useLocation();
    const pathSplitted = pathname.split("/");
    const idOrder = pathSplitted.includes("esportazione-commessa")
        ? NaN
        : parseInt(pathSplitted[3], 10);
    if (typeof idOrder !== "number") throw new Error("idOrder not found on URL params");
    return { idOrder };
};
