export const utilityGetRemainingTimeBetweenTwoDates = (
    startDate: Date,
    endDate: Date
): { days: number; hours: number; minutes: number } => {
    const startDateTimeStamp = startDate.getTime();
    const endDateTimeStamp = endDate.getTime();
    let diffInMilliSeconds =
        !!endDate && !!startDate && Math.abs(endDate.valueOf() - startDate.valueOf()) / 1000;
    let days = 0;
    let hours = 0;
    let minutes = 0;

    if (endDateTimeStamp > startDateTimeStamp) {
        days = -1;
        hours = -1;
        minutes = -1;
    }

    if (startDateTimeStamp > endDateTimeStamp) {
        // calculate days
        days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        // calculate hours
        hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        // calculate minutes
        minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
    }

    return { days, hours, minutes };
};
