// #region ::: IMPORT
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, memo } from "react";

// #endregion

export interface Props {
    svgIcon: IconProp;
    color?: string;
    size?: "xs" | "sm" | "md" | "lg" | SizeProp;
    colorHover?: string;
    isHover?: boolean;
    title?: string;
    onClick?: () => any;
}

export const UIIcon: FC<Props> = memo(
    ({
        svgIcon,
        color = "rgb(121, 121, 121)",
        size = "lg",
        title,
        colorHover,
        isHover = false,
        onClick,
    }: Props): JSX.Element => {
        return (
            <div
                className={`flex h-fit w-fit items-center justify-between p-0 transition-all active:border-tundora-200 active:bg-transparent 
          ${onClick ? "cursor-pointer" : ""}
        `}
                onClick={onClick}
            >
                <FontAwesomeIcon
                    fixedWidth
                    icon={svgIcon}
                    color={isHover ? colorHover : color}
                    size={size === "md" ? "1x" : size}
                    title={title}
                />
            </div>
        );
    }
);

UIIcon.displayName = "UIIcon";
