import { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { actionAddressBookListRequest } from "@magoot-sdk/redux-modules/actions/addressBook/actionAddressBookList";
import { actionRootDictionaries } from "@magoot-sdk/redux-modules/actions/dictionary-system/actionRootDictionaries";
import { actionUtentiDirittiCommessaRequest } from "@magoot-sdk/redux-modules/actions/diritti";
import { actionOrderStatsRequest } from "@magoot-sdk/redux-modules/actions/order/actionOrderStats";
import { actionUserListRequest } from "@magoot-sdk/redux-modules/actions/user/actionUserList";
import { actionWorkListRequest } from "@magoot-sdk/redux-modules/actions/work/actionWorkList";
import {
    actionAnnotationSummaryRequest,
    actionDocumentSummaryRequest,
    actionInspectionSummaryRequest,
    actionSummaryOfCountRequest,
} from "@magoot-sdk/redux-modules/actions/work/actionWorkSummary";
import { useOrderID } from "@magoot-sdk/redux-modules/hooks/useOrderID";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { useWorkID } from "@magoot-sdk/redux-modules/hooks/useWorkId";
import { selectorUserID } from "@magoot-sdk/redux-modules/selectors/selectorsAuth";
import { useDispatch } from "@magoot-sdk/redux-modules/utils/useDispatch";

interface Props {
    children: JSX.Element;
}
export const Listener: FC<Props> = ({ children }): JSX.Element => {
    const dispatch = useDispatch();
    const { idOrder } = useOrderID();
    const { idStudio } = useStudioID();
    const { idWork } = useWorkID();
    const idUtente = useSelector(selectorUserID);

    useEffect(() => {
        if (idStudio && idStudio !== 0) {
            dispatch(actionAddressBookListRequest({ idStudio }));
            dispatch(actionRootDictionaries({ idStudio }));
            dispatch(actionUserListRequest({ idStudio }));
        }
    }, [dispatch, idStudio]);

    useEffect(() => {
        if (idStudio && idOrder) {
            dispatch(actionWorkListRequest({ idStudio, idOrder, idWork }));
            dispatch(actionOrderStatsRequest({ idOrder, idStudio }));
            dispatch(
                actionUtentiDirittiCommessaRequest({
                    idStudio,
                    idCommessa: idOrder,
                    idUtente,
                })
            );
        }
    }, [dispatch, idOrder, idStudio]);

    useEffect(() => {
        if (idStudio && idOrder) {
            dispatch(
                actionDocumentSummaryRequest({
                    idStudio,
                    idOrder,
                })
            );

            dispatch(
                actionInspectionSummaryRequest({
                    idStudio,
                    idOrder,
                })
            );

            dispatch(
                actionAnnotationSummaryRequest({
                    idStudio,
                    idOrder,
                })
            );
        }

        if (idStudio && idOrder && idWork) {
            dispatch(actionSummaryOfCountRequest({ idStudio, idOrder, idWork }));
        }
    }, [dispatch, idWork, idOrder]);

    return children;
};
