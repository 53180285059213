import { Actions } from "../../utils/Actions";
import { TReducerMail, TReducerMailActions } from "./declaration";

export const reducerMail = (
    prevStore: TReducerMail = {},
    action: TReducerMailActions
): TReducerMail => {
    let newStore = { ...prevStore };

    switch (action.type) {
        case Actions.mailFileListSuccess:
            // @ts-ignore
            newStore = action.payload;
            return newStore;
        default:
            return newStore;
    }
};
