import { Actions } from "../../utils/Actions";

export function reducerAppReady(prevStore: boolean = true, action: any): boolean {
    switch (action.type) {
        case Actions.asyncResourcesLoaded:
            return true;
        default:
            return prevStore;
    }
}
