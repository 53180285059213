interface Translations {
    "view.signup.title.signup-successful": string;
    "view.signup.description.signup-successful": string;
    "view.signup.do-it-first": string;
    "view.signup.enter-magoot": string;
    "view.signup.description": string;
    "view.signup.privacy-policy": string;
}

interface ViewSignup {
    it: Translations;
    en: Translations;
}

export const translationsViewSignUp: ViewSignup = {
    it: {
        "view.signup.title.signup-successful": "Registrazione effettuata con successo!",
        "view.signup.description.signup-successful":
            "Per attivare il tuo account segui le indicazioni riportate nella mail che ti abbiamo appena inviato!",
        "view.signup.do-it-first": "Fai prima,",
        "view.signup.enter-magoot": "Entra in Magoot",
        "view.signup.description": "Non devi pagare ora. Cancellati quando vuoi.",
        "view.signup.privacy-policy":
            "Accedendo e registrandoti dichiari di aver preso visione ed accettato i termini e condizioni. " +
            "I tuoi dati personali saranno trattati in conformità con la privacy policy.\n" +
            "Potrai formulare le tue preferenze circa il trattamento dei tuoi dati nella successiva schermata.",
    },
    en: {
        "view.signup.title.signup-successful": "Registration complete successfully!",
        "view.signup.description.signup-successful":
            "To activate your account, follow the instructions in the email we just sent you!",
        "view.signup.do-it-first": "Do it first,",
        "view.signup.enter-magoot": "Enter in Magoot",
        "view.signup.description": "Cancel subscription whenever you want.",
        "view.signup.privacy-policy":
            "By accessing and registering, you declare that you have read and accepted the terms and conditions. " +
            "Your personal data will be treated in accordance with the privacy policy.\n" +
            "You can formulate your preferences regarding the processing of your data in the following screen.",
    },
};

export type TTranslationsViewSignup = keyof Translations;
