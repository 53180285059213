import { TStore } from "../reducers/declarations";
import { TOrder } from "../reducers/reducerOrder/declarations";

export const selectorOrderList = (store: TStore): TOrder[] => {
    const orderData = store.orders || {};
    const orderList = Object.values(orderData);

    if (orderList.length > 0) return orderList;
    return [];
};

export const selectorOrderListLoading = (store: TStore): boolean =>
    store.ui?.isLoadingOrders || false;

export const selectorOrderName = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): string => {
    if (!store.orders) return "";
    if (!store.orders[idOrder]) return "";
    return store.orders[idOrder].titolo;
};

export const selectorOrderNotes = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): string => {
    if (!store.orders) return "";
    if (!store.orders[idOrder]) return "";
    if (!store.orders[idOrder]?.numeroNote) return "";
    return store?.orders[idOrder]?.numeroNote?.toString();
};
