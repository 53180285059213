import {
    ORDER_STATUS_DONE,
    ORDER_STATUS_PROGRESS,
    ORDER_STATUS_SUSPENDED,
    ORDER_STATUS_TO_DO,
    OrderStatus,
} from "@magoot-sdk/constants/general";

import { TTask } from "../../redux-modules/reducers/reducerTask/declarations";

export const utilityGetTaskStatusDescription = (status: TTask["statoEsecuzione"]["id"]): string => {
    switch (status) {
        case OrderStatus.DaFare:
            return ORDER_STATUS_TO_DO;

        case OrderStatus.InCorso:
            return ORDER_STATUS_PROGRESS;
        case OrderStatus.Sospesa:
            return ORDER_STATUS_SUSPENDED;
        case OrderStatus.Completata:
            return ORDER_STATUS_DONE;

        default:
            throw new Error(`Task status ${status} not valid `);
    }
};
