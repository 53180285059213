import { TStore } from "../reducers/declarations";
import { TReducerUsers, TUser } from "../reducers/reducerUsers/declarations";

export const getUsers = (store: TStore): TReducerUsers | {} => store.users || {};

export const getUserRole = (store: TStore, user: TUser): string => {
    const operatorTypes = store.dictionaries?.operator || {};
    const values = Object.values(operatorTypes);
    const operatorType = values.find((value) => value.id === user.ruolo);
    if (operatorType) {
        return operatorType.descr;
    }
    return "";
};
