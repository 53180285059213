import { useEffect } from "react";
import smartlookClient from "smartlook-client";

interface Prop {
    userEmail?: string;
    nameUser?: string;
    lastNameUser?: string;
}

export const useSmartlookClient = ({ userEmail, nameUser, lastNameUser }: Prop): void => {
    useEffect(() => {
        if (userEmail) {
            smartlookClient.identify(userEmail.toLowerCase(), {
                email: userEmail,
                name: `${nameUser} ${lastNameUser}`,
            });
        }
    }, [userEmail]);
};
