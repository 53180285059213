// #region ::: IMPORT
import React, { FC, memo } from "react";

import { translate } from "@magoot-sdk/i18n/translate";
import { actionWorkDeleteRequest } from "@magoot-sdk/redux-modules/actions/work/actionWorkDelete";
import { useLocale } from "@magoot-sdk/redux-modules/hooks/ui/useLocale";
import { useOrderID } from "@magoot-sdk/redux-modules/hooks/useOrderID";
import { useStudioID } from "@magoot-sdk/redux-modules/hooks/useStudioID";
import { useDispatch } from "@magoot-sdk/redux-modules/utils/useDispatch";

import { UIButton } from "@components-ui/Button";

// #endregion

interface Props {
    idWork: number;
    onCloseModal: () => void;
}

export const WorkDelete: FC<Props> = memo(({ idWork, onCloseModal }) => {
    const locale = useLocale();
    const dispatch = useDispatch();
    const { idStudio } = useStudioID();
    const { idOrder } = useOrderID();
    const textWorkDelete = translate({ locale, id: "view-order.work.modal.work-delete" });

    const onDelete = (): void => {
        dispatch(
            actionWorkDeleteRequest({
                idStudio,
                idOrder,
                idWork,
            })
        );
        onCloseModal();
    };

    return (
        <UIButton
            text={textWorkDelete}
            onClick={onDelete}
            dataCy='TO_DO'
            variant='link'
            textAlign='right'
        />
    );
});

WorkDelete.displayName = "WorkDelete";
